import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';

const Tag = ({model, category, withCategory, small}) => {
	const classes = classnames('ptr-Tag', {
		'is-small': small,
	});
	return (
		<div className={classes}>
			{withCategory ? (
				<span className="ptr-Tag-title">{category?.data?.nameDisplay}: </span>
			) : null}
			<span className="ptr-Tag-name">{model?.data?.nameDisplay}</span>
		</div>
	);
};

Tag.propTypes = {
	model: PropTypes.object,
	withCategory: PropTypes.bool,
	category: PropTypes.object,
	small: PropTypes.bool,
};

export default Tag;
