import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setMapView: place => {
			dispatch(Action.unhab.updateMapView(ownProps.mapKey, place));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
