export default {
	componentKey: "landConsumptionRate_populationGrowthRate",
	title: 'Land Consumption Rate/Population Growth Rate',
	configuration: {
		valueFormat: {
			decimals: 3,
		},
		attributes: [
			'3483d7cc-1cde-4a98-b09a-7dddc0ee16f4', // GHSL 1975-2030
			'0432a5e6-f169-45c1-bb6a-c7407c41ea45' // WSF 2016-2023 + WSF 2016-2023 Population
		],
		type: 'ratesRatio',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'rate',
				description: 'Indicator 2 description',
			},
		],
	},
};
