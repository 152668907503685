export default [
	{
		key: 'wsfBuildUp2016_2023-ghsBuiltUpSurface',
		data: {
			nameInternal: "WSF 2016-2023 (10m) + GHSL 1975-2030 (100m)",
			datasets: ['wsfBuildUp2016_2023', 'ghsBuiltUpSurface'],
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
		},
	},
	{
		key: 'wsfPopulation2016_2023-ghsPopulationGrid',
		data: {
			nameInternal: "WSF 2016-2023 (10m) population + GHSL 1975-2030 (100m) population",
			datasets: ['wsfPopulation2016_2023', 'ghsPopulationGrid'],
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
		},
	},
	{
		key: 'wsfEvolution1985_2023-ghsBuiltUpSurface',
		data: {
			nameInternal: "WSF Evolution 1985-2023 - Built-up (10m) + GHSL 1975-2030 (100m)",
			datasets: ['wsfEvolution1985_2023', 'ghsBuiltUpSurface'],
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
		},
	},
	{
		key: 'wsfEvolution1985_2015-ghsBuiltUpSurface',
		data: {
			nameInternal: "WSF Evolution 1985-2015 - Built-up (30m) + GHSL 1975-2030 (100m)",
			datasets: ['wsfEvolution1985_2015', 'ghsBuiltUpSurface'],
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
		},
	},
	{
		key: 'wsfEvolution1985_2023-wsfEvolution1985_2023',
		data: {
			nameInternal: "WSF Evolution 1985-2023 - Built-up (10m) + WSF Evolution 1985-2015 - Built-up (30m)",
			datasets: ['wsfEvolution1985_2023', 'wsfEvolution1985_2015'],
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
		},
	},
];
