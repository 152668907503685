import PropTypes from 'prop-types';
import {createElement, useEffect, useState} from 'react';
import Chip, {ChipsContainer, ChipsContainerTools} from '../Chip';
import SelectedDatasetLabel from '../SelectedDatasetLabel';
import DatasetTags from '../DatasetTags';
import SmallCircularButton from '../SmallCircularButton';
import ModalWindow from '../ModalWindow';
import DatasetInfo from '../DatasetInfo';
import './style.scss';

const SelectedDatasetsControl = ({
	activeSelectionKeys,
	activeDatasetKeys,
	availableDatasets,
	setActiveKeys,
	darkMode,
	onMount,
	ChangeDatasetDialog,
	enableChange,
	disableRemove,
	simpleList,
	comparingDatasets,
	onActiveDatasetsChange,
}) => {
	const [activeDatasets, setActiveDatasets] = useState(activeDatasetKeys);
	const [datasetAddControlOpen, setDatasetAddControlOpen] = useState(false);
	const [datasetChangeControlOpen, setDatasetChangeControlOpen] =
		useState(false);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	useEffect(() => {
		if (!comparingDatasets) {
			if (availableDatasets?.length) {
				if (activeDatasetKeys?.length) {
					const filteredActiveDatasetKeys = activeDatasetKeys.filter(
						datasetKey =>
							availableDatasets?.find(dataset => dataset?.key === datasetKey),
					);
					if (filteredActiveDatasetKeys?.length) {
						setActiveKeys(filteredActiveDatasetKeys);
					} else {
						setActiveKeys([availableDatasets[0]?.key]);
					}
				} else {
					setActiveKeys([availableDatasets[0]?.key]);
				}
			} else {
				setActiveKeys([]);
			}
		}
	}, [availableDatasets, comparingDatasets]);

	useEffect(() => {
		if (JSON.stringify(activeDatasetKeys) !== JSON.stringify(activeDatasets)) {
			if (onActiveDatasetsChange) {
				onActiveDatasetsChange();
			}
			setActiveDatasets(activeDatasetKeys);
		}
	}, [activeDatasetKeys]);

	const onDatasetsRemove = datasetKey => {
		if (!datasetKey) {
			setActiveKeys([]);
		} else if (datasetKey?.length) {
			const updatedKeys = activeDatasetKeys.filter(key => key !== datasetKey);
			setActiveKeys(updatedKeys);
		}
	};

	return (
		<>
			<ChipsContainer>
				{activeDatasetKeys?.length > 0 &&
					activeDatasetKeys?.map(datasetKey => {
						if (simpleList) {
							return (
								<SelectedDatasetLabel
									key={datasetKey}
									datasetKey={datasetKey}
								/>
							);
						} else {
							return (
								<Chip
									key={datasetKey}
									removable={!disableRemove && activeDatasetKeys?.length !== 1}
									onRemove={evt => {
										onDatasetsRemove(datasetKey), evt.stopPropagation();
									}}
								>
									<SelectedDatasetLabel datasetKey={datasetKey}>
										<DatasetTags datasetKey={datasetKey} />
										<DatasetInfo datasetKey={datasetKey} />
									</SelectedDatasetLabel>
								</Chip>
							);
						}
					})}
				{!simpleList && (
					<ChipsContainerTools>
						{activeDatasetKeys?.length === 1 || enableChange ? (
							<SmallCircularButton
								className="change"
								icon="ri-change"
								onClick={() => setDatasetChangeControlOpen(true)}
								tooltip={{
									text: 'Change dataset',
									id: 'ChangeDataset',
									position: 'bottom',
								}}
							/>
						) : null}
						{activeSelectionKeys?.length === 1 ? (
							<SmallCircularButton
								icon="ri-add"
								onClick={() => setDatasetAddControlOpen(true)}
								tooltip={{
									text: 'Add dataset',
									id: 'AddDataset',
									position: 'bottom',
								}}
							/>
						) : null}
					</ChipsContainerTools>
				)}
			</ChipsContainer>
			<ModalWindow
				isOpen={datasetChangeControlOpen}
				isDark={darkMode}
				onClose={() => setDatasetChangeControlOpen(false)}
			>
				{createElement(ChangeDatasetDialog, {
					onClose: () => setDatasetChangeControlOpen(false),
				})}
			</ModalWindow>
			<ModalWindow
				isOpen={datasetAddControlOpen}
				isDark={darkMode}
				onClose={() => setDatasetAddControlOpen(false)}
			>
				{createElement(ChangeDatasetDialog, {
					onClose: () => setDatasetAddControlOpen(false),
					allowMultiple: true,
				})}
			</ModalWindow>
		</>
	);
};

SelectedDatasetsControl.propTypes = {
	activeSelectionKeys: PropTypes.array,
	activeDatasetKeys: PropTypes.array,
	availableDatasets: PropTypes.array,
	darkMode: PropTypes.bool,
	enableChange: PropTypes.bool,
	disableRemove: PropTypes.bool,
	setActiveKeys: PropTypes.func,
	onMount: PropTypes.func,
	ChangeDatasetDialog: PropTypes.object,
	simpleList: PropTypes.bool,
	comparingDatasets: PropTypes.bool,
	onActiveDatasetsChange: PropTypes.func,
};

export default SelectedDatasetsControl;
