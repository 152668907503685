import PropTypes from 'prop-types';
import DataFilterControl from '../../../common/DataFilterControl';
import './style.scss';

const BenchmarkHeader = () => {
	return (
		<div className="unhab-BenchmarkHeader">
			<DataFilterControl />
		</div>
	);
};

BenchmarkHeader.propTypes = {
	darkTheme: PropTypes.bool,
};

export default BenchmarkHeader;
