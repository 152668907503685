import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

const getSubstate = state => state.unhab.providers;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const getDatasetKeysByKey = createSelector([getByKey], datasetSet => {
	if (!datasetSet) {
		return [];
	}
	return datasetSet.data?.datasets;
});

export default {
	getSubstate,

	getAll,
	getAllAsObject,

	getDatasetKeysByKey,
};
