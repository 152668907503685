import {commonSelectors, Select as CommonSelect} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {difference as _difference} from 'lodash';

const getSubstate = state => state.unhab.indicatorsForList;

const getAll = commonSelectors.getAll(getSubstate);

const getAllForGivenTags = createSelector(
	[getAll, (state, tags) => tags],
	(indicators, tags) => {
		if (indicators?.length) {
			if (tags?.length) {
				return indicators.filter(
					indicator => _difference(tags, indicator.data.tags).length === 0,
				);
			} else {
				return indicators;
			}
		} else {
			return [];
		}
	},
);

const getAllForGivenTagsGroupedByAttributeSet = createSelector(
	[getAllForGivenTags, CommonSelect.attributeSets.getAll],
	(indicators, attributeSets) => {
		if (attributeSets?.length && indicators?.length) {
			const groups = [];
			attributeSets.forEach(attributeSet => {
				const indicatorsForGroup = [];
				const attributeSetIndicators = attributeSet.data.indicatorsForList;
				if (attributeSetIndicators) {
					attributeSetIndicators.forEach(indicatorKey => {
						const indicator = indicators.find(ind => ind.key === indicatorKey);
						if (indicator) {
							indicatorsForGroup.push(indicator);
						}
					});
				}

				if (indicatorsForGroup?.length) {
					groups.push({
						...attributeSet,
						indicators: indicatorsForGroup,
					});
				}
			});
			return groups;
		} else {
			return null;
		}
	},
);

export default {
	getSubstate,
	getAllForGivenTagsGroupedByAttributeSet,
};
