import {areaNameColumns} from '../constants/app';

/**
 * For whatever reason the feature key in '123' format must be a number. However, the feature key in '123_123' format must be a string
 * @param key {string|number}
 */
function getKeyFormatted(key) {
	if (typeof key === 'number') {
		return key;
	} else if (typeof key === 'string') {
		const parts = key.split('_');
		if (parts.length === 2) {
			return key;
		} else {
			return Number(parts[0]);
		}
	} else {
		return null;
	}
}

function getCountryKeyFromFeatureKey(featureKey) {
	const [country] = featureKey?.split?.('_') || [featureKey];
	return country || null;
}

function getRegionKeyFromFeatureKey(featureKey) {
	const [, region] = featureKey?.split?.('_') || [featureKey];
	return region ? featureKey : null;
}

function getCountryRegionKeysFromFeatureKey(featureKey) {
	const country = getCountryKeyFromFeatureKey(featureKey);
	const region = getRegionKeyFromFeatureKey(featureKey);
	return [country, region];
}

function isFeatureRegion(featureKey) {
	if (typeof featureKey === 'number') {
		return false;
	} else if (typeof featureKey === 'string') {
		return featureKey.split('_')?.length === 2;
	} else {
		return false;
	}
}

function isFeatureCountry(featureKey) {
	if (typeof featureKey === 'number') {
		return true;
	} else if (typeof featureKey === 'string') {
		return featureKey.split('_')?.length === 1;
	} else {
		return false;
	}
}

function getFeatureName(regionName, countryName, featureKey) {
	const isCountry = isFeatureCountry(featureKey);
	const isRegion = isFeatureRegion(featureKey);
	if (isRegion && regionName) {
		return `${regionName} (${countryName})`;
	} else if (isCountry && countryName) {
		return countryName;
	} else {
		return featureKey;
	}
}

function getCityClusterFeatureName(feature, countries, featureKey) {
	if (feature?.data?.[areaNameColumns[2]]) {
		const country = countries[feature.data.parent]?.data?.name;
		return `${feature.data[areaNameColumns[2]]} ${country ? `(${country})` : ''}`;
	} else {
		return featureKey;
	}
}

export default {
	getKeyFormatted,
	getCountryRegionKeysFromFeatureKey,
	isFeatureRegion,
	isFeatureCountry,
	getFeatureName,
	getCityClusterFeatureName,
};
