import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = state => {
	return {
		models: Select.unhab.datasetSets.getAllForActiveTags(state),
		activeKey: Select.unhab.datasetSets.getActiveKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: datasetSetKey => {
			dispatch(Action.unhab.benchmark.handleDatasetSetChange(datasetSetKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
