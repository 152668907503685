import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';
import config from '../../../config';
import utils from '../../../utils';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../constants/app';

function useSelectedFeatures() {
	return (dispatch, getState) => {
		const state = getState();
		const selectedFeatureKeys =
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys;
		const area1FeatureKey = Select.selections.getByKey(
			state,
			benchmarkArea1SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		const area2FeatureKey = Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		if (selectedFeatureKeys) {
			selectedFeatureKeys.forEach(key => {
				dispatch(ensure(key));
			});
		}

		if (area1FeatureKey) {
			dispatch(ensure(area1FeatureKey));
		}

		if (area2FeatureKey) {
			dispatch(ensure(area2FeatureKey));
		}
	};
}

function ensure(featureKey) {
	return (dispatch, getState) => {
		const state = getState();
		const existingFeature = Select.unhab.features.getByKey(state, featureKey);

		if (!existingFeature) {
			const url = `${config.featuresRepo}/${featureKey}.json`;

			return utils.request(url, 'GET', null, null).then(data => {
				if (data) {
					dispatch(actionAdd([{key: featureKey, data}]));
				} else {
					console.warn(`No data for feature ${featureKey}!`);
				}
			});
		}
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.FEATURES.ADD,
		data,
	};
};

export default {
	useSelectedFeatures,
	ensure,
};
