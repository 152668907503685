import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';
import Presentation from '../../../../../common/ChangeUDTAreaDialog';

const mapStateToProps = state => {
	return {
		countries: Select.unhab.udtStructure.getStructure(state),
		activeSelectionKeys: Select.unhab.benchmark.getSelectedFeatureKeys(state),
		activeComparisonType:
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onActiveFeatureChange: nextSelectedFeatureKeys => {
			dispatch(
				Action.unhab.benchmark.handleSelectedUDTFeatureKeysChange(
					nextSelectedFeatureKeys,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
