import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		data: Select.unhab.indicators.getIndicatorData(
			state,
			ownProps.configuration,
			ownProps.componentKey,
		),
		selected: Select.components.get(state, ownProps.componentKey, 'selected'),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			if (ownProps.selectable) {
				dispatch(
					Action.components.set(ownProps.componentKey, 'selected', true),
				);
			}
		},
		onUnmount: () => {
			if (ownProps.selectable) {
				dispatch(
					Action.components.set(ownProps.componentKey, 'selected', false),
				);
			}
		},
		onSelectedChange: selected => {
			dispatch(
				Action.components.set(ownProps.componentKey, 'selected', selected),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
