import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const DataFilterCategory = ({
	models,
	activeKey,
	categoryTagKey,
	isSwitcher,
	onChange,
	children,
}) => {
	const availableModelKeys = models?.map(({key}) => key);

	if (isSwitcher) {
		return models?.map(({key}, index) => {
			if (index === 1) {
				return (
					<MenuToggleItem
						key={key}
						large
						active={key === activeKey}
						onChange={active => {
							if (active) {
								return onChange(categoryTagKey, key, availableModelKeys);
							} else {
								return onChange(
									categoryTagKey,
									models?.[0]?.key,
									availableModelKeys,
								);
							}
						}}
					>
						{children}
					</MenuToggleItem>
				);
			} else {
				return null;
			}
		});
	} else {
		return (
			<ButtonSwitch
				className="unhab-ButtonSwitch"
				ghost
				onClick={selectedTagKey =>
					onChange(categoryTagKey, selectedTagKey, availableModelKeys)
				}
			>
				{models?.map(({key, data}) => (
					<ButtonSwitchOption key={key} value={key} active={key === activeKey}>
						{data.nameDisplay}
					</ButtonSwitchOption>
				))}
			</ButtonSwitch>
		);
	}
};

DataFilterCategory.propTypes = {
	isSwitcher: PropTypes.bool,
	models: PropTypes.array,
	activeKey: PropTypes.string,
	categoryTagKey: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
};

export default DataFilterCategory;
