import ActionTypes from '../../../constants/ActionTypes';
import config from '../../../config';
import utils from '../../../utils';

function use() {
	return dispatch => {
		dispatch(ensure());
	};
}

function ensure() {
	return dispatch => {
		const url = `${config.scatterPlotDataUrl}`;

		return utils.request(url, 'GET', null, null).then(data => {
			if (data) {
				let finalData = data.map(feature => {
					return {
						// TODO pass to constants
						key: feature['ADM0_CODE_ADM1_CODE'] || feature['ADM0_CODE'],
						data: feature,
					};
				});
				dispatch(actionAdd(finalData));
			} else {
				console.warn(`No data for loaded!`);
			}
		});
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.FEATURES_FOR_SCATTER_PLOT.ADD,
		data,
	};
};

export default {
	use,
};
