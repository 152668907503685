import PropTypes from 'prop-types';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const RemoveLayerControl = ({removeLayer}) => {
	return (
		<div
			className="unhab-DataLayerControl-tool"
			title="Remove layer"
			onClick={removeLayer}
		>
			<Icon icon="ri-close" />
		</div>
	);
};

RemoveLayerControl.propTypes = {
	removeLayer: PropTypes.func,
};

export default RemoveLayerControl;
