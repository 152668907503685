import {unhabIndicatorLayersRadioGroupKey} from '../../constants/app';

import styleUtils from '../../utils/styles';

export default {
	// URBANIZATION AREA EVOLUTION
	WSFE_LC_tot_rel: {
		nameDisplay: 'unused_Urbanization area evolution, total increase',
		key: 'WSFE_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d7629a-7d3f-11ee-b962-0242ac120002',
				absolute: '79d763c6-7d3f-11ee-b962-0242ac120002',
			},
			calculationType: 'totalIncreaseEvolution',
			style: styleUtils.getByKey('WSFE_LC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_LC_tot_rel'),
		},
	},
	GHS_BUILT_LC_tot_rel: {
		nameDisplay: 'unused_Urbanization area evolution, total increase',
		key: 'GHS_BUILT_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d75e58-7d3f-11ee-b962-0242ac120002',
				absolute: '79d7615a-7d3f-11ee-b962-0242ac120002',
			},
			calculationType: 'totalIncreaseEvolution',
			style: styleUtils.getByKey('GHS_BUILT_LC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_LC_tot_rel'),
		},
	},
	WSFE_LC_avg_rel: {
		nameDisplay: 'unused_Urbanization area evolution, average increase',
		key: 'WSFE_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d7674a-7d3f-11ee-b962-0242ac120002',
				absolute: '79d76862-7d3f-11ee-b962-0242ac120002',
			},
			calculationType: 'avgYearlyIncreaseEvolution',
			style: styleUtils.getByKey('WSFE_LC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_LC_avg_rel'),
		},
	},
	GHS_BUILT_LC_avg_rel: {
		nameDisplay: 'unused_Urbanization area evolution, average increase',
		key: 'GHS_BUILT_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d764f2-7d3f-11ee-b962-0242ac120002',
				absolute: '79d7661e-7d3f-11ee-b962-0242ac120002',
			},
			calculationType: 'avgYearlyIncreaseEvolution',
			style: styleUtils.getByKey('GHS_BUILT_LC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_LC_avg_rel'),
		},
	},
	WSFE_LC_min_rel: {
		nameDisplay: 'Urbanization area evolution, minimum increase',
		key: 'WSFE_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d76f56-7d3f-11ee-b962-0242ac120002',
				absolute: '79d77064-7d3f-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('WSFE_LC_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_LC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	GHS_BUILT_LC_min_rel: {
		nameDisplay: 'Urbanization area evolution, minimum increase',
		key: 'GHS_BUILT_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d76d08-7d3f-11ee-b962-0242ac120002',
				absolute: '79d76e3e-7d3f-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('GHS_BUILT_LC_min_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_LC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	WSFE_LC_max_rel: {
		nameDisplay: 'Urbanization area evolution, maximum increase',
		key: 'WSFE_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d773b6-7d3f-11ee-b962-0242ac120002',
				absolute: '79d777f8-7d3f-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('WSFE_LC_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_LC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},
	GHS_BUILT_LC_max_rel: {
		nameDisplay: 'Urbanization area evolution, maximum increase',
		key: 'GHS_BUILT_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d77190-7d3f-11ee-b962-0242ac120002',
				absolute: '79d77294-7d3f-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('GHS_BUILT_LC_max_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_LC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},
	// URBAN AREA
	WSFE_UE_min_rel: {
		nameDisplay: 'Total area of the urban extent share, minimum',
		key: 'WSFE_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa60c-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa6e8-7e22-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('WSFE_UE_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_UE_min_rel'),
			calculationType: 'minimum',
		},
	},
	GHS_BUILT_UE_min_rel: {
		nameDisplay: 'Total area of the urban extent share, minimum',
		key: 'GHS_BUILT_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa260-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa508-7e22-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('GHS_BUILT_UE_min_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_UE_min_rel'),
			calculationType: 'minimum',
		},
	},
	WSFE_UE_max_rel: {
		nameDisplay: 'Total area of the urban extent share, maximum',
		key: 'WSFE_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aac2e-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aaf4e-7e22-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('WSFE_UE_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_UE_max_rel'),
			calculationType: 'maximum',
		},
	},

	GHS_BUILT_UE_max_rel: {
		nameDisplay: 'Total area of the urban extent share, maximum',
		key: 'GHS_BUILT_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa7ce-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa8be-7e22-11ee-b962-0242ac120002',
			},
			style: styleUtils.getByKey('GHS_BUILT_UE_max_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_UE_max_rel'),
			calculationType: 'maximum',
		},
	},
	GHS_POP_TOTAL_min_rel: {
		nameDisplay: 'Total population - Minimum',
		key: 'GHS_POP_TOTAL_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'c3914386-8047-4526-93dd-ad6b7497f8c4',
				absolute: '00eb991b-167c-4b2e-a7c9-86e5725374e7',
			},
			style: styleUtils.getByKey('GHS_POP_TOTAL_min_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_TOTAL_min_rel'),
			calculationType: 'minimumPop',
		},
	},
	GHS_POP_TOTAL_max_rel: {
		nameDisplay: 'Total population - Maximum',
		key: 'GHS_POP_TOTAL_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '48b6e7c7-b386-43ae-8172-2395df2900e6',
				absolute: '0440450f-2e73-49a0-abdb-6f147515d998',
			},
			style: styleUtils.getByKey('GHS_POP_TOTAL_max_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_TOTAL_max_rel'),
			calculationType: 'maximumPop',
		},
	},
	// next layers

	WSFE_LCR: {
		nameDisplay: 'Land consumption rate',
		key: 'WSFE_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'ca15b319-d332-49ca-870c-b9240bd340a2',
			},
			selectable: true,
			style: styleUtils.getByKey('WSFE_LCR_2015'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSFE_LCR_2015'), //NOTE: style for specific year
			calculationType: 'lcRate',
		},
	},

	GHS_BUILT_LCR: {
		nameDisplay: 'Land Consumption Rate for GHS-BUILT-S',
		key: 'GHS_BUILT_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_BUILT_LCR_2020'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('GHS_BUILT_LCR_2020'), //NOTE: style for specific year
			calculationType: 'lcRate',
		},
	},
	// GHS POPULATION GRID
	GHS_POP_PC_tot_rel: {
		nameDisplay: 'Population evolution, total increase',
		key: 'GHS_POP_PC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '07c38b31-9fdb-463e-b917-dbbafaa01f7a',
				absolute: '79d7615a-7d3f-11ee-b962-0242ac120002',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_POP_PC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_PC_tot_rel'),
			calculationType: 'totalIncrease',
		},
	},
	GHS_POP_PC_avg_rel: {
		nameDisplay: 'Population evolution, average increase',
		key: 'GHS_POP_PC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'ff258f32-5a90-4b51-a760-17a6d6e2b704',
				absolute: 'cbd8cc55-5a1c-4492-892b-86e87ed63872',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_POP_PC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_PC_avg_rel'),
			calculationType: 'avgYearlyIncrease',
		},
	},
	GHS_POP_PC_min_rel: {
		nameDisplay: 'Population evolution, min increase',
		key: 'GHS_POP_PC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'e1e76504-3a30-48fb-936b-1830557ad812',
				absolute: '6da76d87-977e-4b50-9bfa-3190c28f8a20',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_POP_PC_min_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_PC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	GHS_POP_PC_max_rel: {
		nameDisplay: 'Population evolution, max increase',
		key: 'GHS_POP_PC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a',
				absolute: '08630acd-7ecf-4e90-bcaf-b0af90ca52e4',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_POP_PC_max_rel'),
			indicatorStyle: styleUtils.getByKey('GHS_POP_PC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},
	GHS_POP_PGR: {
		nameDisplay: 'Population Growth Rate for GHS-POP',
		key: 'GHS_POP_PGR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'fedcb00a-92dc-4a03-acf0-a63040373b9f',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_POP_PGR_2020'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('GHS_POP_PGR_2020'),
			calculationType: 'growthRate',
		},
	},
	// WSF2019POP3D_LCPC: {
	// 	nameDisplay: 'Land Consumption per capita',
	// 	key: 'WSF2019POP3D_LCPC',
	// 	filterByActive: {
	// 		application: true,
	// 		areaTreeLevel: true,
	// 	},
	// 	radioGroup: unhabIndicatorLayersRadioGroupKey,
	// 	options: {
	// 		selectable: true,
	// 		attributes: {
	// 			relative: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5',
	// 		},
	// 		style: styleUtils.getByKey('WSF2019POP3D_LCPC'),
	// 		calculationType: 'lastValuesRatio',
	// 	},
	// },
	WSFE_10m_UE_min_rel: {
		nameDisplay: ' Total area of urban extent - Minimum',
		key: 'WSFE_10m_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'e9cad7d0-15f0-4439-99a3-e07a808734d0',
				absolute: 'a3f664d8-3fa0-47cd-a0a6-81d4df001c7e'
			},
			style: styleUtils.getByKey('WSFE_10m_UE_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_UE_min_rel'),
			calculationType: 'minimum',
		},
	},
	WSFE_10m_UE_max_rel: {
		nameDisplay: ' Total area of urban extent - Maximum',
		key: 'WSFE_10m_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '870b2857-176a-4471-82a8-5ab909d44b72',
				absolute: '72129a88-cd36-45a5-b811-a4ff23b38223',
			},
			style: styleUtils.getByKey('WSFE_10m_UE_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_UE_max_rel'),
			calculationType: 'maximum',
		},
	},
	WSFE_10m_LC_tot_rel: {
		nameDisplay: 'Total increase',
		key: 'WSFE_10m_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '882e8fe1-c758-42c1-9344-c60214c699c2',
				absolute: '6cd70af5-8db3-4a78-8f01-8ccc99e17f5a',
			},
			style: styleUtils.getByKey('WSFE_10m_LC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_LC_tot_rel'),
			calculationType: 'totalIncreaseEvolution',
		},
	},
	WSFE_10m_LC_avg_rel: {
		nameDisplay: 'Average increase',
		key: 'WSFE_10m_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '0a96537a-3da1-4713-96cd-12d9efc1a7ba',
				absolute: '7938c69d-366f-4642-aea5-a15bc91977de',
			},
			style: styleUtils.getByKey('WSFE_10m_LC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_LC_avg_rel'),
			calculationType: 'avgYearlyIncreaseEvolution',
		},
	},
	WSFE_10m_LC_min_rel: {
		nameDisplay: 'Min. increase',
		key: 'WSFE_10m_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'fc4d2120-a0da-4f72-a718-f1a68bda6681',
				absolute: '4e25db77-aaa0-4e8e-9d07-c435199fbe6b'
			},
			style: styleUtils.getByKey('WSFE_10m_LC_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_LC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	WSFE_10m_LC_max_rel: {
		nameDisplay: 'Max. increase',
		key: 'WSFE_10m_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '033ea81e-0660-4234-9d87-47ba1b689910',
				absolute: '52761164-26aa-46c6-ad64-7d9e8560025d'
			},
			style: styleUtils.getByKey('WSFE_10m_LC_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSFE_10m_LC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},

	WSF_POP_PC_tot_rel: {
		nameDisplay: 'Total increase',
		key: 'WSF_POP_PC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				absolute: '02d27e1c-44cd-4a0a-8834-8be215e8f44e',
				relative: 'bab2ea5b-50f8-400e-a725-ef6ffb4eef9c',
			},
			style: styleUtils.getByKey('WSF_POP_PC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_PC_tot_rel'),
			calculationType: 'totalIncrease',
		},
	},
	WSF_POP_PC_avg_rel: {
		nameDisplay: 'Average increase',
		key: 'WSF_POP_PC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				absolute: '1225d913-6aff-4674-a8ac-2e0240daf11d',
				relative: '7341e0a4-a9da-4bce-a516-62d8bd7abc87',
			},
			style: styleUtils.getByKey('WSF_POP_PC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_PC_avg_rel'),
			calculationType: 'avgYearlyIncrease',
		},
	},
	WSF_POP_PC_min_rel: {
		nameDisplay: 'Min. increase',
		key: 'WSF_POP_PC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				absolute: '056c47e1-ae4e-4933-9a23-286088590a35',
				relative: '695cd019-df5e-4cb8-b8b5-e841fbaa49e4',
			},
			style: styleUtils.getByKey('WSF_POP_PC_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_PC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	WSF_POP_PC_max_rel: {
		nameDisplay: 'Max. increase',
		key: 'WSF_POP_PC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				absolute: '6b882ebf-cc81-4ce4-a788-eb574a3e359d',
				relative: '2951954d-cacf-470a-96a5-41bb5fd8bdad',
			},
			style: styleUtils.getByKey('WSF_POP_PC_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_PC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},

	WSF_POP_TOTAL_min_rel: {
		nameDisplay: 'Total population - Minimum rel.',
		key: 'WSF_POP_TOTAL_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'b626dff7-2382-403f-9907-87c9b7a54e3c',
				absolute: '8281c4e7-3fb0-4024-afae-ea476de33a73',
			},
			style: styleUtils.getByKey('WSF_POP_TOTAL_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_TOTAL_min_rel'),
			calculationType: 'minimumPop',
		},
	},
	WSF_POP_TOTAL_max_rel: {
		nameDisplay: 'Total population - Maximum rel.',
		key: 'WSF_POP_TOTAL_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'c88e464a-5dbc-4c87-9aa8-b619abfc6d58',
				absolute: '66b88a05-8b68-4afb-a8b0-1cb5e9f635eb',
			},
			style: styleUtils.getByKey('WSF_POP_TOTAL_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_POP_TOTAL_max_rel'),
			calculationType: 'maximumPop',
		},
	},

	WSF_BUILT_UE_min_rel: {
		nameDisplay: 'Total area of urban extent - Minimum rel.',
		key: 'WSF_BUILT_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '1e01f96a-ca6d-4a57-a849-12f2079a5d20',
				absolute: '1ae9cb89-ea52-400e-8a8c-4a0cd809fbf7',
			},
			style: styleUtils.getByKey('WSF_BUILT_UE_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_UE_min_rel'),
			calculationType: 'minimum',
		},
	},
	WSF_BUILT_UE_max_rel: {
		nameDisplay: 'Total area of urban extent - Maximum rel.',
		key: 'WSF_BUILT_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'feb42dc0-332d-41ad-b96d-633ff3f52fce',
				absolute: '8412a01d-3b7f-4c25-abad-caccff9eb4e0',
			},
			style: styleUtils.getByKey('WSF_BUILT_UE_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_UE_max_rel'),
			calculationType: 'maximum',
		},
	},
	WSF_BUILT_LC_tot_rel: {
		nameDisplay: 'Total increase rel.',
		key: 'WSF_BUILT_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '17843dcd-060c-4c44-83e4-6380ffe40215',
				absolute: '5e9898f4-01b2-48e7-a945-9afc664a3cae',
			},
			style: styleUtils.getByKey('WSF_BUILT_LC_tot_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_LC_tot_rel'),
			calculationType: 'totalIncreaseEvolution',
		},
	},
	WSF_BUILT_LC_avg_rel: {
		nameDisplay: 'Average increase rel.',
		key: 'WSF_BUILT_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '840f8957-c450-4cb5-8e2e-2c88ee6ff4a4',
				absolute: '0eaac7cf-3e6d-4263-b8ac-c95649c9e977',
			},
			style: styleUtils.getByKey('WSF_BUILT_LC_avg_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_LC_avg_rel'),
			calculationType: 'avgYearlyIncreaseEvolution',
		},
	},
	WSF_BUILT_LC_min_rel: {
		nameDisplay: 'Min. increase rel.',
		key: 'WSF_BUILT_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '533a1634-b742-4897-9d11-4f11be8dc350',
				absolute: 'f5f83add-0c3f-4d4b-acae-28cf0a538a3b',
			},
			style: styleUtils.getByKey('WSF_BUILT_LC_min_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_LC_min_rel'),
			calculationType: 'minYearlyIncrease',
		},
	},
	WSF_BUILT_LC_max_rel: {
		nameDisplay: 'Max. increase rel.',
		key: 'WSF_BUILT_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '0e998d61-74da-46d4-886b-d30e04d15934',
				absolute: '4889a1c5-f6d9-403c-910e-c669d27a6f00',
			},
			style: styleUtils.getByKey('WSF_BUILT_LC_max_rel'),
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_LC_max_rel'),
			calculationType: 'maxYearlyIncrease',
		},
	},
	WSFE_10m_LCR: {
		nameDisplay: 'Land consumption rate',
		key: 'WSFE_10m_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '73e1d399-0f65-46ea-b8d4-fc91b715cd21',
			},
			selectable: true,
			style: styleUtils.getByKey('WSFE_10m_LCR_2023'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSFE_10m_LCR_2023'), //NOTE: style for specific year
			calculationType: 'lcRate',
		},
	},
	WSF_POP_PGR: {
		nameDisplay: 'Population growth rate',
		key: 'WSF_POP_PGR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'de759d8f-bb00-441a-a06f-0a060c855637',
			},
			selectable: true,
			style: styleUtils.getByKey('WSF_POP_PGR_2023'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSF_POP_PGR_2023'), //NOTE: style for specific year
			calculationType: 'growthRate',
		},
	},
	WSF_BUILT_LCR: {
		nameDisplay: 'Land consumption rate',
		key: 'WSF_BUILT_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '61d2b450-9546-4e68-8bf6-d09603dbaea2',
			},
			selectable: true,
			style: styleUtils.getByKey('WSF_BUILT_LCR_2023'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSF_BUILT_LCR_2023'), //NOTE: style for specific year
			calculationType: 'lcRate',
		},
	},
	WSF_LCRPGR: {
		nameDisplay: 'Land Consumption Rate/Population Growth Rate',
		key: 'WSF_LCRPGR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '0432a5e6-f169-45c1-bb6a-c7407c41ea45',
			},
			selectable: true,
			style: styleUtils.getByKey('WSF_LCRPGR_2023'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSF_LCRPGR_2023'), //NOTE: style for specific year
			calculationType: 'ratesRatio',
		},
	},
	WSF_LCPC: {
		nameDisplay: 'Land Consumption per capita',
		key: 'WSF_LCPC',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83',
			},
			selectable: true,
			style: styleUtils.getByKey('WSF_LCPC_2023'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('WSF_LCPC_2023'), //NOTE: style for specific year
			calculationType: 'lastValuesRatio',
		},
	},
	GHS_LCRPGR: {
		nameDisplay: 'Land Consumption Rate/Population Growth Rate',
		key: 'GHS_LCRPGR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_LCRPGR_2020'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('GHS_LCRPGR_2020'), //NOTE: style for specific year
			calculationType: 'ratesRatio',
		},
	},
	GHS_LCPC: {
		nameDisplay: 'Land Consumption per capita',
		key: 'GHS_LCPC',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571',
			},
			selectable: true,
			style: styleUtils.getByKey('GHS_LCPC_2020'), //NOTE: style for specific year
			indicatorStyle: styleUtils.getByKey('GHS_LCPC_2020'), //NOTE: style for specific year
			calculationType: 'lastValuesRatio',
		},
	},
};
