import PropTypes from 'prop-types';
import DataLayerControl from '../components/DataLayerControl';
import DataLayersGroup from '../components/DataLayersGroup';
import LayersGroupControl from '../components/LayersGroupControl';
import './style.scss';

const DatasetLayersControl = ({
	activeLayers,
	ghsPopulationDatasetLayers,
	ghsBuiltUpDatasetLayers,
	wsfBuiltUpDatasetLayers,
	wsfPopulationDatasetLayers,
	wsfDatasetLayers,
	mapKey,
}) => {
	const isLayerActive = layer => {
		return activeLayers?.find(
			activeLayer =>
				(activeLayer?.layerTemplateKey &&
					layer?.layerTemplateKey &&
					activeLayer?.layerTemplateKey === layer?.layerTemplateKey) ||
				(activeLayer?.key && layer?.key && activeLayer?.key === layer?.key),
		);
	};

	const isSectionActive = datasetLayers => {
		let layerTemplateKeys = activeLayers?.map(layer => {
			return layer?.layerTemplateKey;
		});
		let datasetLayerTemplateKeys = datasetLayers?.map(layer => {
			return layer?.layerTemplateKey;
		});
		return layerTemplateKeys?.some(layerTemplateKey =>
			datasetLayerTemplateKeys?.includes(layerTemplateKey),
		);
	};

	return (
		<DataLayersGroup title="Dataset layers" defaultOpen>
			<DataLayersGroup title="Global Human Settlement Layer Suite">
				{ghsBuiltUpDatasetLayers?.length > 0 ? (
					<LayersGroupControl
						datasetKey={'ghsBuiltUpSurface'}
						title="GHSL 1975 - 2030 Built-up (100m)"
						mapKey={mapKey}
						layer={
							ghsBuiltUpDatasetLayers?.[ghsBuiltUpDatasetLayers?.length - 1]
						}
						layers={ghsBuiltUpDatasetLayers}
						active={isSectionActive(ghsBuiltUpDatasetLayers)}
					>
						{ghsBuiltUpDatasetLayers?.map(layer => {
							const active = isLayerActive(layer);

							return (
								<DataLayerControl
									key={layer.key}
									mapKey={mapKey}
									active={!!active}
									data={layer}
									type={'checkbox'}
									initialized={false} //should be false only for COG layers
									datasetLayerKey={'ghsBuiltUpSurface'}
								/>
							);
						})}
					</LayersGroupControl>
				) : null}
				{ghsPopulationDatasetLayers?.length > 0 ? (
					<LayersGroupControl
						datasetKey={'ghsPopulationGrid'}
						title="GHSL 1975 - 2030 Population (100m)"
						mapKey={mapKey}
						layer={
							ghsPopulationDatasetLayers?.[
								ghsPopulationDatasetLayers?.length - 1
							]
						}
						layers={ghsPopulationDatasetLayers}
						active={isSectionActive(ghsPopulationDatasetLayers)}
					>
						{ghsPopulationDatasetLayers?.map(layer => {
							const active = isLayerActive(layer);

							return (
								<DataLayerControl
									key={layer.key}
									mapKey={mapKey}
									active={!!active}
									data={layer}
									type={'checkbox'}
									initialized={false} //should be false only for COG layers
									datasetLayerKey={'ghsPopulationGrid'}
								/>
							);
						})}
					</LayersGroupControl>
				) : null}
			</DataLayersGroup>
			<DataLayersGroup title="World Settlement Footprint Suite">
				{wsfBuiltUpDatasetLayers?.length > 0 ? (
					<LayersGroupControl
						datasetKey={'wsfBuildUp2016_2023'}
						title="WSF 2016 - 2023 Built-up (10m)"
						mapKey={mapKey}
						layer={
							wsfBuiltUpDatasetLayers?.[wsfBuiltUpDatasetLayers?.length - 1]
						}
						layers={wsfBuiltUpDatasetLayers}
						active={isSectionActive(wsfBuiltUpDatasetLayers)}
					>
						{wsfBuiltUpDatasetLayers?.map(layer => {
							const active = isLayerActive(layer);

							return (
								<DataLayerControl
									key={layer.key}
									mapKey={mapKey}
									active={!!active}
									data={layer}
									type={'checkbox'}
									initialized={false} //should be false only for COG layers
									datasetLayerKey={'wsfBuildUp2016_2023'}
								/>
							);
						})}
					</LayersGroupControl>
				) : null}
				{wsfPopulationDatasetLayers?.length > 0 ? (
					<LayersGroupControl
						datasetKey={'wsfPopulation2016_2023'}
						title="WSF 2016 - 2023 Population (10m)"
						mapKey={mapKey}
						layer={
							wsfPopulationDatasetLayers?.[
								wsfPopulationDatasetLayers?.length - 1
							]
						}
						layers={wsfPopulationDatasetLayers}
						active={isSectionActive(wsfPopulationDatasetLayers)}
					>
						{wsfPopulationDatasetLayers?.map(layer => {
							const active = isLayerActive(layer);

							return (
								<DataLayerControl
									key={layer.key}
									mapKey={mapKey}
									active={!!active}
									data={layer}
									type={'checkbox'}
									initialized={false} //should be false only for COG layers
									datasetLayerKey={'wsfPopulation2016_2023'}
								/>
							);
						})}
					</LayersGroupControl>
				) : null}
				{wsfDatasetLayers?.map(layer => {
					const active = isLayerActive(layer);

					return (
						<DataLayerControl
							key={layer.key}
							mapKey={mapKey}
							active={!!active}
							data={layer}
							type={'checkbox'}
							initialized={false} //should be false only for COG layers
							tagKeys={layer?.tags}
						/>
					);
				})}
			</DataLayersGroup>
		</DataLayersGroup>
	);
};

DatasetLayersControl.propTypes = {
	mapKey: PropTypes.string,
	activeLayers: PropTypes.array,
	ghsPopulationDatasetLayers: PropTypes.array,
	ghsBuiltUpDatasetLayers: PropTypes.array,
	ghsDatasetLayers: PropTypes.array,
	wsfDatasetLayers: PropTypes.array,
	wsfBuiltUpDatasetLayers: PropTypes.array,
	wsfPopulationDatasetLayers: PropTypes.array,
};

export default DatasetLayersControl;
