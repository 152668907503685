export default {
	componentKey: "urbanizationAreaEvolution_minimumIncrease",
	title: 'Minimal yearly increase',
	configuration: {
		attributes: [
			'79d77064-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - absolute
			'79d76f56-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - relative
			'79d76e3e-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - absolute
			'79d76d08-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - relative
			'f5f83add-0c3f-4d4b-acae-28cf0a538a3b', // WSF 2016-2023 - Built-up (10m) - absolute
			'533a1634-b742-4897-9d11-4f11be8dc350', // WSF 2016-2023 - Built-up (10m) - relative
			'4e25db77-aaa0-4e8e-9d07-c435199fbe6b', // WSF Evolution 1985-2015 - Built-up (10m) - absolute
			'fc4d2120-a0da-4f72-a718-f1a68bda6681', // WSF Evolution 1985-2015 - Built-up (10m) - relative
		],
		type: 'minYearlyIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
