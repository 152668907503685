import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = state => {
	return {
		activeAreaTreeKey: Select.unhab.areaTreeTypes.getActiveKey(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
