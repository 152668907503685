export default {
	componentKey: "landConsumptionRate",
	title: 'Land consumption rate',
	configuration: {
		valueFormat: {
			decimals: 3,
		},
		attributes: [
			'ca15b319-d332-49ca-870c-b9240bd340a2', // WSF Evolution 1985-2015 - Built-up (30m)
			'c09bac02-f276-4ea4-a5f6-e090a8313454', // GHSL 1975-2030 - Built-up (100m)
			'61d2b450-9546-4e68-8bf6-d09603dbaea2', // WSF 2016-2023 - Built-up (10m)
			'73e1d399-0f65-46ea-b8d4-fc91b715cd21', // WSF Evolution 1985-2023 - Built-up (10m)
		],
		type: 'lcRate',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'rate',
				description: 'Indicator 2 description',
			},
		],
	},
};
