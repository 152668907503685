import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	byKey: {},
	active: [{}, {}],
	// EXAMPLE
	// active: [
	// 	{
	// 		DLR_WSF_YEAR: {
	// 			key: DLR_WSF_YEAR,
	// 			data: {
	// 				value: 2022,
	// 			}
	// 		},
	// 	}, //related to firstMap
	// 	{
	// 		DLR_WSF_YEAR: {
	// 			key: DLR_WSF_YEAR,
	// 			data: {
	// 				value: 2020,
	// 			}
	// 		},
	// 	}, //related to secondMap
	// ],
};

/**
 * Set active values for given index of (map).
 * @param {Object} state - the state
 * @param {Object} action - the action
 * @param {Number} action.index - the index in active array [0 || 1]
 * @param {Object} action.parameters - the parameters to set {PARAMETERKEY:{value:VALUE}}
 * @returns {Object} newState - the new state
 */
const setActiveValuesAtIndex = (state, action) => {
	const {parameters, index} = action;
	const newState = {...state};
	newState.active[index] = parameters;
	return newState;
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.CITY_CLUSTER_METHOD_PARAMETERS.ADD:
			return commonReducers.add(state, action);
		case ActionTypes.UNHAB.CITY_CLUSTER_METHOD_PARAMETERS
			.SET_ACTIVE_VALUES_AT_INDEX:
			return setActiveValuesAtIndex(state, action);
		default:
			return state;
	}
};
