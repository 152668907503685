import Action from '../../Action';
import Select from '../../Select';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	countryLevel,
	regionLevel,
	cityClustersAreaTreeKey,
	defaultActiveCityClusterMetodKeys,
	defaultActiveCityClusterMetodKey,
	defaultActiveCityClusterFeatureKey2,
	datasetsComparisonType,
	areaComparisonType,
} from '../../../constants/app';
import features from '../../../utils/features';

function setMapLevelByFeature(feature, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const map = Select.maps.getMapByKey(state, mapKey);
		const currentLevelKey = map?.data?.metadataModifiers?.areaTreeLevelKey;
		const areaLevels = Select.areas.areaTreeLevels.getAllAsObject(state);
		const level = areaLevels?.[currentLevelKey]?.data?.level;
		const featureIsRegion = feature?.key?.toString()?.split('_')?.length === 2;
		if (level === 0 && featureIsRegion) {
			dispatch(
				Action.maps.setMapMetadataModifiers(mapKey, {
					areaTreeLevelKey: regionLevel,
				}),
			);
		} else if (level === 1 && !featureIsRegion) {
			dispatch(
				Action.maps.setMapMetadataModifiers(mapKey, {
					areaTreeLevelKey: countryLevel,
				}),
			);
		}
	};
}

function handleComparisonTypeChange(comparisonTypeKey) {
	return (dispatch, getState) => {
		const state = getState();
		const activeAreaTreeKey = Select.unhab.areaTreeTypes.getActiveKey(state);

		const mapMode = Select.components.get(state, 'Maps', 'mode');
		if (comparisonTypeKey === areaComparisonType && mapMode === 'compare') {
			dispatch(Action.components.set('Maps', 'mode', 'set'));
		}

		if (activeAreaTreeKey === cityClustersAreaTreeKey) {
			return dispatch(
				handleCityClusterAreaComparisonTypeChange(comparisonTypeKey),
			);
		} else {
			return dispatch(handleAdmComparisonTypeChange(comparisonTypeKey));
		}
	};
}

function handleAdmComparisonTypeChange(comparisonTypeKey) {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const activeDatasets = Select.unhab.datasets.getActiveKeys(state);
		const activeDatasetSetKey = Select.unhab.datasetSets.getActiveKey(state);
		const datasetSets = Select.unhab.datasetSets.getAll(state);

		dispatch(
			Action.unhab.benchmarkComparisonTypes.setActiveKey(comparisonTypeKey),
		);
		if (comparisonTypeKey === datasetsComparisonType) {
			dispatch(Action.unhab.setMapSetSync(mapSetKey, true));
			if (activeDatasetSetKey) {
				dispatch(Action.unhab.datasetSets.setActive(activeDatasetSetKey));
			} else {
				dispatch(Action.unhab.datasetSets.setActive(datasetSets[0].key));
			}

			const featureKey = Select.selections.getByKey(
				state,
				benchmarkArea1SelectionKey,
			)?.data?.featureKeysFilter?.keys?.[0];
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey,
				]),
			);
			const maps = Select.maps.getMapSetMapKeys(state, mapSetKey);
			const featureKey1 = features.getKeyFormatted(featureKey);
			const feature1 = Select.unhab.admStructure.getAdmByKey(
				state,
				featureKey1,
			);
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[1]));
		} else {
			dispatch(Action.unhab.setMapSetSync(mapSetKey, false));
			dispatch(Action.unhab.datasets.setActiveKeys([activeDatasets?.[0]]));
			//todo
			//zoom to areas
		}
	};
}
function handleCityClusterAreaComparisonTypeChange(comparisonTypeKey) {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const activeMethods = Select.unhab.cityClusterMethods.getActiveKeys(state);
		dispatch(
			Action.unhab.benchmarkComparisonTypes.setActiveKey(comparisonTypeKey),
		);

		if (comparisonTypeKey === datasetsComparisonType) {
			dispatch(
				Action.unhab.cityClusterMethods.setActiveKeys(
					defaultActiveCityClusterMetodKeys,
				),
			);
			dispatch(Action.unhab.setMapSetSync(mapSetKey, true));

			const featureKey = Select.selections.getByKey(
				state,
				benchmarkArea1SelectionKey,
			)?.data?.featureKeysFilter?.keys?.[0];
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey,
				]),
			);
			const maps = Select.maps.getMapSetMapKeys(state, mapSetKey);
			const featureKey1 = features.getKeyFormatted(featureKey);
			const feature1 = Select.unhab.udtStructure.getByKey(state, featureKey1);
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[1]));
		} else {
			dispatch(
				Action.unhab.cityClusterMethods.setActiveKeys(
					defaultActiveCityClusterMetodKey,
				),
			);
			dispatch(Action.unhab.setMapSetSync(mapSetKey, false));
			dispatch(Action.unhab.datasets.setActiveKeys([activeMethods?.[0]]));
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					defaultActiveCityClusterFeatureKey2,
				]),
			);
		}
	};
}

function handleSelectedFeatureKeysChange(selectedFeatureKeys) {
	return (dispatch, getState) => {
		const state = getState();
		const comparisonType =
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const maps = Select.maps.getMapSetMapKeys(state, mapSetKey);
		const featureKey1 = features.getKeyFormatted(selectedFeatureKeys[0]);
		const feature1 = Select.unhab.admStructure.getAdmByKey(state, featureKey1);

		if (comparisonType === datasetsComparisonType) {
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea1SelectionKey, [
					featureKey1,
				]),
			);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey1,
				]),
			);

			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature1, maps[1]));
		} else {
			const featureKey2 = features.getKeyFormatted(selectedFeatureKeys[1]);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea1SelectionKey, [
					featureKey1,
				]),
			);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey2,
				]),
			);

			const feature2 = Select.unhab.admStructure.getAdmByKey(
				state,
				featureKey2,
			);

			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature2], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature2, maps[1]));
		}
	};
}
function handleSelectedUDTFeatureKeysChange(selectedFeatureKeys) {
	return (dispatch, getState) => {
		const state = getState();
		const comparisonType =
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const maps = Select.maps.getMapSetMapKeys(state, mapSetKey);
		const featureKey1 = features.getKeyFormatted(selectedFeatureKeys[0]);
		const feature1 = Select.unhab.udtStructure.getByKey(state, featureKey1);
		if (comparisonType === datasetsComparisonType) {
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea1SelectionKey, [
					featureKey1,
				]),
			);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey1,
				]),
			);

			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature1, maps[1]));
		} else {
			const featureKey2 = features.getKeyFormatted(selectedFeatureKeys[1]);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea1SelectionKey, [
					featureKey1,
				]),
			);
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(benchmarkArea2SelectionKey, [
					featureKey2,
				]),
			);

			const feature2 = Select.unhab.udtStructure.getByKey(state, featureKey2);

			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature2], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature2, maps[1]));
		}
	};
}

function handleDatasetSetChange(datasetSetKey) {
	return dispatch => {
		dispatch(Action.unhab.datasetSets.setActive(datasetSetKey));
	};
}

// helpers -------------------------------------------------------------------------------------

export default {
	handleComparisonTypeChange,
	handleSelectedFeatureKeysChange,
	handleSelectedUDTFeatureKeysChange,
	handleDatasetSetChange,
};
