export default {
	componentKey: "populationEvolution_average",
	title: 'Average increase',
	subtitle: 'per year',
	configuration: {
		attributes: [
			'cbd8cc55-5a1c-4492-892b-86e87ed63872', // GHSL absolute
			'ff258f32-5a90-4b51-a760-17a6d6e2b704', // GHSL relative
			'1225d913-6aff-4674-a8ac-2e0240daf11d', // WSF 2016-2023 (10m) - absolute
			'7341e0a4-a9da-4bce-a516-62d8bd7abc87', // WSF 2016-2023 (10m) - relative
		],
		type: 'avgYearlyIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
