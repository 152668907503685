export default {
	componentKey: "totalPopulation_maximum",
	title: 'Total population - maximum',
	configuration: {
		attributes: [
			'0440450f-2e73-49a0-abdb-6f147515d998', // GHSL - absolute
			'48b6e7c7-b386-43ae-8172-2395df2900e6', // GHSL - relative
			'66b88a05-8b68-4afb-a8b0-1cb5e9f635eb', // WSF - absolute
			'c88e464a-5dbc-4c87-9aa8-b619abfc6d58', // WSF - relative
		],
		type: 'maximumPop',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'inhabitant/sqkm',
				description: 'Indicator 2 description',
			},
		],
	},
};
