import PropTypes from 'prop-types';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const TooltipControl = ({active, onChange}) => {
	return (
		<MenuToggleItem icon="ri-tooltip" active={active} onChange={onChange}>
			Display map tooltips
		</MenuToggleItem>
	);
};

TooltipControl.propTypes = {
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

export default TooltipControl;
