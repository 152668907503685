import PropTypes from 'prop-types';
import AppOptions from '../../AppOptions';
import HeaderTool from './HeaderTool';
import MapCountControl from '../../Controls/MapCountControl';
import FiveYearPeriodControl from '../../Controls/FiveYearPeriodControl';
import DarkModeControl from '../../Controls/DarkModeControl';
import './style.scss';
import {
	benchmarkViewKey,
	defaultViewKey,
	reportViewKey,
	xCubeViewKey,
} from '../../../../constants/app';

const getContentByViewKey = (viewKey, mapSetKey) => {
	switch (viewKey) {
		// Explore
		case defaultViewKey:
			return (
				<>
					<HeaderToolsGroup>
						<MapCountControl icon="ri-columns" mapSetKey={mapSetKey} />
						<FiveYearPeriodControl icon="ri-circle-five" />
						<DarkModeControl icon="ri-dark-mode" />
					</HeaderToolsGroup>
					<HeaderToolsGroup>
						<AppOptions viewKey={viewKey} mapSetKey={mapSetKey} />
						<HeaderTool icon="ri-info" disabled={true} />
						<HeaderTool icon="ri-user" disabled={true} />
					</HeaderToolsGroup>
				</>
			);
		// Benchmark
		case benchmarkViewKey:
			return (
				<>
					<HeaderToolsGroup>
						<MapCountControl
							icon="ri-columns"
							disabled={true}
							mapSetKey={mapSetKey}
						/>
						<FiveYearPeriodControl icon="ri-circle-five" />
						<DarkModeControl icon="ri-dark-mode" />
					</HeaderToolsGroup>
					<HeaderToolsGroup>
						<AppOptions viewKey={viewKey} mapSetKey={mapSetKey} />
						<HeaderTool icon="ri-info" disabled={true} />
						<HeaderTool icon="ri-user" disabled={true} />
					</HeaderToolsGroup>
				</>
			);
		// Report
		case reportViewKey:
			return (
				<>
					<HeaderToolsGroup>
						<MapCountControl
							icon="ri-columns"
							disabled={true}
							mapSetKey={mapSetKey}
						/>
						<FiveYearPeriodControl icon="ri-circle-five" />
						<DarkModeControl icon="ri-dark-mode" />
					</HeaderToolsGroup>
					<HeaderToolsGroup>
						<AppOptions viewKey={viewKey} mapSetKey={mapSetKey} />
						<HeaderTool icon="ri-info" disabled={true} />
						<HeaderTool icon="ri-user" disabled={true} />
					</HeaderToolsGroup>
				</>
			);
		// Limassol
		case xCubeViewKey:
			return (
				<>
					<HeaderToolsGroup>
						<DarkModeControl icon="ri-dark-mode" />
					</HeaderToolsGroup>
					<HeaderToolsGroup>
						<AppOptions viewKey={viewKey} mapSetKey={mapSetKey} />
						<HeaderTool icon="ri-info" disabled={true} />
						<HeaderTool icon="ri-user" disabled={true} />
					</HeaderToolsGroup>
				</>
			);
		default:
			return null;
	}
};

const HeaderToolsGroup = ({children}) => {
	return <div className="unhab-HeaderToolsGroup">{children}</div>;
};

HeaderToolsGroup.propTypes = {
	children: PropTypes.node,
};

const HeaderTools = ({activeViewKey, mapSetKey}) => {
	return (
		<div className="unhab-HeaderTools">
			{getContentByViewKey(activeViewKey, mapSetKey)}
		</div>
	);
};

HeaderTools.propTypes = {
	activeViewKey: PropTypes.string,
	mapSetKey: PropTypes.string,
};

export default HeaderTools;
