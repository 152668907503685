import theme from './_theme';

export default {
	componentKey: 'scatterPlot_chart_lcr_pgr_wsf',
	type: 'scatterChart',
	title: 'Featured areas: Land Consumption Rate/Population Growth Rate (WSF)',
	subtitle: '2016 - ',
	configuration: {
		attributes: [
			'f950af79-83be-42b7-8174-12f9353cb642', // WSF 2016-2023 LCR
			'0a17814e-18cc-46e3-ac26-f7e97465d3a1', // WSF 2016-2023 - Population growth rate
			'd5e2a536-b344-4bc6-9bc8-d509801d82f9', // WSF 2016-2023 LCR/PGR
		],
		metadata: {
			nodeSize: {
				inputRange: [0, 5],
				outputRange: [3, 7],
			},
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 40, left: 60},
				xScale: {
					type: 'linear',
					max: 0.15,
				},
				yScale: {
					type: 'linear',
					min: 0,
					max: 0.1,
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
					legend: 'Pop. Growth Rate',
					legendPosition: 'middle',
					legendOffset: -50,
				},
				axisBottom: {
					tickValues: 6,
					legend: 'Land Consumption Rate',
					legendPosition: 'middle',
					legendOffset: 33,
				},
				gridYValues: 4,
				gridXValues: 6,
			},
		},
	},
};
