import theme from './_theme';

export default {
	componentKey: 'urbanAreaEvolution_chart',
	type: 'timeProgressChart',
	title: 'Urban area evolution - absolute',
	subtitle: 'Yearly changes [sqkm]',
	configuration: {
		attributes: [
			'8f77c076-ec70-4444-8ebb-1c7a757650f9', // GHSL Built up
			'a8558a05-ff96-4a50-aa7f-8187cce59900', // WSF 2016-2023 - Built-up (10m)
			'3f640746-34c7-4225-b408-c8d77cd21bb0', // WSF Evolution 1985-2015 - Built-up (30m)
			'99e44729-2e5b-4a5e-b885-27a90dbf9e4a', // WSF Evolution 1985-2023 - Built-up (10m)
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 30},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
