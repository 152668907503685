import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		tagKeys: Select.unhab.datasets.getTagKeysForLabelsByDatasetKey(
			state,
			ownProps.datasetKey,
		),
	};
};

export default connect(mapStateToProps)(Presentation);
