export default [
	// Tag groups
	{
		key: '1bdaa461-23eb-4437-9f03-ea8764a31e87',
		data: {
			nameDisplay: 'Coverage',
			nameInternal: 'Coverage',
		},
	},
	{
		key: 'c75adc77-c487-45a7-8fc5-82f3c7b27c86',
		data: {
			nameDisplay: 'Theme',
			nameInternal: 'Theme',
		},
	},
	{
		key: 'c9bc599b-a6df-4664-8871-3e2e11b5f7c8',
		data: {
			nameDisplay: 'Indicators',
			nameInternal: 'Indicators',
		},
	},

	// Data tags
	{
		key: 'fbf21504-58a2-4429-b9f4-8d35e6630b84',
		data: {
			nameDisplay: 'Base mode',
			nameInternal: 'Base user',
			shortName: 'Base',
		},
	},
	{
		key: '75a8374c-edb3-4944-ba97-a15d6a6d90ba',
		data: {
			nameDisplay: 'Expert mode',
			nameInternal: 'Expert user',
			shortName: 'Expert',
		},
	},
	{
		key: 'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5',
		data: {
			nameDisplay: 'All',
			nameInternal: 'All coverages',
			tags: ['1bdaa461-23eb-4437-9f03-ea8764a31e87'],
		},
	},
	{
		key: '41a7ab61-037d-4884-bb6e-4b17ddfde9be',
		data: {
			nameDisplay: 'Partial',
			nameInternal: 'Partial coverage',
			tags: ['1bdaa461-23eb-4437-9f03-ea8764a31e87'],
		},
	},
	{
		key: 'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5',
		data: {
			nameDisplay: 'Global',
			nameInternal: 'Global coverage',
			tags: ['1bdaa461-23eb-4437-9f03-ea8764a31e87'],
		},
	},
	{
		key: '13b7fd98-d80d-4993-8293-77dac979bfe8',
		data: {
			nameDisplay: 'All',
			nameInternal: 'All themes',
			tags: ['c75adc77-c487-45a7-8fc5-82f3c7b27c86'],
		},
	},
	{
		key: 'a90bfb74-5e8a-47a4-a674-0e5a577c376a',
		data: {
			nameDisplay: 'Land',
			nameInternal: 'Land',
			tags: ['c75adc77-c487-45a7-8fc5-82f3c7b27c86'],
		},
	},
	{
		key: '3af8b2ab-d56d-417d-b465-df4d6e4819e8',
		data: {
			nameDisplay: 'Population',
			nameInternal: 'Population',
			tags: ['c75adc77-c487-45a7-8fc5-82f3c7b27c86'],
		},
	},
	{
		key: 'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787',
		data: {
			nameDisplay: 'All',
			nameInternal: 'all indicators sdg and non sdg',
			tags: ['c9bc599b-a6df-4664-8871-3e2e11b5f7c8'],
		},
	},
	{
		key: '1688cdcd-4613-459a-8cd9-638eb1cdb5d0',
		data: {
			nameDisplay: 'SDG',
			nameInternal: 'SDG',
			tags: ['c9bc599b-a6df-4664-8871-3e2e11b5f7c8'],
		},
	},
	{
		key: 'e7b59b9e-8ea3-4626-b4b9-34d08fc71202',
		data: {
			nameDisplay: 'Map layer',
			nameInternal: 'mapLayer',
		},
	},
	{
		key: '03305e1a-0052-4cca-a7c4-bbc4abffd0f2',
		data: {
			nameDisplay: 'Absolute',
			nameInternal: 'absolute',
		},
	},
	{
		key: '5e46cbd3-f899-4750-a2cb-77eacc796c99',
		data: {
			nameDisplay: 'Relative',
			nameInternal: 'relative',
		},
	},
];
