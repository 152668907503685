import {useEffect, useState, useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import helpers from './helpers';

import TimeRangeLabel from './TimeRangeLabel';
import TimeRangeMainChart from './TimeRangeMainChart';
import TimeRangeSelector from './TimeRangeSelector';

import './style.scss';
import TimeRangeChartLegend from './TimeRangeChartLegend';

/**
 * TimeRangeChart enables to restrict the time/date range for time-based charts.
 *
 * @param ChartComponent {React.FunctionComponent} Chart component based on Nivo.rocks charts
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} Input data following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object} Metadata of main chart
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param overviewChartMetadata {Object} Metadata of overview chart
 * @param overviewChartMetadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param [Tooltip] {React.FunctionComponent}
 * @param [step=1] {number} Time range slider step (in days)
 * @param dateRange {Array} Given actual date range
 * @param onDateRangeChange {function} Called if date range was changed
 * @param onHover {function} callback for onHover node element in chart
 * @param displayTimeFormat {string} format displayed time range
 * @param hideLegend {bool} true, if legend should be hidden
 * @param hideRangeSelector {bool} true, if range selector should be hidden
 * @returns {JSX.Element|null}
 */
// eslint-disable-next-line react/display-name
const TimeRangeChart = forwardRef(
	(
		{
			ChartComponent,
			chartComponentSettings,
			data,
			metadata,
			overviewChartMetadata,
			step = 1,
			dateRange,
			defaultPeriod,
			onDateRangeChange,
			onHover,
			hovered,
			displayTimeFormat,
			hideLegend,
			hideRangeSelector,
		},
		ref,
	) => {
		const [selectedDateRange, setSelectedDateRange] = useState([]);

		const {startDate, endDate} = useMemo(() => {
			return helpers.getTimeRangeFromData(data);
		}, [data]);

		// When input data changed, current time range is the same as default
		useEffect(() => {
			if (dateRange) {
				setSelectedDateRange(dateRange);
			} else {
				const [start, end] = selectedDateRange;
				if (startDate !== start || endDate !== end) {
					setSelectedDateRange([
						startDate !== start ? startDate : start,
						endDate !== end ? endDate : end,
					]);
				}
			}
		}, [data, dateRange]);

		const onTimeRangeChange = (start, end) => {
			if (onDateRangeChange) {
				onDateRangeChange([start, end]);
			} else {
				setSelectedDateRange([start, end]);
			}
		};

		const [defaultStart, defaultEnd] = defaultPeriod.split('/');

		return metadata && overviewChartMetadata ? (
			<div className="ptr-TimeRangeChart">
				{!hideLegend ? (
					<TimeRangeChartLegend data={data} metadata={metadata} />
				) : null}
				<TimeRangeLabel
					startDate={selectedDateRange[0]}
					endDate={selectedDateRange[1]}
					mainChartMetadata={metadata}
					format={displayTimeFormat}
				/>
				<TimeRangeMainChart
					ChartComponent={ChartComponent}
					chartComponentSettings={
						typeof chartComponentSettings === 'function'
							? chartComponentSettings({hovered})
							: chartComponentSettings
					}
					data={data}
					metadata={metadata}
					onHover={onHover}
					startDate={selectedDateRange[0]}
					endDate={selectedDateRange[1]}
					ref={ref}
					displayTimeFormat={displayTimeFormat}
				/>
				{!hideRangeSelector &&
				startDate &&
				endDate &&
				selectedDateRange[0] &&
				selectedDateRange[1] ? (
					<TimeRangeSelector
						startDate={defaultStart || startDate}
						endDate={defaultEnd || endDate}
						currentStartDate={selectedDateRange[0]}
						currentEndDate={selectedDateRange[1]}
						onTimeRangeChange={onTimeRangeChange}
						ChartComponent={ChartComponent}
						chartComponentSettings={
							typeof chartComponentSettings === 'function'
								? chartComponentSettings({hovered})
								: chartComponentSettings
						}
						data={data}
						mainChartMetadata={metadata}
						metadata={overviewChartMetadata}
						step={step}
						displayTimeFormat={displayTimeFormat}
					/>
				) : null}
			</div>
		) : null;
	},
);

TimeRangeChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.func,
	]),
	data: PropTypes.array,
	displayTimeFormat: PropTypes.string,
	metadata: PropTypes.object,
	dateRange: PropTypes.array,
	onDateRangeChange: PropTypes.func,
	onHover: PropTypes.func,
	overviewChartMetadata: PropTypes.object,
	step: PropTypes.number,
	hovered: PropTypes.array,
	hideLegend: PropTypes.bool,
	hideRangeSelector: PropTypes.bool,
	defaultPeriod: PropTypes.string,
};

export default TimeRangeChart;
