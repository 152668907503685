import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const mapLevel = Select.unhab.getMapLevelByMapKey(state, ownProps.mapKey);

	return {
		levels: Select.areas.areaTreeLevels.getAll(state),
		activeLevel: mapLevel,
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLevelChange: (mapKey, key) => {
			dispatch(
				Action.maps.setMapMetadataModifiers(mapKey, {areaTreeLevelKey: key}),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
