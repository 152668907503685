export default [
	//
	// ˇˇˇˇrevized
	//

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Minimum abs.
	{
		key: '1ae9cb89-ea52-400e-8a8c-4a0cd809fbf7',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_UE_min_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Minimum rel.
	{
		key: '1e01f96a-ca6d-4a57-a849-12f2079a5d20',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_UE_min_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Maximum abs.
	{
		key: '8412a01d-3b7f-4c25-abad-caccff9eb4e0',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_UE_max_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Maximum rel.
	{
		key: 'feb42dc0-332d-41ad-b96d-633ff3f52fce',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_UE_max_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Total increase abs.
	{
		key: '5e9898f4-01b2-48e7-a945-9afc664a3cae',
		data: {
			nameDisplay: 'Total increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_tot_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Total increase rel.
	{
		key: '17843dcd-060c-4c44-83e4-6380ffe40215',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_tot_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) },Urbanization area evolution
	// Average increase abs.
	{
		key: '0eaac7cf-3e6d-4263-b8ac-c95649c9e977',
		data: {
			nameDisplay: 'Average increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_avg_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Average increase rel.
	{
		key: '840f8957-c450-4cb5-8e2e-2c88ee6ff4a4',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_avg_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Min. increase abs.
	{
		key: 'f5f83add-0c3f-4d4b-acae-28cf0a538a3b',
		data: {
			nameDisplay: 'Min. increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_min_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Min. increase rel.
	{
		key: '533a1634-b742-4897-9d11-4f11be8dc350',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_min_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) },Urbanization area evolution
	// Max. increase abs.
	{
		key: '4889a1c5-f6d9-403c-910e-c669d27a6f00',
		data: {
			nameDisplay: 'Max. increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_max_abs',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urbanization area evolution
	// Max. increase rel.
	{
		key: '0e998d61-74da-46d4-886b-d30e04d15934',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_LC_max_rel',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) SDG indicators
	// Land consumption rate

	{
		key: '61d2b450-9546-4e68-8bf6-d09603dbaea2',
		data: {
			nameDisplay: 'Land consumption rate',
			mapLayerPeriod: '2023',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_BUILT_LCR_2023',
				sourceColumnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Total increase abs.
	{
		key: '02d27e1c-44cd-4a0a-8834-8be215e8f44e',
		data: {
			nameDisplay: 'Total increase abs.',
			shortName: 'Pop',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Total increase rel.
	{
		key: 'bab2ea5b-50f8-400e-a725-ef6ffb4eef9c',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_PC_tot_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Average increase abs.
	{
		key: '1225d913-6aff-4674-a8ac-2e0240daf11d',
		data: {
			nameDisplay: 'Average increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_POP_PC_avg_abs',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Average increase rel.
	{
		key: '7341e0a4-a9da-4bce-a516-62d8bd7abc87',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_PC_avg_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Min. increase abs.
	{
		key: '056c47e1-ae4e-4933-9a23-286088590a35',
		data: {
			nameDisplay: 'Min. increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_POP_PC_min_abs',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Min. increase rel.
	{
		key: '695cd019-df5e-4cb8-b8b5-e841fbaa49e4',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_PC_min_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Max. increase abs.
	{
		key: '6b882ebf-cc81-4ce4-a788-eb574a3e359d',
		data: {
			nameDisplay: 'Max. increase abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_POP_PC_max_abs',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Min. increase rel.
	{
		key: '2951954d-cacf-470a-96a5-41bb5fd8bdad',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_PC_max_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population
	// Min. increase abs.
	{
		key: 'b626dff7-2382-403f-9907-87c9b7a54e3c',
		data: {
			nameDisplay: 'Total population - Minimum',
			unit: 'inh./sqkm',
			type: 'relative',

			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_TOTAL_min_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population
	// Min. increase abs.
	{
		key: '8281c4e7-3fb0-4024-afae-ea476de33a73',
		data: {
			nameDisplay: 'Total population - Minimum abs.',
			type: 'absolute',
			unit: 'sqkm',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_POP_TOTAL_min_abs',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population
	// Max. increase rel.
	{
		key: 'c88e464a-5dbc-4c87-9aa8-b619abfc6d58',
		data: {
			nameDisplay: 'Total population - Maximum',
			unit: 'inh./sqkm',
			type: 'relative',

			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_TOTAL_max_rel',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population
	// Max. increase abs.
	{
		key: '66b88a05-8b68-4afb-a8b0-1cb5e9f635eb',
		data: {
			nameDisplay: 'Total population - Maximum abs.',
			type: 'absolute',
			unit: 'sqkm',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF_POP_TOTAL_max_abs',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) SDG indicators
	// Population growth rate
	{
		key: 'de759d8f-bb00-441a-a06f-0a060c855637',
		data: {
			nameDisplay: 'Population growth rate',
			mapLayerPeriod: '2023',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_POP_PGR_2023',
				sourceColumnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) Combined WSF 2016-2023 (WSF 2016-2023 + WSF 2016-2023 Population)
	// (III) SDG indicators
	// Land Consumption Rate/Population Growth Rate
	{
		key: '0432a5e6-f169-45c1-bb6a-c7407c41ea45',
		data: {
			nameDisplay: 'Land Consumption Rate/Population Growth Rate',
			mapLayerPeriod: '2023',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSF_LCRPGR_2023',
				sourceColumnNameTemplates: [
					'WSF_BUILT_LC_abs_{XXXX}',
					'WSF_POP_PC_abs_{XXXX}',
				],
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) Combined WSF 2016-2023 (WSF 2016-2023 + WSF 2016-2023 Population)
	// (III) SDG indicators
	// Land Consumption per capita
	{
		key: 'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83',
		data: {
			nameDisplay: 'Land Consumption per capita',
			mapLayerPeriod: '2023',
			unit: 'sqm/inh.',
			shortName: 'LCpC',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnNameTemplate: 'WSF_LCPC_{XXXX}',
				sourceColumnNameTemplates: [
					'WSF_BUILT_LC_abs_{XXXX}',
					'WSF_POP_PC_abs_{XXXX}',
				],
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Minimum
	{
		key: 'e9cad7d0-15f0-4439-99a3-e07a808734d0',
		data: {
			nameDisplay: 'Minimum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_UE_min_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area
	//  Total area of urban extent - Minimum
	{
		key: 'a3f664d8-3fa0-47cd-a0a6-81d4df001c7e',
		data: {
			nameDisplay: 'Minimum absolute',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_UE_min_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Maximum, relative
	{
		key: '870b2857-176a-4471-82a8-5ab909d44b72',
		data: {
			nameDisplay: 'Maximum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_UE_max_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - Maximum
	{
		key: '72129a88-cd36-45a5-b811-a4ff23b38223',
		data: {
			nameDisplay: 'Maximum absolute',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_UE_max_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Total increase Rel.
	{
		key: '882e8fe1-c758-42c1-9344-c60214c699c2',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_LC_tot_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Total increase Abs.
	{
		key: '6cd70af5-8db3-4a78-8f01-8ccc99e17f5a',
		data: {
			nameDisplay: 'Total increase absolute',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_LC_tot_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_DIFF_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Average increase Rel.
	{
		key: '0a96537a-3da1-4713-96cd-12d9efc1a7ba',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_LC_avg_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Average increase Abs.
	{
		key: '7938c69d-366f-4642-aea5-a15bc91977de',
		data: {
			nameDisplay: 'Average increase Abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_LC_avg_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_DIFF_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Min. increase Rel.
	{
		key: 'fc4d2120-a0da-4f72-a718-f1a68bda6681',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_LC_min_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Min. increase Abs.
	{
		key: '4e25db77-aaa0-4e8e-9d07-c435199fbe6b',
		data: {
			nameDisplay: 'Min. increase Abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_LC_min_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Max. increase Rel.
	{
		key: '033ea81e-0660-4234-9d87-47ba1b689910',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_LC_max_rel',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urbanization area evolution
	// Max. increase Abs.
	{
		key: '52761164-26aa-46c6-ad64-7d9e8560025d',
		data: {
			nameDisplay: 'Max. increase Abs.',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_10m_LC_max_abs',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) SDG
	// Land consumption rate
	{
		key: '73e1d399-0f65-46ea-b8d4-fc91b715cd21',
		data: {
			nameDisplay: 'Land consumption rate',
			mapLayerPeriod: '2023',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_10m_LCR_2023',
				sourceColumnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2019 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)]
	{
		key: 'be396866-7981-4c2c-a90a-f9b23ce9e158',
		data: {
			nameDisplay:
				'Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)]',
			unit: 'sqkm',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSF2019_tot_abs',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2019 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)]
	{
		key: 'b0c2f72d-3977-46a9-bad4-983e7af73e2e',
		data: {
			nameDisplay:
				'Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)]',
			unit: 'per unit area (%)',
			configuration: {
				columnName: 'WSF2019_tot_rel',
			},
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF 2019 - Built-up (10m)
	// (III) Pop
	// Total population - absolute [inh] & relative [per unit area (%)]
	{
		key: '76b858f6-a289-4fa2-916a-7fb0bbe13f25',
		data: {
			nameDisplay:
				'Total population - absolute [inh] & relative [per unit area (%)] ',
			unit: 'inh.',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', //population
			],
			configuration: {
				columnName: 'WSF2019POP3D_POP_tot',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) Combined WSF 2019 (WSF 2019 + WSF 2019 Population)
	// (III) SDG indicators
	// Land Consumption per capita
	{
		key: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5',
		data: {
			nameDisplay: 'Land Consumption per capita',
			mapLayerPeriod: '2019',
			unit: 'sqm/inh.',
			configuration: {
				columnName: 'WSF2019POP3D_LCPC',
			},
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area
	// Total area of urban extent - Minimum
	{
		key: '2f6aa60c-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_UE_min_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area
	// Total area of urban extent - Minimum - absolute
	{
		key: '2f6aa6e8-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum (abs)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_UE_min_abs',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area
	// Total area of urban extent - Maximum
	{
		key: '2f6aac2e-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_UE_max_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area
	// Total area of urban extent - absolute [sqkm]
	{
		key: '2f6aaf4e-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum (abs)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_UE_max_abs',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Total increase
	{
		key: '79d7629a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_LC_tot_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Total increase - absolute
	{
		key: '79d763c6-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_LC_tot_abs',
				sourceColumnNameTemplate: 'WSFE_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Average increase
	{
		key: '79d7674a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_LC_avg_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Average increase - absolute
	{
		key: '79d76862-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_LC_avg_abs',
				sourceColumnNameTemplate: 'WSFE_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Min. increase
	{
		key: '79d76f56-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_LC_min_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Min. increase - absolute
	{
		key: '79d77064-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_LC_min_abs',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Max. increase
	{
		key: '79d773b6-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_LC_max_rel',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// Max. increase - absolute
	{
		key: '79d777f8-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'WSFE_LC_max_abs',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urbanization area evolution
	// FIXME add for graph Yearly Yearly increase -  absolute [sqkm per year]  & relative [% to previous year stock]

	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) SDG
	// Land consumption rate
	{
		key: 'ca15b319-d332-49ca-870c-b9240bd340a2',
		data: {
			nameInternal: 'WSFE_LCR',
			nameDisplay: 'Land consumption rate',
			mapLayerPeriod: '2015',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'WSFE_LCR',
				sourceColumnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area
	// Total area of urban extent - Minimum
	{
		key: '2f6aa260-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum - relative',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_UE_min_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area
	// Total area of urban extent - Minimum - absolute
	{
		key: '2f6aa508-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Minimum - absolute',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_BUILT_UE_min_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area
	// Total area of urban extent - Maximum
	{
		key: '2f6aa7ce-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum - relative',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_UE_max_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area
	// Total area of urban extent - Maximum - absolute [sqkm]
	{
		key: '2f6aa8be-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total area of urban extent - Maximum - absolute',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_BUILT_UE_max_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Total increase - relative
	{
		key: '79d75e58-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_tot_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Total increase
	{
		key: '79d7615a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase absolute (GHS Built-up data)',
			shortName: 'LC',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Average increase
	{
		key: '79d764f2-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase - relative',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_avg_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Average increase - absolute
	{
		key: '79d7661e-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase absolute (GHS Built-up data)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_avg_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Min. increase
	{
		key: '79d76d08-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_min_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Min. increase - absolute
	{
		key: '79d76e3e-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase absolute (GHS Built-up)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_min_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Max. increase
	{
		key: '79d77190-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_LC_max_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urbanization area evolution
	// Max. increase - absolute
	{
		key: '79d77294-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase absolute (GHS Built-up)',
			unit: 'sqkm',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_BUILT_LC_max_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) SDG
	// Land consumption rate
	{
		key: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
		data: {
			nameInternal: 'GHS_BUILT_LCR',
			nameDisplay: 'Land Consumption Rate',
			mapLayerPeriod: '2020',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_BUILT_LCR_2020',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// Total population - Minimum
	{
		key: 'c3914386-8047-4526-93dd-ad6b7497f8c4',
		data: {
			nameDisplay: 'Total population - Minimum',
			unit: 'inh./sqkm',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_TOTAL_min_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// Total population - Minimum
	{
		key: '00eb991b-167c-4b2e-a7c9-86e5725374e7',
		data: {
			nameDisplay: 'Total population - Minimum, absolute',
			unit: 'inh.',
			type: 'absolute',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_POP_TOTAL_min_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// Total population - Maximum
	{
		key: '48b6e7c7-b386-43ae-8172-2395df2900e6',
		data: {
			nameDisplay: 'Total population - Maximum',
			unit: 'inh./sqkm',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_TOTAL_max_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// Total population - Maximum
	{
		key: '0440450f-2e73-49a0-abdb-6f147515d998',
		data: {
			nameDisplay: 'Total population - Maximum, absolute',
			unit: 'inh.',
			type: 'absolute',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_POP_TOTAL_max_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// FIXME add for graph Total population (density) - absolute [inh] & relative [per unit area (%)]

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Total increase - absolute
	{
		key: 'ab4fe885-65db-4843-b43b-3c16693a9ac7',
		data: {
			nameDisplay: 'Total increase',
			unit: 'inh.',
			type: 'absolute',
			shortName: 'Pop',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Total increase - relative
	{
		key: '07c38b31-9fdb-463e-b917-dbbafaa01f7a',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_PC_tot_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Average increase - relative
	{
		key: 'ff258f32-5a90-4b51-a760-17a6d6e2b704',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_PC_avg_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Average increase - absolute
	{
		key: 'cbd8cc55-5a1c-4492-892b-86e87ed63872',
		data: {
			nameDisplay: 'Average increase absolute',
			unit: 'inh.',
			type: 'absolute',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_POP_PC_avg_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Min. increase - relative
	{
		key: 'e1e76504-3a30-48fb-936b-1830557ad812',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_PC_min_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Min. increase - absolute
	{
		key: '6da76d87-977e-4b50-9bfa-3190c28f8a20',
		data: {
			nameDisplay: 'Min. increase absolute',
			unit: 'inh.',
			type: 'absolute',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_POP_PC_min_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Max. increase - relative
	{
		key: 'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_PC_max_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Max. increase - absolute
	{
		key: '08630acd-7ecf-4e90-bcaf-b0af90ca52e4',
		data: {
			nameDisplay: 'Max. increase absolute',
			unit: 'inh.',
			type: 'absolute',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'03305e1a-0052-4cca-a7c4-bbc4abffd0f2', //Absolute
			],
			configuration: {
				columnName: 'GHS_POP_PC_max_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// FIXME add for graph Yearly increase - absolute [inh per year] & relative [% to previous year stock]

	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) SDG
	// Population growth rate
	{
		key: 'fedcb00a-92dc-4a03-acf0-a63040373b9f',
		data: {
			nameDisplay: 'Population Growth Rate',
			mapLayerPeriod: '2020',
			type: 'relative',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'e7b59b9e-8ea3-4626-b4b9-34d08fc71202', //mapLayer
				'5e46cbd3-f899-4750-a2cb-77eacc796c99', //relative
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
			],
			configuration: {
				columnName: 'GHS_POP_PGR_2020', // sdg attribute
				sourceColumnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) Combined GHSL 1975-2030 - Built-up + Population (100m)
	// (III) SDG
	// Land Consumption Rate/Population Growth Rate
	{
		key: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4',
		data: {
			nameDisplay: 'Land Consumption Rate/Population Growth Rate',
			mapLayerPeriod: '2020',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
			],
			configuration: {
				columnName: 'GHS_LCRPGR',
				sourceColumnNameTemplates: [
					'GHS_BUILT_LC_abs_{XXXX}',
					'GHS_POP_PC_abs_{XXXX}',
				],
			},
		},
	},

	// (I) Global Human Settlement Layer Suite
	// (II) Combined GHSL 1975-2030 - Built-up + Population (100m)
	// (III) SDG
	// Land Consumption per capita
	{
		key: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571',
		data: {
			nameDisplay: 'Land Consumption per capita',
			shortName: 'LCpC',
			mapLayerPeriod: '2020',
			unit: 'sqm/inh.',
			tags: [
				'13b7fd98-d80d-4993-8293-77dac979bfe8', //All themes
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', //expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', //base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', //coverage all
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
			],
			configuration: {
				columnNameTemplate: 'GHS_LCPC_{XXXX}',
				sourceColumnNameTemplates: [
					'GHS_BUILT_LC_abs_{XXXX}',
					'GHS_POP_PC_abs_{XXXX}',
				],
			},
		},
	},

	//
	// ^^^^^revized
	//

	// UNUSED ATTRIBUTES IN MAP => Chart attributes

	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - time series
	{
		key: '03a6772d-c07e-4ac2-a18d-a87ec02889fe',
		data: {
			nameDisplay: 'Total area of urban extent',
			unit: 'sqkm',
			shortName: 'LC',
			configuration: {
				columnNameTemplate: 'WSF_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area evolution
	// absolute
	{
		key: 'a8558a05-ff96-4a50-aa7f-8187cce59900',
		data: {
			nameDisplay:
				'Urban area evolution - absolute (WSF 2016-2023 - Built-up (10m) - absolute)',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'WSF_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) Urban area evolution
	// relative
	{
		key: '43a63903-21e3-4b6b-9b09-a4dc2df4402c',
		data: {
			nameDisplay:
				'Urban area evolution - relative (WSF 2016-2023 - Built-up (10m) - absolute)',
			unit: '%',
			configuration: {
				columnNameTemplate: 'WSF_BUILT_LC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) SDG
	// Land consumption rate
	{
		key: 'f950af79-83be-42b7-8174-12f9353cb642',
		data: {
			nameDisplay:
				'Land consumption rate (WSF 2016-2023 - Built-up (10m) - absolute)',
			shortName: 'LCR',
			configuration: {
				columnNameTemplate: 'WSF_BUILT_LCR_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population
	// Population progress
	{
		key: '5b9897d2-6a55-4010-b4db-c172006188b2',
		data: {
			nameDisplay: 'Population',
			unit: 'inh.',
			configuration: {
				columnNameTemplate: 'WSF_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Absolute - time series
	{
		key: '34d478e3-c42c-4893-9330-1e961a93d667',
		data: {
			nameDisplay: 'Population evolution abs.',
			unit: 'inh.',
			type: 'absolute',
			configuration: {
				columnNameTemplate: 'WSF_POP_PC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Population (10m)
	// (III) Population evolution
	// Relative - time series
	{
		key: 'de413f2a-02a3-4ec5-93a9-348d6ef71fc0',
		data: {
			nameDisplay: 'Population evolution rel.',
			unit: '%',
			type: 'relative',
			configuration: {
				columnNameTemplate: 'WSF_POP_PC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) SDG
	// Population growth rate - time series
	{
		key: '0a17814e-18cc-46e3-ac26-f7e97465d3a1',
		data: {
			nameDisplay: 'Population growth rate (WSF 2016-2023 Population (10m))',
			shortName: 'PGR',
			configuration: {
				columnNameTemplate: 'WSF_POP_PGR_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) SDG
	// Land Consumption Rate/Population growth rate - time series
	{
		key: 'd5e2a536-b344-4bc6-9bc8-d509801d82f9',
		data: {
			nameDisplay:
				'Land consumption rate / Population growth rate (WSF 2016-2023 (10m))',
			shortName: 'LCR/PGR',
			configuration: {
				columnNameTemplate: 'WSF_LCRPGR_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF 2016-2023 Built-up (10m)
	// (III) SDG
	// Land Consumption per capita - time series
	{
		key: 'f27dc482-2112-4ebb-8a1f-ab3e466d4a35',
		data: {
			nameDisplay: 'Land Consumption per capita',
			unit: 'sqm/inh.',
			configuration: {
				columnNameTemplate: 'WSF_LCPC_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area
	// Total area of urban extent - time series
	{
		key: '08a819f3-d71b-4753-8929-65f67107dd22',
		data: {
			nameDisplay: 'Total area of urban extent',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'WSFE_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area evolution
	// absolute
	{
		key: '3f640746-34c7-4225-b408-c8d77cd21bb0',
		data: {
			nameDisplay: 'Urban area evolution',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'WSFE_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) Urban area evolution
	// relative
	{
		key: '91e8179b-0598-488c-a214-5fa53a03d956',
		data: {
			nameDisplay: 'Urban area evolution',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'WSFE_LC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (30m)
	// (III) SDG
	// Land consumption rate
	{
		key: '58db1745-da16-420a-bb48-7aa5c654b279',
		data: {
			nameDisplay: 'Land consumption rate',
			configuration: {
				columnNameTemplate: 'WSFE_LCR_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area
	// Total area of urban extent - time series
	{
		key: '73e4f64a-47c1-4e8c-9dc6-1687fe3e9eb3',
		data: {
			nameDisplay: 'Total area of urban extent',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnNameTemplate: 'WSFE_10m_LC_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area evolution
	// absolute - time series
	{
		key: '99e44729-2e5b-4a5e-b885-27a90dbf9e4a',
		data: {
			nameDisplay: 'Urban area evolution - absolute',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnNameTemplate: 'WSFE_10m_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2023 - Built-up (10m)
	// (III) Urban area evolution
	// relative - time series
	{
		key: 'af18f499-e699-40d7-81d7-00cb4ce0f131',
		data: {
			nameDisplay: 'Urban area evolution - relative',
			unit: '%',
			type: 'relative',
			configuration: {
				columnNameTemplate: 'WSFE_10m_LC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) World Settlement Footprint Suite
	// (II) WSF Evolution 1985-2015 - Built-up (10m)
	// (III) SDG
	// Land consumption rate
	{
		key: 'ee76613c-362b-43f0-b8fe-71560e7ac094',
		data: {
			nameDisplay: 'Land consumption rate',
			configuration: {
				columnNameTemplate: 'WSFE_10m_LCR_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area
	// Total area of urban extent - time series
	{
		key: 'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f',
		data: {
			nameDisplay: 'Total area of urban extent',
			shortName: 'LC',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area evolution
	// absolute
	{
		key: '8f77c076-ec70-4444-8ebb-1c7a757650f9',
		data: {
			nameDisplay: 'Urban area evolution - absolute',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) Urban area evolution
	// absolute
	{
		key: '80431bc7-fc92-444d-8748-889fc1b4c5c9',
		data: {
			nameDisplay: 'Urban area evolution - relative',
			unit: '%',
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) SDG
	// Land consumption rate
	{
		key: '42154c13-4360-4de1-ae1e-7a0ceb5162f4',
		data: {
			nameDisplay: 'Land consumption rate',
			shortName: 'LCR',
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LCR_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population
	// Population progress
	{
		key: '363d9e21-d608-462b-b021-d78b86e56b39',
		data: {
			nameDisplay: 'Population progress (GHS Population data)',
			unit: 'inh.',
			configuration: {
				columnNameTemplate: 'GHS_POP_PC_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Absolute
	{
		key: 'a1e05dde-4a6b-46f0-a233-f6db97f9b386',
		data: {
			nameDisplay: 'Population evolution abs.',
			unit: 'inh.',
			configuration: {
				columnNameTemplate: 'GHS_POP_PC_DIFF_abs_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Population (100m)
	// (III) Population evolution
	// Relative
	{
		key: 'ba1ae30f-df22-4914-8e28-57ce2a88c1cc',
		data: {
			nameDisplay: 'Population evolution rel.',
			unit: '%',
			configuration: {
				columnNameTemplate: 'GHS_POP_PC_DIFF_rel_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) SDG
	// Population growth rate
	{
		key: '338aa0b6-8ec4-4ad3-ab05-4e917bf8cee7',
		data: {
			nameDisplay: 'Population growth rate',
			shortName: 'PGR',
			configuration: {
				columnNameTemplate: 'GHS_POP_PGR_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) SDG
	// Land Consumption Rate / Population growth rate
	{
		key: 'a260ff12-fd67-444f-8b7a-2a6d44f67cec',
		data: {
			nameDisplay: 'Land consumption rate / Population growth rate',
			shortName: 'LCR/PGR',
			configuration: {
				columnNameTemplate: 'GHS_LCRPGR_{XXXX}',
			},
		},
	},
	// (I) Global Human Settlement Layer Suite
	// (II) GHSL 1975-2030 - Built-up  (100m)
	// (III) SDG
	// Land Consumption per capita
	{
		key: 'c1f287e2-a30f-465b-a384-1e283471aca2',
		data: {
			nameDisplay: 'Land Consumption per capita',
			unit: 'sqm/inh.',
			configuration: {
				columnNameTemplate: 'GHS_LCPC_{XXXX}',
			},
		},
	},
	// Chart attribute - normalization
	{
		key: '4e05b390-0e33-41e1-949d-2d51616a4af7',
		data: {
			nameDisplay: 'Area',
			unit: 'sqkm',
			configuration: {
				columnName: 'Shape_Area_km2',
			},
		},
	},
];
