import PropTypes from 'prop-types';
import {useState} from 'react';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';

import './style.scss';
import datasets from '../../../../../../data/datasets';
import Tag from '../../../../Tag';
import {globalTagKey, partialTagKey} from '../../../../../../constants/app';

const DataLayersGroup = ({
	datasetKey,
	title,
	defaultOpen,
	children,
	secondary,
	highlighted,
	closeable = true,
	large,
	classes,
	disableOpen,
	setSectionIsOpen,
}) => {
	const [isOpen, setOpen] = useState(defaultOpen);

	const localClasses = classnames(
		`unhab-DataLayersGroup`,
		{
			'is-open': isOpen,
			'is-secondary': secondary,
			'is-highlighted': highlighted,
			'is-large': large,
			'is-closeable': closeable,
		},
		classes,
	);

	const dataset = datasets.find(dataset => {
		return dataset?.key === datasetKey;
	});

	const datasetTags = dataset?.data?.tags.filter(tag => {
		return tag === globalTagKey || tag === partialTagKey;
	});

	return (
		<div className={localClasses}>
			{title ? (
				<div
					className="unhab-DataLayersGroup-header"
					onClick={() => {
						if (closeable && !disableOpen) {
							setOpen(!isOpen),
								setSectionIsOpen ? setSectionIsOpen(!isOpen) : null;
						}
					}}
					tabIndex={0}
				>
					<div className="unhab-DataLayersGroup-title" title={title}>
						{title}
						<div className="unhab-DataLayersGroup-tags">
							{datasetTags?.map(tag => {
								return <Tag tagKey={tag} key={tag} small />;
							})}
						</div>
					</div>
					{closeable ? (
						<div className="unhab-DataLayersGroup-control">
							<Icon icon={isOpen ? 'ri-chevron-up' : 'ri-chevron-down'} />
						</div>
					) : null}
				</div>
			) : null}
			<div className="unhab-DataLayersGroup-content">{children}</div>
		</div>
	);
};

DataLayersGroup.propTypes = {
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	children: PropTypes.node,
	defaultOpen: PropTypes.bool,
	large: PropTypes.bool,
	secondary: PropTypes.bool,
	highlighted: PropTypes.bool,
	closeable: PropTypes.bool,
	classes: PropTypes.string,
	datasetKey: PropTypes.string,
	disableOpen: PropTypes.bool,
	setSectionIsOpen: PropTypes.func,
};

export default DataLayersGroup;
