import PropTypes from 'prop-types';
import './style.scss';
import Scale from '../../../../../common/Map/Scale';
import MapAttribution from '../../../../../common/Map/MapAttribution';
import MapLegend from '../../../../../common/Map/MapLegend';

const MapInfo = ({mapKey, map}) => {
	return (
		<div className="unhab-MapInfo-elements">
			<MapLegend mapKey={mapKey} />
			<div className="unhab-MapInfo-elementsGroup">
				<Scale mapKey={mapKey} {...map} />
				<MapAttribution mapKey={mapKey} />
			</div>
		</div>
	);
};

MapInfo.propTypes = {
	mapKey: PropTypes.string,
	map: PropTypes.object,
};

export default MapInfo;
