import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import featureUtils from '../../../utils/features';
import FormFooter from '../Form/FormFooter';
import Form from '../Form/Form';
import AreaPicker from '../AreaPicker';

import './style.scss';
import {areaComparisonType} from '../../../constants/app';

const ChangeUDTAreaDialog = ({
	countries = {},
	activeSelectionKeys = [],
	activeComparisonType,
	onActiveFeatureChange,
	onClose,
}) => {
	const [country, region] = featureUtils.getCountryRegionKeysFromFeatureKey(
		activeSelectionKeys?.[0],
	);
	const [country2, region2] = featureUtils.getCountryRegionKeysFromFeatureKey(
		activeSelectionKeys?.[1],
	);
	const [selectedCountry, setSelectedCountry] = useState({value: country});
	const [selectedRegion, setSelectedRegion] = useState({value: region});
	const [selectedCountry2, setSelectedCountry2] = useState({value: country2});
	const [selectedRegion2, setSelectedRegion2] = useState({value: region2});

	useEffect(() => {
		const [country, region] = featureUtils.getCountryRegionKeysFromFeatureKey(
			activeSelectionKeys?.[0],
		);
		const [country2, region2] = featureUtils.getCountryRegionKeysFromFeatureKey(
			activeSelectionKeys?.[1],
		);

		if (country) {
			setSelectedCountry({value: country});
		}
		if (region) {
			setSelectedRegion({value: region});
		}
		if (country2) {
			setSelectedCountry2({value: country2});
		}
		if (region2) {
			setSelectedRegion2({value: region2});
		}
	}, [onActiveFeatureChange]);

	const setActiveFeatures = () => {
		let featureKey = null;
		let featureKey2 = null;

		if (selectedRegion?.value) {
			featureKey = selectedRegion?.value;
		} else if (selectedCountry?.value) {
			featureKey = selectedCountry?.value;
		}

		if (selectedRegion2?.value) {
			featureKey2 = selectedRegion2?.value;
		} else if (selectedCountry2?.value) {
			featureKey2 = selectedCountry2?.value;
		}

		// clear selection state
		setSelectedCountry(null);
		setSelectedRegion(null);
		setSelectedCountry2(null);
		setSelectedRegion2(null);

		if (featureKey2) {
			onActiveFeatureChange([featureKey, featureKey2]);
		} else {
			onActiveFeatureChange([featureKey]);
		}
	};

	const area1NotChanged =
		selectedCountry?.value === country &&
		(selectedRegion?.value === region || (!region && !selectedRegion));
	let disabled;

	if (activeComparisonType === areaComparisonType) {
		const area2NotChanged =
			selectedCountry2?.value === country2 &&
			(selectedRegion2?.value === region2 || (!region2 && !selectedRegion2));
		//disable save when city is not selected
		const citiesDefined = !!(selectedRegion?.value && selectedRegion2?.value);
		disabled = (area1NotChanged && area2NotChanged) || !citiesDefined;
	} else {
		disabled = area1NotChanged;
	}

	const onChangeClick = () => {
		setActiveFeatures();
		onClose();
	};

	return (
		<div className="unhab-ChangeAreaDialog">
			<h2>Change area</h2>
			<Form>
				<div className="unhab-ChangeAreaDialog-form-row">
					<div className="unhab-ChangeAreaDialog-form-column">
						<AreaPicker
							large
							countries={countries}
							selectedCountry={selectedCountry}
							selectedRegion={selectedRegion}
							onCountryChange={setSelectedCountry}
							onRegionChange={setSelectedRegion}
							regionsClearable={false}
							autoselectFirstRegion={true}
							regionLabel="City"
						/>
					</div>
					{activeComparisonType === areaComparisonType && (
						<div className="unhab-ChangeAreaDialog-form-column">
							<AreaPicker
								large
								countries={countries}
								selectedCountry={selectedCountry2}
								onCountryChange={setSelectedCountry2}
								selectedRegion={selectedRegion2}
								onRegionChange={setSelectedRegion2}
								regionsClearable={false}
								autoselectFirstRegion={true}
								regionLabel="City"
							/>
						</div>
					)}
				</div>
				<FormFooter>
					<Button disabled={disabled} onClick={onChangeClick} primary>
						{'Change & close'}
					</Button>
					<Button onClick={onClose}>{'Close'}</Button>
				</FormFooter>
			</Form>
		</div>
	);
};

ChangeUDTAreaDialog.propTypes = {
	countries: PropTypes.object,
	onActiveFeatureChange: PropTypes.func,
	onClose: PropTypes.func,
	activeComparisonType: PropTypes.string,
	activeSelectionKeys: PropTypes.array,
	isOpen: PropTypes.bool,
};

export default ChangeUDTAreaDialog;
