import PropTypes from 'prop-types';
import './style.scss';

const Menu = ({children}) => {
	return <div className="unhab-Menu">{children}</div>;
};

Menu.propTypes = {
	children: PropTypes.node,
};

export default Menu;
