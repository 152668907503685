import PropTypes from 'prop-types';
import './style.scss';

const RelativeAttribute = ({value, unit, isChange}) => {
	const sign =
		isChange && (value || value === 0) ? (value >= 0 ? '+' : '-') : '';

	return (
		<div className="unhab-Attribute-relative">
			<div>
				{sign} {value && value.toFixed(2)}
			</div>
			<span>{unit || ''}</span>
		</div>
	);
};

RelativeAttribute.propTypes = {
	value: PropTypes.number,
	unit: PropTypes.string,
	isChange: PropTypes.bool,
};

export default RelativeAttribute;
