import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import {reportingPeriod} from '../../../../../constants/app';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const periodStep = Select.components.get(state, 'App', 'periodStep');

	return {
		data: Select.unhab.charts.getTimeProgressData(
			state,
			ownProps.configuration,
			ownProps.componentKey,
		),
		multipleFeatures:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.key?.length >
			1,
		currentDateRange: Select.unhab.getCurrentDateRange(state),
		showMarkers: periodStep === reportingPeriod,
		periodStep,
		selected: Select.components.get(state, ownProps.componentKey, 'selected'),
		defaultPeriod: Select.unhab.getPeriodExtent(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			if (ownProps.selectable) {
				dispatch(
					Action.components.set(ownProps.componentKey, 'selected', true),
				);
			}
		},
		onUnmount: () => {
			if (ownProps.selectable) {
				dispatch(
					Action.components.set(ownProps.componentKey, 'selected', false),
				);
			}
		},
		onSelectedChange: selected => {
			dispatch(
				Action.components.set(ownProps.componentKey, 'selected', selected),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
