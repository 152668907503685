import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
// import Action from '../../../state/Action';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		model: Select.unhab.cityClusterMethods.getByKey(state, ownProps.methodKey),
		parameter: Select.unhab.cityClusterMethodParameters.getByKey(
			state,
			ownProps.parameterKey,
		),
		//TODO - get parameter value which is in store for related method
		// parameterValue: Select.unhab.cityClusterMethods.getAll(state),
	};
};

const mapDispatchToProps = () => {
	return {
		setActiveKeys: () => {
			//set parameter value for related method
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
