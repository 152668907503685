import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {uniqBy as _uniqBy} from 'lodash';
import {Select} from '@gisatcz/ptr-state';
import indicatorsSelectors from '../indicators/selectors';
import areaTreeTypesSelectors from '../areaTreeTypes/selectors';
import cityClusterMethodsSelectors from '../cityClusterMethods/selectors';
import benchmarkComparisonTypesSelectors from '../benchmarkComparisonTypes/selectors';
import featuresForScatterPlot from '../featuresForScatterPlot/selectors';
import datasetSelectors from '../datasets/selectors';
import utils from '../../../utils';
import featureUtils from '../../../utils/features';
import selectorHelpers from '../selectorHelpers';
import {
	benchmarkViewKey,
	distinctColours,
	reportViewKey,
	cityClustersAreaTreeKey,
	datasetsComparisonType,
	auSelectionKey,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../constants/app';

/**
 * For now, just get normalization attribute columnName
 * @param configuration {Object} Chart configuration
 */
const getNormalizationDefinition = createCachedSelector(
	[
		Select.attributes.getAllAsObject,
		(state, configuration) => configuration?.normalization,
	],
	(attributes, normalization) => {
		if (normalization && attributes?.[normalization.attribute]) {
			const attribute = attributes?.[normalization.attribute];
			return {
				...normalization,
				attribute: attribute?.data?.configuration?.columnName,
			};
		} else {
			return null;
		}
	},
)((state, config, key) => key);

// Charts selectors
const getTimeProgressData = createCachedSelector(
	[
		indicatorsSelectors.getActiveFeaturesByActiveAreaTreeKey,
		Select.selections.getActive,
		Select.views.getActiveKey,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
		areaTreeTypesSelectors.getActiveKey,
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
		benchmarkComparisonTypesSelectors.getActiveKey,
	],
	(
		features,
		activeSelection,
		viewKey,
		attributes,
		normalization,
		activeAreaTreeKey,
		activeCityClustersMethodsParametersKeys,
		benchmarkComparisonType,
	) => {
		if (features?.length && attributes?.length) {
			const data = [];

			if (
				features?.length > 1 &&
				activeAreaTreeKey === cityClustersAreaTreeKey &&
				benchmarkComparisonType === datasetsComparisonType
			) {
				attributes.forEach(attribute => {
					const method = attribute.originMethod;

					const paramsRelatedToAttribute =
						attribute.originMethod.parametersKeys;

					const feature = features.find(
						f => f.key === paramsRelatedToAttribute,
					);

					const {data: properties} = feature;
					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);

					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);

					if (timeSerie.length > 0) {
						data.push({
							id: paramsRelatedToAttribute,
							name: `${method.data.nameDisplay}`,
							data: timeSerie,
							color: method.data.color,
						});
					}
				});
				return data;
			} else if (features?.length > 1) {
				const uniqueFeatures = _uniqBy(features, 'key');
				uniqueFeatures.forEach((feature, index) => {
					const {data: properties} = feature;
					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					attributes.forEach(attribute => {
						const filteredDataAsObject =
							selectorHelpers.filterPropertiesByTemplate(
								properties,
								attribute.template,
							);
						const timeSerie = selectorHelpers.getFormattedTimeSerie(
							filteredDataAsObject,
							normValue,
						);
						let color;
						if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
							color = distinctColours[index];
						} else {
							color = utils.getSelectedFeaturePrimaryColor(
								feature?.key,
								activeSelection?.data,
							);
						}

						data.push({
							id: feature?.key,
							name: selectorHelpers.getAreaName(properties),
							data: timeSerie,
							color,
							attribute: attribute?.name,
						});
					});
				});
				return data;
			} else if (attributes?.length === 1) {
				features.forEach(feature => {
					const {data: properties} = feature;
					const attribute = attributes[0];

					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);

					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute.template,
						name: attribute.nameCombined || attribute.name,
						data: timeSerie,
						color: attribute.colorCombined || attribute.color,
					});
				});
				return data;
			} else if (features.length === 1) {
				const feature = features[0];
				const {data: properties} = feature;
				const normValue = selectorHelpers.getNormalizationAttributeValue(
					properties,
					normalization,
				);
				attributes.forEach(attribute => {
					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);
					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute?.template,
						name: attribute.nameCombined || attribute?.name,
						data: timeSerie,
						color: attribute.colorCombined || attribute?.color,
					});
				});

				return data;
			}

			return null;
		} else {
			// TODO add another ways how to define attributes for time serie
			return null;
		}
	},
)((state, config, key) => key);

const getAreaShareData = createSelector(
	[
		indicatorsSelectors.getActiveFeaturesByActiveAreaTreeKey,
		Select.selections.getActive,
		Select.views.getActiveKey,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
	],
	(features, activeSelection, viewKey, attributes, normalization) => {
		if (features?.length && attributes?.length) {
			const [themeAttribute] = attributes;
			const areaAttribute = normalization.attribute;
			if (themeAttribute && areaAttribute) {
				const data = [];
				features.forEach((feature, index) => {
					const properties = feature.data;
					const themeValue = properties?.[themeAttribute.key];
					const areaValue = properties?.[areaAttribute];
					let color;
					if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
						color = distinctColours[index];
					} else {
						color = utils.getSelectedFeaturePrimaryColor(
							feature?.key,
							activeSelection?.data,
						);
					}

					data.push({
						name: selectorHelpers.getAreaName(properties),
						share: themeValue / areaValue,
						data: [
							{
								id: themeAttribute.key,
								label: themeAttribute.name,
								originalValue: themeValue,
								value: themeValue,
								color,
							},
							{
								id: areaAttribute,
								label: areaAttribute,
								originalValue: areaValue - themeValue,
								value: areaValue - themeValue,
								color: '#bbbbbb',
							},
						],
					});
				});
				return data;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
);

const getMultipleAttributesData = createSelector(
	[
		selectorHelpers.getFilteredAttributesByDatasets,
		featuresForScatterPlot.getAll,
		datasetSelectors.getActiveModels,
		Select.periods.getActive,
		Select.selections.getAllAsObject,
		Select.views.getActiveKey,
		(state, configuration) => configuration,
	],
	(
		attributes,
		features,
		activeDatasets,
		period,
		selections,
		viewKey,
		configuration,
	) => {
		const activeAttributeKeys = filterAttributeKeysByActiveDatasets(
			configuration.attributes,
			activeDatasets,
		);
		if (
			attributes?.length > 1 &&
			features?.length &&
			period &&
			activeAttributeKeys?.length > 0
		) {
			const year = period.data.period.split('/')[1]; // TODO get last year for now
			const finalFeatures = [];
			let atLeastOneFeatureSelected = false;
			features.forEach(feature => {
				const {data: properties, key: featureKey} = feature;
				const data = {};
				const attributeNameByData = {};
				const params = ['x', 'y', 'z'];

				// add values
				attributes.forEach((attribute, i) => {
					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);
					const value = filteredDataAsObject[year];
					if (value) {
						data[params[i]] = value;
					}
					attributeNameByData[params[i]] = attribute.shortAttributeName;
				});

				// add name & color
				data.name = selectorHelpers.getAreaName(properties);
				data.color = selectorHelpers.getFourCountriesAreaColor(properties);
				data.shape = selectorHelpers.getFourCountriesAreaShape(properties);
				data.attributeNameByData = attributeNameByData;

				let fitsFilter = true;
				if (configuration?.regionsOnly) {
					fitsFilter = featureUtils.isFeatureRegion(featureKey);
				} else if (configuration?.countriesOnly) {
					fitsFilter = featureUtils.isFeatureCountry(featureKey);
				}

				// add selection color and check selections
				if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
					const selectionArea1 = selections[benchmarkArea1SelectionKey];
					if (
						selectionArea1?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							selectionArea1?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
					const selectionArea2 = selections[benchmarkArea2SelectionKey];
					if (
						selectionArea2?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							selectionArea2?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
				} else {
					const activeSelection = selections[auSelectionKey];
					if (
						activeSelection?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							activeSelection?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
				}

				const hasValues =
					data.x ||
					(data.x === 0 && data.y) ||
					(data.y === 0 && data.z) ||
					data.z === 0;

				// add feature
				if (fitsFilter && hasValues) {
					finalFeatures.push({
						id: featureKey,
						data: [data],
					});
				}
			});

			// return data only if at least one area from all features is among selected
			return atLeastOneFeatureSelected ? finalFeatures : null;
		} else {
			return null;
		}
	},
);

function filterAttributeKeysByActiveDatasets(attributeKeys, datasets) {
	if (attributeKeys?.length && datasets?.length) {
		return attributeKeys.filter(attributeKey =>
			datasets.some(dataset =>
				dataset.data.attributes.some(a => a.key === attributeKey),
			),
		);
	} else {
		return null;
	}
}

export default {
	getTimeProgressData,
	getAreaShareData,
	getMultipleAttributesData,
};
