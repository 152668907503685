import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import cityClusterMethodsSelectors from '../cityClusterMethods/selectors';
const getSubstate = state => state.unhab.cityClusterFeatures;
const getAllAsObject = state => state.unhab.cityClusterFeatures.byKey;
const getByKey = commonSelectors.getByKey(getSubstate);
const getByKeys = commonSelectors.getByKeys(getSubstate);

const getSelectedFeatures = createSelector(
	[
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
		getAllAsObject,
	],
	(activeCityClustersMethodsParametersKeys, allFeatures) => {
		const area1Key = activeCityClustersMethodsParametersKeys?.[0];
		const area2Key = activeCityClustersMethodsParametersKeys?.[1];

		// Replace last item in key with 'latest', because attribute data are available only for latest year
		let selectedFeatureKeys = [area1Key, area2Key];
		selectedFeatureKeys = selectedFeatureKeys?.map((key = '') => {
			const keySplit = key.split('/');
			keySplit.pop();
			return [...keySplit, 'latest'].join('/');
		});

		if (selectedFeatureKeys?.length && allFeatures) {
			const features = [];
			selectedFeatureKeys.forEach(key => {
				const feature = allFeatures?.[key];
				if (feature) {
					features.push({...feature, key});
				}
			});
			return features?.length ? features : null;
		} else {
			return null;
		}
	},
);

const getSelectedFeatureFeatureKeys = createSelector(
	[getSelectedFeatures],
	features => {
		if (features) {
			return features.map(feature => feature.data.properties.featureKey);
		} else {
			return null;
		}
	},
);

export default {
	getSelectedFeatures,
	getSelectedFeatureFeatureKeys,
	getByKey,
	getByKeys,
};
