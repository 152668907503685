import PropTypes from 'prop-types';
import DatasetTags from '../../DatasetTags';
import './style.scss';
import DatasetInfo from '../../DatasetInfo';

const FilteredDatasetListItem = ({datasetKey, name}) => {
	return (
		<div className="unhab-FilteredDatasetListItem">
			<div className="unhab-FilteredDatasetListItem-name">{name}</div>
			<DatasetTags datasetKey={datasetKey} />
			<DatasetInfo datasetKey={datasetKey} />
		</div>
	);
};

FilteredDatasetListItem.propTypes = {
	datasetKey: PropTypes.string,
	name: PropTypes.string,
};

const FilteredDatasetList = ({datasets}) => {
	return (
		<div className="unhab-FilteredDatasetList">
			{datasets?.length
				? datasets.map(({key, data}) => (
						<FilteredDatasetListItem
							key={key}
							datasetKey={key}
							name={data?.nameDisplay}
						/>
					))
				: 'No datasets'}
		</div>
	);
};

FilteredDatasetList.propTypes = {
	datasets: PropTypes.array,
};

export default FilteredDatasetList;
