import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.unhab.parameterValues;

const getByKey = commonSelectors.getByKey(getSubstate);

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);

export default {
	getSubstate,
	getAll,
	getAllAsObject,
	getByKey,
};
