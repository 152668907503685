export default {
	rules: [
		{
			styles: [
				{
					outlineColor: '#888888',
				},
				{
					attributeKey: 'ADM0_CODE',
					attributeValues: {
						33: {
							outlineColor: '#888888',
							outlineWidth: 2,
						},
						51: {
							outlineColor: '#888888',
							outlineWidth: 2,
						},
						133: {
							outlineColor: '#888888',
							outlineWidth: 2,
						},
						171: {
							outlineColor: '#888888',
							outlineWidth: 2,
						},
					},
				},
			],
		},
	],
};
