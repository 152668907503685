import {commonActions} from '@gisatcz/ptr-state';
import ActionTypes from '../../../constants/ActionTypes';

const actionUpdate = data => {
	return {
		type: ActionTypes.UNHAB.AREA_TREE_TYPES.UPDATE,
		data,
	};
};

/**
 * Update whole visat state from view definition
 * @param data {Object}
 */
function updateStateFromView(data) {
	return dispatch => {
		if (data) {
			dispatch(actionUpdate(data));
		}
	};
}

export default {
	setActiveKey: commonActions.setActiveKey(ActionTypes.UNHAB.AREA_TREE_TYPES),
	updateStateFromView,
};
