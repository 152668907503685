import PropTypes from 'prop-types';
import {cloneElement} from 'react';
import {ResponsiveLineCanvas as ResponsiveLine} from '@nivo/line';
import './style.scss';

const LineChartTooltip = ({point}) => {
	if (!point.data.noHover) {
		return (
			<div className="unhab-LineChartTooltip">
				<div
					className="unhab-LineChartTooltip-color"
					style={{backgroundColor: point.color}}
				/>
				<div className="unhab-LineChartTooltip-period">
					{point.data.period}:
				</div>
				<div className="unhab-LineChartTooltip-value">
					{point.data.y.toLocaleString('en-US')}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

LineChartTooltip.propTypes = {
	point: PropTypes.object,
};

/**
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param customSettings {Object} settings customization, following nivo line chart props (https://nivo.rocks/line/)
 * @param onHover {function}
 * @param Tooltip {React.FunctionComponent}
 * @returns {JSX.Element|null}
 */
const LineChart = ({data, metadata, customSettings}) => {
	const {settings} = metadata; // TODO if no settings, then basic settings?
	return data ? (
		<ResponsiveLine
			tooltip={LineChartTooltip}
			key={JSON.stringify(data.map(item => item.id))} // TODO hot fix for multiple points selection
			data={data}
			{...settings}
			{...customSettings}
			{...(customSettings?.pointSymbol
				? {
						pointSymbol: point => {
							return cloneElement(customSettings.pointSymbol, {
								point,
								hovered: customSettings.pointSymbol.props.hovered,
							});
						},
					}
				: {})}
		/>
	) : null;
};

LineChart.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
	customSettings: PropTypes.object,
	Tooltip: PropTypes.func,
	onHover: PropTypes.func,
};

export default LineChart;
