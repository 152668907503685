import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';
import udtStructureactions from '../udtStructure/actions';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import config from '../../../config';
import utils from '../../../utils';
import {replaceUrlTemplates, composeCityClusterFeatureURI} from '../utils/url';
import {separateCityKeyFromFeatureID} from '../utils/udt';
import {
	areaComparisonType,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	benchmarkMap1Key,
	benchmarkMap2Key,
	benchmarkMapKeys,
} from '../../../constants/app';

function clearCityClustersMapLayers() {
	return dispatch => {
		benchmarkMapKeys.forEach(mapKey => {
			const layerKey = `${mapKey}-citycluster`;
			dispatch(CommonAction.maps.removeMapLayer(mapKey, `${layerKey}`));
		});
	};
}
function toggleCityClusterLayer(
	mapKey,
	featureKey,
	cityClustersMethod,
	selectionKey,
) {
	return (dispatch, getState) => {
		const layerKey = `${mapKey}-citycluster`;
		dispatch(CommonAction.maps.removeMapLayer(mapKey, `${layerKey}`));

		const state = getState();

		const cityFeatureKey = separateCityKeyFromFeatureID(featureKey);

		const urlParamsPart = replaceUrlTemplates(
			cityClustersMethod.data.urlTemplate,
			cityClustersMethod.data.parameters,
		);

		const dataUri = composeCityClusterFeatureURI(
			cityClustersMethod.data.urlPart,
			cityFeatureKey,
			urlParamsPart,
		);

		const existingFeature = Select.unhab.cityClusterFeatures.getByKey(
			state,
			dataUri,
		);

		const structureItem = Select.unhab.udtStructure.getByKey(state, featureKey);

		if (existingFeature) {
			//ensure remove layer while deactivete city cluster mode
			dispatch(
				CommonAction.maps.addMapLayerToIndex(
					mapKey,
					{
						type: 'vector',
						id: layerKey,
						key: layerKey,
						nameDisplay: `City cluster ${structureItem.data.name}`,
						options: {
							features: [
								{
									...existingFeature.data,
									properties: {
										...existingFeature.data.properties,
										id: featureKey,
									},
								},
							],
							fidColumnName: 'id',
							selectable: false,
							selected: {
								[selectionKey]: {},
							},
							style: {
								rules: [
									{
										styles: [
											{
												fill: '#000000',
												fillOpacity: 0,
												outlineWidth: 1,
												name: 'City cluster boundaries',
											},
										],
									},
								],
							},
						},
					},
					100,
				),
			);

			//zoom map to feature
			dispatch(
				udtStructureactions.fitActiveMapSetToKey(
					featureKey,
					benchmarkMapKeys.indexOf(mapKey),
				),
			);
		}
	};
}
function useSelectedFeatures() {
	return (dispatch, getState) => {
		const state = getState();
		const benchmarkType =
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state);
		const area1FeatureKey = Select.selections.getByKey(
			state,
			benchmarkArea1SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		const area2FeatureKey = Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0];

		const cityClustersMethods =
			Select.unhab.cityClusterMethods.getParametersByActiveMethods(state);

		if (area1FeatureKey) {
			//first map
			const method1 = cityClustersMethods?.[0];
			dispatch(ensure(area1FeatureKey, method1)).then(() => {
				dispatch(
					toggleCityClusterLayer(
						benchmarkMap1Key,
						area1FeatureKey,
						method1,
						benchmarkArea1SelectionKey,
					),
				);
			});
		}

		if (area2FeatureKey) {
			//second map
			const method2 =
				benchmarkType === areaComparisonType
					? cityClustersMethods?.[0]
					: cityClustersMethods?.[1];

			dispatch(ensure(area2FeatureKey, method2)).then(() => {
				dispatch(
					toggleCityClusterLayer(
						benchmarkMap2Key,
						area2FeatureKey,
						method2,
						benchmarkArea2SelectionKey,
					),
				);
			});
		}
	};
}

function ensure(featureKey, cityClustersMethod) {
	return (dispatch, getState) => {
		const state = getState();

		const cityFeatureKey = separateCityKeyFromFeatureID(featureKey);

		const urlParamsPart = replaceUrlTemplates(
			cityClustersMethod.data.urlTemplate,
			cityClustersMethod.data.parameters,
		);

		const dataUri = composeCityClusterFeatureURI(
			cityClustersMethod.data.urlPart,
			cityFeatureKey,
			urlParamsPart,
		);

		const url = `${config.cityClustersFeaturesRepo}/${dataUri}.geojson`;
		const existingFeature = Select.unhab.cityClusterFeatures.getByKey(
			state,
			dataUri,
		);
		if (!existingFeature) {
			return utils.request(url, 'GET', null, null).then(data => {
				if (data) {
					const feature = data?.features?.[0] || data;
					dispatch(
						actionAdd([
							{
								key: dataUri,
								data: {
									...feature,
									properties: {...feature.properties, featureKey}, //save origin feature key
								},
							},
						]),
					);
				} else {
					console.warn(`No data for feature ${dataUri}!`);
				}
			});
		} else {
			return Promise.resolve();
		}
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTER_FEATURES.ADD,
		data,
	};
};

export default {
	clearCityClustersMapLayers,
	useSelectedFeatures,
};
