import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import './style.scss';
import {Button, Icon} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import Form from '../Form/Form';
import FormFooter from '../Form/FormFooter';

const mapStateToProps = (state, ownProps) => {
	return {
		model: Select.unhab.datasets.getByKey(state, ownProps.datasetKey),
	};
};

const DatasetItemPresentation = ({model}) => {
	return <div className="unhab-DatasetItem">{model?.data?.nameDisplay}</div>;
};

DatasetItemPresentation.propTypes = {
	model: PropTypes.object,
};

const DatasetItem = connect(mapStateToProps)(DatasetItemPresentation);

const DatasetSetSelectorItem = ({
	datasetSetKey,
	datasets,
	active,
	onChange,
}) => {
	const classes = classnames('unhab-DatasetSetSelectorItem', {
		'is-active': active,
	});

	return (
		<div className={classes} onClick={() => onChange(datasetSetKey)}>
			{datasets?.map(datasetKey => (
				<DatasetItem key={datasetKey} datasetKey={datasetKey} />
			))}
			<div className="unhab-DatasetSetSelectorItem-icon">
				<Icon icon="ri-check" />
			</div>
		</div>
	);
};

DatasetSetSelectorItem.propTypes = {
	datasetSetKey: PropTypes.string,
	datasets: PropTypes.array,
	active: PropTypes.bool,
	onChange: PropTypes.func,
	onMount: PropTypes.func,
};

const DatasetSetSelector = ({models, activeKey, onChange, onClose}) => {
	const [selectedDatasetSet, setSelectedDatasetSet] = useState(activeKey);

	const onChangeClick = () => {
		onChange(selectedDatasetSet);
		onClose();
	};

	return (
		<div className="unhab-ChangeDatasetDialog">
			<h2>Select datasets</h2>
			<Form>
				<div className="unhab-DatasetSetSelector">
					{models?.map(model => (
						<DatasetSetSelectorItem
							key={model.key}
							datasets={model.data.datasets}
							datasetSetKey={model.key}
							active={model.key === selectedDatasetSet}
							onChange={setSelectedDatasetSet}
						/>
					))}
				</div>
				<FormFooter>
					<Button
						disabled={selectedDatasetSet === activeKey}
						onClick={onChangeClick}
						primary
					>
						{'Change & close'}
					</Button>
					<Button onClick={onClose}>{'Close'}</Button>
				</FormFooter>
			</Form>
		</div>
	);
};

DatasetSetSelector.propTypes = {
	models: PropTypes.array,
	activeKey: PropTypes.string,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
};

export default DatasetSetSelector;
