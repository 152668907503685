import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = state => {
	return {
		activeKey: Select.unhab.benchmarkComparisonTypes.getActiveKey(state),
		models: Select.unhab.benchmarkComparisonTypes.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: comparisonTypeKey => {
			dispatch(
				Action.unhab.benchmark.handleComparisonTypeChange(comparisonTypeKey),
			);

			dispatch(Action.unhab.fitMapsToSelectedFeatures());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
