import PropTypes from 'prop-types';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const ScaleControl = ({active, onChange}) => {
	return (
		<MenuToggleItem icon="ri-scale" active={active} onChange={onChange}>
			Display scale
		</MenuToggleItem>
	);
};

ScaleControl.propTypes = {
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

export default ScaleControl;
