import {
	commonSelectors,
	createRecomputeObserver,
	Select as CommonSelect,
} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {
	flatten as _flatten,
	intersection as _intersection,
	cloneDeep as _cloneDeep,
	difference as _difference,
	includes as _includes,
} from 'lodash';
import {
	modelVisibleTags,
	combinedDatasetsKeys,
	sdgIndicatorsOnlyTagKey,
	cityClustersAreaTreeKey,
} from '../../../constants/app';
import tagsSelectors from '../tags/selectors';
import areaTreeTypesSelectors from '../areaTreeTypes/selectors';
import cityClusterMethodsSelectors from '../cityClusterMethods/selectors';

const getSubstate = state => state.unhab.datasets;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getActiveKeys = commonSelectors.getActiveKeys(getSubstate);
const getActiveModels = commonSelectors.getActiveModels(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const getByAttributeKey = createSelector(
	[getAll, (state, attributeKey) => attributeKey],
	(datasets, attributeKey) => {
		return datasets.find(dataset =>
			dataset.data.attributes.find(attr => attr.key === attributeKey),
		);
	},
);

const getByAttributeKeyObserver = createRecomputeObserver((state, key) => {
	return getByAttributeKey(state, key);
});

// Filter out combined datasets
const getDatasetsForUser = createSelector([getAll], datasets => {
	return datasets.filter(
		dataset => !combinedDatasetsKeys.includes(dataset.key),
	);
});

const getAllForActiveTags = createSelector(
	[getDatasetsForUser, CommonSelect.tags.getActiveKeys],
	(datasets, activeTags) => {
		if (datasets?.length) {
			if (activeTags?.length) {
				return datasets.filter(
					dataset => _difference(activeTags, dataset.data.tags).length === 0,
				);
			} else {
				return datasets;
			}
		} else {
			return [];
		}
	},
);

const getAllForGivenTags = createSelector(
	[getDatasetsForUser, (state, tags) => tags],
	(datasets, tags) => {
		if (datasets?.length) {
			if (tags?.length) {
				return datasets.filter(
					dataset => _difference(tags, dataset.data.tags).length === 0,
				);
			} else {
				return datasets;
			}
		} else {
			return [];
		}
	},
);

const getTagKeysForLabelsByDatasetKey = createSelector([getByKey], dataset => {
	const tagKeys = dataset?.data?.tags;
	if (tagKeys) {
		return _intersection(tagKeys, modelVisibleTags);
	} else {
		return null;
	}
});

const getSdgAttributeKeys = createSelector(
	[CommonSelect.attributes.getAll],
	attributes => {
		if (attributes?.length) {
			const models = attributes.filter(attribute =>
				_includes(attribute.data.tags, sdgIndicatorsOnlyTagKey),
			);
			return models?.map(model => model.key);
		} else {
			return null;
		}
	},
);

const filterGivenAttributeSetsByActiveDatasetsAttributes = createSelector(
	[
		CommonSelect.attributeSets.getByKeys,
		getActiveModels,
		tagsSelectors.isSdgIndicatorsTagActive,
		getSdgAttributeKeys,
		areaTreeTypesSelectors.getActiveKey,
		cityClusterMethodsSelectors.getActiveModels,
		getAllAsObject,
	],
	(
		attributeSets,
		activeDatasets,
		isSdgOnlyActive,
		sdgAttributes,
		activeAreaTreeKey,
		activeMethods,
		allDatasets,
	) => {
		let mergedActiveDatasets = [];
		if (activeAreaTreeKey === cityClustersAreaTreeKey && activeMethods) {
			// allDatasets
			const mergedActiveMethodsDatasetKeys = Array.prototype.concat(
				...activeMethods.map(method => method.data.reletedDatasetKeys),
			);
			mergedActiveDatasets = mergedActiveMethodsDatasetKeys.map(
				datasetKey => allDatasets[datasetKey],
			);
		} else {
			mergedActiveDatasets = activeDatasets;
		}

		if (attributeSets && mergedActiveDatasets?.length) {
			let allDatasetsAttributes = _flatten(
				mergedActiveDatasets.map(dataset => {
					return dataset.data.attributes.map(a => a.key || a);
				}),
			);

			if (isSdgOnlyActive) {
				allDatasetsAttributes = _intersection(
					allDatasetsAttributes,
					sdgAttributes,
				);
			}

			return attributeSets?.filter(
				attributeSet =>
					_intersection(attributeSet?.data.attributes, allDatasetsAttributes)
						?.length > 0,
			);
		} else {
			return null;
		}
	},
);

const filterGivenAttributeSetsByDatasetKeyAttributes = createSelector(
	[
		CommonSelect.attributeSets.getByKeys,
		getAllAsObject,
		(state, attributeSetsKeys, datasetKey) => datasetKey,
		(state, attributeSetsKeys, datasetKey, attributes) => attributes,
	],
	(attributeSets, datasets, datasetKey, attributes) => {
		const dataset = datasets?.[datasetKey];

		if (attributeSets && dataset) {
			const allDatasetsAttributes = dataset.data.attributes.map(
				a => a.key || a,
			);
			const datasetAttributeSets = _cloneDeep(
				attributeSets?.filter(
					attributeSet =>
						_intersection(attributeSet?.data.attributes, allDatasetsAttributes)
							?.length > 0,
				),
			);
			datasetAttributeSets.forEach(datasetAttributeSet => {
				datasetAttributeSet.data.attributes = [
					...(datasetAttributeSet?.data.attributes.filter(attributeKey =>
						dataset.data.attributes.some(
							a =>
								a.key === attributeKey &&
								a.mapLayer &&
								attributes.includes(a.key),
						),
					) || []),
				];
			});
			return datasetAttributeSets;
		} else {
			return null;
		}
	},
);

export default {
	getSubstate,

	getAll,
	getAllAsObject,
	getActiveKeys,
	getActiveModels,
	getByKey,
	getByAttributeKey,
	getByAttributeKeyObserver,

	getAllForActiveTags,
	getAllForGivenTags,
	getTagKeysForLabelsByDatasetKey,

	filterGivenAttributeSetsByActiveDatasetsAttributes,
	filterGivenAttributeSetsByDatasetKeyAttributes,
};
