import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../../state/Action';
import Select from '../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	let layerOpacity;
	if (ownProps?.datasetLayerKey) {
		layerOpacity = Select.unhab.layers.getLayerOpacityByDatasetLayerKey(
			state,
			ownProps.mapKey,
			ownProps.datasetLayerKey,
		);
	} else {
		layerOpacity = Select.unhab.layers.getLayerOpacity(
			state,
			ownProps.mapKey,
			ownProps.layerKey,
			ownProps?.data?.layerTemplateKey,
		);
	}
	return {
		layerVisible: layerOpacity === undefined || layerOpacity > 0,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		handleLayer: showLayer => {
			if (ownProps?.datasetLayerKey) {
				dispatch(
					Action.unhab.layers.setLayersOpacityByDatasetLayerKey(
						ownProps.mapKey,
						ownProps.datasetLayerKey,
						showLayer ? 100 : 0,
					),
				);
			} else {
				dispatch(
					Action.unhab.layers.setLayerOpacity(
						ownProps.mapKey,
						ownProps.layerKey,
						ownProps?.data?.layerTemplateKey,
						showLayer ? 100 : 0,
					),
				);
			}

			if (ownProps.isIndicatorLayer) {
				dispatch(
					Action.unhab.layers.setLayerInteractivity(
						ownProps.mapKey,
						ownProps.layerKey,
						showLayer,
					),
				);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
