import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, 'App', 'displayTooltipsInMap'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: displayTooltipsInMap =>
			dispatch(
				Action.components.set(
					'App',
					'displayTooltipsInMap',
					displayTooltipsInMap,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
