import PropTypes from 'prop-types';
import {useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import FormFooter from '../Form/FormFooter';
import Form from '../Form/Form';
import AreaPicker from '../AreaPicker';

import './style.scss';

const AddUDTAreaDialog = ({
	countries = {},
	activeSelectionKeys = [],
	onActiveFeatureChange,
	onClose,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);

	const setActiveFeature = () => {
		let featureKey = null;

		if (selectedRegion) {
			featureKey = selectedRegion?.value;
		} else if (selectedCountry) {
			featureKey = selectedCountry?.value;
		}

		// clear selection state
		setSelectedCountry(null);
		setSelectedRegion(null);

		onActiveFeatureChange([...activeSelectionKeys, featureKey]);
	};

	const disabled =
		!selectedCountry ||
		(activeSelectionKeys.includes(selectedCountry?.value) && !selectedRegion);

	const onAddClick = () => {
		setActiveFeature();
		onClose();
	};

	return (
		<div className="unhab-AddAreaDialog">
			<h2>Add area</h2>
			<Form>
				<AreaPicker
					large
					countries={countries}
					selectedCountry={selectedCountry}
					selectedRegion={selectedRegion}
					onCountryChange={setSelectedCountry}
					onRegionChange={setSelectedRegion}
					keysToOmit={activeSelectionKeys}
				/>
				<FormFooter>
					<Button disabled={disabled} onClick={onAddClick} primary>
						{'Add & close'}
					</Button>
					<Button onClick={onClose}>{'Close'}</Button>
				</FormFooter>
			</Form>
		</div>
	);
};

AddUDTAreaDialog.propTypes = {
	countries: PropTypes.object,
	onActiveFeatureChange: PropTypes.func,
	onClose: PropTypes.func,
	activeSelectionKeys: PropTypes.array,
	isOpen: PropTypes.bool,
};

export default AddUDTAreaDialog;
