import ActionTypes from '../../../constants/ActionTypes';
import {DEFAULT_INITIAL_STATE, commonReducers} from '@gisatcz/ptr-state';
const INITIAL_STATE = {
	...DEFAULT_INITIAL_STATE,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.FEATURES_FOR_SCATTER_PLOT.ADD:
			return commonReducers.add(state, action);
		default:
			return state;
	}
};
