import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Button} from '@gisatcz/ptr-atoms';
import './style.scss';

export const ChipsContainer = ({children}) => {
	return <div className="unhab-ChipsContainer">{children}</div>;
};

ChipsContainer.propTypes = {
	children: PropTypes.node,
};

export const ChipsContainerTools = ({children}) => {
	return <div className="unhab-ChipsContainerTools">{children}</div>;
};

ChipsContainerTools.propTypes = {
	children: PropTypes.node,
};

const Chip = ({deletable, removable, onRemove, onDelete, children}) => {
	const classes = classnames('unhab-Chip', {
		'without-tools': !removable && !deletable,
	});

	return (
		<div className={classes}>
			<div className="unhab-Chip-content">{children}</div>
			<div className="unhab-Chip-tools">
				{removable ? (
					<Button
						title={'Remove'}
						small
						invisible
						icon="ri-close"
						onClick={onRemove}
					/>
				) : null}
				{deletable ? (
					<Button
						title={'Delete'}
						small
						invisible
						icon="delete"
						onClick={onDelete}
					/>
				) : null}
			</div>
		</div>
	);
};

Chip.propTypes = {
	deletable: PropTypes.bool,
	removable: PropTypes.bool,
	onDelete: PropTypes.func,
	onRemove: PropTypes.func,
	children: PropTypes.node,
};

export default Chip;
