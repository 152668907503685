import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import {cityClustersAreaTreeKey} from '../../../constants/app';
import {
	defaultCoverageTagKey,
	defaultThemeTagKey,
	defaultSdgTagKey,
} from '../../../constants/app';

const mapStateToProps = state => {
	return {
		activeDataSwitcherOptionKey:
			Select.unhab.tags.getActiveDataSwitcherOptionKey(state),
		dataSwitcherOptions: Select.unhab.tags.getDataSwitcherTags(state),
		activeExpertFilterKeys: Select.unhab.tags.getActiveExpertFilterKeys(state),
		darkTheme: Select.components.get(state, 'UnhabApp', 'darkMode'),
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
		cityClustersActive:
			Select.unhab.areaTreeTypes.getActiveKey(state) ===
			cityClustersAreaTreeKey,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onDataSwitcherChange: (key, cityClustersActive) => {
			dispatch(
				Action.unhab.tags.setActiveKeys([
					key,
					defaultCoverageTagKey,
					defaultThemeTagKey,
					defaultSdgTagKey,
				]),
			);
			dispatch(Action.unhab.clearMapLayers(cityClustersActive));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
