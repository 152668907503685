import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../../common/SelectedMethodsControl/presentation';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import BenchmarkChangeMethodDialog from './BenchmarkChangeMethodDialog';

const mapStateToProps = state => {
	return {
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeMethodKeys: Select.unhab.cityClusterMethods.getActiveKeys(state),
		models: Select.unhab.cityClusterMethods.getAll(state),
		darkMode: Select.components.get(state, 'UnhabApp', 'darkMode'),
		availableMethods: Select.unhab.cityClusterMethods.getAll(state),
		ChangeMethodDialog: BenchmarkChangeMethodDialog,
		enableChange: true,
		disableRemove: true,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActiveKeys: keys => {
			dispatch(Action.unhab.cityClusterMethods.setActiveKeys(keys));
			dispatch(Action.unhab.cityClusterFeatures.useSelectedFeatures());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
