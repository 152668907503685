import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Presentation from '../../../../common/SelectedFeaturesControl';
import Action from '../../../../../state/Action';
import BenchmarkChangeAreaDialog from './BenchmarkChangeAreaDialog';

const mapStateToProps = state => {
	return {
		allowChange: true,
		disableRemove: true,
		featureKeys: Select.unhab.benchmark.getSelectedFeatureKeys(state),
		darkMode: Select.components.get(state, 'UnhabApp', 'darkMode'),
		ChangeAreaDialog: BenchmarkChangeAreaDialog,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.admStructure.ensureAreas());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
