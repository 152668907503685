import PropTypes from 'prop-types';
import './style.scss';
import Map from '../../../../common/Map';
import LayerLabel from './LayerLabel';
import MapInfo from './MapInfo';

const ReportMap = ({mapKeys}) => {
	return (
		<div className="unhab-ReportMap">
			<div className="unhab-ReportMap-MapLabels">
				{mapKeys
					? mapKeys.map((mapKey, mapIndex) => (
							<LayerLabel key={mapKey} mapKey={mapKey} mapIndex={mapIndex} />
						))
					: null}
			</div>
			<div className="unhab-ReportMap-mapWindow">
				<Map />
			</div>
			<div className="unhab-ReportMap-MapInfo">
				{mapKeys
					? mapKeys.map(mapKey => <MapInfo key={mapKey} mapKey={mapKey} />)
					: null}
			</div>
		</div>
	);
};

ReportMap.propTypes = {
	mapKeys: PropTypes.array,
};

export default ReportMap;
