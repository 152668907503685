import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';
import {forwardRef} from 'react';

export const BoxTitle = ({children, className, onClick}) => {
	const classNames = classnames(
		'ptr-BoxTitle',
		{'is-active': !!onClick},
		className,
	);

	return (
		<div className={classNames} onClick={onClick}>
			{children}
		</div>
	);
};

BoxTitle.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export const BoxSubtitle = ({children, className}) => {
	const classNames = classnames('ptr-BoxSubtitle', {}, className);

	return <div className={classNames}>{children}</div>;
};

BoxSubtitle.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

// eslint-disable-next-line react/display-name
const Box = forwardRef(({children, className}, ref) => {
	const classNames = classnames('ptr-Box', {}, className);

	return (
		<div className={classNames} ref={ref}>
			{children}
		</div>
	);
});

Box.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Box;
