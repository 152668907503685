import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Checkbox = ({
	children,
	className,
	onChange,
	checked,
	disabled,
	htmlTitle,
}) => {
	const classes = classnames(
		'ptr-Checkbox',
		{
			'is-disabled': disabled,
		},
		className,
	);

	return (
		<label className={classes} title={htmlTitle}>
			<input
				type="checkbox"
				onChange={onChange}
				checked={checked || false}
				disabled={disabled || false}
			/>
			{children ? <span>{children}</span> : null}
		</label>
	);
};

Checkbox.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	disabled: PropTypes.bool,
	htmlTitle: PropTypes.string,
};

export default Checkbox;
