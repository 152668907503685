import theme from './_theme';

export default {
	componentKey: 'landConsumptionRate_chart',
	type: 'timeProgressChart',
	title: 'Land consumption rate',
	subtitle: 'Yearly values',
	configuration: {
		attributes: [
			'42154c13-4360-4de1-ae1e-7a0ceb5162f4', // GHS Built up
			'f950af79-83be-42b7-8174-12f9353cb642', // WSF 2016-2023 - Built-up (10m)
			'58db1745-da16-420a-bb48-7aa5c654b279', // WSF Evolution 1985-2015 - Built-up (30m)
			'ee76613c-362b-43f0-b8fe-71560e7ac094', // WSF Evolution 1985-2023 - Built-up (10m)
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 50},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
