import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import {xor as _xor} from 'lodash';
import Form from '../Form/Form';
import FormFooter from '../Form/FormFooter';
import DatasetSelect from '../DatasetSelect/presentation';
import './style.scss';

const ChangeDatasetDialog = ({
	activeSelectionKeys,
	activeDatasetKeys,
	allowMultiple,
	datasets,
	onClose,
	onSave,
}) => {
	const [activeDatasets, setDatasets] = useState(activeDatasetKeys);

	const saveSettings = () => {
		onSave(activeDatasets);
		onClose();
	};

	const disabled = _xor(activeDatasets, activeDatasetKeys)?.length === 0;

	return (
		<div className="unhab-ChangeDatasetDialog">
			<h2>Set dataset</h2>
			<Form>
				<DatasetSelect
					large
					forceSingle={!allowMultiple}
					activeKeys={activeDatasets}
					models={datasets}
					setActiveKeys={setDatasets}
					activeSelectionKeys={activeSelectionKeys}
				/>
				<FormFooter>
					<Button onClick={saveSettings} primary disabled={disabled}>
						{'Save & close'}
					</Button>
					<Button onClick={onClose} primary>
						{'Close'}
					</Button>
				</FormFooter>
			</Form>
		</div>
	);
};

ChangeDatasetDialog.propTypes = {
	activeSelectionKeys: PropTypes.array,
	activeDatasetKeys: PropTypes.array,
	datasets: PropTypes.array,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	allowMultiple: PropTypes.bool,
};

export default ChangeDatasetDialog;
