import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';

const ComparisonTypeSelector = ({activeKey, models, onChange}) => {
	return (
		<ButtonSwitch small ghost onClick={onChange}>
			{models.map(model => {
				return (
					<ButtonSwitchOption
						key={model.key}
						value={model.key}
						active={model.key === activeKey}
					>
						{model.data.nameDisplay}
					</ButtonSwitchOption>
				);
			})}
		</ButtonSwitch>
	);
};

ComparisonTypeSelector.propTypes = {
	activeKey: PropTypes.string,
	models: PropTypes.array,
	onChange: PropTypes.func,
};

export default ComparisonTypeSelector;
