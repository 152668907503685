import PropTypes from 'prop-types';
import {useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import {difference as _difference, xor as _xor} from 'lodash';
import FormItemLabel from '../Form/FormItemLabel';
import FormItem from '../Form/FormItem';
import DataFilterCategory from './DataFilterCategory';
import FilteredDatasetList from './FilteredDatasetsList';
import FormFooter from '../Form/FormFooter';
import FilteredIndicatorsList from './FilteredIndicatorsList';
import './style.scss';

const DataFilter = ({
	activeTagKeys,
	categories,
	onClose,
	onSave,
	cityClustersActive,
}) => {
	const [activeTags, setActiveTags] = useState(activeTagKeys);

	const handleActiveTags = (
		categoryTagKey,
		selectedTagKey,
		availableModelKeys,
	) => {
		const withoutCategory = _difference(activeTags, availableModelKeys);
		const newActiveKeys = [
			...withoutCategory,
			...(selectedTagKey ? [selectedTagKey] : []),
		];
		setActiveTags(newActiveKeys);
	};

	const saveSettings = () => {
		onSave(activeTags, cityClustersActive);
		onClose();
	};

	const disabled = _xor(activeTags, activeTagKeys).length === 0;

	return (
		<>
			<div className="unhab-DataFilter-filter">
				<h2>Data filter</h2>
				<div className="unhab-DataFilter-filter-categories">
					{categories.map(({key, data}) => {
						if (data.nameInternal === 'Indicators') {
							return (
								<div key={key} className="unhab-DataFilter-filter-sdg">
									<FormItemLabel>Indicators</FormItemLabel>
									<DataFilterCategory
										isSwitcher
										categoryTagKey={key}
										activeTags={activeTags}
										onChange={handleActiveTags}
									>
										Show SDG indicators only
									</DataFilterCategory>
								</div>
							);
						} else {
							return (
								<FormItem key={key}>
									<FormItemLabel>{data.nameDisplay}</FormItemLabel>
									<DataFilterCategory
										categoryTagKey={key}
										activeTags={activeTags}
										onChange={handleActiveTags}
									/>
								</FormItem>
							);
						}
					})}
				</div>
			</div>
			<div className="unhab-DataFilter-content">
				<div className="unhab-DataFilter-content-body">
					<h3>Datasets</h3>
					<FilteredDatasetList activeTags={activeTags} />
					<h3>Indicators</h3>
					<FilteredIndicatorsList activeTags={activeTags} />
				</div>
				<FormFooter>
					<Button onClick={saveSettings} primary disabled={disabled}>
						{'Save & close'}
					</Button>
					<Button onClick={onClose} primary>
						{'Close'}
					</Button>
				</FormFooter>
			</div>
		</>
	);
};

DataFilter.propTypes = {
	activeTagKeys: PropTypes.array,
	categories: PropTypes.array,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	cityClustersActive: PropTypes.bool,
};

export default DataFilter;
