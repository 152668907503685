import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	cityClustersAreaTreeKey,
} from '../../../../constants/app';

const componentId = 'BenchmarkSidePanel';

const mapStateToProps = state => {
	return {
		activeAreaTreeKey: Select.unhab.areaTreeTypes.getActiveKey(state),
		open: Select.components.get(state, componentId, 'open'),
		area1SelectedFeatureKey: Select.selections.getByKey(
			state,
			benchmarkArea1SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0],
		area2SelectedFeatureKey: Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0],
		selectedDatasetKeys: Select.unhab.datasets.getActiveKeys(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.datasetSets.add());
		},
		setOpen: open => dispatch(Action.components.set(componentId, 'open', open)),
		onSelectedFeatureKeysChange: activeAreaTreeKey => {
			if (activeAreaTreeKey === cityClustersAreaTreeKey) {
				dispatch(Action.unhab.cityClusterFeatures.useSelectedFeatures());
			} else {
				dispatch(Action.unhab.features.useSelectedFeatures());
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
