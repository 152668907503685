import {createSelector} from 'reselect';
import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.unhab.cityClusterMethodParameters;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const getPatatersWithActiveValues = createSelector(
	[getAll],
	parameters => {
		return parameters ? parameters.filter(p => p.data.activeValue) : [];
	},
	{
		memoizeOptions: {
			maxSize: 0, //do not cache that selector
		},
	},
);

const getActive = createSelector([getSubstate], substate => {
	return substate.active;
});

export default {
	getSubstate,

	getAll,
	getAllAsObject,
	getByKey,
	getPatatersWithActiveValues,
	getActive,
};
