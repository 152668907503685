import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isArray as _isArray, cloneDeep as _cloneDeep} from 'lodash';
import './style.scss';

const MapConfigurationControlLabel = ({isRight, data}) => {
	const classes = classnames('ptr-MapConfigurationControlLabel', {
		'is-right': isRight,
	});

	let content = data;
	if (_isArray(data) && data?.length) {
		const layers = _cloneDeep(data).reverse();
		// If more layers, display just the first one
		const layer = layers?.[0];
		if (layer) {
			content = (
				<div className="ptr-MapConfigurationControlLabel-item">
					{_isArray(layer) ? (
						<>
							<div>{layer[0]}</div>
							<span>{layer[1]}</span>
						</>
					) : (
						<div>{layer}</div>
					)}
				</div>
			);
		}
	}

	return <div className={classes}>{content}</div>;
};

MapConfigurationControlLabel.propTypes = {
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	isRight: PropTypes.bool,
};

export default MapConfigurationControlLabel;
