export default {
	componentKey: "totalPopulation_minimum",
	title: 'Total population - minimum',
	configuration: {
		attributes: [
			'00eb991b-167c-4b2e-a7c9-86e5725374e7', // GHSL - absolute
			'c3914386-8047-4526-93dd-ad6b7497f8c4', // GHSL - relative
			'8281c4e7-3fb0-4024-afae-ea476de33a73', // WSF - absolute
			'b626dff7-2382-403f-9907-87c9b7a54e3c', // WSF - relative
		],
		type: 'minimumPop',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'inhabitant/sqkm',
				description: 'Indicator 2 description',
			},
		],
	},
};
