import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../../common/SelectedFeatureLabel';
import Select from '../../../../../state/Select';
import {limassolAreasNameColumnName} from '../../../../../constants/app';
import utils from '../../../../../utils';

const mapStateToProps = (state, ownProps) => {
	const selectionData = Select.selections.getActive(state)?.data;
	const data = Select.xCubeFeatures.getByKey(state, ownProps.featureKey);
	const title = data?.properties?.[limassolAreasNameColumnName];
	const name = title
		? `${title} (${ownProps.featureKey})`
		: ownProps.featureKey;
	const color = utils.getSelectedFeaturePrimaryColor(
		ownProps.featureKey,
		selectionData,
	);
	return {
		name,
		color,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
