import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import Presentation from '../Header/HeaderTools/HeaderTool';

const mapStateToProps = (state, ownProps) => {
	const appIsDark = Select.components.get(state, 'UnhabApp', 'darkMode');

	return {
		tooltip: {
			text: 'Two maps',
			id: 'MapCountControl',
			position: 'bottom',
			appIsDark,
		},
		active:
			(Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey) || []).length ===
			2,
	};
};
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: active => {
			dispatch(Action.unhab.setMapsCount(ownProps.mapSetKey, active ? 2 : 1));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
