import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const mapViewPort = Select.maps.getViewportByMapKey(state, ownProps.mapKey);
	const mapView = Select.maps.getViewByMapKey(state, ownProps.mapKey);
	return {
		map: {
			mapWidth: mapViewPort?.width,
			mapHeight: mapViewPort?.height,
			view: mapView,
		},
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
