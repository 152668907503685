export default {
	componentKey: "totalPopulation_2019",
	title: 'Total population',
	subtitle: '2019',
	configuration: {
		attributes: [
			'76b858f6-a289-4fa2-916a-7fb0bbe13f25', // Total population (WSF 2019 next gen)
		],
		columns: [
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
		],
	},
};
