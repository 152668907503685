import PropTypes from 'prop-types';
import MapLegendTitle from '../../components/MapLegendTitle';
import MapLayerLegend from '../../components/MapLayerLegend';
import MapLegendValueItem from '../../components/MapLegendValueItem';
import AttributeLegend from '../AttributeLegend';

const VectorLegend = ({layerDefinition, expanded, title, unit}) => {
	const styles = layerDefinition?.options?.style?.rules?.[0]?.styles;
	// FIXME - add common customLegend component
	const customLegend = layerDefinition?.options?.customLegend;
	let content = [];

	if (customLegend) {
		content.push(
			<AttributeLegend
				style={customLegend}
				expanded={expanded}
				unit={null}
				key={'customLegend'}
			/>,
		);
	} else if (styles) {
		const [defaultStyle, attributeStyle] = styles;
		if (defaultStyle?.name) {
			content.push(
				<MapLegendValueItem
					color={defaultStyle.fill}
					label={defaultStyle?.name}
					key={defaultStyle.fill}
				/>,
			);
		}

		if (attributeStyle) {
			content.push(
				<AttributeLegend
					style={attributeStyle}
					expanded={expanded}
					unit={unit}
					key={attributeStyle}
				/>,
			);
		}
	}

	return (
		<MapLayerLegend>
			<MapLegendTitle>{title}</MapLegendTitle>
			{content}
		</MapLayerLegend>
	);
};

VectorLegend.propTypes = {
	expanded: PropTypes.bool,
	layerDefinition: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	unit: PropTypes.string,
};

export default VectorLegend;
