import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getDatasetInfo} from '../../../utils/getDatasetInfo';
import './style.scss';
import MetadataItemInfoSectionImage from './MetadataItemInfoSectionImage.js';

const InfoModal = ({datasetKey, indicator, onClose}) => {
	const [description, setDescription] = useState(null);

	useEffect(() => {
		if (datasetKey) {
			const data = getDatasetInfo(datasetKey);
			setDescription(data);
		}
	}, [datasetKey]);

	if (datasetKey && !description) {
		return null;
	}

	const renderDatasetContent = () => (
		<>
			<h1>Dataset Information</h1>
			{description.brief_description && (
				<div className="section">
					<h3>Brief Description</h3>
					<p>{description.brief_description.text}</p>
					{description.brief_description.source_link && (
						<a
							href={description.brief_description.source_link}
							target="_blank"
							rel="noopener noreferrer"
							className="brief-description-link"
						>
							Detailed description from the source website
						</a>
					)}
					{description.brief_description.references && (
						<ul>
							<h5>References:</h5>
							{description.brief_description.references.map((ref, index) => (
								<li key={index}>{ref}</li>
							))}
						</ul>
					)}
				</div>
			)}
			<h3>Specific Notes to Indicators Implementation</h3>
			{description.urban_area && (
				<div className="section">
					<h4>Urban Area</h4>
					{description.urban_area.map((item, index) => (
						<div key={index}>
							<h5>{item.title}</h5>
							<p>{item.text}</p>
							<MetadataItemInfoSectionImage
								imgUrl={require(`../../../data/datasets-images/${item.image}`)}
							/>
						</div>
					))}
				</div>
			)}
			{description.urbanization_area_evolution && (
				<div className="section">
					<h4>Urbanization Area Evolution</h4>
					{description.urbanization_area_evolution.map((item, index) => (
						<div key={index}>
							<h5>{item.title}</h5>
							<p>{item.text}</p>
							{item.image && (
								<MetadataItemInfoSectionImage
									imgUrl={require(
										`../../../data/datasets-images/${item.image}`,
									)}
								/>
							)}
						</div>
					))}
				</div>
			)}
			{description.population && (
				<div className="section">
					<h4>Population</h4>
					{description.population.map((item, index) => (
						<div key={index}>
							<h5>{item.title}</h5>
							<p>{item.text}</p>
							{item.image && (
								<MetadataItemInfoSectionImage
									imgUrl={require(
										`../../../data/datasets-images/${item.image}`,
									)}
								/>
							)}
						</div>
					))}
				</div>
			)}
			{description.population_evolution && (
				<div className="section">
					<h4>Population Evolution</h4>
					{description.population_evolution.map((item, index) => (
						<div key={index}>
							<h5>{item.title}</h5>
							<p>{item.text}</p>
							{item.image && (
								<MetadataItemInfoSectionImage
									imgUrl={require(
										`../../../data/datasets-images/${item.image}`,
									)}
								/>
							)}
						</div>
					))}
				</div>
			)}
			{description.sdg && (
				<div className="section">
					<h4>SDG</h4>
					{description.sdg.map((item, index) => (
						<div key={index}>
							<h5>{item.title}</h5>
							<p>{item.text}</p>
							{item.image && (
								<MetadataItemInfoSectionImage
									imgUrl={require(
										`../../../data/datasets-images/${item.image}`,
									)}
								/>
							)}
						</div>
					))}
				</div>
			)}
		</>
	);

	const renderIndicatorContent = () => {
		if (!indicator) {
			return <p>Indicator is not available.</p>;
		}
		if (indicator === 'Urban area') {
			return (
				<>
					<h1>Indicator description</h1>
					<h2>Urban area</h2>
					<h3>Total area of urban extent - minimal</h3>
					<p>
						This indicator shows the minimal extent of built-up area in sqkm and
						the percentage share of built-up area on the entire extent of the
						reference unit (administrative of functional area) within an
						analyzed period. The minimal extent of built-up area is usually
						associated with the first year of the reference period.
					</p>
					<h3>Total area of urban extent – maximum</h3>
					<p>
						This indicator shows the maximum extent of built-up area in sqkm and
						the percentage share of built-up area on the entire extent of the
						reference unit (administrative of functional area) within an
						analyzed period. The maximum extent of built-up area is usually
						associated with the last year of the reference period.
					</p>
					<h3>Total area of urban extent - absolute</h3>
					<p>
						This indicator represents the extent of built-up area in sqkm within
						the reference unit (administrative of functional area) for the
						selected year.
					</p>
					<h3>Total area of urban extent - relative</h3>
					<p>
						This indicator represents the percentage share of built-up extent on
						the total area of the reference unit (administrative of functional
						area) for the selected year.
					</p>
				</>
			);
		}

		if (indicator === 'Urbanization area evolution') {
			return (
				<>
					<h1>Indicator description</h1>
					<h2>Urbanization area evolution</h2>
					<h3>Total increase</h3>
					<p>
						This indicator shows the total built-up area increase in sqkm and
						the overall percentage change of built-up area in the reference unit
						(administrative of functional area) within an analyzed period.
						Actual increase in particular year within a period can vary.
					</p>
					<h3>Average increase</h3>
					<p>
						This indicator shows the average built-up area increase in sqkm and
						the average percentage change of built-up area in the reference unit
						(administrative of functional area) within an analyzed period.
						Actual increase in particular year within a period can vary.
					</p>
					<h3>Minimal increase</h3>
					<p>
						This indicator shows the minimal built-up area increase in sqkm in
						the reference unit (administrative of functional area) within an
						analyzed period. Actual increase in a particular year within a
						period can vary and be even 0 if no progress in the evolution of
						urban extent was detected. Decrease of the built-up area extent is
						exceptional.
					</p>
					<h3>Maximum increase</h3>
					<p>
						This indicator shows the maximum built-up area increase in sqkm in
						the reference unit (administrative of functional area) within an
						analyzed period. Actual increase in a particular year within a
						period can vary.
					</p>
					<h3>Urban area evolution – absolute</h3>
					<p>
						This indicator shows the absolute change in built-up area between
						two selected years in the reference unit (administrative of
						functional area) within an analyzed period. It is computed as the
						built-up area difference in the selected year and the built-up area
						in the previous year.
					</p>
					<h3>Urban area evolution – relative</h3>
					<p>
						This indicator shows the percentage change in built-up area between
						two selected years in the reference unit (administrative of
						functional area) within an analyzed period. It is computed as the
						ratio of the built-up extent in the selected year and the built-up
						extent in the previous year minus 1 and multiplied by 100. Actual
						percentage change in each year within a period can be observed.
					</p>
				</>
			);
		}

		if (indicator === 'Population') {
			return (
				<>
					<h1>Indicator description</h1>
					<h2>Population</h2>
					<h3>Total population – maximum</h3>
					<p>
						This indicator shows the year with highest population, respective
						population figure and the corresponding value of population density
						in the reference unit (administrative of functional area) within an
						analyzed period.
					</p>
					<h3>Total population – minimal</h3>
					<p>
						This indicator shows the year with lowest population, respective
						population figure and the corresponding value of population density
						in the reference unit (administrative of functional area) within an
						analyzed period.
					</p>
					<h3>Population growth</h3>
					<p>
						This indicator represents total population figure for the selected
						year in the reference unit (administrative of functional area)
						within an analyzed period. Actual total population figure in each
						year within a period can be observed.
					</p>
					<h3>Population density growth</h3>
					<p>
						This indicator represents population density for the selected year
						in the reference unit (administrative of functional area) within an
						analyzed period. The integer value is expressed as the number of
						inhabitants per sqkm. Actual population density in each year within
						a period can be observed.
					</p>
				</>
			);
		}

		if (indicator === 'Population evolution') {
			return (
				<>
					<h1>Indicator description</h1>
					<h2>Population evolution</h2>
					<h3>Total increase</h3>
					<p>
						This indicator shows total population increase and the corresponding
						percentage change in the population in the reference unit
						(administrative of functional area) within an analyzed period. The
						indicator does not provide any information about fluctuations in
						values within the monitored period.
					</p>
					<h3>Average increase</h3>
					<p>
						This indicator shows average population increase and the
						corresponding average percentage change in the population in the
						reference unit (administrative of functional area) within an
						analyzed period. The indicator does not provide any information
						about fluctuations in values within the monitored period.
					</p>
					<h3>Minimal yearly increase</h3>
					<p>
						This indicator shows the year with minimal population increase,
						respective population increase figure and the corresponding average
						percentage change in the population in the reference unit
						(administrative of functional area) within an analyzed period. The
						indicator does not provide any information about fluctuations in
						values within the monitored period.
					</p>
					<h3>Maximum yearly increase</h3>
					<p>
						This indicator shows the year with maximum population increase,
						respective population increase figure and the corresponding average
						percentage change in the population in the reference unit
						(administrative of functional area) within an analyzed period. The
						indicator does not provide any information about fluctuations in
						values within the monitored period.
					</p>
					<h3>Population evolution - absolute</h3>
					<p>
						This indicator shows the absolute change in population between two
						selected years in the reference unit (administrative of functional
						area) within an analyzed period. It is computed as the population
						difference in the selected year and the population in the previous
						year.
					</p>
					<h3>Population evolution relative</h3>
					<p>
						This indicator shows the percentage change in population between two
						selected years in the population in the reference unit
						(administrative of functional area) within an analyzed period. It is
						computed as the ratio of the population in the selected year and the
						population in the previous year minus 1 and multiplied by 100.
					</p>
				</>
			);
		}

		if (indicator === 'SDG Indicators') {
			return (
				<>
					<h1>Indicator description</h1>
					<h2>SDG Indicators</h2>
					<h3>Land consumption rate</h3>
					<p>
						Land consumption rate (LCR) is the yearly rate at which urbanized
						land (built-up area) changes in the reference unit (administrative
						of functional area) within an analyzed period. It is expressed as
						the natural logarithm of the shares of the built-up area in the
						selected year divided by the built-up area in the initial year, and
						the result is divided by the number of years between the selected
						years.
					</p>
					<h3>Population growth rate</h3>
					<p>
						Population growth rate (PGR) is the yearly rate at which the
						population changed (increased or decreased) in the reference unit
						(administrative of functional area) within an analyzed period. It is
						expressed as the natural logarithm of the population in the selected
						year divided by the population in the initial year, and the result
						is divided by the number of years between the selected years.
					</p>
					<h3>Land consumption rate / Population growth rate</h3>
					<p>
						Land consumption and population growth rates (LCRPGR) index is used
						to assess the sustainability of urban growth, which considers both
						the built-up area and the population increase. LCRPGR is calculated
						as the land consumption rate (LCR) and population growth rate (PGR)
						ratio in the reference unit (administrative of functional area) for
						a reference year within an analyzed period.
					</p>
					<h3>Land consumption per capita</h3>
					<p>
						This indicator represents the total urban area in sqkm divided by
						the total population (Land Consumption per capita) in the reference
						unit (administrative of functional area). Land consumption per
						capita represents the average amount of urbanized land (built-up
						area) each person in a city consumes/occupies during for a reference
						year within an analyzed period.
					</p>
				</>
			);
		} else {
			return <p>No information available for this indicator.</p>;
		}
	};

	return (
		<div className="info-modal-content">
			{datasetKey ? renderDatasetContent() : renderIndicatorContent()}
			<button onClick={onClose}>Close</button>
		</div>
	);
};

InfoModal.propTypes = {
	datasetKey: PropTypes.string,
	indicator: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};

export default InfoModal;
