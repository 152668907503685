import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../../common/SelectedDatasetsControl/presentation';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import BenchmarkChangeDatasetDialog from './BenchmarkChangeDatasetDialog';
import {datasetsComparisonType} from '../../../../../constants/app';

const mapStateToProps = state => {
	return {
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeDatasetKeys: Select.unhab.datasets.getActiveKeys(state),
		models: Select.unhab.datasets.getAllForActiveTags(state),
		darkMode: Select.components.get(state, 'UnhabApp', 'darkMode'),
		availableDatasets: Select.unhab.datasets.getAllForActiveTags(state),
		ChangeDatasetDialog: BenchmarkChangeDatasetDialog,
		enableChange: true,
		disableRemove: true,
		comparingDatasets:
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state) ===
			datasetsComparisonType,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.datasets.add());
		},
		setActiveKeys: keys => {
			dispatch(Action.unhab.datasets.setActiveKeys(keys));
		},
		onActiveDatasetsChange: () => {
			dispatch(Action.unhab.layers.handleLayersOnDatasetChange());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
