import {createSelector} from 'reselect';
import {Select} from '@gisatcz/ptr-state';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../constants/app';

const getSelectedFeatureKeys = createSelector(
	[
		state =>
			Select.selections.getByKey(state, benchmarkArea1SelectionKey)?.data
				?.featureKeysFilter?.keys,
		state =>
			Select.selections.getByKey(state, benchmarkArea2SelectionKey)?.data
				?.featureKeysFilter?.keys,
	],
	(area1SelectionFeatureKeys, area2SelectionFeatureKeys) => {
		return [
			...(area1SelectionFeatureKeys || []),
			...(area2SelectionFeatureKeys || []),
		];
	},
);

export default {
	getSelectedFeatureKeys,
};
