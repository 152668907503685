import theme from './_theme';

export default {
	componentKey: 'totalAreaOfUrbanExtent_chart',
	type: 'timeProgressChart',
	title: 'Total area of urban extent - absolute',
	subtitle: 'Yearly evolution [sqkm]',
	configuration: {
		attributes: [
			'08a819f3-d71b-4753-8929-65f67107dd22', // WSF Evolution 1985-2015 - Built-up (30m) - time series
			'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f', // GHSL 1975-2030 - Built-up  (100m) - time series
			'03a6772d-c07e-4ac2-a18d-a87ec02889fe', // WSF 2016-2023 - Built-up (10m) - time series
			'73e4f64a-47c1-4e8c-9dc6-1687fe3e9eb3', // WSF Evolution 1985-2023 - Built-up (10m) - time series
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 50},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
