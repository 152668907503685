import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		maps: Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey),
		active: Select.unhab.hasMapSetSyncedJustScale(state, ownProps.mapSetKey),
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
		benchmarkComparisonType:
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSyncedJustScale: lock => {
			// toggle sync view
			dispatch(Action.unhab.setMapSetSyncJustScale(ownProps.mapSetKey, lock));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
