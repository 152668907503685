import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import MapLegendValueItem from './MapLegendValueItem';

const MapLegendScale = ({values, colors, image, expanded, colorsValues}) => {
	const classes = classNames('ptr-MapLegendScale', {
		'is-expanded': expanded,
	});

	let imageUrl = null;
	if (image) {
		imageUrl = `data:image/png;base64, ${image}`;
	}

	let content = null;
	if (expanded) {
		if (colorsValues) {
			content = (
				<>
					{colorsValues.map(item => (
						<MapLegendValueItem key={item[0]} color={item[1]} label={item[0]} />
					))}
				</>
			);
		} else {
			content = (
				<>
					{colors.map((color, index) => (
						<MapLegendValueItem
							key={color}
							color={color}
							label={values[index]}
						/>
					))}
				</>
			);
		}
	} else {
		if (colorsValues) {
			const colorsForBar = colorsValues.map(item => item[1]);
			content = (
				<>
					<span>{colorsValues?.[0]?.[0]}</span>
					<div
						style={{
							backgroundImage: `linear-gradient(90deg, ${
								colorsForBar ? colorsForBar.join(',') : ''
							})`,
						}}
					/>
					<span>{colorsValues?.[colorsValues?.length - 1]?.[0]}</span>
				</>
			);
		} else {
			content = (
				<>
					<span>{values[0]}</span>
					{image ? (
						<img src={imageUrl} />
					) : (
						<div
							style={{
								backgroundImage: `linear-gradient(90deg, ${
									colors ? colors.join(',') : ''
								})`,
							}}
						/>
					)}
					<span>{values[values.length - 1]}</span>
				</>
			);
		}
	}

	return <div className={classes}>{content}</div>;
};

MapLegendScale.propTypes = {
	values: PropTypes.array,
	colors: PropTypes.array,
	colorsValues: PropTypes.array,
	image: PropTypes.string,
	expanded: PropTypes.bool,
};

export default MapLegendScale;
