// import PropTypes from 'prop-types';
import Header from '../../common/Header';
import AppContent from '../../common/AppContent';
import ReportSidePanel from './ReportSidePanel';
import ReportPreview from './ReportPreview';
import './style.scss';

const Report = () => {
	return (
		<>
			<Header />
			<AppContent>
				<ReportPreview />
				<ReportSidePanel open={true} />
			</AppContent>
		</>
	);
};

Report.propTypes = {};

export default Report;
