import PropTypes from 'prop-types';
import {useEffect} from 'react';
import SidePanel, {
	SidePanelBody,
	SidePanelHeader,
	SidePanelHeaderItem,
	SidePanelHeaderRow,
} from '../../../common/SidePanel';
import {
	cityClustersAreaTreeKey,
	defaultPeriod,
	regionsAreaTreeKey,
} from '../../../../constants/app';
import AnalyticsPanelStatistics from '../../../common/AnalyticsPanelStatistics';
import PeriodSelector from '../../../common/PeriodSelector';
import ComparisonTypeSelector from './ComparisonTypeSelector';
import BenchmarkSelectedFeatureControl from './BenchmarkSelectedFeatureControl';
import BenchmarkSelectedFeatureLabel from './BenchmarkSelectedFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedUDTFeatureLabel from './BenchmarkSelectedUDTFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedUDTFeatureControl from './BenchmarkSelectedUDTFeatureControl';
import BenchmarkSelectedDatasetControl from './BenchmarkSelectedDatasetControl';
import BenchmarkSelectedMethodControl from './BenchmarkSelectedMethodControl';
import AreaTreeTypeSelector from '../../../common/AreaTreeTypeSelector';
import './style.scss';

const BenchmarkSidePanel = ({
	activeAreaTreeKey,
	defaultOpen,
	open,
	setOpen,
	area1SelectedFeatureKey,
	area2SelectedFeatureKey,
	onSelectedFeatureKeysChange,
	onMount,
}) => {
	useEffect(() => {
		if (setOpen && defaultOpen) {
			setOpen(true);
		}
	}, []);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	useEffect(() => {
		onSelectedFeatureKeysChange(activeAreaTreeKey);
	}, [area1SelectedFeatureKey, area2SelectedFeatureKey]);

	return (
		<SidePanel
			open={open}
			setOpen={setOpen}
			className="unhab-BenchmarkSidePanel"
		>
			<SidePanelHeader>
				<SidePanelHeaderRow>
					<SidePanelHeaderItem title="Benchmark type">
						<ComparisonTypeSelector />
					</SidePanelHeaderItem>
					<SidePanelHeaderItem title="Area type">
						<AreaTreeTypeSelector />
					</SidePanelHeaderItem>
				</SidePanelHeaderRow>
				{activeAreaTreeKey === regionsAreaTreeKey ? (
					<SidePanelHeaderItem title="Areas">
						<BenchmarkSelectedFeatureControl
							FeatureLabel={BenchmarkSelectedFeatureLabel}
						/>
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === cityClustersAreaTreeKey ? (
					<SidePanelHeaderItem title="Areas">
						<BenchmarkSelectedUDTFeatureControl
							FeatureLabel={BenchmarkSelectedUDTFeatureLabel}
						/>
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === regionsAreaTreeKey ? (
					<SidePanelHeaderItem title="Datasets">
						<BenchmarkSelectedDatasetControl />
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === cityClustersAreaTreeKey ? (
					<SidePanelHeaderItem title="Methods - datasets">
						<BenchmarkSelectedMethodControl />
					</SidePanelHeaderItem>
				) : null}
				<SidePanelHeaderItem
					title="Period"
					info="Any change to the selected period only affects the statistics, not the map layers."
				>
					<PeriodSelector defaultPeriod={defaultPeriod} />
				</SidePanelHeaderItem>
			</SidePanelHeader>
			<SidePanelBody>
				<AnalyticsPanelStatistics />
			</SidePanelBody>
		</SidePanel>
	);
};

BenchmarkSidePanel.propTypes = {
	activeAreaTreeKey: PropTypes.string,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	defaultOpen: PropTypes.bool,
	area1SelectedFeatureKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	area2SelectedFeatureKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	selectedDatasetKeys: PropTypes.array,
	onSelectedFeatureKeysChange: PropTypes.func,
	onMount: PropTypes.func,
};

export default BenchmarkSidePanel;
