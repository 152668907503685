import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
// import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	cityClustersAreaTreeKey,
} from '../../../../constants/app';

const mapStateToProps = state => {
	return {
		activeAreaTreeKey: Select.unhab.areaTreeTypes.getActiveKey(state),
		area1SelectedFeatureKey: Select.selections.getByKey(
			state,
			benchmarkArea1SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0],
		area2SelectedFeatureKey: Select.selections.getByKey(
			state,
			benchmarkArea2SelectionKey,
		)?.data?.featureKeysFilter?.keys?.[0],
		selectedDatasetKeys: Select.unhab.datasets.getActiveKeys(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.datasetSets.add());
		},
		onSelectedFeatureKeysChange: activeAreaTreeKey => {
			if (activeAreaTreeKey === cityClustersAreaTreeKey) {
				dispatch(Action.unhab.cityClusterFeatures.useSelectedFeatures());
			} else {
				dispatch(Action.unhab.features.useSelectedFeatures());
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
