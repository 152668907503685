import ActionTypes from '../../../constants/ActionTypes';
import {stateManagement} from '@gisatcz/ptr-utils';

const INITIAL_STATE = {
	byKey: {},
};

const set = (state, key, value) => {
	return {
		...state,
		byKey: {
			...state.byKey,
			[key]: value,
		},
	};
};

const remove = (state, key) => {
	return {
		...state,
		byKey: {
			...stateManagement.removeItemByKey(state.byKey, key),
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.PARAMETER_VALUES.SET:
			return set(state, action.key, action.value);
		case ActionTypes.UNHAB.PARAMETER_VALUES.REMOVE:
			return remove(state, action.key);
		default:
			return state;
	}
};
