import PropTypes from 'prop-types';
import './style.scss';
import Select from '../../../state/Select';
import classnames from 'classnames';
import {connect} from '@gisatcz/ptr-state';

const mapStateToProps = (state, ownProps) => {
	return {
		condensedControls:
			ownProps.condensedControls ||
			Select.components.get(state, 'UnhabApp', 'condensedControls'),
	};
};

const FormItem = ({children, allowCondensed, condensedControls}) => {
	const className = classnames('ptr-FormItem', {
		'is-condensed': allowCondensed && condensedControls,
	});

	return <div className={className}>{children}</div>;
};

FormItem.propTypes = {
	allowCondensed: PropTypes.bool,
	condensedControls: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(FormItem);
