import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		datasets: Select.unhab.datasets.getAllForGivenTags(
			state,
			ownProps.activeTags,
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
