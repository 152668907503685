import wsfDatasetLayers, {
	wsfBuiltUpLayerKeys,
	wsfBuiltUpYearLayerMapping,
	wsfPopulationLayerKeys,
	wsfPopulationYearLayerMapping,
} from '../../../data/layers/wsfDatasetLayers';
import ghsDatasetLayers, {
	ghsBuiltUpLayerKeys,
	ghsBuiltUpYearLayerMapping,
	ghsPopulationLayerKeys,
	ghsPopulationYearLayerMapping,
} from '../../../data/layers/ghsDatasetLayers';
import Select from '../../Select';

export const getOriginalLayerKey = (layerKey, opacity) => {
	let originalKey = layerKey.replace('_suffix' + opacity, '');
	if (!opacity && opacity !== 0) {
		return layerKey;
	} else {
		return originalKey;
	}
};

/**
 *
 * @param currentLayerKeys {Array}
 * @param currentYear {Number}
 */
export const getDatasetLayerKeysToRemoveUpdate = (
	currentLayerKeys,
	currentYear,
) => {
	if (currentLayerKeys?.length) {
		const currentWsfPopLayerKeys = currentLayerKeys.filter(layerKey => {
			return !!wsfPopulationLayerKeys.find(key => key === layerKey);
		});
		const currentWsfBuiltUpLayerKeys = currentLayerKeys.filter(layerKey => {
			return !!wsfBuiltUpLayerKeys.find(key => key === layerKey);
		});
		const currentGhslBuiltUpLayerKeys = currentLayerKeys.filter(layerKey => {
			return !!ghsBuiltUpLayerKeys.find(key => key === layerKey);
		});
		const currentGhslPopLayerKeys = currentLayerKeys.filter(layerKey => {
			return !!ghsPopulationLayerKeys.find(key => key === layerKey);
		});

		const wsfPopLayers = wsfPopulationLayerKeys.map(
			layerKey => wsfDatasetLayers[layerKey],
		);
		const wsfBuiltUpLayers = wsfBuiltUpLayerKeys.map(
			layerKey => wsfDatasetLayers[layerKey],
		);
		const ghslBuiltUpLayers = ghsBuiltUpLayerKeys.map(
			layerKey => ghsDatasetLayers[layerKey],
		);
		const ghslPopLayers = ghsPopulationLayerKeys.map(
			layerKey => ghsDatasetLayers[layerKey],
		);

		const wsfPopLayersToRemoveUpdate = handleLayerGroupWithPeriod(
			currentWsfPopLayerKeys,
			wsfPopLayers,
			currentYear,
			wsfPopulationYearLayerMapping,
		);
		const wsfBuiltUpLayersToRemoveUpdate = handleLayerGroupWithPeriod(
			currentWsfBuiltUpLayerKeys,
			wsfBuiltUpLayers,
			currentYear,
			wsfBuiltUpYearLayerMapping,
		);
		const ghslBuiltUpLayersToRemoveUpdate = handleLayerGroupWithPeriod(
			currentGhslBuiltUpLayerKeys,
			ghslBuiltUpLayers,
			currentYear,
			ghsBuiltUpYearLayerMapping,
		);
		const ghslPopLayersToRemoveUpdate = handleLayerGroupWithPeriod(
			currentGhslPopLayerKeys,
			ghslPopLayers,
			currentYear,
			ghsPopulationYearLayerMapping,
		);

		return {
			remove: [
				...wsfPopLayersToRemoveUpdate.remove,
				...ghslPopLayersToRemoveUpdate.remove,
				...wsfBuiltUpLayersToRemoveUpdate.remove,
				...ghslBuiltUpLayersToRemoveUpdate.remove,
			],
			update: [
				...wsfPopLayersToRemoveUpdate.update,
				...ghslPopLayersToRemoveUpdate.update,
				...wsfBuiltUpLayersToRemoveUpdate.update,
				...ghslBuiltUpLayersToRemoveUpdate.update,
			],
			remain: [
				...wsfPopLayersToRemoveUpdate.remain,
				...ghslPopLayersToRemoveUpdate.remain,
				...wsfBuiltUpLayersToRemoveUpdate.remain,
				...ghslBuiltUpLayersToRemoveUpdate.remain,
			],
		};
	} else {
		return null;
	}
};

const handleLayerGroupWithPeriod = (
	currentLayerKeys,
	layers,
	currentYear,
	yearLayerMapping,
) => {
	let remove = [];
	let update = [];
	let remain = [];

	if (currentLayerKeys?.length) {
		const nearestLayerKey = yearLayerMapping[currentYear];
		const isNearestAlreadyPresent = !!currentLayerKeys.find(
			layerKey => layerKey === nearestLayerKey,
		);
		if (isNearestAlreadyPresent) {
			remain.push(nearestLayerKey);
			remove = currentLayerKeys.filter(
				layerKey => layerKey !== nearestLayerKey,
			);
		} else {
			const fromKey = currentLayerKeys[0];
			update.push({
				fromKey,
				newLayer: layers.find(layer => layer.key === nearestLayerKey),
			});
			remove = currentLayerKeys.filter(layerKey => layerKey !== fromKey);
		}
	}

	return {
		remove,
		update,
		remain,
	};
};

const getLayersFromGroupByLayerKey = layerKey => {
	const isWsfPopLayer = wsfPopulationLayerKeys.includes(layerKey);
	const isWsfBuiltUpLayer = wsfBuiltUpLayerKeys.includes(layerKey);
	const isGhsBuiltUpLayer = ghsBuiltUpLayerKeys.includes(layerKey);
	const isGhsPopLayer = ghsPopulationLayerKeys.includes(layerKey);

	if (isWsfPopLayer) {
		return wsfPopulationLayerKeys.map(layerKey => wsfDatasetLayers[layerKey]);
	} else if (isWsfBuiltUpLayer) {
		return wsfBuiltUpLayerKeys.map(layerKey => wsfDatasetLayers[layerKey]);
	} else if (isGhsBuiltUpLayer) {
		return ghsBuiltUpLayerKeys.map(layerKey => ghsDatasetLayers[layerKey]);
	} else if (isGhsPopLayer) {
		return ghsPopulationLayerKeys.map(layerKey => ghsDatasetLayers[layerKey]);
	} else {
		return [wsfDatasetLayers[layerKey]] || null;
	}
};

const getMappingByLayerKey = layerKey => {
	const isWsfPopLayer = wsfPopulationLayerKeys.includes(layerKey);
	const isWsfBuiltUpLayer = wsfBuiltUpLayerKeys.includes(layerKey);
	const isGhsBuiltUpLayer = ghsBuiltUpLayerKeys.includes(layerKey);
	const isGhsPopLayer = ghsPopulationLayerKeys.includes(layerKey);

	if (isWsfPopLayer) {
		return wsfPopulationYearLayerMapping;
	} else if (isWsfBuiltUpLayer) {
		return wsfBuiltUpYearLayerMapping;
	} else if (isGhsBuiltUpLayer) {
		return ghsBuiltUpYearLayerMapping;
	} else if (isGhsPopLayer) {
		return ghsPopulationYearLayerMapping;
	} else {
		return null;
	}
};

export const getLayerForYear = (layerKey, currentYear, fullPeriod) => {
	const layers = getLayersFromGroupByLayerKey(layerKey);
	const mapping = getMappingByLayerKey(layerKey);
	if (layers && mapping) {
		const layerKeyForYear = mapping[currentYear];
		return layers.find(layer => layer.key === layerKeyForYear);
	} else if (layers && fullPeriod) {
		const layer = layers[0];
		const newLayerKey = getCogLayerKey(layer.key, fullPeriod);
		return Select.unhab.layers.updateLayerStyleWithActivePeriod(
			{...layer, key: newLayerKey},
			fullPeriod,
		);
	} else {
		return null;
	}
};

export const getCogLayerKey = (prevLayerKey, suffix) => {
	const prevValue = `${prevLayerKey.split(`_suffix`)?.[1]}`;
	const layerKey = `${prevLayerKey.split(`_suffix`)?.[0]}`;
	if (prevValue === suffix) {
		return layerKey;
	} else {
		return `${layerKey}_suffix${suffix}`;
	}
};
