import theme from './_theme';

export default {
	componentKey: 'scatterPlot_chart_lcpc_ghsl_countries',
	type: 'scatterChart',
	title: 'Featured countries: Land Consumption per capita (GHSL)',
	subtitle: '',
	configuration: {
		attributes: [
			'79d7615a-7d3f-11ee-b962-0242ac120002', // GHS Land consumption
			'ab4fe885-65db-4843-b43b-3c16693a9ac7', // GHS Population
			'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', // GHS Land consumption per capita
		],
		countriesOnly: true,
		metadata: {
			nodeSize: {
				inputRange: [0, 120],
				outputRange: [3, 7],
			},
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 40, left: 85},
				xScale: {
					type: 'linear',
					max: 4000,
				},
				yScale: {
					type: 'linear',
					max: 65000000,
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 4,
					legend: 'Population (inh.)',
					legendPosition: 'middle',
					legendOffset: -78,
				},
				axisBottom: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 6,
					legend: 'Land Consumption (sqkm)',
					legendPosition: 'middle',
					legendOffset: 33,
				},
				gridYValues: 4,
				gridXValues: 6,
			},
		},
	},
};
