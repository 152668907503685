import {Button} from '@gisatcz/ptr-atoms';
import {usePDF} from 'react-to-pdf';
import classnames from 'classnames';
import './style.scss';
import IndicatorFromTimeSerie from '../../../common/IndicatorBox/IndicatorFromTimeSerie';
import urbanizationAreaEvolution_total from '../../../../data/indicators/urbanizationAreaEvolution_total';
import urbanizationAreaEvolution_average from '../../../../data/indicators/urbanizationAreaEvolution_average';
import urbanizationAreaEvolution_minimumIncrease from '../../../../data/indicators/urbanizationAreaEvolution_minimumIncrease';
import urbanizationAreaEvolution_maximumIncrease from '../../../../data/indicators/urbanizationAreaEvolution_maximumIncrease';
import Chart from '../../../common/Chart';
import urbanAreaEvolution from '../../../../data/charts/urbanAreaEvolution';
import urbanAreaEvolutionRelative from '../../../../data/charts/urbanAreaEvolutionRelative';
import totalAreaOfUrbanExtent_minimum from '../../../../data/indicators/totalAreaOfUrbanExtent_minimum';
import totalAreaOfUrbanExtent_maximum from '../../../../data/indicators/totalAreaOfUrbanExtent_maximum';
import IndicatorBox from '../../../common/IndicatorBox';
import urbanArea_2019 from '../../../../data/indicators/urbanArea_2019';
import totalAreaOfUrbanExtent from '../../../../data/charts/totalAreaOfUrbanExtent';
import totalAreaOfUrbanExtentRelative from '../../../../data/charts/totalAreaOfUrbanExtentRelative';
import urbanAreaShare from '../../../../data/charts/urbanAreaShare';
import ReportElementResizeDetector from './ReportElementResizeDetector';
import PropTypes from 'prop-types';
import {reportPxHeight} from '../../../../constants/app';
import landConsumptionRate from '../../../../data/indicators/landConsumptionRate';
import populationGrowthRate from '../../../../data/indicators/populationGrowthRate';
import landConsumptionRate_populationGrowthRate from '../../../../data/indicators/landConsumptionRate_populationGrowthRate';
import landConsumptionPerCapita from '../../../../data/indicators/landConsumptionPerCapita';
import landConsumptionRateChart from '../../../../data/charts/landConsumptionRate';
import populationGrowthRateChart from '../../../../data/charts/populationGrowthRate';
import landConsumptionRate_populationGrowthRateChart from '../../../../data/charts/landConsumptionRate_populationGrowthRate';
import landConsumptionPerCapitaChart from '../../../../data/charts/landConsumptionPerCapita';
import totalPopulation_maximum from '../../../../data/indicators/totalPopulation_maximum';
import totalPopulation_minimum from '../../../../data/indicators/totalPopulation_minimum';
import populationGrowth from '../../../../data/charts/populationGrowth';
import populationDensityGrowth from '../../../../data/charts/populationDensityGrowth';
import populationEvolution_total from '../../../../data/indicators/populationEvolution_total';
import populationEvolution_average from '../../../../data/indicators/populationEvolution_average';
import populationEvolution_minimumIncrease from '../../../../data/indicators/populationEvolution_minimumIncrease';
import populationEvolution_maximumIncrease from '../../../../data/indicators/populationEvolution_maximumIncrease';
import totalPopulation_2019 from '../../../../data/indicators/totalPopulation_2019';
import populationEvolution from '../../../../data/charts/populationEvolution';
import populationEvolutionRelative from '../../../../data/charts/populationEvolutionRelative';
import ReportSelections from './ReportSelections';
import ReportTitle from './ReportTitle';
import ReportDescription from './ReportDescription';
import ReportMap from './ReportMap';
import scatterPlot_lcpc_ghsl from '../../../../data/charts/scatterPlot_lcpc_ghsl';
import scatterPlot_lcr_pgr_ghsl from '../../../../data/charts/scatterPlot_lcr_pgr_ghsl';
import scatterPlot_lcr_pgr_wsf from '../../../../data/charts/scatterPlot_lcr_pgr_wsf';
import scatterPlot_lcpc_ghsl_countries from '../../../../data/charts/scatterPlot_lcpc_ghsl_countries';
import scatterPlot_lcpc_wsf_countries from '../../../../data/charts/scatterPlot_lcpc_wsf_countries';
import scatterPlot_lcpc_wsf from '../../../../data/charts/scatterPlot_lcpc_wsf';

const components = [
	{
		key: 'title',
		component: <ReportTitle editableKey="editableTitle" />,
	},
	{
		key: 'description',
		component: <ReportDescription editableKey="editableDescription" />,
	},
	{
		key: 'selections',
		component: <ReportSelections />,
	},
	{
		key: 'map_h3',
		component: <h3 className="unhab-ReportPreview-section-header">Map</h3>,
	},
	{
		key: 'map',
		component: <ReportMap />,
	},
	{
		key: 'urbanArea_h3',
		type: 'attributeSetTitle',
		component: (
			<h3 className="unhab-ReportPreview-section-header">Urban area</h3>
		),
	},
	{
		key: 'urbanArea_totalAreaOfUrbanExtent_minimum',
		component: (
			<IndicatorFromTimeSerie report {...totalAreaOfUrbanExtent_minimum} />
		),
	},
	{
		key: 'urbanArea_totalAreaOfUrbanExtent_maximum',
		component: (
			<IndicatorFromTimeSerie report {...totalAreaOfUrbanExtent_maximum} />
		),
	},
	{
		key: 'urbanArea_urbanArea_2019',
		component: <IndicatorBox report {...urbanArea_2019} />,
	},
	{
		key: 'urbanArea_totalAreaOfUrbanExtent_Chart',
		component: <Chart report {...totalAreaOfUrbanExtent} />,
	},
	{
		key: 'urbanArea_totalAreaOfUrbanExtentRelative_Chart',
		component: <Chart report {...totalAreaOfUrbanExtentRelative} />,
	},
	{
		key: 'urbanArea_urbanAreaShare_Chart',
		component: <Chart report {...urbanAreaShare} />,
	},
	{
		key: 'urbanizationAreaEvolution_h3',
		type: 'attributeSetTitle',
		component: (
			<h3 className="unhab-ReportPreview-section-header">
				Urbanization area evolution
			</h3>
		),
	},
	{
		key: 'urbanizationAreaEvolution_total',
		component: (
			<IndicatorFromTimeSerie report {...urbanizationAreaEvolution_total} />
		),
	},
	{
		key: 'urbanizationAreaEvolution_average',
		component: (
			<IndicatorFromTimeSerie report {...urbanizationAreaEvolution_average} />
		),
	},
	{
		key: 'urbanizationAreaEvolution_minimumIncrease',
		component: (
			<IndicatorFromTimeSerie
				report
				{...urbanizationAreaEvolution_minimumIncrease}
			/>
		),
	},
	{
		key: 'urbanizationAreaEvolution_maximumIncrease',
		component: (
			<IndicatorFromTimeSerie
				report
				{...urbanizationAreaEvolution_maximumIncrease}
			/>
		),
	},
	{
		key: 'urbanizationAreaEvolution_urbanAreaEvolution_Chart',
		component: <Chart report {...urbanAreaEvolution} />,
	},
	{
		key: 'urbanizationAreaEvolution_urbanAreaEvolutionRelative_Chart',
		component: <Chart report {...urbanAreaEvolutionRelative} />,
	},
	{
		key: 'population_h3',
		type: 'attributeSetTitle',
		component: (
			<h3 className="unhab-ReportPreview-section-header">Population</h3>
		),
	},
	{
		key: 'population_totalPopulation_maximum',
		component: <IndicatorFromTimeSerie {...totalPopulation_maximum} report />,
	},
	{
		key: 'population_totalPopulation_minimum',
		component: <IndicatorFromTimeSerie {...totalPopulation_minimum} report />,
	},
	{
		key: 'population_populationGrowth_Chart',
		component: <Chart {...populationGrowth} report />,
	},
	{
		key: 'population_populationDensityGrowth_Chart',
		component: <Chart {...populationDensityGrowth} report />,
	},
	{
		key: 'populationEvolution_h3',
		type: 'attributeSetTitle',
		component: (
			<h3 className="unhab-ReportPreview-section-header">
				Population evolution
			</h3>
		),
	},
	{
		key: 'populationEvolution_total',
		component: <IndicatorFromTimeSerie {...populationEvolution_total} report />,
	},
	{
		key: 'populationEvolution_average',
		component: (
			<IndicatorFromTimeSerie {...populationEvolution_average} report />
		),
	},
	{
		key: 'populationEvolution_minimumIncrease',
		component: (
			<IndicatorFromTimeSerie {...populationEvolution_minimumIncrease} report />
		),
	},
	{
		key: 'populationEvolution_maximumIncrease',
		component: (
			<IndicatorFromTimeSerie {...populationEvolution_maximumIncrease} report />
		),
	},
	{
		key: 'populationEvolution_totalPopulation_2019',
		component: <IndicatorBox {...totalPopulation_2019} report />,
	},
	{
		key: 'populationEvolution_populationEvolution_Chart',
		component: <Chart {...populationEvolution} report />,
	},
	{
		key: 'populationEvolution_populationEvolutionRelative_Chart',
		component: <Chart {...populationEvolutionRelative} report />,
	},
	{
		key: 'sdg_h3',
		type: 'attributeSetTitle',
		component: (
			<h3 className="unhab-ReportPreview-section-header">SDG indicators</h3>
		),
	},
	{
		key: 'sdg_landConsumptionRate',
		component: <IndicatorFromTimeSerie report {...landConsumptionRate} />,
	},
	{
		key: 'sdg_populationGrowthRate',
		component: <IndicatorFromTimeSerie report {...populationGrowthRate} />,
	},
	{
		key: 'sdg_landConsumptionRate_populationGrowthRate',
		component: (
			<IndicatorFromTimeSerie
				report
				{...landConsumptionRate_populationGrowthRate}
			/>
		),
	},
	{
		key: 'sdg_landConsumptionPerCapita',
		component: <IndicatorFromTimeSerie report {...landConsumptionPerCapita} />,
	},
	{
		key: 'sdg_landConsumptionRate_Chart',
		component: <Chart report {...landConsumptionRateChart} />,
	},
	{
		key: 'sdg_populationGrowthRate_Chart',
		component: <Chart report {...populationGrowthRateChart} />,
	},
	{
		key: 'sdg_landConsumptionRate_populationGrowthRate_Chart',
		component: (
			<Chart report {...landConsumptionRate_populationGrowthRateChart} />
		),
	},
	{
		key: 'sdg_landConsumptionPerCapita_Chart',
		component: <Chart report {...landConsumptionPerCapitaChart} />,
	},
	{
		key: 'sdg_scatterPlot_lcr_pgr_ghsl',
		component: <Chart report {...scatterPlot_lcr_pgr_ghsl} />,
	},
	{
		key: 'sdg_scatterPlot_lcr_pgr_wsf',
		component: <Chart report {...scatterPlot_lcr_pgr_wsf} />,
	},
	{
		key: 'sdg_scatterPlot_lcpc_ghsl_countries',
		component: <Chart report {...scatterPlot_lcpc_ghsl_countries} />,
	},
	{
		key: 'sdg_scatterPlot_lcpc_ghsl',
		component: <Chart report {...scatterPlot_lcpc_ghsl} />,
	},
	{
		key: 'sdg_scatterPlot_lcpc_wsf_countries',
		component: <Chart report {...scatterPlot_lcpc_wsf_countries} />,
	},
	{
		key: 'sdg_scatterPlot_lcpc_wsf',
		component: <Chart report {...scatterPlot_lcpc_wsf} />,
	},
];

const ReportPage = ({children}) => {
	return <div className="unhab-ReportPage">{children}</div>;
};

ReportPage.propTypes = {
	children: PropTypes.node,
};

const ReportPreview = ({
	componentsSizes,
	printInProgress,
	onSetPrintInProgress,
}) => {
	const {toPDF, targetRef} = usePDF({filename: 'report.pdf'});
	const pages = [];
	if (componentsSizes) {
		let currentPage = [];
		let previousComponentType = null;
		let currentPageHeight = 0;
		components.forEach(({component, key, type}, index) => {
			const {height} = componentsSizes[key];
			if (height) {
				if (previousComponentType === type && type === 'attributeSetTitle') {
					currentPage.pop();
				}

				if (currentPageHeight + height < reportPxHeight) {
					currentPageHeight += height;
					currentPage.push(component);
				} else {
					pages.push(currentPage);
					currentPage = [component];
					currentPageHeight = height;
				}
				previousComponentType = type;
			}
			if (index === components.length - 1) {
				pages.push(currentPage);
			}
		});
	}

	const onPdfGenerate = () => {
		onSetPrintInProgress(true);
		setTimeout(() => {
			toPDF().then(() => {
				onSetPrintInProgress(false);
			});
		}, 1000);
	};

	const classes = classnames('unhab-ReportPreview ptr-light', {
		'is-print-in-progress': printInProgress,
	});

	return (
		<div className={classes}>
			<div className="unhab-ReportPages" ref={targetRef}>
				{pages.map((page, index) => (
					<ReportPage key={index}>{page}</ReportPage>
				))}
			</div>
			<Button
				primary
				icon="file-export"
				className="unhab-ReportPreview-GenerateButton"
				onClick={onPdfGenerate}
			>
				Generate PDF report
			</Button>
			<div className="unhab-ReportPreview-prerender">
				{components.map(({component, key}) => (
					<ReportElementResizeDetector key={key} componentKey={key}>
						{component}
					</ReportElementResizeDetector>
				))}
			</div>
		</div>
	);
};

ReportPreview.propTypes = {
	printInProgress: PropTypes.bool,
	componentsSizes: PropTypes.object,
	onSetPrintInProgress: PropTypes.func,
};

export default ReportPreview;
