import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	const mapView = Select.maps.getMapSetActiveMapView(state, mapSetKey);
	const map = Select.maps.getMapByKey(state, ownProps.mapKey);

	return {
		mapHeight: map.data.viewport.height,
		mapWidth: map.data.viewport.width,
		mapView,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
