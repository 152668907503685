import PropTypes from 'prop-types';
import {useState} from 'react';
import {Button, ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import ModalWindow from '../ModalWindow';
import DataFilter from '../DataFilter';
import Tag from '../Tag';
import {tagExpertKey} from '../../../constants/app';
import './style.scss';

const DataFilterControl = ({
	dataSwitcherOptions,
	activeDataSwitcherOptionKey,
	activeExpertFilterKeys,
	onDataSwitcherChange,
	darkTheme,
	condensedControls,
	cityClustersActive,
}) => {
	const [isFilterOpen, setFilterOpen] = useState(false);

	return (
		<>
			{dataSwitcherOptions ? (
				<div className="unhab-DataFilterControl-content">
					<ButtonSwitch
						className="unhab-ButtonSwitch"
						small
						ghost
						onClick={key => onDataSwitcherChange(key, cityClustersActive)}
					>
						{dataSwitcherOptions.map(({key, data}) => (
							<ButtonSwitchOption
								key={key}
								value={key}
								active={key === activeDataSwitcherOptionKey}
							>
								{condensedControls ? data.shortName : data.nameDisplay}
							</ButtonSwitchOption>
						))}
					</ButtonSwitch>
					{tagExpertKey === activeDataSwitcherOptionKey ? (
						<div className="unhab-DataFilterControl-tagsContainer">
							<Button
								icon="ri-filter"
								invisible
								small
								onClick={() => setFilterOpen(true)}
							>
								{condensedControls ? '' : 'Filter'}
							</Button>
							{activeExpertFilterKeys?.length ? (
								<div className="unhab-DataFilterControl-tags">
									{activeExpertFilterKeys.map(key => (
										<Tag tagKey={key} key={key} withCategory />
									))}
								</div>
							) : null}
						</div>
					) : null}
				</div>
			) : null}
			<ModalWindow
				className="unhab-DataFilter"
				isOpen={isFilterOpen}
				onClose={() => setFilterOpen(false)}
				isDark={darkTheme}
			>
				<DataFilter onClose={() => setFilterOpen(false)} />
			</ModalWindow>
		</>
	);
};

DataFilterControl.propTypes = {
	activeDataSwitcherOptionKey: PropTypes.string,
	activeExpertFilterKeys: PropTypes.array,
	dataSwitcherOptions: PropTypes.array,
	onDataSwitcherChange: PropTypes.func,
	darkTheme: PropTypes.bool,
	condensedControls: PropTypes.bool,
	cityClustersActive: PropTypes.bool,
};

export default DataFilterControl;
