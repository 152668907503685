import ActionTypes from '../../constants/ActionTypes';
// import {stateManagement} from '@gisatcz/ptr-utils';

// App specific reducers
const INITIAL_STATE = {
	byFeatureKey: {},
};

const add = (state, layerKey, featureKey, data) => {
	return {
		...state,
		byFeatureKey: state.byFeatureKey[featureKey]
			? {
					...state.byFeatureKey,
					[featureKey]: {
						...state.byFeatureKey[featureKey],
						[layerKey]: data,
					},
				}
			: {
					...state.byFeatureKey,
					[featureKey]: {
						[layerKey]: data,
					},
				},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.XCUBE_TIME_SERIES.ADD:
			return add(state, action.layerKey, action.featureKey, action.data);
		default:
			return state;
	}
};
