export default {
	componentKey: "urbanizationAreaEvolution_average",
	title: 'Average increase',
	subtitle: 'per year',
	configuration: {
		attributes: [
			'79d76862-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - absolute
			'79d7674a-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - relative
			'79d7661e-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - absolute
			'79d764f2-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - relative
			'0eaac7cf-3e6d-4263-b8ac-c95649c9e977', // WSF 2016-2023 - Built-up (10m) - absolute
			'840f8957-c450-4cb5-8e2e-2c88ee6ff4a4', // WSF 2016-2023 - Built-up (10m) - relative
			'7938c69d-366f-4642-aea5-a15bc91977de', // WSF Evolution 1985-2015 - Built-up (10m) - absolute
			'0a96537a-3da1-4713-96cd-12d9efc1a7ba', // WSF Evolution 1985-2015 - Built-up (10m) - relative
		],
		type: 'avgYearlyIncreaseEvolution',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
