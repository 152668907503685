import PropTypes from 'prop-types';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const MapCoordinatesControl = ({active, onChange}) => {
	return (
		<MenuToggleItem
			icon="ri-location-search"
			active={active}
			onChange={onChange}
		>
			Coordinates
		</MenuToggleItem>
	);
};

MapCoordinatesControl.propTypes = {
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

export default MapCoordinatesControl;
