import PropTypes from 'prop-types';
import {ResponsiveScatterPlotCanvas} from '@nivo/scatterplot';

/**
 * @param data {Array} following nivo scatter chart input data format (https://nivo.rocks/scatterplot/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo scatter chart props (https://nivo.rocks/scatterplot/)
 * @param Tooltip {React.FunctionComponent}
 * @param Node {React.FunctionComponent}
 * @returns {JSX.Element|null}
 */
const ScatterChart = ({data, metadata, Tooltip, renderNode}) => {
	const {settings, nodeSize} = metadata; // TODO if no settings, then basic settings?

	return data ? (
		<ResponsiveScatterPlotCanvas
			tooltip={Tooltip}
			key={data?.length} // TODO hot fix for multiple points selection
			data={data}
			{...settings}
			renderNode={(ctx, node) => renderNode(ctx, node, nodeSize)}
			// nodeComponent={props => <Node {...props} nodeSize={nodeSize} />}
		/>
	) : null;
};

ScatterChart.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
	Tooltip: PropTypes.func,
	renderNode: PropTypes.func,
};

export default ScatterChart;
