import PropTypes from 'prop-types';
import DataLayerControl from '../DataLayerControl';
import DataLayersGroup from '../DataLayersGroup';
import './style.scss';

const AuLayersControl = ({
	activeLayers,
	availableLayers = [],
	mapKey,
	attributeSet,
	interactive,
	large,
}) => {
	return availableLayers && availableLayers.length ? (
		<DataLayersGroup
			title={attributeSet?.data?.nameDisplay}
			closeable={false}
			secondary
			large={large}
			defaultOpen
			datasetKey={attributeSet?.key}
		>
			{availableLayers?.map(layer => {
				const active = activeLayers?.find(
					activeLayer =>
						(activeLayer?.layerTemplateKey &&
							activeLayer?.layerTemplateKey === layer?.layerTemplateKey) ||
						activeLayer?.key === layer?.key,
				);

				return (
					<DataLayerControl
						removeable={true}
						type={'radio'}
						key={layer.key}
						mapKey={mapKey}
						active={!!active}
						data={layer}
						activeLayers={activeLayers}
						attributeKey={layer?.options?.attributes?.relative}
						initialized={false} //should be false only for COG layers
						interactive={interactive}
						large={large}
					/>
				);
			})}
		</DataLayersGroup>
	) : null;
};

AuLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	availableLayers: PropTypes.array,
	mapKey: PropTypes.string,
	attributeSet: PropTypes.object,
	interactive: PropTypes.bool,
	large: PropTypes.bool,
	datasetKey: PropTypes.string,
};

export default AuLayersControl;
