import './style.scss';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {
	limassolAreasNameColumnName,
	limassolAreasFidColumnName,
} from '../../../../../constants/app';
import {SidePanelHeaderItem} from '../../../../common/SidePanel';
import Chip, {ChipsContainer} from '../../../../common/Chip';

const XCubePointsControl = ({features, onDeleteFeature}) => {
	const pointFeatures = features.filter(
		feature => feature?.properties?.[limassolAreasNameColumnName] === 'Point',
	);

	return (
		<SidePanelHeaderItem title={'Points'}>
			<ChipsContainer>
				{pointFeatures?.map(feature => {
					const title = feature?.properties?.[limassolAreasNameColumnName];
					const featureKey = feature?.properties?.[limassolAreasFidColumnName];
					return (
						<Chip
							key={featureKey}
							deletable={true}
							onDelete={() => onDeleteFeature('map-1', featureKey)}
						>
							<span className="unhab-XCubePointsControl-controlName">{`${title} (${featureKey})`}</span>
						</Chip>
					);
				})}
				{pointFeatures?.length > 1 ? (
					<Button
						small
						invisible
						icon="delete"
						onClick={() => onDeleteFeature('map-1', null)}
					>
						Delete all
					</Button>
				) : null}
			</ChipsContainer>
		</SidePanelHeaderItem>
	);
};

XCubePointsControl.propTypes = {
	FeatureControl: PropTypes.object,
	features: PropTypes.object,
	onDeleteFeature: PropTypes.func,
	selectionData: PropTypes.array,
};

export default XCubePointsControl;
