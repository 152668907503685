import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import Toggle from '../../Toggle';
import './style.scss';

const MenuToggleItem = ({
	icon,
	children,
	onChange,
	active,
	large,
	disabled,
}) => {
	const classes = classnames('unhab-MenuToggleItem', {
		'is-disabled': disabled,
		'is-large': large,
	});

	return (
		<div className={classes} onClick={() => onChange(!active)}>
			{icon ? <Icon icon={icon} /> : null}
			<div className="unhab-MenuToggleItem-content">{children}</div>
			<Toggle disabled={disabled} notInteractive on={active} />
		</div>
	);
};

MenuToggleItem.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
	large: PropTypes.bool,
};

export default MenuToggleItem;
