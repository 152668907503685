import PropTypes from 'prop-types';
import './style.scss';
import {Button} from '@gisatcz/ptr-atoms';
import html2canvas from 'html2canvas';
import {useRef} from 'react';
import MapForExport from '../MapForExport';

const MapExportDialog = ({mapKey, mapImageData}) => {
	const exportContainerRef = useRef(null);

	function downloadContent() {
		html2canvas(exportContainerRef.current).then(canvas => {
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = 'content.png';
			link.click();
		});
	}

	return (
		<div className="unhab-MapExportDialog">
			<h2>Export map</h2>
			<div className="unhab-MapExportDialog-tools">
				<Button icon="ri-download" onClick={downloadContent}>
					{'Download as PNG'}
				</Button>
			</div>
			<div className="unhab-MapExportDialog-mapWindow" ref={exportContainerRef}>
				<MapForExport mapKey={mapKey} mapImageData={mapImageData} />
			</div>
		</div>
	);
};

MapExportDialog.propTypes = {
	mapImageData: PropTypes.string,
	mapKey: PropTypes.string,
};

export default MapExportDialog;
