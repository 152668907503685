import PropTypes from 'prop-types';
import {createElement, useEffect, useState} from 'react';
import Chip, {ChipsContainer, ChipsContainerTools} from '../Chip';
import SelectedMethodLabel from '../SelectedMethodLabel';
import SmallCircularButton from '../SmallCircularButton';
import ModalWindow from '../ModalWindow';
import './style.scss';

const SelectedMethodsControl = ({
	activeSelectionKeys,
	activeMethodKeys,
	setActiveKeys,
	darkMode,
	onMount,
	ChangeMethodDialog,
	enableChange,
	disableRemove,
	simpleList,
}) => {
	const [methodAddControlOpen, setMethodAddControlOpen] = useState(false);
	const [methodChangeControlOpen, setMethodChangeControlOpen] = useState(false);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const onMethodsRemove = methodKey => {
		if (!methodKey) {
			setActiveKeys([]);
		} else if (methodKey?.length) {
			const updatedKeys = activeMethodKeys.filter(key => key !== methodKey);
			setActiveKeys(updatedKeys);
		}
	};

	return (
		<>
			<ChipsContainer>
				{activeMethodKeys?.length > 0 &&
					activeMethodKeys?.map((methodKey, i) => {
						if (simpleList) {
							return (
								<SelectedMethodLabel
									key={methodKey + i}
									methodKey={methodKey}
								/>
							);
						} else {
							return (
								<Chip
									key={methodKey + i}
									removable={!disableRemove && activeMethodKeys?.length !== 1}
									onRemove={evt => {
										onMethodsRemove(methodKey), evt.stopPropagation();
									}}
								>
									<SelectedMethodLabel
										methodKey={methodKey}
									></SelectedMethodLabel>
								</Chip>
							);
						}
					})}
				{!simpleList && (
					<ChipsContainerTools>
						{activeMethodKeys?.length === 1 || enableChange ? (
							<SmallCircularButton
								icon="ri-change"
								onClick={() => setMethodChangeControlOpen(true)}
								tooltip={{
									text: 'Change method',
									id: 'ChangeMethod',
									position: 'bottom',
								}}
							/>
						) : null}
						{activeSelectionKeys?.length === 1 ? (
							<SmallCircularButton
								icon="ri-add"
								onClick={() => setMethodAddControlOpen(true)}
								tooltip={{
									text: 'Add method',
									id: 'AddMethod',
									position: 'bottom',
								}}
							/>
						) : null}
					</ChipsContainerTools>
				)}
			</ChipsContainer>
			<ModalWindow
				isOpen={methodChangeControlOpen}
				isDark={darkMode}
				onClose={() => setMethodChangeControlOpen(false)}
			>
				{createElement(ChangeMethodDialog, {
					onClose: () => setMethodChangeControlOpen(false),
				})}
			</ModalWindow>
			<ModalWindow
				isOpen={methodAddControlOpen}
				isDark={darkMode}
				onClose={() => setMethodAddControlOpen(false)}
			>
				{createElement(ChangeMethodDialog, {
					onClose: () => setMethodAddControlOpen(false),
					allowMultiple: true,
				})}
			</ModalWindow>
		</>
	);
};

SelectedMethodsControl.propTypes = {
	activeSelectionKeys: PropTypes.array,
	activeMethodKeys: PropTypes.array,
	darkMode: PropTypes.bool,
	enableChange: PropTypes.bool,
	disableRemove: PropTypes.bool,
	setActiveKeys: PropTypes.func,
	onMount: PropTypes.func,
	ChangeMethodDialog: PropTypes.object,
	simpleList: PropTypes.bool,
};

export default SelectedMethodsControl;
