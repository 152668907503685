import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../../state/Action';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		removeLayer: () => {
			if (ownProps?.datasetLayerKey) {
				dispatch(
					Action.unhab.layers.removeLayersByDatasetLayerKey(
						ownProps.datasetLayerKey,
						ownProps.mapKey,
					),
				);
			} else {
				dispatch(
					Action.maps.removeMapLayer(ownProps.mapKey, ownProps.layerKey),
				);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
