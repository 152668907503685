export function replaceUrlTemplates(urlTemplate, parameters = {}) {
	let updated = urlTemplate;
	Object.keys(parameters).forEach(key => {
		updated = updated.replace(key, parameters[key]);
	});
	return updated;
}

export function composeCityClusterFeatureURI(
	urlPart,
	areaFeatureKey,
	urlParamsPart,
) {
	return `${urlPart}/${areaFeatureKey}/${urlParamsPart}`;
}
