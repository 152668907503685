export default {
	componentKey: "populationEvolution_total",
	title: 'Total increase',
	configuration: {
		attributes: [
			'ab4fe885-65db-4843-b43b-3c16693a9ac7', // GHSL absolute
			'07c38b31-9fdb-463e-b917-dbbafaa01f7a', // GHSL relative
			'02d27e1c-44cd-4a0a-8834-8be215e8f44e', // WSF 2016-2023 (10m) - absolute
			'bab2ea5b-50f8-400e-a725-ef6ffb4eef9c', // WSF 2016-2023 (10m) - relative
		],
		type: 'totalIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
