import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import classnames from 'classnames';
import './style.scss';

const SmallCircularButton = ({title, icon, tooltip, onClick, className}) => {
	const classes = classnames('unhab-SmallCircularButton', className);

	return (
		<Button
			title={title}
			small
			circular
			invisible
			icon={icon}
			className={classes}
			onClick={onClick}
			tooltip={tooltip}
		/>
	);
};

SmallCircularButton.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func,
	tooltip: PropTypes.object,
};

export default SmallCircularButton;
