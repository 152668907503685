import theme from './_theme';

export default {
	componentKey: 'urbanAreaShare_chart',
	type: 'areaShareChart',
	title: 'Urban extent share (2019)',
	subtitle: 'WSF 2019, total area [%]',
	configuration: {
		attributes: ['be396866-7981-4c2c-a90a-f9b23ce9e158'],
		normalization: {
			attribute: '4e05b390-0e33-41e1-949d-2d51616a4af7',
		},
		metadata: {
			settings: {
				colors: {datum: 'data.color'},
				margin: {top: 10, right: 10, bottom: 10, left: 10},
				innerRadius: 0.5,
				padAngle: 2,
				arcLabel: false,
				activeOuterRadiusOffset: 6,
				theme,
			},
		},
	},
};
