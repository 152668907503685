import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover/Popover';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Menu from '../Menu';
import MenuToggleItem from '../Menu/MenuToggleItem';
import HeaderTool from '../Header/HeaderTools/HeaderTool';
import CondensedControlsControl from '../Controls/CondensedControlsControl';
import HorizontalMapsControl from '../Controls/HorizontalMapsControl';
import ScaleControl from '../Controls/ScaleControl';
import TooltipControl from '../Controls/TooltipControl';
import './style.scss';
import {
	benchmarkViewKey,
	defaultViewKey,
	reportViewKey,
	xCubeViewKey,
} from '../../../constants/app';
import MapCoordinatesControl from '../Controls/MapCoordinatesControl';

const getContentByViewKey = viewKey => {
	switch (viewKey) {
		// Explore
		case defaultViewKey:
			return (
				<Menu>
					<MenuToggleItem disabled icon="ri-tooltip">
						Map tooltips
					</MenuToggleItem>
					<HorizontalMapsControl />
					<CondensedControlsControl />
					<MapCoordinatesControl />
					<ScaleControl />
					<TooltipControl />
				</Menu>
			);
		// Benchmark
		case benchmarkViewKey:
			return (
				<Menu>
					<MenuToggleItem disabled icon="ri-tooltip">
						Map tooltips
					</MenuToggleItem>
					<HorizontalMapsControl />
					<CondensedControlsControl />
					<MapCoordinatesControl />
				</Menu>
			);
		// Report
		case reportViewKey:
			return (
				<Menu>
					<CondensedControlsControl />
				</Menu>
			);
		// Limassol
		case xCubeViewKey:
			return (
				<Menu>
					<MenuToggleItem disabled icon="ri-tooltip">
						Map tooltips
					</MenuToggleItem>
					<CondensedControlsControl />
					<MapCoordinatesControl />
				</Menu>
			);
		default:
			return null;
	}
};

const AppOptions = ({viewKey, mapSetKey}) => {
	const wrapperEl = useRef(null);
	const [isOpen, setOpen] = useState(false);

	useOnClickOutside(wrapperEl, () => {
		setOpen(false);
	});

	return (
		<div className="unhab-AppOptions" ref={wrapperEl}>
			<HeaderTool
				icon="ri-settings"
				onChange={setOpen}
				active={isOpen}
				tooltip={{
					text: 'Advanced settings',
					id: 'AppOptions',
					position: 'bottom',
				}}
			/>
			<Popover isOpen={isOpen} classNames="unhab-AppOptions-menu">
				{getContentByViewKey(viewKey, mapSetKey)}
			</Popover>
		</div>
	);
};

AppOptions.propTypes = {
	viewKey: PropTypes.string,
	mapSetKey: PropTypes.string,
};

export default AppOptions;
