import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = state => {
	const mapKeys = Select.maps.getAllMapsInUse(state);
	return {
		mapKeys: mapKeys,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
