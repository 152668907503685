import PropTypes from 'prop-types';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';
import DatasetInfo from '../../DatasetInfo';

const FilteredIndicatorsListItem = ({nameDisplay, map, statistics}) => {
	return (
		<div className="unhab-FilteredIndicatorsListItem">
			<div className="unhab-FilteredIndicatorsListItem-icons">
				{map ? <Icon icon="map-pin" /> : <div className="icon-placeholder" />}
				{statistics ? (
					<Icon icon="ri-bar-chart" />
				) : (
					<div className="icon-placeholder" />
				)}
			</div>
			<div className="unhab-FilteredIndicatorsListItem-name">{nameDisplay}</div>
		</div>
	);
};

FilteredIndicatorsListItem.propTypes = {
	indicatorKey: PropTypes.string,
	nameDisplay: PropTypes.string,
	map: PropTypes.bool,
	statistics: PropTypes.bool,
};

const FilteredIndicatorsList = ({indicatorGroups}) => {
	return (
		<div className="unhab-FilteredIndicatorsList-wrapper">
			<div className="unhab-FilteredIndicatorsList">
				{indicatorGroups?.length
					? indicatorGroups.map(({key, data, indicators}) => {
							return (
								<div className="unhab-FilteredIndicatorsList-group" key={key}>
									<div className="unhab-FilteredIndicatorsList-TitleGroup">
										<h4 className="unhab-FilteredIndicatorsList-groupTitle">
											{data?.nameDisplay}
										</h4>
										<div className="unhab-FilteredIndicatorsList-groupInfo">
											<DatasetInfo indicator={data?.nameDisplay} />
										</div>
									</div>
									{indicators?.length
										? indicators.map(({key, data}) => (
												<FilteredIndicatorsListItem
													key={key}
													indicatorKey={key}
													{...data}
												/>
											))
										: 'No indicators'}
								</div>
							);
						})
					: 'No indicators'}
			</div>
			<div className="unhab-FilteredIndicatorsLegend">
				<div className="unhab-FilteredIndicatorsLegend-item">
					<Icon icon="map-pin" />
					<div className="unhab-FilteredIndicatorsLegend-name">
						Indicator in map
					</div>
				</div>
				<div className="unhab-FilteredIndicatorsLegend-item">
					<Icon icon="ri-bar-chart" />
					<div className="unhab-FilteredIndicatorsLegend-name">
						Indicator in statistic panel
					</div>
				</div>
			</div>
		</div>
	);
};

FilteredIndicatorsList.propTypes = {
	indicatorGroups: PropTypes.array,
};

export default FilteredIndicatorsList;
