import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import classnames from 'classnames';
import './style.scss';
import {useEffect} from 'react';
import {reportingPeriod} from '../../../constants/app';

const setPeriod = (step, start, stop, setActivePeriod) => {
	const adjustValue = (value, step) => {
		const diff = value % step;
		if (diff) {
			if (diff < 3) {
				return value - diff;
			} else {
				return value + step - diff;
			}
		}
		return value;
	};

	const newStart = adjustValue(Number(start), step);
	const newStop = adjustValue(Number(stop), step);

	if (start % step || stop % step) {
		setActivePeriod([newStart, newStop].join('/'));
	}
};

const PeriodSelector = ({
	activePeriod,
	setActivePeriod,
	narrow,
	step,
	simpleView,
	periodExtent,
}) => {
	const [min, max] = periodExtent.split('/');
	const [start, stop] = activePeriod.split('/');

	const classes = classnames('unhab-PeriodSelector', {
		'is-narrow': narrow,
		'is-simple-view': simpleView,
	});
	const onPeriodChange = period => {
		setActivePeriod(period.join('/'));
	};

	useEffect(() => {
		const [min, max] = periodExtent.split('/');
		const [start, stop] = activePeriod.split('/');
		let newStart, newEnd;
		if (start < min) {
			newStart = min;
		}
		if (stop > max) {
			newEnd = max;
		}
		if (newStart || newEnd) {
			if (step === reportingPeriod) {
				setPeriod(step, newStart || start, newEnd || stop, setActivePeriod);
			} else {
				setActivePeriod([newStart || start, newEnd || stop].join('/'));
			}
		}
	}, [periodExtent, activePeriod]);

	useEffect(() => {
		if (step === reportingPeriod) {
			setPeriod(step, start, stop, setActivePeriod);
		}
	}, [step]);

	const sliderMin = Number(min);
	const sliderMax = Number(max);
	const sliderStart = Number(start);
	const sliderStop = Number(stop);

	return (
		<div className={classes}>
			{simpleView ? (
				<>
					<span>{start}</span>-<span>{stop}</span>
					{start !== min || stop !== max ? (
						<p>(Selected period is not applied to the map)</p>
					) : null}
				</>
			) : (
				<>
					<span>{min}</span>
					<ReactSlider
						min={sliderMin}
						max={sliderMax}
						minDistance={step || 1}
						pearling
						step={
							step
								? sliderMax - sliderMin > 10
									? step
									: sliderMax - sliderMin
								: 1
						}
						value={[
							sliderStart < sliderMin ? sliderMin : sliderStart,
							sliderStop > sliderMax ? sliderMax : sliderStop,
						]}
						onChange={onPeriodChange}
						className="unhab-PeriodSelector-slider"
						thumbClassName="unhab-PeriodSelector-thumb"
						trackClassName="unhab-PeriodSelector-track"
						renderThumb={(p, state) => {
							const {key, ...rest} = p;
							return (
								<div key={key} {...rest}>
									<div title={state.valueNow}>{`${state.valueNow
										?.toString()
										.substring(2, 4)}`}</div>
								</div>
							);
						}}
					/>
					<span>{max}</span>
				</>
			)}
		</div>
	);
};

PeriodSelector.propTypes = {
	narrow: PropTypes.bool,
	step: PropTypes.number,
	periodExtent: PropTypes.string,
	activePeriod: PropTypes.string,
	setActivePeriod: PropTypes.func,
	simpleView: PropTypes.bool,
};

export default PeriodSelector;
