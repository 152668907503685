import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import Presentation from '../Header/HeaderTools/HeaderTool';
import {reportingPeriod} from '../../../constants/app';

const mapStateToProps = state => {
	return {
		tooltip: {
			text: '5-year period',
			id: 'FiveYearReportingPeriod',
			position: 'bottom',
		},
		active:
			Select.components.get(state, 'App', 'periodStep') === reportingPeriod,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		onChange: active => {
			dispatch(
				Action.components.set(
					'App',
					'periodStep',
					active ? reportingPeriod : 1,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
