import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';

import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	ownProps.mapKey = ownProps.mapKey || Select.maps.getActiveMapKey(state);
	return {
		providers:
			Select.unhab.layers.getIndicatorLayersByProvidersFilteredByActiveTags(
				state,
				true,
				ownProps.activeTags,
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
