import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Selectors from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, 'App', 'horizontalMaps'),
		disabled: Selectors.components.get(state, 'Maps', 'mode') === 'compare',
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: horizontalMapsActive =>
			dispatch(
				Action.components.set('App', 'horizontalMaps', horizontalMapsActive),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
