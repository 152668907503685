import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';
import {
	datasetsComparisonType,
	areaComparisonType,
} from '../../../constants/app';

const INITIAL_STATE = {
	byKey: {
		areas: {
			key: areaComparisonType,
			data: {
				nameDisplay: 'Areas',
			},
		},
		datasets: {
			key: datasetsComparisonType,
			data: {
				nameDisplay: 'Datasets',
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.BENCHMARK_COMPARISON_TYPES.SET_ACTIVE_KEY:
			return commonReducers.setActive(state, action);
		default:
			return state;
	}
};
