import PropTypes from 'prop-types';
import Chart from '../Chart';
import './style.scss';

// Components
import StatisticsSection from './StatisticsSection';
import IndicatorBox from '../IndicatorBox';
import IndicatorBoxSet from '../IndicatorBox/IndicatorBoxSet';
import IndicatorFromTimeSerie from '../IndicatorBox/IndicatorFromTimeSerie';

// Indicators
import urbanizationAreaEvolution_total from '../../../data/indicators/urbanizationAreaEvolution_total';
import urbanizationAreaEvolution_average from '../../../data/indicators/urbanizationAreaEvolution_average';
import urbanizationAreaEvolution_minimumIncrease from '../../../data/indicators/urbanizationAreaEvolution_minimumIncrease';
import urbanizationAreaEvolution_maximumIncrease from '../../../data/indicators/urbanizationAreaEvolution_maximumIncrease';
import landConsumptionRate from '../../../data/indicators/landConsumptionRate';
import totalAreaOfUrbanExtent_minimum from '../../../data/indicators/totalAreaOfUrbanExtent_minimum';
import totalAreaOfUrbanExtent_maximum from '../../../data/indicators/totalAreaOfUrbanExtent_maximum';
import urbanArea_2019 from '../../../data/indicators/urbanArea_2019';
import populationEvolution_total from '../../../data/indicators/populationEvolution_total';
import populationEvolution_average from '../../../data/indicators/populationEvolution_average';
import populationEvolution_minimumIncrease from '../../../data/indicators/populationEvolution_minimumIncrease';
import populationEvolution_maximumIncrease from '../../../data/indicators/populationEvolution_maximumIncrease';
import populationGrowthRate from '../../../data/indicators/populationGrowthRate';
import landConsumptionRate_populationGrowthRate from '../../../data/indicators/landConsumptionRate_populationGrowthRate';
import landConsumptionPerCapita from '../../../data/indicators/landConsumptionPerCapita';
import totalPopulation_2019 from '../../../data/indicators/totalPopulation_2019';
import totalPopulation_maximum from '../../../data/indicators/totalPopulation_maximum';
import totalPopulation_minimum from '../../../data/indicators/totalPopulation_minimum';

// Charts
import totalAreaOfUrbanExtent from '../../../data/charts/totalAreaOfUrbanExtent';
import totalAreaOfUrbanExtentRelative from '../../../data/charts/totalAreaOfUrbanExtentRelative';
import urbanAreaEvolution from '../../../data/charts/urbanAreaEvolution';
import urbanAreaEvolutionRelative from '../../../data/charts/urbanAreaEvolutionRelative';
import landConsumptionRateChart from '../../../data/charts/landConsumptionRate';
import urbanAreaShare from '../../../data/charts/urbanAreaShare';
import populationGrowth from '../../../data/charts/populationGrowth';
import populationDensityGrowth from '../../../data/charts/populationDensityGrowth';
import populationEvolution from '../../../data/charts/populationEvolution';
import populationEvolutionRelative from '../../../data/charts/populationEvolutionRelative';
import populationGrowthRateChart from '../../../data/charts/populationGrowthRate';
import landConsumptionRate_populationGrowthRateChart from '../../../data/charts/landConsumptionRate_populationGrowthRate';
import landConsumptionPerCapitaChart from '../../../data/charts/landConsumptionPerCapita';
import scatterPlotLcrPgrGhsl from '../../../data/charts/scatterPlot_lcr_pgr_ghsl';
import scatterPlotLcrPgrWsf from '../../../data/charts/scatterPlot_lcr_pgr_wsf';
import scatterPlotLcpcGhsl from '../../../data/charts/scatterPlot_lcpc_ghsl';
import scatterPlotLcpcGhslCountries from '../../../data/charts/scatterPlot_lcpc_ghsl_countries';
import scatterPlotLcpcWsf from '../../../data/charts/scatterPlot_lcpc_wsf';
import scatterPlotLcpcWsfCountries from '../../../data/charts/scatterPlot_lcpc_wsf_countries';

const getContentBySectionKey = (key, selectable) => {
	switch (key) {
		// Urbanization area evolution
		case '4c9aa8c4-3d5b-4fd2-acb7-38026e074d91':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_total}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_average}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_minimumIncrease}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_maximumIncrease}
							selectable={selectable}
						/>
					</IndicatorBoxSet>
					<Chart {...urbanAreaEvolution} selectable={selectable} />
					<Chart {...urbanAreaEvolutionRelative} selectable={selectable} />
				</>
			);

		// Urban area
		case '59b06cf6-278f-4d4b-8de7-c929ba94ebef':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie
							{...totalAreaOfUrbanExtent_minimum}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...totalAreaOfUrbanExtent_maximum}
							selectable={selectable}
						/>
						<IndicatorBox {...urbanArea_2019} selectable={selectable} />
					</IndicatorBoxSet>
					<Chart {...totalAreaOfUrbanExtent} selectable={selectable} />
					<Chart {...totalAreaOfUrbanExtentRelative} selectable={selectable} />
					<Chart {...urbanAreaShare} selectable={selectable} />
				</>
			);

		// Population
		case '1c2eadd3-abc4-4bc8-b58b-6115e86b5894':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie
							{...totalPopulation_maximum}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...totalPopulation_minimum}
							selectable={selectable}
						/>
					</IndicatorBoxSet>
					<Chart {...populationGrowth} selectable={selectable} />
					<Chart {...populationDensityGrowth} selectable={selectable} />
				</>
			);

		// Population evolution
		case '731cdec7-27cd-483e-895f-4eb6d3bf312f':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie
							{...populationEvolution_total}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...populationEvolution_average}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...populationEvolution_minimumIncrease}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...populationEvolution_maximumIncrease}
							selectable={selectable}
						/>
						<IndicatorBox {...totalPopulation_2019} selectable={selectable} />
					</IndicatorBoxSet>
					<Chart {...populationEvolution} selectable={selectable} />
					<Chart {...populationEvolutionRelative} selectable={selectable} />
				</>
			);

		// SDG Indicators
		case '762f0ade-9663-4287-8a5e-8c0e10c89a49':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie
							{...landConsumptionRate}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...populationGrowthRate}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...landConsumptionRate_populationGrowthRate}
							selectable={selectable}
						/>
						<IndicatorFromTimeSerie
							{...landConsumptionPerCapita}
							selectable={selectable}
						/>
					</IndicatorBoxSet>
					<Chart {...landConsumptionRateChart} selectable={selectable} />
					<Chart {...populationGrowthRateChart} selectable={selectable} />
					<Chart
						{...landConsumptionRate_populationGrowthRateChart}
						selectable={selectable}
					/>
					<Chart {...landConsumptionPerCapitaChart} selectable={selectable} />
					<Chart {...scatterPlotLcrPgrGhsl} selectable={selectable} />
					<Chart {...scatterPlotLcrPgrWsf} selectable={selectable} />
					<Chart {...scatterPlotLcpcGhslCountries} selectable={selectable} />
					<Chart {...scatterPlotLcpcGhsl} selectable={selectable} />
					<Chart {...scatterPlotLcpcWsfCountries} selectable={selectable} />
					<Chart {...scatterPlotLcpcWsf} selectable={selectable} />
				</>
			);

		default:
			return null;
	}
};

const AnalyticsPanelStatistics = ({sections, dataElementsSelectable}) => {
	return (
		<div className="unhab-AnalyticsPanelStatistics">
			<div className="unhab-AnalyticsPanelStatistics-body">
				<div className="unhab-AnalyticsPanelStatistics-sections">
					{sections?.map(section => {
						const {key, data} = section;
						const chart = getContentBySectionKey(key, dataElementsSelectable);

						return chart ? (
							<StatisticsSection key={key} open title={data.nameDisplay}>
								{chart}
							</StatisticsSection>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

AnalyticsPanelStatistics.propTypes = {
	dataElementsSelectable: PropTypes.bool,
	sections: PropTypes.array,
};

export default AnalyticsPanelStatistics;
