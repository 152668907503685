import PropTypes from 'prop-types';
import './style.scss';

const MapForExport = ({mapImageData}) => {
	return (
		<div className="unhab-MapForExport">
			<img className="unhab-MapForExport-mapImage" src={mapImageData} />
		</div>
	);
};

MapForExport.propTypes = {
	mapImageData: PropTypes.string,
	mapKey: PropTypes.string,
	mapView: PropTypes.object,
	mapHeight: PropTypes.number,
	mapWidth: PropTypes.number,
};

export default MapForExport;
