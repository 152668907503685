export default {
	componentKey: "populationGrowthRate",
	title: 'Population growth rate',
	configuration: {
		valueFormat: {
			decimals: 3,
		},
		attributes: [
			'fedcb00a-92dc-4a03-acf0-a63040373b9f', // GHSL 1975-2030 - Population (100m)
			'de759d8f-bb00-441a-a06f-0a060c855637' // WSF 2016-2023 Population (10m)
		],
		type: 'growthRate',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'rate',
				description: 'Indicator 2 description',
			},
		],
	},
};
