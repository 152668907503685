import PropTypes from 'prop-types';
import ColorDotLabel from '../ColorDotLabel';
import './style.scss';

const SelectedDatasetLabel = ({
	color,
	color2,
	large,
	name,
	title,
	onClick,
	children,
}) => {
	return (
		<ColorDotLabel
			large={large}
			color={color}
			color2={color2}
			title={title}
			onClick={onClick}
		>
			{name}
			{children}
		</ColorDotLabel>
	);
};

SelectedDatasetLabel.propTypes = {
	color: PropTypes.string,
	color2: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	large: PropTypes.bool,
	title: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};

export default SelectedDatasetLabel;
