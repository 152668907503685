import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		activeLayers: Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSort: (fromIndex, toIndex, layers) => {
			dispatch(
				Action.unhab.layers.sortLayers(
					ownProps.mapKey,
					fromIndex,
					toIndex,
					layers,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
