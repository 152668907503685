import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';

const MapCoordinatesInfoBox = ({active, coordinates, onInfoClose}) => {
	return active ? (
		<div className="unhab-MapCoordinatesInfo">
			{coordinates ? (
				<div className="unhab-MapCoordinatesInfo-coordinates">
					<Icon icon="map-pin" />
					<div className="unhab-MapCoordinatesInfo-coordinates-text">
						{coordinates[0].toFixed(6)}, {coordinates[1].toFixed(6)}
					</div>
				</div>
			) : (
				<div className="unhab-MapCoordinatesInfo-placeholder">
					{'Click on the map to see coordinates'}
				</div>
			)}
			<div className="unhab-MapCoordinatesInfo-close" onClick={onInfoClose}>
				<Icon icon="ri-close" />
			</div>
		</div>
	) : null;
};

MapCoordinatesInfoBox.propTypes = {
	active: PropTypes.bool,
	coordinates: PropTypes.array,
	onInfoClose: PropTypes.func,
};

export default MapCoordinatesInfoBox;
