import PropTypes from 'prop-types';
import DatasetLayerControls from '../DatasetLayerControls/';
import DataLayersGroup from '../components/DataLayersGroup';

const IndicatorLayersControl = ({
	closable,
	mapKey,
	providers,
	interactive,
	large,
	justContent,
}) => {
	const content = providers.map(provider => {
		return (
			<DataLayersGroup
				title={provider.nameDisplay}
				key={provider.key}
				closable={closable}
				large={large}
				defaultOpen
			>
				{provider?.data?.datasets.map(dataset => {
					return (
						<DatasetLayerControls
							key={dataset.key}
							datasetKey={dataset.key}
							title={dataset.data.nameDisplay}
							attributes={dataset.data.attributes}
							mapKey={mapKey}
							interactive={interactive}
							large={large}
						/>
					);
				})}
			</DataLayersGroup>
		);
	});

	return justContent ? (
		content
	) : (
		<DataLayersGroup title={'Indicator layers'} defaultOpen>
			{content}
		</DataLayersGroup>
	);
};

IndicatorLayersControl.propTypes = {
	mapKey: PropTypes.string,
	providers: PropTypes.array,
	interactive: PropTypes.bool,
	large: PropTypes.bool,
	justContent: PropTypes.bool,
	closable: PropTypes.bool,
};

export default IndicatorLayersControl;
