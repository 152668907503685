import PropTypes from 'prop-types';
import './style.scss';

const MetadataItemInfoSectionImage = ({imgUrl}) => {
	return (
		<img
			className="unhab-DatasetInfo-image"
			src={imgUrl}
			alt="Image data example"
		/>
	);
};

MetadataItemInfoSectionImage.propTypes = {
	imgUrl: PropTypes.string,
};

export default MetadataItemInfoSectionImage;
