//import Link from '../../common/Link';
import {useState} from 'react';
//import {getRouter} from '../../../router';
import './style.scss';

import logoUnhab from './images/unhab.png';
import logoGisat from './images/gisat.png';
import logoDlr from './images/dlr.png';
import logoUtep from './images/utep.png';
import logoEsa from './images/esa.png';

const HomePage = () => {
	// const router = getRouter();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<main className="insar-Intro">
			{/* Background overlay */}
			<div className="overlay"></div>

			{/* Content in the middle */}
			<div className="content">
				<div className="page-title">
					<h1>Urban Expansion Insight</h1>
					<p>Sustainable Development Goal Indicator 11.3.1 made easy</p>
				</div>

				<div className="card-group">
					<a
						href="/app?viewKey=6f59d02b-7055-4292-ba4a-939d616f9ea9"
						className="card-box"
					>
						<h3 className="card-title">Explore</h3>
						<p className="card-subtitle">
							Dive deep into global urbanization patterns and trends
						</p>
					</a>
					<a
						href="/app?viewKey=a068c9c2-3dbf-4818-87e6-03418bc467a8"
						className="card-box"
					>
						<h3 className="card-title">Benchmark</h3>
						<p className="card-subtitle">
							Compare urbanization metrics for countries or regions
						</p>
					</a>
					<a
						href="/app?viewKey=7c6dbfaf-1ee7-43db-a5db-8eda9d8182c4"
						className="card-box"
					>
						<h3 className="card-title">Report</h3>
						<p className="card-subtitle">
							Generate reports with your fundings and insights easily
						</p>
					</a>
				</div>

				<div className="page-button">
					<a href="/app">Go to App</a>
				</div>
			</div>

			{/* Partner logos at the bottom */}
			<footer className="footer">
				<img src={logoGisat} alt="Gisat" className="partner-logo" />
				<img src={logoDlr} alt="DLR" className="partner-logo" />
				<img src={logoUtep} alt="UTEP" className="partner-logo" />
				<img src={logoEsa} alt="ESA" className="partner-logo" />
				<img src={logoUnhab} alt="UNHABITAT" className="partner-logo" />
			</footer>

			{/* Info button in the top right corner */}
			<button className="info-button" onClick={openModal}>
				i
			</button>

			{/* Modal for project description */}
			{isModalOpen && (
				<div className="modal-overlay" onClick={closeModal}>
					<div className="modal" onClick={e => e.stopPropagation()}>
						<h3>Urban TEP</h3>
						<h4>
							Global Implementation of Sustainable Development Goal Indicator
							11.3.1 and New Urban Agenda Monitoring
						</h4>
						<p>
							Project aims at an international collaboration with UN Habitat in
							order to exploit the opportunities offered by the Urban TEP (and
							EDC), and the World Settlement Footprint (WSF) datasets onboarded.
						</p>
						<p>
							Granted by ESA Earthnet funding to support collaboration with UN
							Habitat on Sustainable Development (SDG) Goal 11 (Sustainable
							cities and communities) and the New Urban Agenda (NUA) support.
						</p>
						<p>
							Implemented by GISAT and DLR, the cooperation directly contributes
							to production of high-quality city data on SDG indicator 11.3.1
							across all world regions, and to prepare the ground for other
							related indicators within the NUA monitoring framework.
						</p>
						<p>
							Moreover, the important goal is to support national and local city
							authorities from developing countries to embrace EO technology in
							their work, to effectively monitor and analyse their urbanisation
							processes and to report on the SDG indicator 11.3.1 on sustainable
							urbanisation.
						</p>
						<button className="close-button" onClick={closeModal} />
					</div>
				</div>
			)}
		</main>
	);
};

export default HomePage;
