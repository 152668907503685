import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const mapStateToProps = state => {
	return {
		componentsSizes: Select.components.getByComponentKey(state, 'Report'),
		printInProgress: Select.components.get(state, 'Report', 'printInProgress'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetPrintInProgress: inProgress => {
			dispatch(Action.components.set('Report', 'printInProgress', inProgress));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
