import {auSelectionKey, distinctColours} from '../../constants/app';

export default {
	key: auSelectionKey,
	data: {
		colourPalette: distinctColours,
		distinctItems: true,
		featureKeysFilter: {
			keyColourIndexPairs: {133: 0},
			keys: [133],
		},
		style: {
			outlineWidth: 4,
			outlineColor: distinctColours[0],
		},
	},
};
