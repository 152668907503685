import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../state/Action';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSizeChange: (width, height) => {
			dispatch(
				Action.components.set('Report', ownProps.componentKey, {width, height}),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
