import { ghsBuiltUpLayerKeys, ghsPopulationLayerKeys } from "./layers/ghsDatasetLayers";
import { wsfBuiltUpLayerKeys, wsfPopulationLayerKeys } from "./layers/wsfDatasetLayers";

export default [
	{
		key: 'ghsBuiltUpSurface',
		data: {
			nameDisplay: 'GHSL 1975-2030 - Built-up  (100m)',
			shortName: 'GHSL 75-30 Built-up (100m)',
			period: '1975/2030',
			defaultIndicatorLayerKey: 'GHS_BUILT_LCR',
			defaultDatasetLayerKey: 'GHSBuiltUp2030',
			layerKeys: ghsBuiltUpLayerKeys,
			attributes: [
				// Urban area evolution
				{key: '79d7615a-7d3f-11ee-b962-0242ac120002'}, // Total increase absolute
				{key: '79d75e58-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Total increase relative
				{key: '79d7661e-7d3f-11ee-b962-0242ac120002'}, // Average increase absolute
				{key: '79d764f2-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Average increase relative
				{key: '79d76e3e-7d3f-11ee-b962-0242ac120002'}, // Minimal increase absolute
				{key: '79d76d08-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Minimal increase relative
				{key: '79d77294-7d3f-11ee-b962-0242ac120002'}, // Maximum increase absolute
				{key: '79d77190-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Maximum increase relative
				{key: '8f77c076-ec70-4444-8ebb-1c7a757650f9'}, // Urban area evolution - absolute
				{key: '80431bc7-fc92-444d-8748-889fc1b4c5c9'}, // Urban area evolution - relative

				// Urban area
				{key: '2f6aa508-7e22-11ee-b962-0242ac120002'}, // Urban extent area, minimal absolute value
				{key: '2f6aa260-7e22-11ee-b962-0242ac120002', mapLayer: true}, // Urban extent area, minimal relative
				{key: '2f6aa8be-7e22-11ee-b962-0242ac120002'}, // Urban extent area, maximum absolute
				{key: '2f6aa7ce-7e22-11ee-b962-0242ac120002', mapLayer: true}, // Urban extent area, maximum relative
				{key: 'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f'}, // time series

				// sdg
				{key: 'c09bac02-f276-4ea4-a5f6-e090a8313454', mapLayer: true}, // Land consumption rate
				{key: '42154c13-4360-4de1-ae1e-7a0ceb5162f4'}, // Land consumption rate (time serie)

				{key: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4'}, // Land Consumption Rate/Population Growth Rate
				{key: 'a260ff12-fd67-444f-8b7a-2a6d44f67cec'}, // Land Consumption Rate/Population Growth Rate (time series)
				{key: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571'}, // Land Consumption Per Capita
				{key: 'c1f287e2-a30f-465b-a384-1e283471aca2'}, // Land Consumption Per Capita (time series)
			],
			color: '#4daf4a',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'The GHSL - Global Human Settlement Layer built-up surface raster dataset depicts the global distribution of built-up surfaces, expressed as a number of square metres. The data report about the total built-up surface and the built-up surface allocated to dominant non-residential (NRES) uses. Data are spatial-temporal interpolated or extrapolated from 1975 to 2030 in 5-year intervals (Pesaresi and Politis 2023).',
					source_link:
						'https://human-settlement.emergency.copernicus.eu/ghs_buS2023.php',
					references: [
						'Pesaresi M., Politis P. (2023): GHS-BUILT-S R2023A - GHS built-up surface grid, derived from Sentinel2 composite and Landsat, multitemporal (1975-2030). European Commission, Joint Research Centre (JRC).',
						'European Commission: GHSL Data Package 2023, Publications Office of the European Union, Luxembourg, 2023, JRC133256, ISBN 978-92-68-02341-9',
					],
				},
				uraban_area: [
					{
						title: 'Total area of urban extent - minimal relative',
						text: 'This indicator shows the minimal percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the minimal extent of built-up area is usually associated with the first year of the reference period. As the reference period for this indicator is 1975-2030, this value should correspond to 1975.',
						image: 'GHS_BUILT_UE_min_rel.png',
					},
					{
						title: 'Total area of urban extent - maximum relative',
						text: 'This indicator shows the maximum percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit). The maximum extent of the built-up area is usually associated with the last 5-year status of the reference period, which is 1975-2030. However, as values for 2025 and 2030 represent modelled future scenarios, the value of this indicator is associated with the year 2020.',
						image: 'GHS_BUILT_UE_max_rel.png',
					},
					{
						title:
							'Total area of urban extent - absolute [sqkm] & relative [per unit area (%)]',
						text: 'This indicator represents the percentage share of built-up extent on the total area of administrative units for the selected year in the 1975-2030 period.',
					},
				],
				urbanization_area_evolution: [
					{
						title: 'Total increase',
						text: "This indicator shows the overall percentage change in the urban area's evolution from 1975 to 2030. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'GHS_BUILT_LC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: "This indicator shows the average yearly percentage change in the urban area's evolution from 1975 to 2030. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'GHS_BUILT_LC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change in the built-up extent over the entire period of interest (1975-2030) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'GHS_BUILT_LC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change in the built-up extent over the entire period of interest (1975-2030) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'GHS_BUILT_LC_max_rel.png',
					},
					{
						title:
							'Yearly increase - absolute [sqkm per year] & relative [% to previous year stock]',
						text: 'This indicator shows the percentage change in built-up extent between two selected years. It is computed as the ratio of the built-up extent in the selected year and the built-up extent in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Land consumption rate',
						text: 'Land consumption rate (LCR) is the yearly rate at which urbanised land or land occupied by a city/urban area changes during the selected period (1975-2020). It is expressed as the natural logarithm of the shares of the built-up area in the selected year divided by the built-up area in the initial year, and the result is divided by the number of years between the selected years. The map shows the LCR for 2020.',
						image: 'GHS_BUILT_LCR_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'ghsPopulationGrid',
		data: {
			nameDisplay: 'GHSL 1975-2030 - Population (100m)',
			shortName: 'GHSL 75-30 Population (100m)',
			period: '1975/2030',
			defaultIndicatorLayerKey: 'GHS_POP_PGR',
			defaultDatasetLayerKey: 'GHSPopulation2030',
			layerKeys: ghsPopulationLayerKeys,
			attributes: [
				// (III) Population evolution
				{key: 'ab4fe885-65db-4843-b43b-3c16693a9ac7'}, // Population evolution total, absolute
				{key: '07c38b31-9fdb-463e-b917-dbbafaa01f7a', mapLayer: true}, // Population evolution total, relative
				{key: 'cbd8cc55-5a1c-4492-892b-86e87ed63872'}, // Population evolution average, absolute
				{key: 'ff258f32-5a90-4b51-a760-17a6d6e2b704', mapLayer: true}, // Population evolution average, relative
				{key: '6da76d87-977e-4b50-9bfa-3190c28f8a20'}, // Population evolution minimal, absolute
				{key: 'e1e76504-3a30-48fb-936b-1830557ad812', mapLayer: true}, // Population evolution minimal, relative
				{key: '08630acd-7ecf-4e90-bcaf-b0af90ca52e4'}, // Population evolution maximum, absolute
				{key: 'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a', mapLayer: true}, // Population evolution maximum, relative
				{key: 'a1e05dde-4a6b-46f0-a233-f6db97f9b386'}, // Population evolution - absolute - time series

				// Population
				{key: '0440450f-2e73-49a0-abdb-6f147515d998'}, // Total population - maximum, absolute
				{key: '48b6e7c7-b386-43ae-8172-2395df2900e6', mapLayer: true}, // Total population - maximum, relative
				{key: '00eb991b-167c-4b2e-a7c9-86e5725374e7'}, // Total population - minimal, absolute
				{key: 'c3914386-8047-4526-93dd-ad6b7497f8c4', mapLayer: true}, // Total population - minimal, relative
				{key: '363d9e21-d608-462b-b021-d78b86e56b39'}, // Population - absolute - time series
				{key: 'ba1ae30f-df22-4914-8e28-57ce2a88c1cc'}, // Population - relative - time series

				// SDG
				{key: 'fedcb00a-92dc-4a03-acf0-a63040373b9f', mapLayer: true}, // Population Growth Rate
				{key: '338aa0b6-8ec4-4ad3-ab05-4e917bf8cee7'}, // Population Growth Rate - time series
				{key: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4'}, // Land Consumption Rate/Population Growth Rate
				{key: 'a260ff12-fd67-444f-8b7a-2a6d44f67cec'}, // Land Consumption Rate/Population Growth Rate (time series)
				{key: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571'}, // Land Consumption Per Capita
				{key: 'c1f287e2-a30f-465b-a384-1e283471aca2'}, // Land Consumption Per Capita (time series)
			],
			color: '#ff7f00',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'The GHSL - Global Human Settlement Layer population raster dataset depicts the distribution of residential population, expressed as the number of people per cell. Residential population estimates between 1975 and 2020 in 5-year intervals and projections to 2025 and 2030 derived from CIESIN GPWv4.11 were disaggregated from census or administrative units to grid cells, informed by the distribution, volume, and classification of built-up as mapped in the Global Human Settlement Layer (GHSL) global layer per corresponding epoch (Schiavina, Freire, Carioli, MacManus 2023).',
					source_link:
						'https://human-settlement.emergency.copernicus.eu/ghs_pop2023.php',
					references: [
						'Schiavina M., Freire S., Carioli A., MacManus K. (2023): GHS-POP R2023A - GHS population grid multitemporal (1975-2030).European Commission, Joint Research Centre (JRC).',
						'Freire S., MacManus K., Pesaresi M., Doxsey-Whitfield E., Mills J. (2016): Development of new open and free multi-temporal global population grids at 250 m resolution. Geospatial Data in a Changing World; Association of Geographic Information Laboratories in Europe (AGILE), AGILE 2016',
					],
				},
				population: [
					{
						title: 'Total population - minimal',
						text: 'This indicator shows the year from the period 1975-2030 and the corresponding value with the minimal population density. If the number of inhabitants decreases over time, then the lowest population density can be expected in the last years and vice versa.',
						image: 'GHS_POP_TOTAL_min_rel.png',
					},
					{
						title: 'Total population - maximum',
						text: 'This indicator shows the year from the period 1975-2030 and the corresponding value with the maximum population density. If the number of inhabitants decreases over time, then the highest population density can be expected in the initial years and vice versa.',
						image: 'GHS_POP_TOTAL_max_rel.png',
					},
					{
						title:
							'Total population (density) - absolute [inh] & relative [per unit area (%)] ',
						text: 'This indicator represents population density for the selected year in the 1975-2030 period. The integer value is expressed as the number of inhabitants per km square.',
					},
				],
				population_evolution: [
					{
						title: 'Total increase',
						text: 'This indicator shows the overall percentage change in the population from 1975 to 2030. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.',
						image: 'GHS_POP_PC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: 'This indicator shows the average yearly percentage change in the population from 1975 to 2030. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.',
						image: 'GHS_POP_PC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change of population over the entire period of interest (1975-2030) in the selected reference unit. Value might be 0 if no progress in the evolution of the population was detected.',
						image: 'GHS_POP_PC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change of population over the entire period of interest (1975-2030) in the selected reference unit. Value might be 0 if no progress in the evolution of the population was detected.',
						image: 'GHS_POP_PC_max_rel.png',
					},
					{
						title:
							'Yearly increase - absolute [inh per year] & relative [% to previous year stock]',
						text: 'This indicator shows the percentage change in population between two selected years. It is computed as the ratio of the population in the selected year and the population in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Population growth rate',
						text: 'Population growth rate (PGR) is the yearly rate at which the population in selected administrative units (ADM1, ADM0) increased during the selected period (1975-2020). It is expressed as the natural logarithm of the population in the selected year divided by the population in the initial year, and the result is divided by the number of years between the selected years. The map shows the PGR for 2020.',
						image: 'GHS_POP_PGR_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'ghsCombinedBuiltUpPop100m',
		data: {
			nameDisplay: 'Combined GHSL 1975-2030 - Built-up + Population (100m)',
			shortName: 'GHSL 75-30 (100m)',
			period: '1975/2030',
			attributes: [
				// SDG
				{key: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4', mapLayer: true}, // Land Consumption Rate/Population Growth Rate
				{key: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', mapLayer: true}, // Land Consumption per capita
				{key: 'a260ff12-fd67-444f-8b7a-2a6d44f67cec'}, // Land Consumption Rate/Population Growth Rate (time series)
				{key: 'c1f287e2-a30f-465b-a384-1e283471aca2'}, // Land Consumption Per Capita (time series)
			],
			color: '#5e5e5e',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'This SDG indicator combines information on the built-up area ("GHSL 1975-2030 - Built-up  (100m)") and at the same time information on the state of the population ("GHSL 1975-2030 - Population (100m)"). For more information, please visit the descriptions of the "GHSL 1975-2030 - Built-up  (100m)" and "GHSL 1975-2030 - Population (100m)" datasets.',
				},
				sdg: [
					{
						title: 'Land Consumption Rate/Population Growth Rate',
						text: 'Land consumption and population growth rates (LCRPGR) are used to assess the sustainability of urban growth, which considers both the change in the built-up area and the population. LC RPGR is calculated as the land consumption rate (LCR) and population growth rate (PGR) ratio. The map shows the LCRPGR for 2020.',
						image: 'GHS_LCRPGR_YYYY.png',
					},
					{
						title: 'Land Consumption per capita',
						text: 'This indicator represents the total urban area in metres divided by the total population (Land Consumption per capita). Land consumption per capita represents the average amount of land each person in a city consumes/occupies during each analysis year. The map shows the LCPC for 2020.',
						image: 'GHS_LCPC_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'wsfBuildUp2016_2023',
		data: {
			featured: true,
			period: '2016/2023',
			nameDisplay: 'WSF 2016-2023 - Built-up (10m)',
			shortName: 'WSF 16-23 Built-up (10m)',
			defaultIndicatorLayerKey: 'WSF_BUILT_LCR',
			defaultDatasetLayerKey: 'WSFBuiltUp2023',
			layerKeys: wsfBuiltUpLayerKeys,
			attributes: [
				//Urban area
				{key: '1ae9cb89-ea52-400e-8a8c-4a0cd809fbf7'}, //Total area of urban extent - minimal absolute value
				{key: '1e01f96a-ca6d-4a57-a849-12f2079a5d20', mapLayer: true}, //Total area of urban extent - minimal relative value
				{key: '8412a01d-3b7f-4c25-abad-caccff9eb4e0'}, //Total area of urban extent - maximum absolute value
				{key: 'feb42dc0-332d-41ad-b96d-633ff3f52fce', mapLayer: true}, //Total area of urban extent - maximum relative value
				{key: '03a6772d-c07e-4ac2-a18d-a87ec02889fe'}, //Total area of urban extent - absolute (timeserie)

				// Urbanization area evolution
				{key: '5e9898f4-01b2-48e7-a945-9afc664a3cae'}, //Total increase absolute
				{key: '17843dcd-060c-4c44-83e4-6380ffe40215', mapLayer: true}, //Total increase relative
				{key: '0eaac7cf-3e6d-4263-b8ac-c95649c9e977'}, //Average increase absolute
				{key: '840f8957-c450-4cb5-8e2e-2c88ee6ff4a4', mapLayer: true}, //Average increase relative
				{key: 'f5f83add-0c3f-4d4b-acae-28cf0a538a3b'}, //Min. increase absolute
				{key: '533a1634-b742-4897-9d11-4f11be8dc350', mapLayer: true}, //Min. increase relative
				{key: '4889a1c5-f6d9-403c-910e-c669d27a6f00'}, //Max. increase absolute
				{key: '0e998d61-74da-46d4-886b-d30e04d15934', mapLayer: true}, //Max. increase relative
				{key: 'a8558a05-ff96-4a50-aa7f-8187cce59900'}, // Urban area evolution - absolute (timeserie)
				{key: '43a63903-21e3-4b6b-9b09-a4dc2df4402c'}, // Urban area evolution - relative (timeserie)

				//sdg
				{key: '61d2b450-9546-4e68-8bf6-d09603dbaea2', mapLayer: true}, // Land consumption rate
				{key: 'f950af79-83be-42b7-8174-12f9353cb642'}, // Land consumption rate (time series)
				{key: '0432a5e6-f169-45c1-bb6a-c7407c41ea45'}, // Land consumption rate/Population growth rate
				{key: 'd5e2a536-b344-4bc6-9bc8-d509801d82f9'}, // Land consumption rate/Population growth rate (time series)
				{key: 'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83'}, // Land consumption per capita
				{key: 'f27dc482-2112-4ebb-8a1f-ab3e466d4a35'}, // Land consumption per capita (time series)
			],
			color: '#a65628',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],

			description: {
				brief_description: {
					text: 'These data are based on the built-up information from the "World Settlement Footprint Population 2016-2023", which provides a high-resolution population distribution map for corresponding years. The dataset is structured to represent population numbers per pixel at ~10-meter resolution, where pixels represent the status of settlements (or non-built-up corresponding to no-data value), built-up pixels might further show the number of population (if any). The built-up area is extracted by summing the area of all pixels (not their values) from "World Settlement Footprint Population 2016-2023" outside no-data values. The dataset is provided in the EPSG 4326 projection.',
				},
				uraban_area: [
					{
						title: 'Total area of urban extent - minimal',
						text: 'This indicator shows the minimal percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the minimal extent of built-up area is usually associated with the first year of the reference period. As the reference period for this indicator is 2016-2023, this value should correspond to 2016.',
						image: 'WSF_BUILT_UE_min_rel.png',
					},
					{
						title: 'Total area of urban extent - maximum',
						text: 'This indicator shows the maximum percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the maximum extent of built-up area is usually associated with the last year of the reference period. As the reference period for this indicator is 2016-2023, this value should correspond to 2023.',
						image: 'WSF_BUILT_UE_max_rel.png',
					},
					{
						title:
							'Total area of urban extent - relative [sqkm per unit area (%)] ',
						text: 'This indicator represents the percentage share of built-up extent on the total area of administrative units for the selected year in the 2016-2023 period.',
					},
				],
				urbanization_area_evolution: [
					{
						title: 'Total increase',
						text: "This indicator shows the overall percentage change in the urban area's evolution for the period 2016-2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSF_BUILT_LC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: "This indicator shows the average yearly percentage change in the urban area's evolution from 2016 to 2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSF_BUILT_LC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change in the built-up extent over the entire period of interest (2016-2023) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSF_BUILT_LC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change in the built-up extent over the entire period of interest (2016-2023) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSF_BUILT_LC_max_rel.png',
					},
					{
						title:
							'Yearly increase [ sqkm per year and % to previous year stock in chart]',
						text: 'This indicator shows the percentage change in built-up extent between two selected years. It is computed as the ratio of the built-up extent in the selected year and the built-up extent in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Land consumption rate',
						text: 'Land consumption rate (LCR) is the yearly rate at which urbanized land or land occupied by a city/urban area changes during a period (2016-2023). It is expressed as the natural logarithm of the shares of the built-up area in the selected year divided by the built-up area in the initial year, and the result is divided by the number of years between the selected years. The map shows the LCR for 2023.',
						image: 'WSF_BUILT_LCR_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'wsfPopulation2016_2023',
		data: {
			featured: true,
			period: '2016/2023',
			nameDisplay: 'WSF 2016-2023 - Population (10m)',
			shortName: 'WSF 16-23 Population (10m)',
			defaultIndicatorLayerKey: 'WSF_POP_PGR',
			defaultDatasetLayerKey: 'WSFPopulation2023',
			layerKeys: wsfPopulationLayerKeys,
			attributes: [
				// Population evolution
				{key: '02d27e1c-44cd-4a0a-8834-8be215e8f44e'}, //'Total increase absolute',
				{key: 'bab2ea5b-50f8-400e-a725-ef6ffb4eef9c', mapLayer: true}, //'Total increase',
				{key: '1225d913-6aff-4674-a8ac-2e0240daf11d'}, //'Average increase absolute',
				{key: '7341e0a4-a9da-4bce-a516-62d8bd7abc87', mapLayer: true}, //'Average increase',
				{key: '056c47e1-ae4e-4933-9a23-286088590a35'}, //'Min. increase absolute',
				{key: '695cd019-df5e-4cb8-b8b5-e841fbaa49e4', mapLayer: true}, //'Min. increase',
				{key: '6b882ebf-cc81-4ce4-a788-eb574a3e359d'}, //'Max. increase absolute',
				{key: '2951954d-cacf-470a-96a5-41bb5fd8bdad', mapLayer: true}, //'Max. increase',
				{key: '34d478e3-c42c-4893-9330-1e961a93d667'}, // Absolute (time series)

				// Population
				{key: 'b626dff7-2382-403f-9907-87c9b7a54e3c', mapLayer: true},
				{key: '8281c4e7-3fb0-4024-afae-ea476de33a73'},
				{key: 'c88e464a-5dbc-4c87-9aa8-b619abfc6d58', mapLayer: true},
				{key: '66b88a05-8b68-4afb-a8b0-1cb5e9f635eb'},
				{key: '5b9897d2-6a55-4010-b4db-c172006188b2'}, // absolute - time series
				{key: 'de413f2a-02a3-4ec5-93a9-348d6ef71fc0'}, // relative - time series

				// SDG
				{key: 'de759d8f-bb00-441a-a06f-0a060c855637', mapLayer: true}, // Population growth rate
				{key: '0a17814e-18cc-46e3-ac26-f7e97465d3a1'}, // Population growth rate (time series)
				{key: '0432a5e6-f169-45c1-bb6a-c7407c41ea45'}, // Land consumption rate/Population growth rate
				{key: 'd5e2a536-b344-4bc6-9bc8-d509801d82f9'}, // Land consumption rate/Population growth rate (time series)
				{key: 'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83'}, // Land consumption per capita
				{key: 'f27dc482-2112-4ebb-8a1f-ab3e466d4a35'}, // Land consumption per capita (time series)
			],
			color: '#f781bf',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'The World Settlement Footprint Population 2016-2023 dataset provides a high-resolution population distribution map for corresponding years. The dataset is structured to represent population numbers per pixel at ~10-meter resolution, where pixels represent the status of settlements (or non-built-up corresponding to no-data value), built-up pixels might further show the number of population (if any). The dataset is provided in the EPSG 4326 projection.',
				},
				population: [
					{
						title: 'Total population - minimal',
						text: 'This indicator shows the year from the period 2016-2023 and the corresponding value with the minimal population density. If the number of inhabitants decreases over time, then the lowest population density can be expected in the last years and vice versa.',
						image: 'WSF_POP_TOTAL_min_rel.png',
					},
					{
						title: 'Total population - maximum',
						text: 'This indicator shows the year from the period 2016-2023 and the corresponding value with the maximum population density. If the number of inhabitants decreases over time, then the highest population density can be expected in the initial years and vice versa.',
						image: 'WSF_POP_TOTAL_max_rel.png',
					},
					{
						title:
							'Total population (density) - absolute [inh] & relative [per unit area (%)] ',
						text: 'This indicator represents population density for the selected year in the 2016-2023 period. The integer value is expressed as the number of inhabitants per km square.',
					},
				],
				population_evolution: [
					{
						title: 'Total increase',
						text: 'This indicator shows the overall percentage change in the population from 2016 to 2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.',
						image: 'WSF_POP_PC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: 'This indicator shows the average yearly percentage change in the population from 2016 to 2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.',
						image: 'WSF_POP_PC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change of population over the entire period of interest (1975-2030) in the selected reference unit. Value might be 0 if no progress in the evolution of the population was detected.',
						image: 'WSF_POP_PC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change of population over the entire period of interest (1975-2030) in the selected reference unit. Value might be 0 if no progress in the evolution of the population was detected.',
						image: 'WSF_POP_PC_max_rel.png',
					},
					{
						title:
							'Yearly increase - absolute [inh per year]  & relative [% to previous year stock]',
						text: 'This indicator shows the percentage change in population between two selected years. It is computed as the ratio of the population in the selected year and the population in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Population growth rate',
						text: 'Population growth rate (PGR) is the yearly rate at which the population in selected administrative units (ADM1, ADM0) increased during a selected period (2016-2023). It is expressed as the natural logarithm of the population in the selected year divided by the population in the initial year, and the result is divided by the number of years between the selected years. The map shows the PGR for 2023.',
						image: 'WSF_POP_PGR_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'wsfCombinedBuiltupPopulation2016_2023',
		data: {
			featured: true,
			period: '2016/2023',
			nameDisplay: 'Combined WSF 2016-2023 - Built-up + Population (10m)',
			shortName: 'WSF 16-23 (10m)',
			attributes: [
				{key: '0432a5e6-f169-45c1-bb6a-c7407c41ea45', mapLayer: true}, // Land Consumption Rate/Population Growth Rate
				{key: 'd5e2a536-b344-4bc6-9bc8-d509801d82f9'}, // Land consumption rate/Population growth rate (time series)
				{key: 'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83', mapLayer: true}, // Land Consumption per capita
				{key: 'f27dc482-2112-4ebb-8a1f-ab3e466d4a35'}, // Land consumption per capita (time series)
			],
			color: '#9e9e9e', //change
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'This SDG indicator combines information on the built-up area ("WSF 2016-2023 - Built-up (10m)") and at the same time information on the state of the population ("WSF 2016-2023 - Population (10m)"). For more information, please visit the descriptions of the "WSF 2016-2023 - Built-up (10m)" and "WSF 2016-2023 - Population (10m)" datasets.',
				},
				sdg: [
					{
						title: 'Land Consumption Rate/Population Growth Rate',
						text: 'Land consumption and population growth rates (LCRPGR) are used to assess the sustainability of urban growth, which considers both the change in the built-up area and the population. LCRPGR is calculated as the land consumption rate (LCR) and population growth rate (PGR) ratio. The map shows the LCRPGR for 2023.',
						image: 'WSF_LCRPGR_YYYY.png',
					},
					{
						title: 'Land Consumption per capita',
						text: 'This indicator represents the total urban area in metres divided by the total population (Land Consumption per capita). Land consumption per capita represents the average amount of land each person in a city consumes/occupies during each analysis year. The map shows the LCPC for 2023.',
						image: 'WSF_LCPC_YYYY.png',
					},
				],
			},
		},
	},
	{
		key: 'wsfEvolution1985_2023',
		data: {
			featured: true,
			period: '1985/2023',
			nameDisplay: 'WSF Evolution 1985-2023 - Built-up (10m)',
			defaultIndicatorLayerKey: 'WSFE_10m_LCR',
			defaultDatasetLayerKey:'WSF_Evolution_1985_2023_10m',
			shortName: 'WSF Evo 85-23 Built-up (10m)',
			attributes: [
				// Urban area
				{key: 'a3f664d8-3fa0-47cd-a0a6-81d4df001c7e'}, // Total area of urban extent - minimal absolute value
				{key: 'e9cad7d0-15f0-4439-99a3-e07a808734d0', mapLayer: true}, // Total area of urban extent - minimal relative value
				{key: '72129a88-cd36-45a5-b811-a4ff23b38223'}, // Total area of urban extent - maximum absolute value
				{key: '870b2857-176a-4471-82a8-5ab909d44b72', mapLayer: true}, // Total area of urban extent - maximum relative value
				{key: '73e4f64a-47c1-4e8c-9dc6-1687fe3e9eb3'}, // Total area of urban extent - absolute (time series)

				// Urbanization area evolution
				{key: '882e8fe1-c758-42c1-9344-c60214c699c2', mapLayer: true}, //Total increase
				{key: '6cd70af5-8db3-4a78-8f01-8ccc99e17f5a'}, //Total increase abs
				{key: '0a96537a-3da1-4713-96cd-12d9efc1a7ba', mapLayer: true}, //Average increase
				{key: '7938c69d-366f-4642-aea5-a15bc91977de'}, //Average increase abs.
				{key: 'fc4d2120-a0da-4f72-a718-f1a68bda6681', mapLayer: true}, //Min. increase
				{key: '4e25db77-aaa0-4e8e-9d07-c435199fbe6b'}, //Min. increase abs.
				{key: '033ea81e-0660-4234-9d87-47ba1b689910', mapLayer: true}, //Max. increase
				{key: '52761164-26aa-46c6-ad64-7d9e8560025d'}, //Max. increase abs.
				{key: '99e44729-2e5b-4a5e-b885-27a90dbf9e4a'}, // time series - absolute
				{key: 'af18f499-e699-40d7-81d7-00cb4ce0f131'}, // time series - relative

				// SDG
				{key: '73e1d399-0f65-46ea-b8d4-fc91b715cd21', mapLayer: true}, //SDG - Land consumption rate
				{key: 'ee76613c-362b-43f0-b8fe-71560e7ac094'}, //SDG - Land consumption rate time series
			],
			color: '#377eb8',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: "The World Settlement Footprint (WSF) Evolution dataset, newly provided at a ~10-meter resolution (in EPSG 4326 projection), is currently available for Bolivia, Chile, Kenya and Myanmar. This dataset delineates the settlement extent annually from 1985 to 2023. The dataset provides a time-coded layer of settlement detection for each year, with settled areas marked by the detection year (ranging from 1985 to 2023), while pixels without detected settlements are marked as '0' (Marconcini et al., 2020, 2021). The description of this dataset is based on a similar raster dataset - the WSF Evolution 1985-2015 - Built-up (30m)",
					source_link: 'https://geoservice.dlr.de/web/maps/eoc:wsfevolution',
					references: [
						'Mattia Marconcini, Annekatrin Metz-Marconcini, Soner Üreye, et al. Outlining where humans live, the World Settlement Footprint 2015. Scientific Data, 2020, 7, 242.',
						'Mattia Marconcini, Annekatrin Metz-Marconcini, Thomas Esch, Noel Gorelick. Understanding Current Trends in Global Urbanisation - The World Settlement Footprint Suite. GI_Forum 2021, 1, 33-38.',
					],
				},
				uraban_area: [
					{
						title: 'Total area of urban extent - minimal',
						text: 'This indicator shows the minimal percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the minimal extent of built-up area is usually associated with the first year of the reference period. As the reference period for this indicator is 1985-2023, this value should correspond to 1985.',
						image: 'WSFE_10m_UE_min_rel.png',
					},
					{
						title: 'Total area of urban extent - maximum',
						text: 'This indicator shows the maximum percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the maximum extent of built-up area is usually associated with the last year of the reference period. As the reference period for this indicator is 1985-2023, this value should correspond to 2023.',
						image: 'WSFE_10m_UE_max_rel.png',
					},
					{
						title:
							'Total area of urban extent -  absolute [sqkm] & relative [per unit area (%)] ',
						text: 'This indicator represents the percentage share of built-up extent on the total area of administrative units for the selected year in the 1985-2023 period.',
					},
				],
				urbanization_area_evolution: [
					{
						title: 'Total increase',
						text: "This indicator shows the overall percentage change in the urban area's evolution from 1985 to 2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSFE_10m_LC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: "This indicator shows the average yearly percentage change in the urban area's evolution from 1985 to 2023. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSFE_10m_LC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change in the built-up extent over the entire period of interest (1985-2023) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSFE_10m_LC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change in the built-up extent over the entire period of interest (1985-2023) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSFE_10m_LC_max_rel.png',
					},
					{
						title:
							'Yearly increase - absolute [sqkm per year]  & relative [% to previous year stock]',
						text: 'This indicator shows the percentage change in built-up extent between two selected years. It is computed as the ratio of the built-up extent in the selected year and the built-up extent in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Land consumption rate',
						text: 'TLand consumption rate (LCR) is the yearly rate at which urbanised land or land occupied by a city/urban area changes during the selected period (1985-2023). It is expressed as the natural logarithm of the shares of the built-up area in the selected year divided by the built-up area in the initial year, and the result is divided by the number of years between the selected years. The map shows the LCR for 2023.',
						image: 'WSFE_10m_LCR_YYYY.png',
					},
				],
			},
		},
	},
	// {
	// 	key: 'wsf2019BuiltUp',
	// 	data: {
	// 		featured: true,
	// 		period: '2019/2019',
	// 		nameDisplay: 'WSF 2019 - Built-up (10m)',
	// 		shortName: 'WSF 19 Built-up (10m)',
	// 		attributes: [
	// 			{key: 'be396866-7981-4c2c-a90a-f9b23ce9e158'}, // Urban area, total, absolute
	// 			{key: 'b0c2f72d-3977-46a9-bad4-983e7af73e2e'}, // Urban area, total, relative
	// 			{key: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5'}, // Land Consumption per capita
	// 		],
	// 		color: '#ffff33',
	// 		tags: [
	// 			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
	// 			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
	// 			'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
	// 			'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
	// 			'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
	// 			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	// 			'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
	// 		],
	// 		description: {
	// 			brief_description: {
	// 				text: 'The World Settlement Footprint (WSF) 2019 dataset outlines the global settlement extent for the year 2019 at a ~10-meter resolution. The dataset is organised in 5138 GeoTIFF files (EPSG 4326 projection) each one referring to a portion of 2 x 2 degree size (~222 x 222 km) on the ground. Settlements are associated with a value of 255; all other pixels are associated with a value of 0 (Marconcini et al., 2021).',
	// 				source_link: 'https://geoservice.dlr.de/web/maps/eoc:wsf2019',
	// 				references: [
	// 					'Mattia Marconcini, Annekatrin Metz-Marconcini, Thomas Esch and Noel Gorelick. Understanding Current Trends in Global Urbanisation - The World Settlement Footprint Suite. GI_Forum, 2021, 1, 33-38.',
	// 				],
	// 			},
	// 			uraban_area: [
	// 				{
	// 					title:
	// 						'Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)]  ',
	// 					text: 'This indicator shows the percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit) in 2019.',
	// 				},
	// 			],
	// 		},
	// 	},
	// },
	// {
	// 	key: 'wsf2019Population',
	// 	data: {
	// 		featured: true,
	// 		period: '2019/2019',
	// 		nameDisplay: 'WSF 2019 - Population (10m)',
	// 		shortName: 'WSF 19 Population (10m)',
	// 		attributes: [
	// 			// FIXME add attribute WSF2019POP3D_POP_tot for graph
	// 			{key: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5'}, // Land Consumption per capita
	// 		],
	// 		color: '#e41a1c',
	// 		tags: [
	// 			'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
	// 			'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
	// 			'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
	// 			'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
	// 			'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
	// 			'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
	// 			'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
	// 		],
	// 		description: {
	// 			brief_description: {
	// 				text: 'The World Settlement Footprint Population 2019 (WSF2019-Pop) dataset provides a high-resolution population distribution map for the year 2019, leveraging the World Settlement Footprint 2019 Imperviousness layer (WSF2019-Imp) as a base at ~10-meter resolution. The dataset is structured to represent population numbers per pixel, where each pixel correlates with the underlying percent of impervious surface value (PIS), enhancing the accuracy of population estimates across varied urban and rural landscapes (Palacios-Lopez et al. 2021).',
	// 				references: [
	// 					'Daniela Palacios-Lopez, Felix Bachofer, Thomas Esch, Mattia Marconcini, Kytt MacManus, Alessandro Sorichetta, Julian Zeidler, Stefan Dech, Andrew J. Tatem, and Peter Reinartz. High-Resolution Gridded Population Datasets: Exploring the Capabilities of the World Settlement Footprint 2019 Imperviousness Layer for the African Continent. Remote Sens. 2021, 13, 1142.',
	// 				],
	// 			},
	// 			population: [
	// 				{
	// 					title:
	// 						'Total population - absolute [inh] & relative [per unit area (%)] ',
	// 					text: 'This indicator shows the population in 2019 for the selected reference unit.',
	// 				},
	// 			],
	// 		},
	// 	},
	// },
	{
		key: 'wsfCombinedBuiltupPopulation2019',
		data: {
			featured: true,
			period: '2019',
			nameDisplay: 'Combined WSF 2019 - Built-up + Population (10m)',
			shortName: 'Combined WSF 2019 - Built-up + Population (10m)',
			attributes: [
				{key: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5', mapLayer: true}, // Land Consumption per capita
			],
			color: '#999999',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: 'This SDG indicator combines information on the built-up area ("WSF 2019 - Built-up (10m)") and at the same time information on the state of the population ("WSF 2019 - Population (10m)"). For more information, please visit the descriptions of the "WSF 2019 - Built-up (10m)" and "WSF 2019 - Population (10m)" datasets.',
				},
				sdg: [
					{
						title: 'Land Consumption per capita',
						text: 'This indicator represents the total urban area in metres divided by the total population (Land Consumption per capita). Land consumption per capita represents the average amount of land each person in a city consumes/occupies during each analysis year.',
						image: 'WSF2019POP3D_LCPC.png',
					},
				],
			},
		},
	},
	{
		key: 'wsfEvolution1985_2015',
		data: {
			nameDisplay: 'WSF Evolution 1985-2015 - Built-up (30m)',
			shortName: 'WSF Evo 85-15 Built-up (30m)',
			period: '1985/2015',
			defaultIndicatorLayerKey: 'WSFE_LCR',
			defaultDatasetLayerKey:'wsfEvolution1985_2015_30m',
			attributes: [
				// Urban area evolution
				{key: '79d763c6-7d3f-11ee-b962-0242ac120002'}, // Total increase absolute
				{key: '79d7629a-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Total increase relative
				{key: '79d76862-7d3f-11ee-b962-0242ac120002'}, // Average increase absolute
				{key: '79d7674a-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Average increase relative
				{key: '79d77064-7d3f-11ee-b962-0242ac120002'}, // Minimal increase absolute
				{key: '79d76f56-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Minimal increase relative
				{key: '79d777f8-7d3f-11ee-b962-0242ac120002'}, // Maximum increase absolute
				{key: '79d773b6-7d3f-11ee-b962-0242ac120002', mapLayer: true}, // Maximum increase relative
				{key: '3f640746-34c7-4225-b408-c8d77cd21bb0'}, // time series - absolute
				{key: '91e8179b-0598-488c-a214-5fa53a03d956'}, // time series - relative

				// Urban area
				{key: '2f6aa6e8-7e22-11ee-b962-0242ac120002'}, // Urban extent area, minimal absolute value
				{key: '2f6aa60c-7e22-11ee-b962-0242ac120002', mapLayer: true}, // Urban extent area, minimal relative
				{key: '2f6aaf4e-7e22-11ee-b962-0242ac120002'}, // Urban extent area, maximum absolute
				{key: '2f6aac2e-7e22-11ee-b962-0242ac120002', mapLayer: true}, // Urban extent area, maximum relative
				{key: '08a819f3-d71b-4753-8929-65f67107dd22'}, // time series

				// SDG
				{key: 'ca15b319-d332-49ca-870c-b9240bd340a2', mapLayer: true}, //SDG - Land consumption rate
				{key: '58db1745-da16-420a-bb48-7aa5c654b279'}, // Land consumption rate (time series)
			],
			color: '#984ea3',
			tags: [
				'75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
				'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
				'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
				'13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
				'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
				'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
				'1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
			],
			description: {
				brief_description: {
					text: "The World Settlement Footprint (WSF) Evolution dataset delineates the global settlement extent annually from 1985 to 2015 at a 30-meter resolution. The dataset is organised in 5138 GeoTIFF files (EPSG 4326 projection), each covering a 2 x 2-degree portion (~222 x 222 km on the ground). Each file provides a time-coded layer of settlement detection for each year, with settled areas marked by the detection year (ranging from 1985 to 2015), while pixels without detected settlements are marked as '0' (Marconcini et al., 2020, 2021).",
					source_link: 'https://geoservice.dlr.de/web/maps/eoc:wsfevolution',
					references: [
						'Mattia Marconcini, Annekatrin Metz-Marconcini, Soner Üreye, et al. Outlining where humans live, the World Settlement Footprint 2015. Scientific Data, 2020, 7, 242.',
						'Mattia Marconcini, Annekatrin Metz-Marconcini, Thomas Esch, Noel Gorelick. Understanding Current Trends in Global Urbanisation - The World Settlement Footprint Suite. GI_Forum 2021, 1, 33-38.',
					],
				},
				uraban_area: [
					{
						title: 'Total area of urban extent - minimal',
						text: 'This indicator shows the minimal percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the minimal extent of built-up area is usually associated with the first year of the reference period. As the reference period for this indicator is 1985-2015, this value should correspond to 1985.',
						image: 'WSFE_UE_min_rel.png',
					},
					{
						title: 'Total area of urban extent - maximum',
						text: 'This indicator shows the maximum percentage share of built-up area on the entire extent of the region (ADM1 or ADM0 unit), the maximum extent of built-up area is usually associated with the last year of the reference period. As the reference period for this indicator is 1985-2015, this value should correspond to 2015.',
						image: 'WSFE_UE_max_rel.png',
					},
					{
						title:
							'Total area of urban extent - absolute [sqkm] & relative [per unit area (%)] ',
						text: 'This indicator represents the percentage share of built-up extent on the total area of administrative units for the selected year in the 1985-2015 period.',
					},
				],
				urbanization_area_evolution: [
					{
						title: 'Total increase',
						text: "This indicator shows the overall percentage change in the urban area's evolution from 1985 to 2015. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSFE_LC_tot_rel.png',
					},
					{
						title: 'Average increase',
						text: "This indicator shows the average yearly percentage change in the urban area's evolution from 1985 to 2015. The indicator does not provide any information about fluctuations in values within the monitored period, thus fundamental changes might have occurred even in a very short period between two years.",
						image: 'WSFE_LC_avg_rel.png',
					},
					{
						title: 'Min. increase',
						text: 'This indicator shows the minimal yearly percentage change in the built-up extent over the entire period of interest (1985-2015) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSFE_LC_min_rel.png',
					},
					{
						title: 'Max. increase',
						text: 'This indicator shows the maximum yearly percentage change in the built-up extent over the entire period of interest (1985-2015) in the selected region (ADM1 or ADM0 unit). Value might be 0 if no progress in the evolution of urban extent was detected.',
						image: 'WSFE_LC_max_rel.png',
					},
					{
						title:
							'Yearly increase -  absolute [sqkm per year]  & relative [% to previous year stock]',
						text: 'This indicator shows the percentage change in built-up extent between two selected years. It is computed as the ratio of the built-up extent in the selected year and the built-up extent in the previous year minus 1 and multiplied by 100.',
					},
				],
				sdg: [
					{
						title: 'Land consumption rate',
						text: 'Land consumption rate (LCR) is the yearly rate at which urbanised land or land occupied by a city/urban area changes during the selected period (1985-2015). It is expressed as the natural logarithm of the shares of the built-up area in the selected year divided by the built-up area in the initial year, and the result is divided by the number of years between the selected years. The map shows the LCR for 2015.',
						image: 'WSFE_LCR_YYYY.png',
					},
				],
			},
		},
	},
];
