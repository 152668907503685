import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';

const Form = ({children, small, className}) => {
	const classes = classnames(
		'ptr-Form',
		{
			'is-small': small,
		},
		className,
	);

	return <div className={classes}>{children}</div>;
};

Form.propTypes = {
	small: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Form;
