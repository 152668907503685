const getVectorLayerDefinitionWithSelection = (layer, selectionKey) => {
	return {
		...layer,
		options: {
			...layer?.options,
			selected: {
				[selectionKey]: {},
			},
		},
	};
};

export default {
	getVectorLayerDefinitionWithSelection,
};
