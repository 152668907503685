import './style.scss';
import PropTypes from 'prop-types';
import {createElement, useEffect, useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import AddAreaDialog from '../AddUDTAreaDialog';
import ModalWindow from '../ModalWindow';
import Chip, {ChipsContainer, ChipsContainerTools} from '../Chip';
import SmallCircularButton from '../SmallCircularButton';

const SelectedUDTFeaturesControl = ({
	featureKeys,
	onSetKeys,
	onMount,
	FeatureLabel,
	ChangeAreaDialog,
	allowAdd,
	allowChange,
	disableRemove,
	darkMode,
	simpleList,
}) => {
	const [areaSelectControlOpen, setAreaSelectControlOpen] = useState(false);
	const [changeAreaSelectControlOpen, setChangeAreaSelectControlOpen] =
		useState(false);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const onFeaturesRemove = featureKey => {
		if (!featureKey) {
			onSetKeys([]);
		} else if (featureKeys?.length) {
			const updatedKeys = featureKeys.filter(key => key !== featureKey);
			onSetKeys(updatedKeys);
		}
	};

	return (
		<>
			<ChipsContainer>
				{(featureKeys?.length > 0 &&
					featureKeys?.map((featureKey, index) => {
						if (simpleList) {
							return (
								FeatureLabel &&
								createElement(FeatureLabel, {
									key: `${featureKey}-${index}`,
									featureKey,
									index,
								})
							);
						} else {
							return (
								<Chip
									key={`${featureKey}-${index}`}
									removable={!disableRemove && featureKeys?.length !== 1}
									onRemove={evt => {
										onFeaturesRemove(featureKey, index), evt.stopPropagation();
									}}
								>
									{FeatureLabel &&
										createElement(FeatureLabel, {featureKey, index})}
								</Chip>
							);
						}
					})) ||
					(allowAdd ? (
						<div className="unhab-SelectedFeaturesControl-note">
							Click on area in map or
							<Button
								small
								className={'unhab-SelectedFeaturesControl-addButton'}
								invisible
								primary
								icon="ri-add"
								onClick={() => setAreaSelectControlOpen(true)}
								tooltip={{
									text: 'Add area',
									id: 'AddArea',
									position: 'bottom',
								}}
							>
								Add
							</Button>
							it from list.
						</div>
					) : null)}
				{!simpleList && (
					<ChipsContainerTools>
						{allowChange && ChangeAreaDialog ? (
							<SmallCircularButton
								tooltip={{
									text: 'Change area',
									id: 'ChangeArea',
									position: 'bottom',
								}}
								icon="ri-change"
								onClick={() => setChangeAreaSelectControlOpen(true)}
							/>
						) : null}
						{allowAdd ? (
							<SmallCircularButton
								tooltip={{
									text: 'Add area',
									id: 'AddArea',
									position: 'bottom',
								}}
								icon="ri-add"
								onClick={() => setAreaSelectControlOpen(true)}
							/>
						) : null}
					</ChipsContainerTools>
				)}
			</ChipsContainer>
			{ChangeAreaDialog ? (
				<ModalWindow
					isOpen={changeAreaSelectControlOpen}
					isDark={darkMode}
					onClose={() => setChangeAreaSelectControlOpen(false)}
				>
					{createElement(ChangeAreaDialog, {
						onClose: () => setChangeAreaSelectControlOpen(false),
					})}
				</ModalWindow>
			) : null}
			<ModalWindow
				isOpen={areaSelectControlOpen}
				isDark={darkMode}
				onClose={() => setAreaSelectControlOpen(false)}
			>
				<AddAreaDialog onClose={() => setAreaSelectControlOpen(false)} />
			</ModalWindow>
		</>
	);
};

SelectedUDTFeaturesControl.propTypes = {
	featureKeys: PropTypes.array,
	ChangeAreaDialog: PropTypes.object,
	FeatureLabel: PropTypes.object,
	selectionData: PropTypes.object,
	onSetKeys: PropTypes.func,
	onMount: PropTypes.func,
	zoomToFeature: PropTypes.func,
	allowAdd: PropTypes.bool,
	allowChange: PropTypes.bool,
	disableRemove: PropTypes.bool,
	darkMode: PropTypes.bool,
	simpleList: PropTypes.bool,
};

export default SelectedUDTFeaturesControl;
