import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		model: Select.tags.getByKey(state, ownProps.tagKey),
		category: ownProps.withCategory
			? Select.unhab.tags.getCategoryByTagKey(state, ownProps.tagKey)
			: null,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
