import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Presentation from '../../../../common/SelectedUDTFeaturesControl';
import BenchmarkChangeAreaDialog from './BenchmarkChangeAreaDialog';

const mapStateToProps = state => {
	return {
		allowChange: true,
		disableRemove: true,
		featureKeys:
			Select.unhab.cityClusterFeatures.getSelectedFeatureFeatureKeys(state),
		darkMode: Select.components.get(state, 'UnhabApp', 'darkMode'),
		ChangeAreaDialog: BenchmarkChangeAreaDialog,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
