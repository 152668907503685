function mapToScale(value, inMin, inMax, outMin, outMax) {
	// Handle edge cases where the value is outside the input range
	if (value <= inMin) {
		return outMin;
	}
	if (value >= inMax) {
		return outMax;
	}

	// Apply a sigmoid-like transformation
	const normalizedValue = (value - inMin) / (inMax - inMin); // Normalize to 0-1 range
	const scaledValue = 1 / (1 + Math.exp(-12 * (normalizedValue - 0.5))); // Sigmoid transformation

	// Map back to the output scale
	return scaledValue * (outMax - outMin) + outMin;
}

const renderNode = (ctx, node, nodeSize) => {
	const inMin = nodeSize?.inputRange?.[0] || 0;
	const inMax = nodeSize?.inputRange?.[1] || 5;
	const outMin = nodeSize?.outputRange?.[0] || 3;
	const outMax = nodeSize?.outputRange?.[1] || 7;
	const size = mapToScale(node.data.z, inMin, inMax, outMin, outMax);

	const centerX = node.x;
	const centerY = node.y;
	const lineWidth = node.data.selectionColor ? 2 : 0;
	const color = node.data.color || node.color;
	const shape = node.data.shape;

	if (shape && shape === 'square') {
		ctx.beginPath();
		ctx.rect(centerX - size, centerY - size, 2 * size, 2 * size);
		ctx.strokeStyle = node.data.selectionColor
			? node.data.selectionColor
			: 'transparent';
		ctx.lineWidth = lineWidth;
		ctx.globalAlpha = 0.5;
		ctx.fillStyle = color ? color : 'transparent';
		ctx.fill();
		ctx.stroke();
	} else {
		ctx.beginPath();
		ctx.arc(centerX, centerY, size, 0, 2 * Math.PI);
		ctx.strokeStyle = node.data.selectionColor
			? node.data.selectionColor
			: 'transparent';
		ctx.lineWidth = lineWidth;
		ctx.globalAlpha = 0.5;
		ctx.fillStyle = color ? color : 'transparent';
		ctx.fill();
		ctx.stroke();
	}
};

export default renderNode;
