import '../../../../common/Chart/charts/TimeProgressChart/style.scss';
import PropTypes from 'prop-types';
import TimeRangeChart from '../../../../common/TimeRangeChart';
import LineChart from '../../../../common/LineChart';
import {useEffect, useState} from 'react';
import ChartWrapper from '../../../../common/Chart/ChartWrapper';
import NivoChartHoverWrapper from '../../../../common/NivoChartHover/NivoChartHoverWrapper';
import moment from 'moment';
import TimeProgressChartLegend from '../../../../common/Chart/charts/TimeProgressChart/Legend';

const getTooltipContent = ({points}) => {
	if (points?.length) {
		return (
			<div className="unhab-TimeProgressChart-tooltip">
				<div className="unhab-TimeProgressChart-tooltip-content">
					<span className="unhab-TimeProgressChart-tooltip-time">
						{moment(points?.[0]?.data?.x).format('YYYY')}
					</span>
					{points.map(point => {
						return (
							<div
								className="unhab-TimeProgressChart-tooltip-item"
								key={point?.id}
							>
								<span
									className="unhab-TimeProgressChart-tooltip-color"
									style={{
										background: point?.color,
									}}
								/>
								<span className="unhab-TimeProgressChart-tooltip-value">
									{point?.data?.y?.toLocaleString('en-US')}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const XCubeProgressChart = ({
	currentLayerKey,
	data,
	title,
	subtitle,
	configuration,
	multipleFeatures,
	onCurrentLayerChange,
}) => {
	const [dateRange, setDateRange] = useState(null);
	useEffect(() => {
		onCurrentLayerChange();
	}, [currentLayerKey]);

	return data?.length ? (
		<div className="xCube-ProgressChart">
			<h3>Time serie</h3>
			<ChartWrapper title={title} subtitle={subtitle} data={data}>
				<div className="unhab-TimeProgressChart">
					<NivoChartHoverWrapper
						getHoverContent={props => {
							return getTooltipContent(props);
						}}
						tooltipPosition="corner"
						wrapperRefQuery={
							multipleFeatures
								? 'svg>g>g:nth-of-type(1)'
								: 'svg>g>g:nth-of-type(4)'
						}
					>
						<TimeRangeChart
							ChartComponent={LineChart}
							data={data}
							metadata={configuration.metadata}
							overviewChartMetadata={configuration.overviewChartMetadata}
							onHover={() => {}}
							dateRange={dateRange}
							onDateRangeChange={setDateRange}
							displayTimeFormat={configuration.displayTimeFormat}
							hideLegend={configuration.hideLegend}
						/>
					</NivoChartHoverWrapper>
				</div>
				<TimeProgressChartLegend showFeatureKey data={data} />
			</ChartWrapper>
		</div>
	) : null;
};

XCubeProgressChart.propTypes = {
	multipleFeatures: PropTypes.bool,
	data: PropTypes.array,
	configuration: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	currentLayerKey: PropTypes.string,
	onCurrentLayerChange: PropTypes.func,
};

export default XCubeProgressChart;
