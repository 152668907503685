export default [
    // Urban area
    {
        key: 'totalAreaOfUrbanExtentMin',
        data: {
            nameDisplay: 'Total area of urban extent - Minimum',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'totalAreaOfUrbanExtentMax',
        data: {
            nameDisplay: 'Total area of urban extent - Maximum',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'totalAreaOfUrbanExtent',
        data: {
            nameDisplay: 'Total area of urban extent - absolute [sqkm] & relative [per unit area]',
            map: false,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    // Urban area evolution
    {
        key: 'totalIncrease_urbanAreaEvolution',
        data: {
            nameDisplay: 'Total increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'averageIncrease_urbanAreaEvolution',
        data: {
            nameDisplay: 'Average increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'minIncrease_urbanAreaEvolution',
        data: {
            nameDisplay: 'Minimal increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'maxIncrease_urbanAreaEvolution',
        data: {
            nameDisplay: 'Maximal increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'yearlyIncrease_urbanAreaEvolution',
        data: {
            nameDisplay: 'Yearly increase - absolute [sqkm per year] & relative [% to previous year stock]',
            map: false,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    // Population
    {
        key: 'totalPopulationMin',
        data: {
            nameDisplay: 'Total population - Minimum',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'totalPopulationMax',
        data: {
            nameDisplay: 'Total population - Maximum',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'totalPopulation',
        data: {
            nameDisplay: 'Total population (density) - absolute [inh] & relative [per unit area]',
            map: false,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    // Population evolution
    {
        key: 'totalIncrease_populationEvolution',
        data: {
            nameDisplay: 'Total increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'averageIncrease_populationEvolution',
        data: {
            nameDisplay: 'Average increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'minIncrease_populationEvolution',
        data: {
            nameDisplay: 'Minimal increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'maxIncrease_populationEvolution',
        data: {
            nameDisplay: 'Maximal increase',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    {
        key: 'yearlyIncrease_populationEvolution',
        data: {
            nameDisplay: 'Yearly increase - absolute [inh. per year] & relative [% to previous year stock]',
            map: false,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDG
            ],
        }
    },
    // SDG
    {
        key: 'landConsumptionRate',
        data: {
            nameDisplay: 'Land consumption rate',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDGs
                '1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
            ],
        }
    },
    {
        key: 'populationGrowthRate',
        data: {
            nameDisplay: 'Population growth rate',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDGs
                '1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
            ],
        }
    },
    {
        key: 'landConsumptionRate_populationGrowthRate',
        data: {
            nameDisplay: 'Land consumption rate/Population growth rate',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDGs
                '1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
            ],
        }
    },
    {
        key: 'landConsumptionPerCapita',
        data: {
            nameDisplay: 'Land consumption per capita',
            map: true,
            statistics: true,
            tags: [
                '75a8374c-edb3-4944-ba97-a15d6a6d90ba', // Expert
                'fbf21504-58a2-4429-b9f4-8d35e6630b84', // Base
                'd3cb1018-1fab-45fb-a512-e1e3d3fea1b5', // All coverages
                '41a7ab61-037d-4884-bb6e-4b17ddfde9be', // Partial
                'c5436d1f-06e6-40e1-9e46-ff5e785eb6c5', // Global
                '13b7fd98-d80d-4993-8293-77dac979bfe8', // All themes
                'a90bfb74-5e8a-47a4-a674-0e5a577c376a', // Land
                '3af8b2ab-d56d-417d-b465-df4d6e4819e8', // Population
                'a3c5aed5-5cee-43f0-b76d-1f8a9ed6a787', // All SDGs
                '1688cdcd-4613-459a-8cd9-638eb1cdb5d0', // SDG
            ],
        }
    }
]