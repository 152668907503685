import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	byKey: {},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.INDICATORS_FOR_LIST.ADD:
			return commonReducers.add(state, action);
		default:
			return state;
	}
};
