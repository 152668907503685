export default {
	componentKey: "urbanizationAreaEvolution_total",
	title: 'Total increase',
	configuration: {
		attributes: [
			'79d763c6-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - absolute
			'79d7629a-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - relative
			'79d7615a-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - absolute
			'79d75e58-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - relative
			'5e9898f4-01b2-48e7-a945-9afc664a3cae', // WSF 2016-2023 - Built-up (10m) - absolute
			'17843dcd-060c-4c44-83e4-6380ffe40215', // WSF 2016-2023 - Built-up (10m) - relative
			'6cd70af5-8db3-4a78-8f01-8ccc99e17f5a', // WSF Evolution 1985-2015 - Built-up (10m) - absolute
			'882e8fe1-c758-42c1-9344-c60214c699c2', // WSF Evolution 1985-2015 - Built-up (10m) - relative
		],
		type: 'totalIncreaseEvolution',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
