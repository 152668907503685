import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		value: Select.components.get(state, ownProps.editableKey, 'value'),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onValueChange: value => {
			return dispatch(
				Action.components.set(ownProps.editableKey, 'value', value),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
