import PropTypes from 'prop-types';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const FormItemLabel = ({children, info}) => {
	return (
		<label className="ptr-FormItemLabel" title={info || ''}>
			{children}
			{info ? (
				<div className="ptr-FormItemLabel-info">
					<Icon icon="ri-info" className="ptr-FormItemLabel-info" />
				</div>
			) : null}
		</label>
	);
};

FormItemLabel.propTypes = {
	info: PropTypes.string,
	children: PropTypes.string.isRequired,
};

export default FormItemLabel;
