import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Popover = ({isOpen, showTop, classNames, children}) => {
	const classes = classnames(
		'ptr-Popover',
		{
			'is-open': isOpen,
			'show-top': showTop,
		},
		classNames,
	);

	return <div className={classes}>{children}</div>;
};

Popover.propTypes = {
	isOpen: PropTypes.bool,
	classNames: PropTypes.string,
	showTop: PropTypes.bool,
	children: PropTypes.node,
};

export default Popover;
