import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	cityClustersAreaTreeKey,
	regionsAreaTreeKey,
	defaultActiveCityClusterFeatureKey1,
	defaultActiveCityClusterFeatureKey2,
	benchmarkMapKeys,
	benchmarkArea1SelectionFeatureKey,
	benchmarkArea2SelectionFeatureKey,
} from '../../../constants/app';

const mapStateToProps = state => {
	return {
		activeKey: Select.unhab.areaTreeTypes.getActiveKey(state),
		models: Select.unhab.areaTreeTypes.getAll(state),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onChange: key => {
				dispatch(Action.unhab.areaTreeTypes.setActiveKey(key));
				if (key === regionsAreaTreeKey) {
					dispatch(Action.unhab.clearMapLayers());
					// clear city clusters map layers
					dispatch(
						Action.unhab.cityClusterFeatures.clearCityClustersMapLayers(),
					);

					dispatch(
						Action.selections.setFeatureKeysFilterKeys(
							benchmarkArea1SelectionKey,
							[benchmarkArea1SelectionFeatureKey],
						),
					);
					dispatch(
						Action.selections.setFeatureKeysFilterKeys(
							benchmarkArea2SelectionKey,
							[benchmarkArea2SelectionFeatureKey],
						),
					);
					benchmarkMapKeys.forEach(mapKey => {
						dispatch(Action.unhab.setActiveFirstIndicatorLayer(mapKey));
					});
					dispatch(Action.unhab.setAppDefaultsByActiveParams());

					//zoom to areas
					dispatch(Action.unhab.fitMapsToSelectedFeatures());
				} else if (key === cityClustersAreaTreeKey) {
					//remove indicator layers from map
					dispatch(Action.unhab.clearMapLayers(true));

					dispatch(
						Action.selections.setFeatureKeysFilterKeys(
							benchmarkArea1SelectionKey,
							[defaultActiveCityClusterFeatureKey1],
						),
					);
					dispatch(
						Action.selections.setFeatureKeysFilterKeys(
							benchmarkArea2SelectionKey,
							[defaultActiveCityClusterFeatureKey2],
						),
					);

					dispatch(Action.unhab.setAppDefaultsByActiveParams());
				}
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
