import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		indicatorGroups:
			Select.unhab.indicatorsForList.getAllForGivenTagsGroupedByAttributeSet(
				state,
				ownProps.activeTags,
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
