import theme from './_theme';

export default {
	componentKey: 'scatterPlot_chart_lcpc_wsf',
	type: 'scatterChart',
	title: 'Featured regions: Land Consumption per capita (WSF)',
	subtitle: '',
	configuration: {
		attributes: [
			'03a6772d-c07e-4ac2-a18d-a87ec02889fe', //
			'02d27e1c-44cd-4a0a-8834-8be215e8f44e', // WSF Population
			'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83', // WSF Land consumption per capita
		],
		regionsOnly: true,
		metadata: {
			nodeSize: {
				inputRange: [0, 120],
				outputRange: [3, 7],
			},
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 40, left: 85},
				xScale: {
					type: 'linear',
					max: 800,
				},
				yScale: {
					type: 'linear',
					max: 15000000,
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 4,
					legend: 'Population (inh.)',
					legendPosition: 'middle',
					legendOffset: -78,
				},
				axisBottom: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 6,
					legend: 'Land Consumption (sqkm)',
					legendPosition: 'middle',
					legendOffset: 33,
				},
				gridYValues: 4,
				gridXValues: 6,
			},
		},
	},
};
