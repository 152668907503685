import {forwardRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MapComponentsGroup from '../MapComponentsGroup';
import MapAttribution from '../MapAttribution';
import Scale from '../Scale';
import ZoomControls from '../ZoomControls';
import ExportMapControl from '../ExportMapControl';
import BaseLayerControl from '../BaseLayerControl';
import DataLayerControl from '../DataLayersControl';
import FocusControl from '../FocusControl';
import './style.scss';
import MapLegendControl from '../MapLegendControl';
import AddPointControl from '../AddPointControl';
import SearchPlaceControl from '../SearchPlaceControl';
import MapCoordinatesInfoBox from '../MapCoordinatesInfoBox';

const getToolsByView = (view, position, mapKey, mapsRef) => {
	switch (view) {
		case 'explore':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<FocusControl horizontalPosition={position} mapKey={mapKey} />
					<ExportMapControl
						horizontalPosition={position}
						mapKey={mapKey}
						ref={mapsRef}
					/>
					<SearchPlaceControl mapKey={mapKey} />
					<MapCoordinatesInfoBox />
				</>
			);
		case 'benchmark':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<ExportMapControl
						horizontalPosition={position}
						mapKey={mapKey}
						ref={mapsRef}
					/>
					<SearchPlaceControl mapKey={mapKey} />
					<MapCoordinatesInfoBox />
				</>
			);
		case 'report':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<ExportMapControl
						horizontalPosition={position}
						mapKey={mapKey}
						ref={mapsRef}
					/>
				</>
			);
		case 'xcube':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<AddPointControl />
					<MapCoordinatesInfoBox />
				</>
			);
		default:
			return null;
	}
};
const MapTools = forwardRef(
	(
		{
			activeAppView,
			position,
			view,
			viewLimits,
			mapKey,
			updateView,
			viewport,
			horizontalMaps,
			displayScale,
			printInProgress,
			hide,
			isCompareMode,
		},
		mapsRef,
	) => {
		position = horizontalMaps ? 'left' : position;

		const wrapperClasses = classnames('unhab-MapControlsWrapper', position, {});
		const mapToolsClasses = classnames('unhab-MapTools', position, {
			'for-print': printInProgress && hide,
		});
		const mapLegendClasses = classnames('unhab-MapLegendGroup', {
			bottom: true,
			left: position === 'right',
			right: position === 'left',
			isCompare: isCompareMode,
		});
		return (
			<MapComponentsGroup className={wrapperClasses}>
				<MapComponentsGroup className={mapToolsClasses}>
					{getToolsByView(activeAppView, position, mapKey, mapsRef)}
				</MapComponentsGroup>
				<MapComponentsGroup className="unhab-MapInfoElements">
					<MapComponentsGroup className="unhab-AttributionScaleContainer">
						{displayScale && !hide ? (
							<>
								<Scale
									{...{
										view,
										mapHeight: viewport?.height,
										mapWidth: viewport?.width,
									}}
								/>
								<MapAttribution mapKey={mapKey} />
							</>
						) : (
							<></>
						)}
					</MapComponentsGroup>
				</MapComponentsGroup>
				<MapComponentsGroup className={mapLegendClasses}>
					{!hide ? (
						<MapLegendControl
							horizontalPosition={position === 'right' ? 'left' : 'right'}
							mapKey={mapKey}
							verticalPosition="bottom"
						/>
					) : (
						<></>
					)}
				</MapComponentsGroup>
				<MapComponentsGroup className="unhab-MapControls">
					<ZoomControls
						{...{
							view,
							viewLimits,
							mapKey,
							mapHeight: viewport?.height,
							mapWidth: viewport?.width,
							updateView,
						}}
					/>
				</MapComponentsGroup>
			</MapComponentsGroup>
		);
	},
);

MapTools.propTypes = {
	activeAppView: PropTypes.string,
	mapSetKey: PropTypes.string,
	position: PropTypes.string,
	view: PropTypes.object,
	viewLimits: PropTypes.object,
	viewport: PropTypes.object,
	mapKey: PropTypes.string,
	updateView: PropTypes.func,
	horizontalMaps: PropTypes.bool,
	displayScale: PropTypes.bool,
	printInProgress: PropTypes.bool,
	hide: PropTypes.bool,
	isCompareMode: PropTypes.bool,
};

MapTools.displayName = 'MapTools';

export default MapTools;
