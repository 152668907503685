import PropTypes from 'prop-types';
import {useEffect} from 'react';
import SidePanel, {
	SidePanelBody,
	SidePanelHeader,
	SidePanelHeaderItem,
	SidePanelHeaderRow,
} from '../../../common/SidePanel';
import {
	cityClustersAreaTreeKey,
	defaultPeriod,
	regionsAreaTreeKey,
} from '../../../../constants/app';
import AnalyticsPanelStatistics from '../../../common/AnalyticsPanelStatistics';
import PeriodSelector from '../../../common/PeriodSelector';
import './style.scss';
import ComparisonTypeSelector from '../../Benchmark/BenchmarkSidePanel/ComparisonTypeSelector';
import AreaTreeTypeSelector from '../../../common/AreaTreeTypeSelector';
import BenchmarkSelectedFeatureControl from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl';
import BenchmarkSelectedFeatureLabel from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedDatasetControl from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedDatasetControl';
import BenchmarkSelectedUDTFeatureLabel from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedUDTFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedUDTFeatureControl from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedUDTFeatureControl';
import BenchmarkSelectedMethodControl from '../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedMethodControl';

const ReportSidePanel = ({
	activeAreaTreeKey,
	open,
	onMount,
	area1SelectedFeatureKey,
	area2SelectedFeatureKey,
	onSelectedFeatureKeysChange,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	useEffect(() => {
		onSelectedFeatureKeysChange(activeAreaTreeKey);
	}, [area1SelectedFeatureKey, area2SelectedFeatureKey]);

	return (
		<SidePanel hideControls open={open} className="unhab-ReportSidePanel">
			<SidePanelHeader>
				<SidePanelHeaderRow>
					<SidePanelHeaderItem title="Benchmark type">
						<ComparisonTypeSelector />
					</SidePanelHeaderItem>
					<SidePanelHeaderItem title="Area type">
						<AreaTreeTypeSelector />
					</SidePanelHeaderItem>
				</SidePanelHeaderRow>
				{/* <SidePanelHeaderItem title="Areas">
					<BenchmarkSelectedFeatureControl
						FeatureLabel={BenchmarkSelectedFeatureLabel}
					/>
				</SidePanelHeaderItem> */}
				{/* <SidePanelHeaderItem title="Datasets">
					<BenchmarkSelectedDatasetControl />
				</SidePanelHeaderItem> */}
				{activeAreaTreeKey === regionsAreaTreeKey ? (
					<SidePanelHeaderItem title="Areas">
						<BenchmarkSelectedFeatureControl
							FeatureLabel={BenchmarkSelectedFeatureLabel}
						/>
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === cityClustersAreaTreeKey ? (
					<SidePanelHeaderItem title="Areas">
						<BenchmarkSelectedUDTFeatureControl
							FeatureLabel={BenchmarkSelectedUDTFeatureLabel}
						/>
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === regionsAreaTreeKey ? (
					<SidePanelHeaderItem title="Datasets">
						<BenchmarkSelectedDatasetControl />
					</SidePanelHeaderItem>
				) : null}
				{activeAreaTreeKey === cityClustersAreaTreeKey ? (
					<SidePanelHeaderItem title="Methods - datasets">
						<BenchmarkSelectedMethodControl />
					</SidePanelHeaderItem>
				) : null}
				<SidePanelHeaderItem title="Period">
					<PeriodSelector defaultPeriod={defaultPeriod} />
				</SidePanelHeaderItem>
			</SidePanelHeader>
			<SidePanelBody>
				<AnalyticsPanelStatistics dataElementsSelectable />
			</SidePanelBody>
		</SidePanel>
	);
};

ReportSidePanel.propTypes = {
	activeAreaTreeKey: PropTypes.string,
	open: PropTypes.bool,
	onMount: PropTypes.func,
	area1SelectedFeatureKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	area2SelectedFeatureKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	onSelectedFeatureKeysChange: PropTypes.func,
};

export default ReportSidePanel;
