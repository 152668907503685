export default {
	axis: {
		domain: {
			line: {
				stroke: '#cfcfcf',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: '#cfcfcf',
			},
			text: {
				fill: '#aaaaaa',
				fontSize: 11,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: '#cfcfcf',
			},
		},
	},
	grid: {
		line: {
			stroke: '#cfcfcf',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base40)',
		},
	},
};
