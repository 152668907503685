// import PropTypes from 'prop-types';
import './style.scss';
import PropTypes from 'prop-types';
import {useState} from 'react';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import DatasetInfo from '../../DatasetInfo';

const StatisticsSection = ({title, open = true, children}) => {
	const [isOpen, setOpen] = useState(open);
	const classes = classnames('unhab-StatisticsSection', {
		'is-open': isOpen,
	});

	return (
		<div className={classes}>
			<div
				className="unhab-StatisticsSection-header"
				onClick={() => setOpen(!isOpen)}
				tabIndex={0}
			>
				<h4 className="unhab-StatisticsSection-title">{title}</h4>
				<div className="unhab-StatisticsSection-right">
					<div className="unhab-StatisticsSection-info">
						<DatasetInfo indicator={title} />
					</div>
					<div className="unhab-StatisticsSection-control">
						<Icon icon={isOpen ? 'ri-chevron-up' : 'ri-chevron-down'} />
					</div>
				</div>
			</div>
			<div className="unhab-StatisticsSection-content">{children}</div>
		</div>
	);
};

StatisticsSection.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	open: PropTypes.bool,
	secondary: PropTypes.bool,
	indicator: PropTypes.string,
};

export default StatisticsSection;
