// eslint-disable-next-line no-unused-vars
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import Modal from 'react-modal';

import './style.scss';

Modal.setAppElement('#root');
const ModalWindow = ({isOpen, onClose, title, className, children, isDark}) => {
	const overlayClasses = classnames('ptr-ModalWindowOverlay', {
		'ptr-dark': isDark,
		'ptr-light': !isDark,
	});
	const classes = classnames('ptr-ModalWindow', {}, className);
	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={() => {}}
			className={classes}
			overlayClassName={overlayClasses}
			onRequestClose={onClose}
			shouldCloseOnOverlayClick={true}
		>
			<div className="ptr-Modal-header">
				<div className="ptr-Modal-title">{title}</div>
				<Button
					onClick={onClose}
					invisible
					icon="close"
					className="ptr-Modal-closeButton"
				/>
			</div>
			<div className="ptr-Modal-content">
				<div>{children}</div>
			</div>
		</Modal>
	);
};

ModalWindow.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isDark: PropTypes.bool,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.object,
};

export default ModalWindow;
