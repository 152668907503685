import PropTypes from 'prop-types';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const ReportDescription = ({value, onValueChange}) => {
	return (
		<div className="unhab-EditableText unhab-ReportDescription">
			<textarea
				placeholder={'Write your description here'}
				onChange={e => onValueChange(e.target.value)}
				value={value || ''}
			/>
			<Icon icon="edit" />
		</div>
	);
};

ReportDescription.propTypes = {
	value: PropTypes.string,
	onValueChange: PropTypes.func,
};

export default ReportDescription;
