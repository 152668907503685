import {useState} from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import InfoModal from './InfoModal';
import ModalWindow from '../ModalWindow'; // Adjust the import path as needed
import {Icon} from '@gisatcz/ptr-atoms';

const DatasetInfo = ({datasetKey, indicator}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const getModalContent = () => {
		if (datasetKey) {
			return <InfoModal datasetKey={datasetKey} onClose={closeModal} />;
		}
		if (indicator) {
			return <InfoModal indicator={indicator} onClose={closeModal} />;
		}
		return null;
	};

	return (
		<>
			<ModalWindow isOpen={isModalOpen} onClose={closeModal}>
				{getModalContent()}
			</ModalWindow>
			<div className="unhab-DatasetInfo-container" onClick={openModal}>
				<Icon
					icon="ri-info"
					tooltip={{
						text: 'Info',
						id: 'ShowInfoDataset',
						position: 'bottom',
					}}
				/>
			</div>
		</>
	);
};

DatasetInfo.propTypes = {
	datasetKey: PropTypes.string,
	indicator: PropTypes.string,
};

export default DatasetInfo;
