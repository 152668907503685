import theme from './_theme';

export default {
	componentKey: 'populationGrowthRate_chart',
	type: 'timeProgressChart',
	title: 'Population growth rate',
	subtitle: 'Yearly values',
	configuration: {
		attributes: [
			'0a17814e-18cc-46e3-ac26-f7e97465d3a1', // WSF 2016-2023 - Population growth rate
			'338aa0b6-8ec4-4ad3-ab05-4e917bf8cee7', // GHSL
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 50},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
