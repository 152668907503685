import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import ComponentRenderer from '../../ComponentRenderer';
import {areaComparisonType} from '../../../../constants/app';

const CompareMapsControl = ({
	maps,
	mapMode,
	setMapMode,
	disabled,
	condensedControls,
	benchmarkComparisonType,
	hidden,
}) => {
	return benchmarkComparisonType === areaComparisonType || hidden ? null : (
		<ComponentRenderer
			component={'compareMaps'}
			configurationGroupKey={'mapSetTools'}
		>
			<Button
				primary={mapMode === 'compare'}
				disabled={maps?.length !== 2 || disabled}
				onClick={() => setMapMode(mapMode === 'compare' ? 'set' : 'compare')}
				icon="ri-compare"
				small
				floating
			>
				{condensedControls ? '' : 'Slideover'}
			</Button>
		</ComponentRenderer>
	);
};

CompareMapsControl.propTypes = {
	maps: PropTypes.array,
	mapMode: PropTypes.string,
	disabled: PropTypes.bool,
	setMapMode: PropTypes.func,
	condensedControls: PropTypes.bool,
	benchmarkComparisonType: PropTypes.string,
	hidden: PropTypes.bool,
};

export default CompareMapsControl;
