import {createRoot} from 'react-dom/client';
import {Provider} from '@gisatcz/ptr-state';

import createStore from './state/Store';
import {App} from './app';
import {appKey} from './constants/app';
import Faro from './Analytics';
import config from './config';

const {store} = createStore();

/**
 * Init analytics
 */
Faro.init({
	url: config.grafanaFaroServiceUrl,
	app: {
		name: appKey,
		// version: '1.0.0',
		environment: config.deploymentEnviroment,
	},
});

function renderApp() {
	const container = document.getElementById('root');
	const root = createRoot(container);
	root.render(
		<Provider store={store}>
			<App />
		</Provider>,
	);
}

renderApp();
