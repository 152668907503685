import PropTypes from 'prop-types';
import './style.scss';
import MapForExport from '../MapForExport';

const MapForReport = ({mapKey, mapImageData}) => {
	return (
		<div className="unhab-MapForReport">
			<MapForExport mapImageData={mapImageData} mapKey={mapKey} />
		</div>
	);
};

MapForReport.propTypes = {
	mapImageData: PropTypes.string,
	mapKey: PropTypes.string,
};

export default MapForReport;
