import {forwardRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';
import MapTools from '../MapTools';

const MapWrapper = forwardRef(
	(
		{
			activeMapKey,
			mapKey,
			mapSetMapKeys,
			children,
			view,
			viewLimits,
			updateView,
			viewport,
			mapSetKey,
		},
		mapsRef,
	) => {
		const position =
			mapSetMapKeys?.indexOf(mapKey) % 2 === 0 ? 'left' : 'right';
		const wrapperClasses = classnames('ptr-map-wrapper unhab-mapWrapper', {
			active: mapKey === activeMapKey,
			'one-map': mapSetMapKeys?.length === 1,
		});

		return (
			<div className={wrapperClasses}>
				{children}
				<MapTools
					{...{
						view,
						viewLimits,
						mapKey,
						position,
						updateView,
						viewport,
						mapSetKey,
						ref: mapsRef,
					}}
				/>
			</div>
		);
	},
);

MapWrapper.propTypes = {
	activeMapKey: PropTypes.string,
	mapKey: PropTypes.string,
	mapSetMapKeys: PropTypes.array,
	children: PropTypes.element,
	view: PropTypes.object,
	viewport: PropTypes.object,
	viewLimits: PropTypes.object,
	updateView: PropTypes.func,
	mapSetKey: PropTypes.string,
};

MapWrapper.displayName = 'MapWrapper';

export default MapWrapper;
