import theme from './_theme';

export default {
	componentKey: 'populationEvolution_chart',
	type: 'timeProgressChart',
	title: 'Population evolution - absolute',
	subtitle: 'Yearly changes [inhabitants]',
	configuration: {
		attributes: [
			'34d478e3-c42c-4893-9330-1e961a93d667', // WSF 2016-2023 Population (10m)
			'a1e05dde-4a6b-46f0-a233-f6db97f9b386', // GHSL Population evolution
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 70},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString('en-US'),
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
