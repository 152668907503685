import PropTypes from 'prop-types';
import Select from '../Select';

import './style.scss';

const ParameterSelect = ({
	parameter,
	// model,
	parameterKey,
	value,
	onValueChange,
}) => {
	const onChange = data => {
		onValueChange(parameterKey, data.value);
	};

	const models = parameter?.data?.values?.map(p => ({
		key: `${p}`,
		data: {nameDisplay: p},
	}));

	return (
		<div className="unhab-ParameterSelect">
			{parameter.data.nameDisplay}
			<Select
				models={models}
				// TODO use value or default value
				activeKeys={[`${value || parameter?.data?.defaultValue}`]}
				onChange={onChange}
				isDisabled={parameter.data.disabled !== false}
				portaling
				isMulti={false}
				isClearable={false}
				disableColors={true}
			/>
		</div>
	);
};

ParameterSelect.propTypes = {
	model: PropTypes.object,
	parameter: PropTypes.object,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
		PropTypes.number,
	]),
	parameterKey: PropTypes.string,
	onValueChange: PropTypes.func,
};

export default ParameterSelect;
