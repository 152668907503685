import {connect} from '@gisatcz/ptr-state';
// import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

import Presentation from './presentation';
import {reportViewKey} from '../../../../constants/app';
import Selectors from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		activeAppView: Select.views.getActive(state)?.data?.nameInternal,
		horizontalMaps: Select.components.get(state, 'App', 'horizontalMaps'),
		displayScale: Select.components.get(state, 'App', 'displayScale'),
		printInProgress: Select.components.get(state, 'Report', 'printInProgress'),
		hide: Select.views.getActiveKey(state) === reportViewKey,
		isCompareMode:
			Selectors.components.get(state, 'Maps', 'mode') === 'compare',
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(Presentation);
