export default {
	componentKey: "populationEvolution_minimumIncrease",
	title: 'Minimal yearly increase',
	configuration: {
		attributes: [
			'6da76d87-977e-4b50-9bfa-3190c28f8a20', // GHSL absolute
			'e1e76504-3a30-48fb-936b-1830557ad812', // GHSL relative
			'056c47e1-ae4e-4933-9a23-286088590a35', // WSF 2016-2023 (10m) - absolute
			'695cd019-df5e-4cb8-b8b5-e841fbaa49e4', // WSF 2016-2023 (10m) - relative
		],
		type: 'minYearlyIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
