export default {
	componentKey: "totalAreaOfUrbanExtent_minimum",
	title: 'Total area of urban extent - minimum',
	configuration: {
		attributes: [
			'2f6aa6e8-7e22-11ee-b962-0242ac120002', // WSF Evolution 1985-2015 - Built-up (30m) - absolute
			'2f6aa60c-7e22-11ee-b962-0242ac120002', // WSF Evolution 1985-2015 - Built-up (30m) - relative
			'2f6aa508-7e22-11ee-b962-0242ac120002', // GHSL Built-up - absolute
			'2f6aa260-7e22-11ee-b962-0242ac120002', // GHSL Built-up - relative
			'1ae9cb89-ea52-400e-8a8c-4a0cd809fbf7', // WSF 2016-2023 - Built-up (10m) - absolute
			'1e01f96a-ca6d-4a57-a849-12f2079a5d20', // WSF 2016-2023 - Built-up (10m) - relative
			'a3f664d8-3fa0-47cd-a0a6-81d4df001c7e', // WSF Evolution 1985-2023 - Built-up (10m) - absolute
			'e9cad7d0-15f0-4439-99a3-e07a808734d0', // WSF Evolution 1985-2023 - Built-up (10m) - relative
		],
		type: 'minimum',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
