import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const comparisonType = ownProps.comparisonType;
	return {
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeMethodKeys: Select.unhab.cityClusterMethods.getActiveKeys(state),
		methods: Select.unhab.cityClusterMethods.getAll(state),
		activeParameters: Select.unhab.cityClusterMethodParameters.getActive(state),
		parameters: Select.unhab.cityClusterMethodParameters.getAll(state),
		comparisonType,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSave: (activeMethods, activeParameters) => {
			dispatch(Action.unhab.cityClusterMethods.setActiveKeys(activeMethods));

			dispatch(
				Action.unhab.cityClusterMethodParameters.setActiveValues(
					activeParameters,
				),
			);

			dispatch(Action.unhab.cityClusterFeatures.useSelectedFeatures());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
