import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const IndicatorBoxSet = ({children, report}) => {
	const classes = classnames('ptr-IndicatorBoxSet', {
		'is-report': report,
	});

	return <div className={classes}>{children}</div>;
};

IndicatorBoxSet.propTypes = {
	children: PropTypes.node,
	report: PropTypes.bool,
};

export default IndicatorBoxSet;
