export default {
	componentKey: "populationEvolution_maximumIncrease",
	title: 'Maximal yearly increase',
	configuration: {
		attributes: [
			'08630acd-7ecf-4e90-bcaf-b0af90ca52e4', // GHSL absolute
			'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a', // GHSL relative
			'6b882ebf-cc81-4ce4-a788-eb574a3e359d', // WSF 2016-2023 (10m) - absolute
			'2951954d-cacf-470a-96a5-41bb5fd8bdad', // WSF 2016-2023 (10m) - relative
		],
		type: 'maxYearlyIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'inhabitants',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
