import PropTypes from 'prop-types';
import Checkbox from '../../../../Checkbox';
import {useEffect, useState} from 'react';

import './style.scss';
import DataLayersGroup from '../DataLayersGroup';

const LayersGroupControl = ({
	active,
	datasetKey,
	title,
	onAdd,
	onMount,
	onRemove,
	open,
	children,
}) => {
	const [disableOpen, setDisableOpen] = useState(false);
	const [sectionIsOpen, setSectionIsOpen] = useState(false);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const addLayer = () => {
		onAdd();
		setSectionIsOpen(true);
		setDisableOpen(false);
	};

	const removeLayers = () => {
		onRemove();
		setSectionIsOpen(false);
		setDisableOpen(false);
	};

	return (
		<DataLayersGroup
			title={
				<div className="unhab-LayersGroupControl-title">
					<div
						className="unhab-LayersGroupControl-checkbox"
						onMouseOver={() => {
							if ((!sectionIsOpen && active) || (sectionIsOpen && !active)) {
								setDisableOpen(true);
							}
						}}
						onMouseLeave={() => setDisableOpen(false)}
					>
						<Checkbox
							checked={active}
							onChange={active ? removeLayers : addLayer}
							htmlTitle={title}
						/>
					</div>
					{title}
				</div>
			}
			open={open}
			datasetKey={datasetKey}
			disableOpen={disableOpen}
			setSectionIsOpen={value => {
				setSectionIsOpen(value);
			}}
		>
			{children}
		</DataLayersGroup>
	);
};

LayersGroupControl.propTypes = {
	active: PropTypes.bool,
	title: PropTypes.string,
	children: PropTypes.node,
	mapKey: PropTypes.string,
	onAdd: PropTypes.func,
	onMount: PropTypes.func,
	onRemove: PropTypes.func,
	datasetKey: PropTypes.string,
	open: PropTypes.bool,
};

export default LayersGroupControl;
