import PropTypes from 'prop-types';
import Link from '../Link';
import ViewSelect from './ViewSelect';
import {getRouter} from '../../../router';
import Logo from './Logo';
import XCubeHeader from '../../pages/XCube/XCubeHeader';
import BenchmarkHeader from '../../pages/Benchmark/BenchmarkHeader';
import ExplorerHeader from '../../pages/Explorer/ExplorerHeader';
import ReportHeader from '../../pages/Report/ReportHeader';

import './style.scss';
import HeaderTools from './HeaderTools';
import {
	benchmarkViewKey,
	defaultViewKey,
	reportViewKey,
} from '../../../constants/app';

const getContentByViewKey = (viewKey, mapSetKey) => {
	switch (viewKey) {
		case defaultViewKey:
			return <ExplorerHeader mapSetKey={mapSetKey} />;
		case benchmarkViewKey:
			return <BenchmarkHeader />;
		case reportViewKey:
			return <ReportHeader />;
		default:
			return null;
	}
};

const Header = ({activeViewKey, mapSetKey, isXCubeApp, visatUrl}) => {
	const router = getRouter();
	return (
		<header className={'unhab-Header'}>
			<div className="unhab-Header-nav">
				{isXCubeApp ? (
					<XCubeHeader visatUrl={visatUrl} />
				) : (
					<>
						<Link
							ignoreHistory={true}
							router={router}
							name={'home'}
							paramsFilter={['viewKey']}
						>
							<Logo />
						</Link>
						<ViewSelect />
					</>
				)}
			</div>
			<div className="unhab-Header-content">
				<div className={'unhab-Header-content-left'}>
					{isXCubeApp ? null : getContentByViewKey(activeViewKey, mapSetKey)}
				</div>
				<div className={'unhab-Header-content-right'}>
					<HeaderTools activeViewKey={activeViewKey} mapSetKey={mapSetKey} />
				</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	activeViewKey: PropTypes.string,
	isXCubeApp: PropTypes.bool,
	visatUrl: PropTypes.string,
	mapSetKey: PropTypes.string,
};

export default Header;
