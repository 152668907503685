import PropTypes from 'prop-types';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const CondensedControlsControl = ({active, onChange}) => {
	return (
		<MenuToggleItem icon="minus-thick" active={active} onChange={onChange}>
			Condensed controls
		</MenuToggleItem>
	);
};

CondensedControlsControl.propTypes = {
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

export default CondensedControlsControl;
