export default {
	componentKey: "landConsumptionPerCapita",
	title: 'Land Consumption per capita',
	configuration: {
		valueFormat: {
			decimals: 3,
		},
		attributes: [
			'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', // GHS population
			'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83', // WSF 2016-2023
			'86fc0cb4-cd4d-4286-bd12-68d4db1969c5', // WSF 2019
		],
		type: 'lastValuesRatio',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqm_inhabitant',
				description: 'Indicator 2 description',
			},
		],
	},
};
