export default [
	{
		key: 'DLR_WSF_YEAR',
		data: {
			parameterKey: 'DLR_WSF_YEAR',
			nameDisplay: 'Reference year',
			defaultValue: 'latest',
			values: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 'latest'],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'DLR_WSF_MIN_POP',
		data: {
			parameterKey: 'DLR_WSF_MIN_POP',
			nameDisplay: 'Minimal population',
			defaultValue: 10000,
			values: [10000],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC1_GHSL_ID',
		data: {
			disabled: true,
			parameterKey: 'job_id',
		},
	},
	{
		key: 'JRC1_GHSL_REF_YEAR',
		data: {
			parameterKey: 'REF_year',
			nameDisplay: 'Reference year',
			defaultValue: 'latest',
			values: [
				1975,
				1980,
				1985,
				1990,
				1995,
				2000,
				2005,
				2010,
				2015,
				2020,
				2025,
				2030,
				'latest',
			],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC1_GHSL_HDC_BDENS',
		data: {
			parameterKey: 'HDC_Bdens',
			nameDisplay: 'Threshold for Built-up Area [m2/km2]',
			defaultValue: 500000,
			values: [300000, 500000],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC1_GHSL_HDC_PDENS',
		data: {
			parameterKey: 'HDC_Pdens',
			nameDisplay: 'Threshold for Population Density [people/km2]',
			defaultValue: 1500,
			values: [500, 1000, 1500],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC1_GHSL_HDC_PMIN',
		data: {
			parameterKey: 'HDC_Pmin',
			nameDisplay: 'Total population in urban cluster',
			defaultValue: 50000,
			values: [50000, 250000, 500000],
			activeValue: null,
			disabled: false,
		},
	},

	{
		key: 'JRC2_GHSL_ID',
		data: {
			disabled: true,
			parameterKey: 'job_id',
		},
	},
	{
		key: 'JRC2_GHSL_REF_YEAR',
		data: {
			parameterKey: 'REF_year',
			nameDisplay: 'Reference year',
			defaultValue: 'latest',
			values: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 'latest'],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC2_GHSL_THR_UPA',
		data: {
			parameterKey: 'THR_UPA',
			nameDisplay: 'Threshold for Urban Patches (in pixels)',
			defaultValue: 50000,
			values: [10000, 50000, 100000],
			activeValue: null,
			disabled: false,
		},
	},
	{
		key: 'JRC2_GHSL_THR_UPP',
		data: {
			parameterKey: 'THR_UPP',
			nameDisplay: 'Threshold for Total Population in Urban Patches',
			defaultValue: 50000,
			values: [10000, 50000, 100000],
			activeValue: null,
			disabled: false,
		},
	},
];
