// import PropTypes from 'prop-types';
import './style.scss';
import PropTypes from 'prop-types';
import ChartWrapper from '../../ChartWrapper';
import DonutChart from '../../../DonutChart';
import {useEffect} from 'react';

const AreaShareChart = ({
	data,
	title,
	subtitle,
	configuration,
	report,
	selectable,
	selected,
	onMount,
	onUnmount,
	onSelectedChange,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return data?.length && (!report || (report && selected)) ? (
		<ChartWrapper
			title={title}
			subtitle={subtitle}
			data={data}
			selectable={selectable}
			selected={selected}
			onSelectedChange={onSelectedChange}
			report={report}
		>
			<div className="unhab-AreaShareChart">
				<div className="unhab-AreaShareChart-content">
					{data.map(item => (
						<div key={item.name}>
							<DonutChart
								key={item.name}
								data={item.data}
								metadata={configuration.metadata}
								centeredMetricValue={`${(item.share * 100).toFixed(1)} %`}
							/>
						</div>
					))}
				</div>
				<div className="unhab-AreaShareChart-legend">
					{data.map(item => (
						<div key={item.name} className="unhab-AreaShareChart-legendItem">
							<div
								className="unhab-AreaShareChart-legendItemColor"
								style={{background: item.data?.[0].color}}
							/>
							<div className="unhab-AreaShareChart-legendItemName">
								{item.name}
							</div>
						</div>
					))}
				</div>
			</div>
		</ChartWrapper>
	) : null;
};

AreaShareChart.propTypes = {
	data: PropTypes.array,
	configuration: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	report: PropTypes.bool,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	onSelectedChange: PropTypes.func,
};

export default AreaShareChart;
