import './style.scss';
import PropTypes from 'prop-types';
import ChartWrapper from '../../ChartWrapper';
import ScatterChart from '../../../ScatterChart';
import {useEffect} from 'react';
import renderNode from './ChartNode';
import FeaturedAreasLegend from '../../FeaturedAreasLegend';
import {benchmarkViewKey, reportViewKey} from '../../../../../constants/app';

const Tooltip = ({node}) => {
	const {name, color, x, y, z, attributeNameByData} = node.data;
	return (
		<div className="unhab-ChartTooltip">
			<div className="unhab-ChartTooltip-content">
				<div className="unhab-ChartTooltip-title">
					<span
						className="unhab-ChartTooltip-color"
						style={{
							background: color,
						}}
					/>
					<span className="unhab-ChartTooltip-name">{name}</span>
				</div>
				<div className="unhab-ChartTooltip-attribute">
					<div className="unhab-ChartTooltip-attributeName">
						{attributeNameByData?.['x'].toLocaleString('en-US')}:
					</div>
					<div className="unhab-ChartTooltip-attributeValue">{x}</div>
				</div>
				<div className="unhab-ChartTooltip-attribute">
					<div className="unhab-ChartTooltip-attributeName">
						{attributeNameByData?.['y'].toLocaleString('en-US')}:
					</div>
					<div className="unhab-ChartTooltip-attributeValue">{y}</div>
				</div>
				<div className="unhab-ChartTooltip-attribute">
					<div className="unhab-ChartTooltip-attributeName">
						{attributeNameByData?.['z'].toLocaleString('en-US')}:
					</div>
					<div className="unhab-ChartTooltip-attributeValue">{z}</div>
				</div>
			</div>
		</div>
	);
};

Tooltip.propTypes = {
	node: PropTypes.object,
};

const MultiAttributesChart = ({
	activeViewKey,
	data,
	title,
	subtitle,
	configuration,
	report,
	selectable,
	selected,
	year,
	onMount,
	onUnmount,
	onSelectedChange,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const showChart =
		(activeViewKey === benchmarkViewKey || activeViewKey === reportViewKey) &&
		data?.length &&
		(!report || (report && selected));

	return showChart ? (
		<ChartWrapper
			title={title}
			subtitle={`${subtitle}${year}`}
			data={data}
			selectable={selectable}
			selected={selected}
			onSelectedChange={onSelectedChange}
			report={report}
		>
			<div className="unhab-MultiAttributesChart">
				<div className="unhab-MultiAttributesChart-content">
					<ScatterChart
						data={data}
						metadata={configuration?.metadata}
						Tooltip={Tooltip}
						renderNode={renderNode}
					/>
				</div>
				<div className="unhab-MultiAttributesChart-legend">
					<FeaturedAreasLegend />
				</div>
			</div>
		</ChartWrapper>
	) : null;
};

MultiAttributesChart.propTypes = {
	activeViewKey: PropTypes.string,
	data: PropTypes.array,
	year: PropTypes.string,
	configuration: PropTypes.object,
	title: PropTypes.string,
	report: PropTypes.bool,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	subtitle: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	onSelectedChange: PropTypes.func,
};

export default MultiAttributesChart;
