import ActionTypes from '../../../constants/ActionTypes';
import cityClusterMethodParameters from '../../../data/cityClusterMethodParameters';

function add() {
	return dispatch => {
		dispatch(actionAdd(cityClusterMethodParameters));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTER_METHOD_PARAMETERS.ADD,
		data,
	};
};

const setActiveValues = (activeValues = {}) => {
	return dispatch => {
		Object.entries(activeValues).forEach(([index, parameters]) => {
			dispatch(setActiveValue(index, parameters));
		});
	};
};
const setActiveValue = (index, parameters) => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTER_METHOD_PARAMETERS
			.SET_ACTIVE_VALUES_AT_INDEX,
		index,
		parameters,
	};
};

export default {
	add,
	setActiveValues,
};
