import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const model = Select.unhab.datasets.getByKey(state, ownProps.datasetKey);
	return {
		color: model?.data?.color,
		name: model?.data?.shortName,
		title: model?.data?.nameDisplay,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
