import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {isArray as _isArray} from 'lodash';
import Select from '../Select';
import DatasetTags from '../DatasetTags';
import './style.scss';

const FormatOptionLabel = ({value, label}) => {
	return (
		<div className="unhab-DatasetSelect-option">
			<div className="unhab-DatasetSelect-option-label">{label}</div>{' '}
			<DatasetTags datasetKey={value} />
		</div>
	);
};

FormatOptionLabel.propTypes = {
	label: PropTypes.string,
	data: PropTypes.object,
	value: PropTypes.string,
	higlightSelected: PropTypes.bool,
};

const DatasetSelect = ({
	activeSelectionKeys,
	activeKeys,
	setActiveKeys,
	models,
	large,
	forceSingle,
	onMount,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	useEffect(() => {
		if (activeSelectionKeys?.length > 1 && activeKeys?.length > 1) {
			setActiveKeys([activeKeys[0]]);
		}
	}, [activeSelectionKeys]);

	const onChange = data => {
		const keys = _isArray(data) ? data?.map(item => item.value) : [data.value];
		setActiveKeys(keys);
	};

	return (
		<div className="unhab-DatasetSelect">
			<Select
				models={models}
				activeKeys={activeKeys}
				onChange={onChange}
				portaling
				large={large}
				isMulti={!forceSingle && activeSelectionKeys?.length === 1}
				isClearable={false}
				disableColors={activeSelectionKeys?.length > 1}
				placeholderText="Select datasets..."
				formatOptionLabel={FormatOptionLabel}
				lastOptionNotClearable
			/>
		</div>
	);
};

DatasetSelect.propTypes = {
	activeKeys: PropTypes.array,
	setActiveKeys: PropTypes.func,
	activeSelectionKeys: PropTypes.array,
	forceSingle: PropTypes.bool,
	large: PropTypes.bool,
	models: PropTypes.array,
	onMount: PropTypes.func,
};

export default DatasetSelect;
