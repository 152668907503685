import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';
import {Button} from '@gisatcz/ptr-atoms';
import FormItemLabel from '../Form/FormItemLabel';
import FormItem from '../Form/FormItem';
import Select from '../../../state/Select';
import {connect} from '@gisatcz/ptr-state';

const SidePanelHeaderMapStateToProps = state => {
	return {
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const SidePanelHeaderPresentation = ({children, condensedControls}) => {
	const className = classnames('ptr-SidePanelHeader', {
		'is-condensed': condensedControls,
	});

	return <div className={className}>{children}</div>;
};

SidePanelHeaderPresentation.propTypes = {
	condensedControls: PropTypes.bool,
	children: PropTypes.node,
};

export const SidePanelHeader = connect(SidePanelHeaderMapStateToProps)(
	SidePanelHeaderPresentation,
);

export const SidePanelHeaderItem = ({children, title, info}) => {
	return (
		<FormItem condensedControls allowCondensed>
			<FormItemLabel info={info}>{title}</FormItemLabel>
			{children}
		</FormItem>
	);
};

SidePanelHeaderItem.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	info: PropTypes.string,
};

export const SidePanelHeaderRow = ({children}) => {
	return <div className="ptr-SidePanelHeaderRow">{children}</div>;
};

SidePanelHeaderRow.propTypes = {
	children: PropTypes.node,
};

export const SidePanelBody = ({children}) => {
	return <div className="ptr-SidePanelBody">{children}</div>;
};

SidePanelBody.propTypes = {
	children: PropTypes.node,
};

const SidePanel = ({
	className,
	children,
	hideControls,
	open = false,
	setOpen,
}) => {
	const classes = classnames('ptr-SidePanel', {}, className);

	return (
		<div className={classes} style={!open ? {width: 0, minWidth: 0} : null}>
			{!hideControls ? (
				<Button
					className="ptr-SidePanel-control"
					onClick={() => setOpen(!open)}
					icon={!open ? 'ri-chevron-left' : 'ri-chevron-right'}
					side="right"
					floating
				/>
			) : null}
			<div className="ptr-SidePanel-content">{children}</div>
		</div>
	);
};

SidePanel.propTypes = {
	className: PropTypes.string,
	hideControls: PropTypes.bool,
	children: PropTypes.node,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};

export default SidePanel;
