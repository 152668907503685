import {Action as CommonAction} from '@gisatcz/ptr-state';
import {map} from '@gisatcz/ptr-utils';
import ActionTypes from '../../../constants/ActionTypes';
import unhabUtils from '../../../utils';
import UDTStructure from '../../../data/UDTStructure';
import Select from '../../Select';

// App specific actions
const actionAdd = structure => {
	return {
		type: ActionTypes.UNHAB.UDT_STRUCTURE.ADD,
		structure,
	};
};

function add() {
	return dispatch => {
		dispatch(actionAdd(UDTStructure));
	};
}

/**
 * Set zoom level for map key of given features
 * @param {Object} features Features whitch will be used for calculation of new extent
 * @param {string} mapKey MapKey which will get new extent (calculated zoom level)
 * @returns
 */
function fitMapToFeatures(features, mapKey) {
	return dispatch => {
		const extents = [...features.map(a => a.data.extent)];

		const combinedExtents = unhabUtils.extentOfExtents(extents);

		const view = map.view.getViewFromBoundingBox(combinedExtents, true);

		// const mapSetKey = Select.maps.getActiveSetKey(state);
		// dispatch(CommonAction.maps.updateSetView(mapSetKey, view));
		return dispatch(CommonAction.maps.updateMapAndSetView(mapKey, view));
	};
}

/**
 * Fit all maps in active mapSet to structure feature
 * @param {string} admKey
 * @param {number} index
 * @returns
 */
function fitActiveMapSetToKey(key, index) {
	return (dispatch, getState) => {
		const state = getState();
		const activeMapSetKey = Select.maps.getActiveSetKey(state);
		const mapSet = Select.maps.getMapSetByKey(state, activeMapSetKey);
		const admFeature = Select.unhab.udtStructure.getByKey(state, key);

		// Zoom only specific map
		if (index || index === 0) {
			const map = mapSet?.maps[index];
			dispatch(fitMapToFeatures([admFeature], map));
		} else {
			if (admFeature && activeMapSetKey) {
				dispatch(fitMapToFeatures([admFeature], mapSet?.maps[0]));

				if (mapSet?.maps[1]) {
					dispatch(fitMapToFeatures([admFeature], mapSet?.maps[1]));
				}
			}
		}
	};
}

export default {
	add,
	actionAdd,
	fitMapToFeatures,
	fitActiveMapSetToKey,
};
