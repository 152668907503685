import PropTypes from 'prop-types';
import {createElement} from 'react';
import ChangeMethodDialog from '../../../../../common/ChangeMethodDialog';

const BenchmarkChangeMethodDialog = ({...props}) => {
	return createElement(ChangeMethodDialog, {...props});
};

BenchmarkChangeMethodDialog.propTypes = {comparisonType: PropTypes.string};

export default BenchmarkChangeMethodDialog;
