import {connect} from '@gisatcz/ptr-state';
import Presentation from '../Header/HeaderTools/HeaderTool';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = state => {
	return {
		tooltip: {
			text: 'Dark mode',
			id: 'darkMode',
			position: 'bottom',
		},
		active: Select.components.get(state, 'UnhabApp', 'darkMode'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: darkModeActive => {
			dispatch(Action.components.set('UnhabApp', 'darkMode', darkModeActive));
			dispatch(Action.unhab.layers.setAllMapsBackgroundLayerByMode());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
