import {commonSelectors, Select} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import parameterValuesSelector from '../parameterValues/selectors';
import benchmarkComparisonTypesSelectors from '../benchmarkComparisonTypes/selectors';
import cityClusterMethodParametersSelector from '../cityClusterMethodParameters/selectors';
import {replaceUrlTemplates, composeCityClusterFeatureURI} from '../utils/url';
import {separateCityKeyFromFeatureID} from '../utils/udt';

import {
	areaComparisonType,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
} from '../../../constants/app';

const getSubstate = state => state.unhab.cityClusterMethods;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);
const getActiveKeys = commonSelectors.getActiveKeys(getSubstate);
const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getActiveModels = commonSelectors.getActiveModels(getSubstate);

const getParametersByActiveMethods = createSelector(
	[
		getActive,
		getActiveModels,
		parameterValuesSelector.getAllAsObject,
		cityClusterMethodParametersSelector.getAllAsObject,
		cityClusterMethodParametersSelector.getActive,
	],
	//TODO rename activeDataset, activeDatasets to methods
	(
		activeDataset,
		activeDatasets,
		parameters,
		cityClusterMethodParameters,
		cityClusterMethodParametersActive,
	) => {
		const active = [
			...(activeDataset ? [activeDataset] : []),
			...(activeDatasets ? activeDatasets : []),
		];
		return active.map((method, index) => {
			return {
				...method,
				data: {
					...method.data,
					parameters: method?.data?.parameters?.reduce((acc, parameterKey) => {
						//use default value if undefined
						const paramValue = parameters[parameterKey];

						if (paramValue === undefined) {
							const defaultValue =
								cityClusterMethodParameters[parameterKey].data.defaultValue;
							const activeValue =
								cityClusterMethodParametersActive[index]?.[parameterKey]?.data
									.value || null;

							acc[parameterKey] =
								activeValue !== null ? activeValue : defaultValue;
						} else {
							acc[parameterKey] = paramValue;
						}
						return acc;
					}, {}),
				},
			};
		});
	},
	{
		memoizeOptions: {
			maxSize: 0, //do not cache that selector
		},
	},
);

/**
 * Return Array of unique data uris based on active methods
 */
const getParametersKeysByActiveMethods = createSelector(
	[
		benchmarkComparisonTypesSelectors.getActiveKey,
		getParametersByActiveMethods,
		state => Select.selections.getByKey(state, benchmarkArea1SelectionKey),
		state => Select.selections.getByKey(state, benchmarkArea2SelectionKey),
	],
	(
		benchmarkType,
		cityClusterMethodParameters,
		area1Selection,
		area2Selection,
	) => {
		const areaFeatureKeys = [
			separateCityKeyFromFeatureID(
				area1Selection?.data?.featureKeysFilter?.keys?.[0],
			),
			...(benchmarkType === areaComparisonType
				? [
						separateCityKeyFromFeatureID(
							area2Selection?.data?.featureKeysFilter?.keys?.[0],
						),
					]
				: []),
		];

		if (benchmarkType === areaComparisonType) {
			return areaFeatureKeys.map(featureKey => {
				const method = cityClusterMethodParameters[0];
				const urlParamsPart = replaceUrlTemplates(
					method?.data?.urlTemplate,
					method?.data?.parameters,
				);

				return composeCityClusterFeatureURI(
					method?.data?.urlPart,
					featureKey,
					urlParamsPart,
				);
			});
		} else {
			return cityClusterMethodParameters.map(method => {
				const urlParamsPart = replaceUrlTemplates(
					method?.data?.urlTemplate,
					method?.data?.parameters,
				);

				return composeCityClusterFeatureURI(
					method?.data?.urlPart,
					areaFeatureKeys[0],
					urlParamsPart,
				);
			});
		}
	},
);

export default {
	getSubstate,

	getAll,
	getAllAsObject,
	getByKey,
	getActive,
	getActiveModels,
	getActiveKeys,
	getActiveKey,
	getParametersByActiveMethods,
	getParametersKeysByActiveMethods,
};
