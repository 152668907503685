export default [
	{
		key: 'wsf',
		nameDisplay: 'World Settlement Footprint Suite',
		//todo description
		data: {
			datasets: [
				'wsfBuildUp2016_2023',
				'wsfPopulation2016_2023',
				'wsfCombinedBuiltupPopulation2016_2023',
				'wsfEvolution1985_2023',
				// 'wsf2019BuiltUp', // is without map layer
				// 'wsf2019Population', // is without map layer
				// 'wsfCombinedBuiltupPopulation2019',
				'wsfEvolution1985_2015',
			],
		},
	},
	{
		key: 'ghs',
		nameDisplay: 'Global Human Settlement Layer Suite',
		//todo description
		data: {
			datasets: [
				'ghsBuiltUpSurface',
				'ghsPopulationGrid',
				'ghsCombinedBuiltUpPop100m',
			],
		},
	},
];
