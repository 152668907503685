import './style.scss';
import {fourCountriesColors} from '../../../../constants/app';

const FeaturedAreasLegend = () => {
	return (
		<div className="unhab-FeaturedAreasLegend">
			{fourCountriesColors.map(country => {
				return (
					<div className="unhab-FeaturedAreasCountry" key={country.key}>
						<div
							className="unhab-FeaturedAreasCountry-color"
							style={{backgroundColor: country.color}}
						/>
						<div className="unhab-FeaturedAreasCountry-name">
							{country.name}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default FeaturedAreasLegend;
