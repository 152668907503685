import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import classnames from 'classnames';

const OutlinesControl = ({active, onChange}) => {
	const classes = classnames('unhab-DataLayerControl-tool outlines', {
		'is-active': active,
	});

	return (
		<div
			className={classes}
			title="Show outlines only"
			onClick={() => onChange(!active)}
		>
			<Icon icon="ri-collections" />
		</div>
	);
};

OutlinesControl.propTypes = {
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

export default OutlinesControl;
