import {createSelector} from 'reselect';
import {getStructureItemByKey} from './helpers';

const getSubstate = state => state.unhab.udtStructure;

const getStructure = createSelector([getSubstate], substate => {
	return substate.structure;
});

const getByKey = createSelector(
	[getSubstate, (state, key) => key],
	(substate, key) => {
		const structure = substate.structure;
		return getStructureItemByKey(structure, key);
	},
);

export default {
	getStructure,
	getByKey,
};
