import PropTypes from 'prop-types';
import ReactDragListView from 'react-drag-listview';
import DataLayersGroup from '../components/DataLayersGroup';
import SortLayersItem from './SortLayersItem';
import './style.scss';

import datasets from '../../../../../data/datasets';
import {getOriginalLayerKey} from '../../../../../state/unhab/layers/helpers';

const SortLayersControl = ({
	activeLayers = [],
	onSort,
	mapKey,
	SortLayerComponent,
}) => {
	if (activeLayers?.length > 0) {
		let uniqueLayers = [];

		activeLayers.map(item => {
			let currentDataset = datasets.find(data => {
				let originalLayerKey = getOriginalLayerKey(
					item?.key,
					item?.options?.alpha,
				);
				return data?.data?.layerKeys?.includes(originalLayerKey);
			});
			if (currentDataset) {
				if (
					!uniqueLayers.some(
						e =>
							getOriginalLayerKey(e?.key, e?.options?.alpha) ===
							currentDataset?.key,
					)
				) {
					uniqueLayers.push({
						key: currentDataset?.key,
						datasetLayerKey: currentDataset?.key,
						nameDisplay: currentDataset?.data?.nameDisplay,
						layers: [item],
					});
				} else {
					let dataset = uniqueLayers.findIndex(el => {
						return (
							getOriginalLayerKey(el?.key, el?.options?.opacity) ===
							currentDataset?.key
						);
					});
					let layers = uniqueLayers[dataset]?.layers;
					uniqueLayers[dataset] = {
						key: uniqueLayers[dataset]?.key,
						datasetLayerKey: uniqueLayers[dataset]?.datasetLayerKey,
						nameDisplay: uniqueLayers[dataset]?.nameDisplay,
						layers: [...layers, item],
					};
				}
			} else {
				uniqueLayers.push(item);
			}
		});

		const layers = [...(uniqueLayers ? uniqueLayers : [])].reverse();

		return (
			<DataLayersGroup
				title="Selected layers"
				defaultOpen={true}
				closeable={false}
				highlighted
				classes="unhab-DataLayersGroup-selected"
			>
				<ReactDragListView
					nodeSelector="li"
					handleSelector="a"
					onDragEnd={(fromIndex, toIndex) => {
						onSort(fromIndex, toIndex, layers);
					}}
					lineClassName="unhab-SortLayerControl-line"
				>
					<ol className="unhab-SortLayerControl-list">
						{layers.map((layer, index) => (
							<SortLayersItem
								data={layer}
								layers={layers}
								key={index}
								mapKey={mapKey}
								datasetLayerKey={layer?.datasetLayerKey}
								SortLayerComponent={SortLayerComponent}
							/>
						))}
					</ol>
				</ReactDragListView>
			</DataLayersGroup>
		);
	}
};

SortLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	onSort: PropTypes.func,
	mapKey: PropTypes.string,
	SortLayerComponent: PropTypes.node,
};

export default SortLayersControl;
