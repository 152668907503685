import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';
import {cityClustersAreaTreeKey} from '../../../../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);
	const activeLayerNames = Select.unhab.layers.getMapActiveLayerNames(
		ownProps.mapKey,
	);

	const activeModels = Select.unhab.cityClusterMethods.getActiveModels(state);

	const featureKeys =
		Select.unhab.cityClusterFeatures.getSelectedFeatureFeatureKeys(state);

	const feature =
		featureKeys &&
		Select.unhab.getFeatureByKeyAndActiveAreaTreeKey(
			state,
			featureKeys[ownProps.mapIndex],
		);
	const activeAreaTreeKey = Select.unhab.areaTreeTypes.getActiveKey(state);
	return {
		activeLayers: activeLayerNames,
		model: activeModels?.[ownProps.mapIndex] || activeModels?.[0],
		feature: feature,
		cityClustersActive: activeAreaTreeKey === cityClustersAreaTreeKey,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
