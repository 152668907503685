import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		ghsPopulationDatasetLayers:
			Select.unhab.layers.getGhsPopulationDatasetLayers(state, true),
		ghsBuiltUpDatasetLayers: Select.unhab.layers.getGhsBuiltUpDatasetLayers(
			state,
			true,
		),
		ghsDatasetLayers: Select.unhab.layers.getGhsDatasetLayers(state, true),
		wsfBuiltUpDatasetLayers: Select.unhab.layers.getWsfBuiltUpDatasetLayers(
			state,
			true,
		),
		wsfPopulationDatasetLayers:
			Select.unhab.layers.getWsfPopulationDatasetLayers(state, true),
		wsfDatasetLayers: Select.unhab.layers.getWsfRestDatasetLayers(state, true),
		activeLayers: Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
