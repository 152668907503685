import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import {
	// auSelectionKey,
	defaultViewKey,
	// benchmarkViewKey,
	// limassolAreasSelectionKey,
	// xCubeViewKey,
	// benchmarkArea1SelectionKey,
	// benchmarkArea2SelectionKey,
	// reportViewKey,
} from '../../../constants/app';
import Action from '../../../state/Action';
import Presentation from './presentation';
// import auSelection from '../../../data/selections/auSelection';
// import {
// 	benchmarkArea1Selection,
// 	benchmarkArea2Selection,
// } from '../../../data/selections/benchmarkSelection';

const mapStateToProps = state => {
	const viewKey =
		Select.router.getViewKey(state) || Select.views.getActiveKey(state);
	return {
		viewKey,
		view: Select.views.getByKey(state, viewKey),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onViewKeyChanged: viewKey => {
				dispatch(Action.unhab.handleViewChange(viewKey));
			},
			setDefaultViewKey: () => {
				//set viewkey to url
				dispatch(Action.router.updateAppUrl('app', {viewKey: defaultViewKey}));
			},
			onMount: () => {},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
