import {useResizeDetector} from 'react-resize-detector';
import {useCallback} from 'react';
import PropTypes from 'prop-types';

const ReportElementResizeDetector = ({children, onSizeChange}) => {
	const onResize = useCallback((width, height) => {
		onSizeChange(width, height);
	}, []);

	const {ref} = useResizeDetector({
		handleHeight: true,
		refreshMode: 'debounce',
		refreshRate: 1000,
		onResize,
	});

	return <div ref={ref}>{children}</div>;
};

ReportElementResizeDetector.propTypes = {
	children: PropTypes.node,
	onSizeChange: PropTypes.func,
};

export default ReportElementResizeDetector;
