import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useEffect} from 'react';

const CompareMapsControl = ({
	active,
	setActive,
	disabled,
	condensedControls,
}) => {
	useEffect(() => {
		setActive(false);
	}, [disabled]);

	return (
		<Button
			primary={active}
			onClick={() => setActive(!active)}
			icon="map-pin"
			small
			floating
			disabled={disabled}
		>
			{condensedControls ? '' : 'Add point'}
		</Button>
	);
};

CompareMapsControl.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	setActive: PropTypes.func,
	condensedControls: PropTypes.bool,
};

export default CompareMapsControl;
