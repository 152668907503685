import PropTypes from 'prop-types';
import MapConfigurationControl from '../MapConfigurationControl';
import classnames from 'classnames';
import images from './images';
import './style.scss';

const Tile = ({layerKey, active, title, thumbnail, onClick}) => {
	const classes = classnames('ptr-BaseLayerControlTile', {
		active,
	});

	let previewParam = {};
	if (thumbnail && Object.hasOwn(images, thumbnail)) {
		const image = images[thumbnail];
		if (typeof image === 'object') {
			previewParam['src'] = images[thumbnail].default;
		} else {
			previewParam['src'] = images[thumbnail];
		}
		previewParam['alt'] = thumbnail;
	} else {
		previewParam['src'] = images.noPreview;
		previewParam['alt'] = 'thumbnail';
	}

	return (
		<div className={classes} onClick={() => onClick(layerKey)} title={title}>
			<img
				className="ptr-BaseLayerControlTile-thumbnail"
				src={previewParam.src}
				alt={previewParam.alt}
			/>
			<label className="ptr-BaseLayerControlTile-title">{title}</label>
		</div>
	);
};

Tile.propTypes = {
	layerKey: PropTypes.string,
	active: PropTypes.bool,
	title: PropTypes.string,
	thumbnail: PropTypes.string,
	onClick: PropTypes.func,
};

const BaseLayerControl = ({
	activeLayer,
	horizontalPosition,
	layers,
	onSelect,
	condensedControls,
}) => {
	const menuClasses = classnames('ptr-BaseLayerControl-menu', {
		'one-row': layers?.length < 4,
	});

	return (
		<MapConfigurationControl
			icon="layers"
			name={condensedControls ? '' : 'Map'}
			horizontalPosition={horizontalPosition}
			detail={activeLayer?.name}
		>
			<div className={menuClasses}>
				{layers.map(layer => (
					<Tile
						key={layer?.key}
						layerKey={layer.key}
						title={layer?.data?.nameDisplay}
						thumbnail={layer?.data?.thumbnail}
						onClick={onSelect}
						active={layer?.key === activeLayer?.key}
					/>
				))}
			</div>
		</MapConfigurationControl>
	);
};

BaseLayerControl.propTypes = {
	activeLayer: PropTypes.object,
	horizontalPosition: PropTypes.string,
	layers: PropTypes.array,
	onSelect: PropTypes.func,
	condensedControls: PropTypes.bool,
};

export default BaseLayerControl;
