import classnames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '../../../../Checkbox';
import Radio from '../../../../Radio';

import './style.scss';
import Tag from '../../../../Tag';
import {globalTagKey, partialTagKey} from '../../../../../../constants/app';

const DataLayerControl = ({
	active,
	data,
	layerTemplate,
	onAdd,
	onRemove,
	showDescriptionInTitle,
	type = 'checkbox',
	attribute,
	removeable = false,
	large,
	interactive,
	disabled,
	tagKeys,
}) => {
	const classes = classnames('unhab-DataLayerControl', {
		'is-active': active,
		'is-large': large,
		'is-disabled': disabled,
	});

	const filteredTagKeys = tagKeys?.filter(tag => {
		return tag === globalTagKey || tag === partialTagKey;
	});

	const name = `${
		attribute?.data.nameDisplay ||
		layerTemplate?.data?.nameDisplay ||
		data?.nameDisplay
	}${attribute?.data.mapLayerPeriod ? ' [' + attribute?.data.mapLayerPeriod + ']' : ''}`;
	const description = layerTemplate?.data?.description;

	const addLayer = () => {
		onAdd();
	};

	return (
		<div className={classes}>
			<div className="unhab-DataLayerControl-header">
				{interactive === false ? (
					<span className="unhab-DataLayerControl-name">{name}</span>
				) : type === 'radio' ? (
					<Radio
						className="unhab-DataLayerControl-name"
						checked={active}
						onChange={active ? onRemove : addLayer}
						onClick={removeable && active ? onRemove : null}
						htmlTitle={name}
					>
						{name}{' '}
						{showDescriptionInTitle ? <span>({description})</span> : null}
					</Radio>
				) : (
					<Checkbox
						className={`unhab-DataLayerControl-name`}
						checked={active}
						onChange={active ? onRemove : addLayer}
						htmlTitle={name}
					>
						{name}{' '}
						{showDescriptionInTitle ? <span>({description})</span> : null}
					</Checkbox>
				)}
				{filteredTagKeys ? (
					<div className="unhab-DataLayerControl-tags">
						{filteredTagKeys?.map(tag => {
							return <Tag tagKey={tag} key={tag} small />;
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};

DataLayerControl.propTypes = {
	active: PropTypes.bool,
	data: PropTypes.object,
	children: PropTypes.node,
	layerTemplate: PropTypes.object,
	mapKey: PropTypes.string,
	onAdd: PropTypes.func,
	onMount: PropTypes.func,
	onRemove: PropTypes.func,
	showDescriptionInTitle: PropTypes.bool,
	type: PropTypes.string,
	attribute: PropTypes.object,
	removeable: PropTypes.bool, //for radio type, remove layer after click on active
	interactive: PropTypes.bool,
	large: PropTypes.bool,
	disabled: PropTypes.bool,
	datasetKey: PropTypes.string,
	tagKeys: PropTypes.array,
};

export default DataLayerControl;
