import PropTypes from 'prop-types';
import DataFilterControl from '../../../common/DataFilterControl';
import './style.scss';
const ExplorerHeader = () => {
	return (
		<div className="unhab-ExplorerHeader">
			<DataFilterControl />
		</div>
	);
};

ExplorerHeader.propTypes = {
	mapSetKey: PropTypes.string,
};

export default ExplorerHeader;
