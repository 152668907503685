import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../../common/SelectedFeaturesControl';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const featureKeys =
		Select.selections.getActive(state)?.data?.featureKeysFilter?.keys;

	return {
		allowChange: featureKeys?.length === 1,
		allowAdd:
			featureKeys?.length > 0 && ownProps.selectedDatasetKeys?.length < 2,
		featureKeys,
		darkMode: Select.components.get(state, 'UnhabApp', 'darkMode'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.admStructure.ensureAreas());
		},
		onSetKeys: keys => {
			dispatch(Action.selections.setActiveSelectionFeatureKeysFilterKeys(keys));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
