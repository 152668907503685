export default {
	componentKey: "urbanizationAreaEvolution_maximumIncrease",
	title: 'Maximal yearly increase',
	configuration: {
		attributes: [
			'79d777f8-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - absolute
			'79d773b6-7d3f-11ee-b962-0242ac120002', // WSF Evolution 2016-2023 - Built-up (30m) - relative
			'79d77294-7d3f-11ee-b962-0242ac120002', // GHS 2016-2023 - Built-up (100m) - absolute
			'79d77190-7d3f-11ee-b962-0242ac120002',	// GHS 2016-2023 - Built-up (100m) - relative
			'4889a1c5-f6d9-403c-910e-c669d27a6f00', // WSF 2016-2023 - Built-up (10m) - absolute
			'0e998d61-74da-46d4-886b-d30e04d15934', // WSF 2016-2023 - Built-up (10m) - relative
			'52761164-26aa-46c6-ad64-7d9e8560025d', // WSF Evolution 1985-2015 - Built-up (10m) - absolute
			'033ea81e-0660-4234-9d87-47ba1b689910', // WSF Evolution 1985-2015 - Built-up (10m) - relative
		],
		type: 'maxYearlyIncrease',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
