import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {isArray as _isArray, cloneDeep as _cloneDeep} from 'lodash';
import Select from '../Select';

import CityClusterMethodParameterSelect from '../CityClusterMethodParameterSelect';

import './style.scss';

const FormatOptionLabel = ({label}) => {
	return (
		<div className="unhab-MethodSelect-option">
			<div className="unhab-MethodSelect-option-label">{label}</div>{' '}
		</div>
	);
};

FormatOptionLabel.propTypes = {
	label: PropTypes.string,
	data: PropTypes.object,
	higlightSelected: PropTypes.bool,
};

const getModelParams = (model, activeParameters) => {
	const activeParametersAsArray = Object.values(activeParameters);
	return model?.data?.parameters?.map(paramKey => {
		const isActiveParameter = activeParametersAsArray?.some(
			p => p.key === paramKey,
		);
		const activeParameterValue = isActiveParameter
			? activeParametersAsArray?.find(p => p.key === paramKey).data.value
			: null;
		return {
			key: paramKey,
			value: isActiveParameter ? activeParameterValue : null,
		};
	});
};

const MethodSelect = ({
	activeParameters,
	activeSelectionKeys,
	activeKeys,
	onActiveMethodChanged,
	onActiveParametersChanged,
	models,
	large,
	forceSingle,
	onMount,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const onChangeMethod = data => {
		const methodKeys = _isArray(data)
			? data?.map(item => item.value)
			: [data.value];
		const model = models.find(m => m.key === methodKeys[0]);

		const methodDefaultParams = getModelParams(model, activeParameters);
		setMethodParams(methodDefaultParams);

		onActiveMethodChanged(methodKeys);
		onActiveMethodChanged(methodKeys, methodDefaultParams);
	};

	const model = models.find(m => m.key === activeKeys[0]);

	const [methodParams, setMethodParams] = useState(
		getModelParams(model, activeParameters),
	);

	const setMethodParamsValue = (key, value) => {
		const index = methodParams.findIndex(p => p.key === key);
		methodParams[index].value = value;
		setMethodParams(_cloneDeep(methodParams));

		onActiveParametersChanged(activeKeys, {key, value});
	};
	return (
		<div className="unhab-MethodSelect">
			<Select
				models={models}
				activeKeys={activeKeys}
				onChange={onChangeMethod}
				portaling
				large={large}
				isMulti={!forceSingle && activeSelectionKeys?.length === 1}
				isClearable={false}
				disableColors={activeSelectionKeys?.length > 1}
				placeholderText="Select methods..."
				formatOptionLabel={FormatOptionLabel}
				lastOptionNotClearable
			/>

			{/* {model?.data?.parameters?.map((paramKey, i) => { */}
			{methodParams.map(paramKey => {
				return (
					<CityClusterMethodParameterSelect
						key={paramKey.key}
						methodKey={activeKeys[0]}
						parameterKey={paramKey.key}
						onValueChange={setMethodParamsValue}
						value={paramKey.value}
					/>
				);
			})}
		</div>
	);
};

MethodSelect.propTypes = {
	activeParameters: PropTypes.array, //in state
	activeKeys: PropTypes.array,
	onActiveMethodChanged: PropTypes.func,
	onActiveParametersChanged: PropTypes.func,
	activeSelectionKeys: PropTypes.array,
	forceSingle: PropTypes.bool,
	large: PropTypes.bool,
	models: PropTypes.array,
	onMount: PropTypes.func,
};

export default MethodSelect;
