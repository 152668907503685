export default [
	{
		key: 'DLR_WSF',
		data: {
			nameDisplay: 'DLR + WSF (10m)',
			shortName: 'DLR + WSF',
			color: '#FFBE98',
			urlPart: 'DLR_WSF',
			urlTemplate: 'DLR_WSF_MIN_POP/DLR_WSF_YEAR',
			defaultDatasetLayerKey: 'WSFBuiltUp2023',
			reletedDatasetKeys: [
				'wsfBuildUp2016_2023',
				'wsfPopulation2016_2023',
				'wsfCombinedBuiltupPopulation2016_2023',
				// only 2016_2023 values should be in UDT
				// 'wsfEvolution1985_2023',
				// 'wsf2019BuiltUp',
				// 'wsf2019Population',
				// 'wsfCombinedBuiltupPopulation2019',
				// 'wsfEvolution1985_2015',
			],
			parameters: ['DLR_WSF_YEAR', 'DLR_WSF_MIN_POP'],
		},
	},
	{
		key: 'JRC1_GHSL',
		data: {
			nameDisplay: 'JRC + GHSL (1000m)',
			shortName: 'JRC + GHSL',
			color: '#F05A7E',
			urlPart: 'JRC1_GHSL',
			urlTemplate:
				'JRC1_GHSL_HDC_BDENS_JRC1_GHSL_HDC_PDENS_JRC1_GHSL_HDC_PMIN/JRC1_GHSL_REF_YEAR',
			defaultDatasetLayerKey: 'GHSBuiltUp2030',
			reletedDatasetKeys: [
				'ghsBuiltUpSurface',
				'ghsPopulationGrid',
				'ghsCombinedBuiltUpPop100m',
			],
			parameters: [
				// 'JRC1_GHSL_ID', //todo ignore in parametersSelect
				'JRC1_GHSL_REF_YEAR',
				'JRC1_GHSL_HDC_BDENS',
				'JRC1_GHSL_HDC_PDENS',
				'JRC1_GHSL_HDC_PMIN',
			],
		},
	},
	{
		key: 'JRC2_WSF',
		data: {
			nameDisplay: 'JRC + WSF (10m)',
			shortName: 'JRC + WSF',
			color: '#125B9A',
			urlPart: 'JRC2_WSF',
			urlTemplate: 'JRC2_GHSL_THR_UPA_JRC2_GHSL_THR_UPP/JRC2_GHSL_REF_YEAR',
			defaultDatasetLayerKey: 'WSFBuiltUp2023',
			reletedDatasetKeys: [
				'wsfBuildUp2016_2023',
				'wsfPopulation2016_2023',
				'wsfCombinedBuiltupPopulation2016_2023',
				// only 2016_2023 values should be in UDT
				// 'wsfEvolution1985_2023',
				// 'wsf2019BuiltUp',
				// 'wsf2019Population',
				// 'wsfCombinedBuiltupPopulation2019',
				// 'wsfEvolution1985_2015',
			],
			parameters: [
				// 'JRC2_GHSL_ID', //todo ignore in parametersSelect
				'JRC2_GHSL_REF_YEAR',
				'JRC2_GHSL_THR_UPA',
				'JRC2_GHSL_THR_UPP',
			],
		},
	},
];
