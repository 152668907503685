import {omit as _omit, isEmpty as _isEmpty} from 'lodash';
import {createSelector} from 'reselect';
import {getParsedQueryString} from './utils';
import {getRouter} from '../../router';
import routerConstants from './constants';

const getSubstate = state => state.router;

const getCurrent = state => {
	const substate = getSubstate(state);
	if (substate?.params?.queryString) {
		return {
			...substate,
			params: {
				...substate?.params,
				parsedQueryString: getParsedQueryString(substate.params.queryString),
			},
		};
	} else {
		return substate;
	}
};

const getHistory = createSelector([getSubstate], substate => {
	return substate.history;
});

const getLatestHistoryPathRecord = createSelector(
	[getHistory, (state, name) => name],
	(history, name) => {
		return history.find(i => i.name === name);
	},
);

const getUrlForPath = createSelector(
	[
		getCurrent,
		getLatestHistoryPathRecord,
		(state, name) => name,
		(state, name, update) => update,
		(state, name, update, ignoreQueryString) => ignoreQueryString,
		(state, name, update, ignoreQueryString, paramsFilter) => paramsFilter,
		(state, name, update, ignoreQueryString, paramsFilter, recoverParams) =>
			recoverParams,
		(
			state,
			name,
			update,
			ignoreQueryString,
			paramsFilter,
			recoverParams,
			recoverParamsFilter,
		) => recoverParamsFilter,
	],
	(
		routerState,
		latestHistoryPathRecord,
		name,
		update,
		ignoreQueryString = false,
		paramsFilter,
		recoverParams,
		recoverParamsFilter,
	) => {
		const historyParams = {
			...(recoverParams
				? {
						...latestHistoryPathRecord?.params?.path,
						...getParsedQueryString(
							latestHistoryPathRecord?.params?.queryString,
						),
					}
				: {}),
		};

		if (recoverParams && recoverParamsFilter?.length > 0) {
			// clear properties params with null or undefined value
			for (const property of Object.keys(historyParams)) {
				if (recoverParamsFilter.includes(property)) {
					delete historyParams[property];
				}
			}
		}

		let newParams = {
			...routerState?.params?.path,
			...(ignoreQueryString ? {} : routerState?.params?.parsedQueryString),
			...update,
		};

		// clear properties params with null or undefined value
		for (const property of Object.keys(newParams)) {
			if (!newParams[property] && newParams[property] !== false) {
				delete newParams[property];
			}
		}

		// apply params filter
		// params filter is not applyed on params from history
		if (!_isEmpty(paramsFilter)) {
			newParams = _omit(newParams, paramsFilter);
		}

		// add params from history
		newParams = {
			...newParams,
			...historyParams,
		};

		const router = getRouter();

		//use current path name if is nor defined
		const urlName = name || routerState.name;
		const url = router.pathFor(urlName, newParams);
		return url;
	},
);

const getViewKey = createSelector([getCurrent], routerState => {
	return (
		routerState?.params?.parsedQueryString?.[routerConstants.viewKey] || null
	);
});

export default {getCurrent, getUrlForPath, getViewKey};
