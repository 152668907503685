export default {
	componentKey: "totalAreaOfUrbanExtent_maximum",
	title: 'Total area of urban extent - maximum',
	configuration: {
		attributes: [
			'2f6aaf4e-7e22-11ee-b962-0242ac120002', // WSF Evolution 1985-2015 - Built-up (30m) - absolute
			'2f6aac2e-7e22-11ee-b962-0242ac120002', // WSF Evolution 1985-2015 - Built-up (30m) - relative
			'2f6aa8be-7e22-11ee-b962-0242ac120002', // GHSL Built-up - absolute
			'2f6aa7ce-7e22-11ee-b962-0242ac120002', // GHSL Built-up - relative
			'8412a01d-3b7f-4c25-abad-caccff9eb4e0', // WSF 2016-2023 - Built-up (10m) - absolute
			'feb42dc0-332d-41ad-b96d-633ff3f52fce', // WSF 2016-2023 - Built-up (10m) - relative
			'72129a88-cd36-45a5-b811-a4ff23b38223', // WSF Evolution 1985-2023 - Built-up (10m) - absolute
			'870b2857-176a-4471-82a8-5ab909d44b72', // WSF Evolution 1985-2023 - Built-up (10m) - relative
		],
		type: 'maximum',
		columns: [
			{
				type: 'period',
			},
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
