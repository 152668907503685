import {
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	distinctColours,
	benchmarkArea1SelectionFeatureKey,
	benchmarkArea2SelectionFeatureKey,
} from '../../constants/app';

export const benchmarkArea1Selection = {
	key: benchmarkArea1SelectionKey,
	data: {
		featureKeysFilter: {
			keys: [benchmarkArea1SelectionFeatureKey],
		},
		style: {
			outlineWidth: 4,
			outlineColor: distinctColours[0],
		},
	},
};

export const benchmarkArea2Selection = {
	key: benchmarkArea2SelectionKey,
	data: {
		featureKeysFilter: {
			keys: [benchmarkArea2SelectionFeatureKey],
		},
		style: {
			outlineWidth: 4,
			outlineColor: distinctColours[1],
		},
	},
};
