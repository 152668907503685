import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import ComponentRenderer from '../../ComponentRenderer';
import {datasetsComparisonType} from '../../../../constants/app';

const LockMapsControl = ({
	maps,
	active,
	setSyncedJustScale,
	condensedControls,
	benchmarkComparisonType,
}) => {
	return benchmarkComparisonType === datasetsComparisonType ? null : (
		<ComponentRenderer
			component={'keepScale'}
			configurationGroupKey={'mapSetTools'}
		>
			<Button
				primary={active}
				disabled={maps?.length !== 2}
				onClick={() => setSyncedJustScale(!active)}
				icon="ri-scale"
				small
				floating
			>
				{condensedControls ? '' : 'Keep scale'}
			</Button>
		</ComponentRenderer>
	);
};

LockMapsControl.propTypes = {
	maps: PropTypes.array,
	active: PropTypes.bool,
	setSyncedJustScale: PropTypes.func,
	condensedControls: PropTypes.bool,
	benchmarkComparisonType: PropTypes.string,
};

export default LockMapsControl;
