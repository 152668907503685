import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, 'UnhabApp', 'condensedControls'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: condensedControlsActive =>
			dispatch(
				Action.components.set(
					'UnhabApp',
					'condensedControls',
					condensedControlsActive,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
