import Map from '../../common/Map';
import AppContent from '../../common/AppContent';
import BenchmarkSidePanel from './BenchmarkSidePanel/index';
import Header from '../../common/Header';
import './style.scss';

const Benchmark = () => {
	return (
		<>
			<Header />
			<AppContent>
				<Map />
				<BenchmarkSidePanel defaultOpen={true} />
			</AppContent>
		</>
	);
};

Benchmark.propTypes = {};

export default Benchmark;
