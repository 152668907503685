import PropTypes from 'prop-types';
import AttribuSetLayers from '../components/AttribuSetLayers';
import DataLayersGroup from '../components/DataLayersGroup';
import './style.scss';
import DatasetInfo from '../../../DatasetInfo';

const DatasetLayerControls = ({
	title,
	sections,
	mapKey,
	datasetKey,
	interactive,
	large,
	open,
}) => {
	return (
		<DataLayersGroup title={title} closeable={true} large={large} open={open}>
			<div className="unhab-DataLayersGroup-info">
				{datasetKey && <DatasetInfo datasetKey={datasetKey} />}
			</div>
			{sections?.map(section => {
				return (
					<AttribuSetLayers
						key={section.key}
						attributeSetKey={section.key}
						mapKey={mapKey}
						datasetKey={datasetKey}
						attributes={section.data.attributes}
						interactive={interactive}
						large={large}
					/>
				);
			})}
		</DataLayersGroup>
	);
};

DatasetLayerControls.propTypes = {
	activeLayers: PropTypes.array,
	sections: PropTypes.array,
	mapKey: PropTypes.string,
	title: PropTypes.string,
	datasetKey: PropTypes.string,
	interactive: PropTypes.bool,
	large: PropTypes.bool,
	open: PropTypes.bool,
};

export default DatasetLayerControls;
