import ActionTypes from '../../../constants/ActionTypes';
import indicatorsForList from '../../../data/indicatorsForList';

function add() {
	return dispatch => {
		dispatch(actionAdd(indicatorsForList));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.INDICATORS_FOR_LIST.ADD,
		data,
	};
};

export default {
	add,
};
