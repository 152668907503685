import theme from './_theme';

export default {
	componentKey: 'urbanAreaEvolutionRelative_chart',
	type: 'timeProgressChart',
	title: 'Urban area evolution - relative',
	subtitle: 'Yearly changes [%]',
	configuration: {
		attributes: [
			'43a63903-21e3-4b6b-9b09-a4dc2df4402c', // WSF 2016-2023 - Built-up (10m)
			'80431bc7-fc92-444d-8748-889fc1b4c5c9', // GHSL Built up
			'91e8179b-0598-488c-a214-5fa53a03d956', // WSF Evolution 1985-2015 - Built-up (30m)
			'af18f499-e699-40d7-81d7-00cb4ce0f131', // WSF Evolution 1985-2023 - Built-up (10m)
		],
		displayTimeFormat: 'YYYY',
		hideLegend: false,
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 50},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				// useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				// enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 1, right: 0, bottom: 1, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
