import ActionTypes from '../../../constants/ActionTypes';
import providers from '../../../data/providers';

function init() {
	return dispatch => {
		dispatch(actionAdd(providers));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.PROVIDERS.ADD,
		data,
	};
};

export default {
	init,
};
