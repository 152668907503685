import PropTypes from 'prop-types';
import DataFilterControl from '../../../common/DataFilterControl';
import './style.scss';

const ReportHeader = () => {
	return (
		<div className="unhab-ReportHeader">
			<DataFilterControl />
		</div>
	);
};

ReportHeader.propTypes = {
	darkTheme: PropTypes.bool,
};

export default ReportHeader;
