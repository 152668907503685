import theme from './_theme';

export default {
	componentKey: 'scatterPlot_chart_lcr_pgr_ghsl',
	type: 'scatterChart',
	title: 'Featured areas: Land Consumption Rate/Population Growth Rate (GHSL)',
	subtitle: '1975 - ',
	configuration: {
		attributes: [
			'42154c13-4360-4de1-ae1e-7a0ceb5162f4', // GHS LCR
			'338aa0b6-8ec4-4ad3-ab05-4e917bf8cee7', // GHS PGR
			'a260ff12-fd67-444f-8b7a-2a6d44f67cec', // GHS LCR/PGR
		],
		metadata: {
			nodeSize: {
				inputRange: [0, 5],
				outputRange: [3, 7],
			},
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 40, left: 60},
				xScale: {
					type: 'linear',
					max: 0.5,
				},
				yScale: {
					type: 'linear',
					min: -0.05,
					max: 0.15,
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
					legend: 'Pop. Growth Rate',
					legendPosition: 'middle',
					legendOffset: -50,
				},
				axisBottom: {
					tickValues: 6,
					legend: 'Land Consumption Rate',
					legendPosition: 'middle',
					legendOffset: 33,
				},
				gridYValues: 4,
				gridXValues: 6,
			},
		},
	},
};
