import PropTypes from 'prop-types';
import MapConfigurationControl from '../MapConfigurationControl';
import DatasetLayersControl from './DatasetLayersControl';
import OutlinesLayersControl from './OutlinesLayersControl';
import IndicatorLayersControl from './IndicatorLayersControl';
import XCubeSortLayerComponent from './XCubeSortLayerComponent';
import XCubeAreasControl from './XCubeAreasControl';
import XCubeLayersControl from './XCubeLayersControl';
import ControlAllSections from './ControlAllSections';
import SortLayersControl from './SortLayersControl';
import './style.scss';
import {useState} from 'react';

const getContentByView = (view, mapKey, openAll, setOpenAll) => {
	switch (view) {
		case 'explore':
		case 'benchmark':
		case 'report':
			return (
				<>
					<SortLayersControl mapKey={mapKey} />
					<ControlAllSections
						openAll={openAll}
						setOpenAll={() => setOpenAll(!openAll)}
					/>
					{openAll ? (
						<>
							<OutlinesLayersControl mapKey={mapKey} />
							<IndicatorLayersControl mapKey={mapKey} />
							<DatasetLayersControl mapKey={mapKey} />
						</>
					) : null}
				</>
			);
		case 'xcube':
			return (
				<>
					<SortLayersControl
						mapKey={mapKey}
						SortLayerComponent={XCubeSortLayerComponent}
					/>
					<XCubeAreasControl mapKey={mapKey} />
					<XCubeLayersControl mapKey={mapKey} />
				</>
			);
		default:
			return null;
	}
};

const DataLayersControl = ({
	activeAppView,
	activeLayerNames,
	horizontalPosition,
	mapKey,
	condensedControls,
}) => {
	const [openAll, setOpenAll] = useState(true);
	return (
		<MapConfigurationControl
			icon="ri-database"
			name={condensedControls ? '' : 'Data'}
			horizontalPosition={horizontalPosition}
			detail={activeLayerNames}
		>
			<div className="unhab-DataLayersControl">
				{getContentByView(activeAppView, mapKey, openAll, setOpenAll)}
			</div>
		</MapConfigurationControl>
	);
};

DataLayersControl.propTypes = {
	activeAppView: PropTypes.string,
	activeLayerNames: PropTypes.array,
	horizontalPosition: PropTypes.string,
	mapKey: PropTypes.string,
	condensedControls: PropTypes.bool,
};

export default DataLayersControl;
