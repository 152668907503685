import PropTypes from 'prop-types';
import {createElement} from 'react';
import ChangeDatasetDialog from '../../../../../common/ChangeDatasetDialog';
import DatasetSetSelector from '../../../../../common/DatasetSetSelector';
import {areaComparisonType} from '../../../../../../constants/app';

const BenchmarkChangeDatasetDialog = ({comparisonType, ...props}) => {
	if (comparisonType === areaComparisonType) {
		return createElement(ChangeDatasetDialog, {...props});
	} else {
		return createElement(DatasetSetSelector, {...props});
	}
};

BenchmarkChangeDatasetDialog.propTypes = {comparisonType: PropTypes.string};

export default BenchmarkChangeDatasetDialog;
