/**
 * Get total increase value (difference between last and first value)
 * @param values {Array} - Array of values
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @returns {number}
 */
const getTotalIncrease = (values, attributeType) => {
	if (attributeType === 'relative') {
		return ((values[values?.length - 1] - values[0]) * 100) / values[0];
	} else {
		return values[values?.length - 1] - values[0];
	}
};

/**
 * Get total increase value based on incremental changes timeserie
 * @param values {Array} - Array of values
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @returns {number}
 */
const getTotalIncreaseEvolution = (values, attributeType) => {
	if (attributeType === 'relative') {
		return ((values[values?.length - 1] - values[0]) / values[0]) * 100;
	} else {
		return sumArray(values);
	}
};

/**
 * Get average yearly increase (average change between values in array)
 * @param values {Array} - Array of values
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @returns {number}
 */
const getAvgYearlyIncrease = (values, attributeType) => {
	const numOfYears = values?.length;
	if (attributeType === 'relative') {
		return (
			((values[numOfYears - 1] - values[0]) * 100) /
			values[0] /
			(numOfYears - 1)
		);
	} else {
		return (values[numOfYears - 1] - values[0]) / (numOfYears - 1);
	}
};

/**
 * Get average yearly increase based on incremental changes timeserie
 * @param values {Array} - Array of values
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @returns {number|null}
 */
const getAvgYearlyIncreaseEvolution = (values, attributeType) => {
	if (values?.length) {
		const numOfYears = values?.length;
		if (attributeType === 'relative') {
			return (
				((values[numOfYears - 1] - values[0]) * 100) /
				values[0] /
				(numOfYears - 1)
			);
		} else {
			return sumArray(values) / values?.length;
		}
	} else {
		return null;
	}
};

/**
 * Calculates the minimum yearly increase for a given array of values.
 * If the attribute type is 'relative', it calculates the minimum relative change as a percentage.
 * Otherwise, it calculates the minimum difference between consecutive values.
 *
 * @param {Array<number>} values - The array of values.
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @param periodsArray {Array} - list of periods
 * @returns {[number, number]} - The minimum yearly increase or null if the array has less than two elements.
 */
const getMinYearlyIncrease = (values, attributeType, periodsArray) => {
	if (values?.length < 2) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}

	if (attributeType === 'relative') {
		const totalIncrease = values[values?.length - 1] - values[0];
		let minRelativeChange = Math.abs((values[1] - values[0]) / totalIncrease);
		let minRelativeChangeYear = periodsArray[1];
		for (let i = 2; i < values.length; i++) {
			let currentRelativeChange = Math.abs(
				(values[i] - values[i - 1]) / totalIncrease,
			);
			if (currentRelativeChange < minRelativeChange) {
				minRelativeChange = currentRelativeChange;
				minRelativeChangeYear = periodsArray[i];
			}
		}

		return [minRelativeChange * 100, minRelativeChangeYear];
	} else {
		let minDiff = Math.abs(values[1] - values[0]);
		let minDiffYear = periodsArray[1];
		for (let i = 2; i < values.length; i++) {
			let currentDiff = Math.abs(values[i] - values[i - 1]);
			if (currentDiff < minDiff) {
				minDiff = currentDiff;
				minDiffYear = periodsArray[i];
			}
		}

		return [minDiff, minDiffYear];
	}
};

/**
 * Calculates the maximal yearly increase for a given array of values.
 * If the attribute type is 'relative', it calculates the maximum relative change as a percentage.
 * Otherwise, it calculates the maximum difference between consecutive values.
 *
 * @param {Array<number>} values - The array of values.
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @param periodsArray {Array} - list of periods
 * @returns {[number, number]} - The maximum yearly increase or null if the array has less than two elements.
 */
const getMaxYearlyIncrease = (values, attributeType, periodsArray) => {
	if (values?.length < 2) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}

	if (attributeType === 'relative') {
		const totalIncrease = values[values?.length - 1] - values[0];
		let maxRelativeChange = Math.abs((values[1] - values[0]) / totalIncrease);
		let maxRelativeChangeYear = periodsArray[1];
		for (let i = 2; i < values.length; i++) {
			let currentRelativeChange = Math.abs(
				(values[i] - values[i - 1]) / totalIncrease,
			);
			if (currentRelativeChange > maxRelativeChange) {
				maxRelativeChange = currentRelativeChange;
				maxRelativeChangeYear = periodsArray[i];
			}
		}

		return [maxRelativeChange * 100, maxRelativeChangeYear];
	} else {
		let maxDiff = Math.abs(values[1] - values[0]);
		let maxDiffChangeYear = periodsArray[1];
		for (let i = 2; i < values.length; i++) {
			let currentDiff = Math.abs(values[i] - values[i - 1]);
			if (currentDiff > maxDiff) {
				maxDiff = currentDiff;
				maxDiffChangeYear = periodsArray[i];
			}
		}

		return [maxDiff, maxDiffChangeYear];
	}
};

/**
 * Calculates the growth rate based on the given values.
 * @param {Array<number>} values - The array of values.
 * @returns {number | null} - The growth rate or null if the array doesn't have enough elements.
 */
const getGrowthRate = values => {
	if (values?.length < 2) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}
	const periodLength = [values?.length - 1];
	const increaseRatio = values[periodLength] / values[0];
	return Math.log(increaseRatio) / periodLength;
};

/**
 * Calculates the land consumption rate based on the given values.
 * @param {Array<number>} values - The array of values.
 * @returns {number | null} - The growth rate or null if the array doesn't have enough elements.
 */
const getLcRate = values => {
	if (values?.length < 2) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}
	const periodLength = [values?.length - 1];
	const increaseRatio = values[periodLength] - values[0];
	return increaseRatio / (values[0] * periodLength);
};

/**
 * Calculates the ratio between the growth rates of two sets of values.
 *
 * @param {Array<Array<number>>} values - An array containing two arrays of numbers.
 * @returns {number|null} - The ratio between the growth rates of the two sets of values, or null if the input is invalid.
 */
const getRatesRatio = values => {
	if (values?.length === 2) {
		const [valuesA, valuesB] = values;
		return getLcRate(valuesA) / getGrowthRate(valuesB);
	} else {
		return null;
	}
};

/**
 * Calculates the ratio of the last values in two arrays.
 *
 * @param {Array<Array<number>>} values - An array containing two arrays of numbers.
 * @param periodsArray {Array} - list of periods
 * @returns {[number, number]} The ratio of the last values in the arrays, or null if the input is invalid. Second item is the year of the last value
 */
const getLastValuesRatio = (values, periodsArray) => {
	if (values?.length === 2) {
		const [valuesA, valuesB] = values;
		if (valuesA?.length && valuesB?.length) {
			return (
				// TODO the multiplier is specific for data we have
				[
					(valuesA[valuesA?.length - 1] * 1000000) /
						valuesB[valuesB?.length - 1],
					periodsArray?.[periodsArray.length - 1],
				]
			);
		} else {
			return null;
		}
	} else {
		return null;
	}
};

/**
 * Calculates the minimum value from an array of values and optionally converts it to a relative value.
 *
 * @param {number[]} values - The array of values to find the minimum from.
 * @param {'relative'|'absolute'} attributeType  - Attribute type
 * @param {number} normalizationValue - The normalization value used to convert the minimum value to a relative value.
 * @param periodsArray {Array} - list of periods
 * @param isPopulation {boolean} - if yes, then not multiply relative values
 * @returns {[number, number]}
 */
const getMinimum = (
	values,
	attributeType,
	normalizationValue,
	periodsArray,
	isPopulation,
) => {
	if (!values || values?.length < 1) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}

	const min = Math.min(...values);
	const minYear = periodsArray[values.indexOf(min)];

	if (attributeType === 'relative') {
		return [(min * (isPopulation ? 1 : 100)) / normalizationValue, minYear];
	} else {
		return [min, minYear];
	}
};

/**
 * Calculates the maximum value from an array of values and optionally converts it to a relative value.
 *
 * @param {number[]} values - The array of values to find the maximum from.
 * @param {'relative'|'absolute'} attributeType  - Attribute type
 * @param {number} normalizationValue - The normalization value used to convert the maximum value to a relative value.
 * @param periodsArray {Array} - list of periods
 * @param isPopulation {boolean} - if yes, then not multiply relative values
 * @returns {[number, number]}
 */
const getMaximum = (
	values,
	attributeType,
	normalizationValue,
	periodsArray,
	isPopulation,
) => {
	if (!values || values?.length < 1) {
		// Array should have at least two elements for differences to be calculated
		return null;
	}

	const max = Math.max(...values);
	const maxYear = periodsArray[values.indexOf(max)];

	if (attributeType === 'relative') {
		return [(max * (isPopulation ? 1 : 100)) / normalizationValue, maxYear];
	} else {
		return [max, maxYear];
	}
};

// helpers
/**
 * Sums all the values in the given array.
 * @param {number[]} arr - The array of numbers to sum.
 * @returns {number} The sum of all the values in the array.
 */
function sumArray(arr) {
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return sum;
}

/**
 * Get value based on calculation type
 * @param values {Array} - Array of values
 * @param calculationType {string} - Calculation type
 * @param attributeType {'relative'|'absolute'} - Attribute type
 * @param normalizationValue {number|null} - value used for normalization
 * @param periodsArray {Array} - list of periods
 * @returns {number|null}
 */
const getValue = (
	values,
	calculationType,
	attributeType,
	normalizationValue,
	periodsArray,
) => {
	if (values && calculationType) {
		switch (calculationType) {
			case 'totalIncrease':
				return getTotalIncrease(values, attributeType);
			case 'totalIncreaseEvolution':
				return getTotalIncreaseEvolution(values, attributeType);
			case 'avgYearlyIncrease':
				return getAvgYearlyIncrease(values, attributeType);
			case 'avgYearlyIncreaseEvolution':
				return getAvgYearlyIncreaseEvolution(values, attributeType);
			case 'minYearlyIncrease':
				return getMinYearlyIncrease(values, attributeType, periodsArray);
			case 'maxYearlyIncrease':
				return getMaxYearlyIncrease(values, attributeType, periodsArray);
			case 'growthRate':
				return getGrowthRate(values);
			case 'lcRate':
				return getLcRate(values);
			case 'minimum':
				return getMinimum(
					values,
					attributeType,
					normalizationValue,
					periodsArray,
				);
			case 'minimumPop':
				return getMinimum(
					values,
					attributeType,
					normalizationValue,
					periodsArray,
					true,
				);
			case 'maximum':
				return getMaximum(
					values,
					attributeType,
					normalizationValue,
					periodsArray,
				);
			case 'maximumPop':
				return getMaximum(
					values,
					attributeType,
					normalizationValue,
					periodsArray,
					true,
				);
			case 'ratesRatio':
				return getRatesRatio(values);
			case 'lastValuesRatio':
				return getLastValuesRatio(values, periodsArray?.[1]);
			default:
				return null;
		}
	} else {
		return null;
	}
};

export default {
	getValue,

	getMaxYearlyIncrease,
	getMinYearlyIncrease,
	getGrowthRate,
};
