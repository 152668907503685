import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	let hidden;
	if (ownProps?.datasetLayerKey) {
		hidden = Select.unhab.layers.isLayerLegendHiddenByDatasetKey(
			state,
			ownProps.mapKey,
			ownProps.datasetLayerKey,
		);
	} else {
		hidden = Select.unhab.layers.isLayerLegendHidden(
			state,
			ownProps.mapKey,
			ownProps.layerKey,
			ownProps.layerTemplateKey,
		);
	}
	return {hidden: hidden};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: hidden => {
			if (ownProps?.datasetLayerKey) {
				dispatch(
					Action.unhab.layers.setLayerLegendHiddenByDatasetLayerKey(
						ownProps.mapKey,
						ownProps?.datasetLayerKey,
						hidden,
					),
				);
			} else {
				dispatch(
					Action.unhab.layers.setLayerLegendHidden(
						ownProps.mapKey,
						ownProps.layerKey,
						ownProps.layerTemplateKey,
						hidden,
					),
				);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
