import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const relativeAttribute =
		Select.unhab.layers.getRelativeAttributeByIndicatorLayerKey(
			ownProps.layerKey,
		);
	const absoluteAttribute =
		Select.unhab.layers.getAbsoluteAttributeByIndicatorLayerKey(
			ownProps.layerKey,
		);

	const attributes = [];
	if (absoluteAttribute) {
		attributes.push(absoluteAttribute?.key);
	}
	if (relativeAttribute) {
		attributes.push(relativeAttribute?.key);
	}

	const valuesForCurrentPeriod =
		Select.unhab.indicators.getAttributeValuesForActivePeriodByFeatureKey(
			state,
			attributes,
			ownProps.featureKey,
			ownProps.layerKey,
		);

	const absoluteValue =
		ownProps[absoluteAttribute?.data?.configuration?.columnName];
	const activeAreaTreeLevel = Select.unhab.getMapLevelByMapKey(
		state,
		ownProps.mapKey,
	);
	const activeAreaTreeLevelName = activeAreaTreeLevel?.data?.nameDisplay;
	const currentPeriod = Select.periods.getActive(state)?.data?.period;
	const dataset = Select.unhab.datasets.getByAttributeKey(
		state,
		relativeAttribute?.key || absoluteAttribute?.key,
	);

	return {
		activeAreaTreeLevelName,
		relativeAttribute,
		absoluteAttribute,
		absoluteValue,
		valuesForCurrentPeriod,
		currentPeriod,
		dataset,
		mapLayers: Select.maps.getLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onFeatureChange: () => {
			dispatch(Action.unhab.features.ensure(ownProps.featureKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
