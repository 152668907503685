import moment from 'moment';
import {Select} from '@gisatcz/ptr-state';
import datasetsSelectors from './datasets/selectors';
import cityClusterMethodsSelectors from './cityClusterMethods/selectors';
import areaTreeTypesSelectors from './areaTreeTypes/selectors';
import {
	areaFidColumns,
	areaNameColumns,
	cityClustersAreaTreeKey,
	fourCountriesColors,
} from '../../constants/app';
import {createCachedSelector} from 're-reselect';
import memoize from 'memoize';

/**
 * Filter attributes by component attributes and selected datasets
 * @param configuration {Object} Chart configuration
 */
const getFilteredAttributesByActiveDatasets = createCachedSelector(
	[
		Select.attributes.getAllAsObject,
		datasetsSelectors.getActiveModels,
		cityClusterMethodsSelectors.getActiveModels,
		datasetsSelectors.getAll,
		areaTreeTypesSelectors.getActiveKey,
		(state, configuration) => configuration?.attributes,
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
	],
	getAttributesByDatasets,
)((state, config, key) => key);

/**
 * Filter attributes by component attributes
 * @param configuration {Object} Chart configuration
 */
const getFilteredAttributesByDatasets = createCachedSelector(
	[
		Select.attributes.getAllAsObject,
		datasetsSelectors.getAll,
		cityClusterMethodsSelectors.getActiveModels,
		datasetsSelectors.getAll,
		areaTreeTypesSelectors.getActiveKey,
		(state, configuration) => configuration?.attributes,
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
	],
	getAttributesByDatasets,
)((state, config, key) => key);

// helpers
function getAttributesByDatasets(
	attributes,
	datasets,
	activeMethods,
	allDatasets,
	activeAreaTreeKey,
	componentAttributes,
	parametersKeysByActiveMethods,
) {
	//clear active methods if activeAreaTreeKey is not cityCluster
	activeMethods =
		activeMethods === null || activeAreaTreeKey !== cityClustersAreaTreeKey
			? []
			: activeMethods;

	let mergedActiveDatasets = [];
	if (
		activeAreaTreeKey === cityClustersAreaTreeKey &&
		activeMethods?.length > 0
	) {
		// allDatasets
		const mergedActiveMethodsDatasetKeys = Array.prototype.concat(
			...activeMethods.map(method => method.data.reletedDatasetKeys),
		);
		mergedActiveDatasets = mergedActiveMethodsDatasetKeys.map(datasetKey =>
			allDatasets.find(ds => ds.key === datasetKey),
		);
	} else {
		mergedActiveDatasets = datasets;
	}

	if (
		attributes &&
		mergedActiveDatasets?.length &&
		componentAttributes?.length
	) {
		const finalAttributes = [];

		componentAttributes.forEach(componentAttributeKey => {
			const datasetFitsFilter = mergedActiveDatasets.filter(activeDataset =>
				activeDataset.data?.attributes.some(
					attr => attr.key === componentAttributeKey,
				),
			);

			const atLeastOneDatasetFitsFilter = datasetFitsFilter?.length > 0;

			// Get parent datasets for given attribute
			const datasets = allDatasets.filter(activeDataset =>
				activeDataset.data?.attributes.some(
					attr => attr.key === componentAttributeKey,
				),
			);
			// Get attribute metadata
			const attribute = attributes[componentAttributeKey];
			if (atLeastOneDatasetFitsFilter && attribute) {
				const attributeConfiguration = attribute?.data?.configuration;
				const dataset = datasets[0];
				const secondaryDataset = datasets[1]; // if value is combined from 2 datasets
				const combinedDataset = datasets[2]; // if value is combined from 2 datasets

				if (attributeConfiguration?.columnName) {
					const finalAttribute = {
						...attributeConfiguration,
						attributeKey: attribute.key,
						key: attributeConfiguration?.columnName,
						name: dataset.data.shortName,
						longName: dataset.data.nameDisplay,
						shortAttributeName: attribute.data.shortName,
						color: dataset.data.color,
						period: dataset.data.period,
						name2: secondaryDataset?.data.shortName, // if value is combined from 2 datasets
						longName2: secondaryDataset?.data.nameDisplay, // if value is combined from 2 datasets
						color2: secondaryDataset?.data.color, // if value is combined from 2 datasets
						period2: secondaryDataset?.data.period, // if value is combined from 2 datasets
						nameCombined: combinedDataset?.data.shortName,
						colorCombined: combinedDataset?.data.color,
						unit: attribute?.data?.unit,
						type: attribute?.data?.type,
						secondaryDataset,
					};

					if (activeMethods.length > 0) {
						activeMethods?.forEach((method, i) => {
							finalAttributes.push({
								...finalAttribute,
								originMethod: {
									...method,
									parametersKeys: parametersKeysByActiveMethods[i],
								},
							});
						});
					} else {
						finalAttributes.push(finalAttribute);
					}
				} else if (attributeConfiguration?.columnNameTemplate) {
					const finalAttribute = {
						attributeKey: attribute.key,
						template: attributeConfiguration?.columnNameTemplate,
						name: dataset.data.shortName,
						longName: dataset.data.nameDisplay,
						shortAttributeName: attribute.data.shortName,
						color: dataset.data.color,
						period: dataset.data.period,
						name2: secondaryDataset?.data.shortName, // if value is combined from 2 datasets
						longName2: secondaryDataset?.data.nameDisplay, // if value is combined from 2 datasets
						color2: secondaryDataset?.data.color, // if value is combined from 2 datasets
						period2: secondaryDataset?.data.period, // if value is combined from 2 datasets
						nameCombined: combinedDataset?.data.shortName,
						colorCombined: combinedDataset?.data.color,
						unit: attribute?.data?.unit,
						type: attribute?.data?.type,
						secondaryDataset,
						...attributeConfiguration,
					};

					if (activeMethods.length > 0) {
						activeMethods?.forEach((method, i) => {
							finalAttributes.push({
								...finalAttribute,
								originMethod: {
									...method,
									parametersKeys: parametersKeysByActiveMethods[i],
								},
							});
						});
					} else {
						finalAttributes.push({
							...finalAttribute,
						});
					}
				}
			}
		});

		return finalAttributes;
	} else {
		return null;
	}
}

function getAreaName(properties) {
	return (
		properties?.[areaNameColumns[0]] ||
		properties?.[areaNameColumns[1]] ||
		properties?.[areaNameColumns[2]]
	);
}

function getFourCountriesAreaColor(properties) {
	const regionKey = properties?.[areaFidColumns[0]];
	const countryKey = properties?.[areaFidColumns[1]];
	const countryColor = fourCountriesColors.find(
		country => country.key == countryKey,
	)?.color;

	if (regionKey) {
		return countryColor;
	} else {
		return countryColor;
	}
}

function getFourCountriesAreaShape(properties) {
	const regionKey = properties?.[areaFidColumns[0]];
	if (regionKey) {
		return 'circle';
	} else {
		return 'square';
	}
}

/**
 * Get value for normalization
 * @param properties {Object} Feature properties
 * @param normalization {Object} Normalization definition
 * @returns {*|number}
 */
function getNormalizationAttributeValue(properties, normalization) {
	let value = properties?.[normalization?.attribute];
	if (value) {
		if (normalization?.multiplyBy) {
			return value / normalization?.multiplyBy;
		} else {
			return value;
		}
	} else {
		return 1;
	}
}

/**
 * Converts a filtered data object into a time series array with formatted timestamps.
 *
 * @param {Object} filteredDataAsObject - The filtered data object with period keys.
 * @param {number} normValue - value used for normalization
 * @returns {Array} - An array of time series data with formatted timestamps.
 */
function getFormattedTimeSerie(filteredDataAsObject, normValue) {
	if (filteredDataAsObject) {
		const timeSerie = [];

		// Iterate through the keys in the filteredDataAsObject.
		for (const key in filteredDataAsObject) {
			// Create a time series data object for each key.
			timeSerie.push({
				period: key, // The period key from the original data.
				x: moment.utc(key).format(), // Format the timestamp using moment.js.
				y: filteredDataAsObject[key]
					? filteredDataAsObject[key] / normValue
					: filteredDataAsObject[key], // The corresponding data value.
			});
		}

		// Return the time series array.
		return timeSerie;
	} else {
		// Return null if the input object is empty.
		return null;
	}
}

/**
 * Filter data object based on a template and extract keys from the original keys.
 *
 * @param {Object} data - The data object to filter.
 * @param {string} template - The template with a placeholder.
 * @returns {Object} - A filtered data object with extracted keys.
 */
// function filterPropertiesByTemplate(data, template) {
// 	console.log('###', data, template);
// 	// Create the regular expression pattern once.
// 	const regex = new RegExp(template.replace('{XXXX}', '(\\d+)(?!.*5YRS)'));
//
// 	// Use reduce to accumulate filtered data in one pass.
// 	return Object.keys(data).reduce((filteredData, key) => {
// 		const match = key.match(regex);
// 		if (match) {
// 			// Use the captured group as the key in the filteredData object.
// 			filteredData[match[1]] = data[key];
// 		}
// 		return filteredData;
// 	}, {});
// }
const filterPropertiesByTemplate = memoize(
	(data, template) => {
		// Create the regular expression pattern once.
		const regex = new RegExp(template.replace('{XXXX}', '(\\d+)(?!.*5YRS)'));

		// Use reduce to accumulate filtered data in one pass.
		return Object.keys(data).reduce((filteredData, key) => {
			const match = key.match(regex);
			if (match) {
				// Use the captured group as the key in the filteredData object.
				filteredData[match[1]] = data[key];
			}
			return filteredData;
		}, {});
	},
	{
		cacheKey: args => {
			const [data, template] = args;
			return `${template}_${data.ADM0_CODE_ADM1_CODE || data.ADM0_CODE}_${data.ADM0_CODE}`;
		},
	},
);

/**
 * Filter data object based on a template and period.
 *
 * @param {Object} data - The data object to filter.
 * @param {string} template - The template with a placeholder.
 * @param {string} period - The period in YYYY/YYYY format
 * @returns {Array} - Filtered time serie.
 */
function getPropertyValuesByTemplateAndPeriod(data, template, period) {
	// Get filtered properties by template
	const fullSerieAsObject = filterPropertiesByTemplate(data, template);
	if (fullSerieAsObject && period) {
		const [startYear, endYear] = period.split('/');
		if (startYear && endYear) {
			const values = [];
			const years = [];
			for (const key in fullSerieAsObject) {
				const year = parseInt(key);
				if (year >= startYear && year <= endYear) {
					values.push(fullSerieAsObject[key]);
					years.push(year);
				}
			}
			return values?.length ? [values, years] : null;
		}
	} else {
		return null;
	}
}

export default {
	getFilteredAttributesByActiveDatasets,
	getFilteredAttributesByDatasets,

	getAreaName,
	getNormalizationAttributeValue,
	getFormattedTimeSerie,
	getFourCountriesAreaColor,
	filterPropertiesByTemplate,
	getPropertyValuesByTemplateAndPeriod,
	getFourCountriesAreaShape,
};
