import {
	forIn as _forIn,
	groupBy as _groupBy,
	isArray as _isArray,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import utils from '../../../utils';
import selectorHelpers from '../selectorHelpers';
import featuresSelectors from '../features/selectors';
import datasetsSelectors from '../datasets/selectors';
import cityClusterMethodsSelectors from '../cityClusterMethods/selectors';
import {createSelector} from 'reselect';

import cityClusterFeaturesSelectors from '../cityClusterFeatures/selectors';
import benchmarkComparisonTypesSelectors from '../benchmarkComparisonTypes/selectors';
import udtStructureSelectors from '../udtStructure/selectors';
import {getStructureItemByKey} from '../udtStructure/helpers';
import areaTreeTypesSelectors from '../areaTreeTypes/selectors';
import calculations from './calculations';
import {
	benchmarkViewKey,
	distinctColours,
	reportingPeriod,
	reportViewKey,
	cityClustersAreaTreeKey,
	datasetsComparisonType,
} from '../../../constants/app';
import createCachedSelector from 're-reselect';
import indicatorLayers from '../../../data/layers/indicatorLayers';

const getActiveFeaturesByActiveAreaTreeKey = createSelector(
	[
		featuresSelectors.getSelectedFeatures,
		cityClusterFeaturesSelectors.getSelectedFeatures,
		udtStructureSelectors.getStructure,
		areaTreeTypesSelectors.getActiveKey,
	],
	(features, cityClusterFeatures, udtStructure, activeAreaTreeKey) => {
		if (activeAreaTreeKey === cityClustersAreaTreeKey) {
			// transform cityClusterFeatures to features
			return cityClusterFeatures?.map(feature => {
				const structureItem = getStructureItemByKey(
					udtStructure,
					feature.data.properties.featureKey,
				);
				return {
					...feature,
					data: {
						...feature.data.properties,
						name: structureItem.data.name,
					},
				};
			});
		} else {
			return features;
		}
	},
);

/**
 * Get attribute metadata (with original value) together with original feature data grouped by feature
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
const getAttributesByFeatures = createCachedSelector(
	[
		getActiveFeaturesByActiveAreaTreeKey,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
	],
	(features, attributes, parametersKeysByActiveMethods) => {
		if (features?.length && attributes?.length) {
			const data = [];
			features.forEach((feature, i) => {
				const attributeData = [];

				attributes.forEach(attribute => {
					attributeData.push({
						...attribute,
						value: feature.data?.[attribute?.key],
						originalValue: feature.data?.[attribute?.key],
					});
				});
				data.push({
					originalFeatureData: {
						...feature,
						data: {
							...feature.data,
							parameters: parametersKeysByActiveMethods[i],
						},
					},
					attributeData,
				});
			});
			return data;
		} else {
			return null;
		}
	},
)((state, config, key) => key);

/**
 * General selector for IndicatorBox (it doesn't count value from timeserie)
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
const getIndicatorData = createCachedSelector(
	[
		getAttributesByFeatures,
		CommonSelect.selections.getActive,
		CommonSelect.views.getActiveKey,
		areaTreeTypesSelectors.getActiveKey,
	],
	(features, activeSelection, viewKey, activeAreaTreeKey) => {
		return prepareDataForIndicatorBox(
			features,
			activeSelection?.data,
			viewKey,
			null,
			activeAreaTreeKey,
		);
	},
)((state, config, componentKey) => componentKey);

const getAttributeValuesForActivePeriodByFeatureKey = createCachedSelector(
	[
		CommonSelect.periods.getActive,
		CommonSelect.attributes.getAllAsObject,
		featuresSelectors.getAllAsObject,
		(state, attributeKeys) => attributeKeys,
		(state, attributeKeys, featureKey) => featureKey,
		(state, attributeKeys, featureKey, layerKey) => layerKey,
	],
	(period, attributes, features, attributeKeys, featureKey, layerKey) => {
		const layer = indicatorLayers?.[layerKey];
		const feature = features?.[featureKey];
		if (layer && feature && attributeKeys?.length) {
			const calculatedValues = [];
			attributeKeys?.forEach(attributeKey => {
				const attribute = attributes?.[attributeKey]?.data?.configuration;
				const attributeType = attributes?.[attributeKey]?.data?.type;

				const valuesAndYears = getValuesAndYearsListFromTimeSeries(
					attribute,
					feature?.data,
					period?.data?.period,
				);

				if (valuesAndYears) {
					// Get list of values and associated periods
					const {values, years} = valuesAndYears;
					const normalizationValue = getNormalizationValue(
						attribute.normalizationColumnName,
						feature.data,
					);

					// Calculate main value
					const {value} = getValue(
						values,
						years,
						normalizationValue,
						layer.options.calculationType,
						attributeType,
					);

					if (value || value === 0) {
						calculatedValues.push(value);
					}
				}
			});
			return calculatedValues?.length ? calculatedValues : null;
		} else {
			return null;
		}
	},
)(
	(state, attributeKeys, featureKey, layerKey) =>
		`${attributeKeys?.join(',')}_${featureKey}_${layerKey}`,
);

/**
 * Calculate indicator data from timeserie for active period
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
// const getIndicatorDataForActivePeriod = createCachedSelector(
const getIndicatorDataForActivePeriod = createSelector(
	[
		getAttributesByFeatures,
		CommonSelect.selections.getActive,
		CommonSelect.periods.getActive,
		CommonSelect.views.getActiveKey,
		areaTreeTypesSelectors.getActiveKey,
		benchmarkComparisonTypesSelectors.getActiveKey,
		datasetsSelectors.getAll,
		(state, configuration) => configuration,
		state => CommonSelect.components.get(state, 'App', 'periodStep'),
	],
	(
		features,
		activeSelection,
		period,
		viewKey,
		activeAreaTreeKey,
		benchmarkType,
		datasets,
		configuration,
		periodStep,
	) => {
		if (features?.length && period) {
			const updatedFeatures = features.map(feature => {
				const {attributeData, originalFeatureData} = feature;

				const updatedAttributeData = attributeData.map(attribute => {
					if (
						attribute.sourceColumnNameTemplate ||
						attribute.sourceColumnNameTemplates
					) {
						const valuesAndYears = getValuesAndYearsListFromTimeSeries(
							attribute,
							originalFeatureData?.data,
							period?.data?.period,
						);

						if (valuesAndYears) {
							const {values, years} = valuesAndYears;
							const normalizationValue = getNormalizationValue(
								attribute.normalizationColumnName,
								originalFeatureData.data,
							);

							// Calculate main value
							const {value, yearToShow} = getValue(
								values,
								years,
								normalizationValue,
								configuration.type,
								attribute.type,
							);

							// Calculate parts per period if reporting periods active
							let partsPerPeriod;
							if (periodStep === reportingPeriod) {
								partsPerPeriod = getPartsPerPeriod(
									values,
									years,
									normalizationValue,
									configuration.type,
									attribute.type,
									period?.data?.period,
									periodStep,
								);
							}

							return {
								...attribute,
								value,
								yearToShow,
								partsPerPeriod,
							};
						} else {
							return {
								...attribute,
								value: null,
							};
						}
					} else {
						return attribute;
					}
				});
				return {
					originalFeatureData,
					attributeData: updatedAttributeData,
				};
			});

			return prepareDataForIndicatorBox(
				updatedFeatures,
				activeSelection?.data,
				viewKey,
				period?.data?.period,
				activeAreaTreeKey,
				benchmarkType === datasetsComparisonType &&
					activeAreaTreeKey === cityClustersAreaTreeKey,
				datasets,
			);
		} else {
			return null;
		}
	},
);
// )((state, config, componentKey) => componentKey);

// helpers ------------------------------------------------------------------------------------------------------

/**
 * Prepares data for the Indicator Box based on the given features,
 * selection data, and period.
 *
 * @param {Array} features - An array of features to prepare data for.
 * @param {Object} selectionData - The selection data to use for color picking.
 * @param {string} viewKey - Panther view key
 * @param {number} [period] - Current period
 * @returns {null|Array} - Returns an array of prepared data or null if no data is available.
 */
function prepareDataForIndicatorBox(
	features,
	selectionData,
	viewKey,
	period,
	activeAreaTreeKey,
	isCityClustersDatasetsComparisonType,
	datasets,
) {
	const data = [];

	if (
		features?.length > 1 &&
		activeAreaTreeKey === cityClustersAreaTreeKey &&
		isCityClustersDatasetsComparisonType
	) {
		//Dataset compare

		const filterByParams = (attribute, featureParams) => {
			return attribute.originMethod.parametersKeys === featureParams;
		};

		const attributeGroups = _groupBy(
			[
				...features[0].attributeData.filter(a =>
					filterByParams(a, features[0].originalFeatureData.key),
				),
				...features[1].attributeData.filter(a =>
					filterByParams(a, features[1].originalFeatureData.key),
				),
			],
			'originMethod.parametersKeys',
		);

		const reduceAttributes = (acc, attribute) => {
			const reletedDatasetKeys = attribute.originMethod.data.reletedDatasetKeys;

			const attribudeInRelatedDatasets = reletedDatasetKeys.some(
				reletedDatasetKey => {
					const dataset = datasets.find(d => d.key === reletedDatasetKey);

					return dataset.data.attributes.some(
						att => att.key === attribute.attributeKey,
					);
				},
			);

			const alreadyAdded = acc.some(a => a.key === attribute.key);

			if (
				attribudeInRelatedDatasets &&
				attribute.value !== null &&
				!alreadyAdded
			) {
				return [...acc, attribute];
			} else {
				return acc;
			}
		};

		_forIn(attributeGroups, (attributes, name) => {
			data.push({
				id: attributes?.[0].key,
				name: isCityClustersDatasetsComparisonType
					? attributes?.[0].originMethod.data.nameDisplay
					: name,
				longName: isCityClustersDatasetsComparisonType
					? attributes?.[0].originMethod.data.nameDisplay
					: attributes?.[0].longName,
				color: isCityClustersDatasetsComparisonType
					? attributes?.[0].originMethod.data.color
					: attributes?.[0].color,
				period: attributes?.[0].period,
				name2: isCityClustersDatasetsComparisonType
					? null
					: attributes?.[0].name2, // if value is combined from 2 datasets
				longName2: isCityClustersDatasetsComparisonType
					? null
					: attributes?.[0].longName2, // if value is combined from 2 datasets
				color2: attributes?.[0].color2, // if value is combined from 2 datasets
				period2: attributes?.[0].period2, // if value is combined from 2 datasets
				currentPeriod: period,
				data: attributes.reduce(reduceAttributes, []),
			});
		});

		return data;
	} else if (features?.length > 1) {
		//Areas compare
		features.forEach((feature, index) => {
			const {attributeData, originalFeatureData} = feature;
			let color;
			if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
				color = distinctColours[index];
			} else {
				color = utils.getSelectedFeaturePrimaryColor(
					originalFeatureData?.key,
					selectionData,
				);
			}
			data.push({
				id: originalFeatureData?.key,
				name: isCityClustersDatasetsComparisonType
					? attributeData[0].originMethod.data.nameDisplay
					: selectorHelpers.getAreaName(originalFeatureData?.data),
				color: isCityClustersDatasetsComparisonType
					? attributeData[0].originMethod.data.color
					: color,
				data: attributeData,
				currentPeriod: period,
				period: attributeData?.[0].period,
			});
		});
		return data;
	} else if (features?.length === 1) {
		const attributeGroups = _groupBy(features[0].attributeData, 'name');

		_forIn(attributeGroups, (attributes, name) => {
			data.push({
				id: attributes?.[0].key,
				name,
				longName: attributes?.[0].longName,
				color: attributes?.[0].color,
				period: attributes?.[0].period,
				name2: attributes?.[0].name2, // if value is combined from 2 datasets
				longName2: attributes?.[0].longName2, // if value is combined from 2 datasets
				color2: attributes?.[0].color2, // if value is combined from 2 datasets
				period2: attributes?.[0].period2, // if value is combined from 2 datasets
				currentPeriod: period,
				data: attributes,
			});
		});
		return data;
	} else {
		return null;
	}
}

/**
 * Gets value and associated period
 * @param calculatedValue {Array|number}
 * @returns {{value, yearToShow}}
 */
function getValueAndAssociatedPeriod(calculatedValue) {
	let value, yearToShow;
	if (_isArray(calculatedValue)) {
		value = calculatedValue[0];
		yearToShow = calculatedValue[1];
	} else {
		value = calculatedValue;
	}

	return {value, yearToShow};
}

/**
 * Returns values and associated periods from time series in form of arrays
 * @param attributeTemplatesConfig {sourceColumnNameTemplates: Array, sourceColumnNameTemplate: string}
 * @param properties {GeoJSON.Feature.properties}
 * @param period {string} Period in format 1990/2010
 * @returns {values: Array, periods: Array}
 */
function getValuesAndYearsListFromTimeSeries(
	attributeTemplatesConfig,
	properties,
	period,
) {
	let valuesAndYears;
	if (attributeTemplatesConfig.sourceColumnNameTemplates) {
		valuesAndYears = attributeTemplatesConfig.sourceColumnNameTemplates.map(
			template =>
				selectorHelpers.getPropertyValuesByTemplateAndPeriod(
					properties,
					template,
					period,
				),
		);
	} else {
		valuesAndYears = selectorHelpers.getPropertyValuesByTemplateAndPeriod(
			properties,
			attributeTemplatesConfig.sourceColumnNameTemplate,
			period,
		);
	}

	if (valuesAndYears?.length) {
		let values, years;
		if (_isArray(valuesAndYears?.[0]?.[0])) {
			values = valuesAndYears?.map(value => value[0]);
			years = valuesAndYears?.[0]?.[1];
		} else {
			values = valuesAndYears?.[0];
			years = valuesAndYears?.[1];
		}

		return {
			values,
			years,
		};
	} else {
		return null;
	}
}

/**
 * @param properties {GeoJSON.Feature.properties}
 * @param normalizationColumnName {string}
 * @returns {number|string|null}
 */
function getNormalizationValue(normalizationColumnName, properties) {
	return normalizationColumnName && properties
		? properties[normalizationColumnName]
		: null;
}

/**
 * @param values {Array} List
 * @param periods {Array} List of associated years
 * @param normalizationValue {number}
 * @param calculationType {string} Which type of calculation choose
 * @param attributeType {string} Which type of attribute choose
 * @returns {{value, yearToShow}}
 */
function getValue(
	values,
	periods,
	normalizationValue,
	calculationType,
	attributeType,
) {
	const calculatedValue = calculations.getValue(
		values,
		calculationType,
		attributeType,
		normalizationValue,
		periods,
	);
	return getValueAndAssociatedPeriod(calculatedValue);
}

/**
 * Get parts per period
 * @param values {Array} List
 * @param periods {Array} List of associated years
 * @param normalizationValue {number}
 * @param calculationType {string} Which type of calculation choose
 * @param attributeType {string} Which type of attribute choose
 * @param period {string}
 * @param periodStep {number}
 */
function getPartsPerPeriod(
	values,
	periods,
	normalizationValue,
	calculationType,
	attributeType,
	period,
	periodStep,
) {
	if (periods?.length > 0) {
		const periodParts = period.split('/');
		const selectedStartYear = Number(periodParts[0]);
		const selectedEndYear = Number(periodParts[1]);
		const datasetStartYear = periods[0];
		const datasetEndYear = periods[periods.length - 1];
		const yearsArray = Array.from(
			{length: selectedEndYear - selectedStartYear + 1},
			(_, index) => selectedStartYear + index,
		);
		const valuesByPeriod = {};
		for (let p = 0; p <= periods?.length; p++) {
			// if indicator is based from multiple attributes
			if (_isArray(values?.[0])) {
				valuesByPeriod[periods[p]] = [];
				values.forEach(arrayOfValues => {
					valuesByPeriod[periods[p]].push(arrayOfValues[p]);
				});
			} else {
				valuesByPeriod[periods[p]] = values[p];
			}
		}

		const endYear =
			selectedEndYear < datasetEndYear ? selectedEndYear : datasetEndYear;
		const startYear =
			selectedStartYear > datasetStartYear
				? selectedStartYear
				: datasetStartYear;

		if (endYear - startYear >= periodStep) {
			const parts = [];
			for (let i = 0; i < yearsArray.length - 1; i += periodStep) {
				const partPeriodList = [];
				const partPeriodValues = [];
				for (let j = i; j <= i + periodStep; j++) {
					if (valuesByPeriod[yearsArray[j]]) {
						partPeriodList.push(yearsArray[j]);
					}
				}

				// if indicator is based from multiple attributes
				if (_isArray(values?.[0])) {
					values.forEach((arrayOfValues, index) => {
						const partPeriodValues2 = [];
						for (let j = i; j <= i + periodStep; j++) {
							if (valuesByPeriod[yearsArray[j]]) {
								partPeriodValues2.push(valuesByPeriod[yearsArray[j]][index]);
							}
						}
						partPeriodValues.push(partPeriodValues2);
					});
				} else {
					for (let j = i; j <= i + periodStep; j++) {
						if (valuesByPeriod[yearsArray[j]]) {
							partPeriodValues.push(valuesByPeriod[yearsArray[j]]);
						}
					}
				}

				if (partPeriodList?.length && partPeriodValues?.length) {
					const part = getValue(
						partPeriodValues,
						partPeriodList,
						normalizationValue,
						calculationType,
						attributeType,
					);
					parts.push({
						...part,
						period: `${yearsArray[i]}/${yearsArray[i + periodStep]}`,
						type: attributeType,
					});
				}
			}
			return parts;
		} else {
			return null;
		}
	} else {
		return null;
	}
}

export default {
	getIndicatorData,
	getActiveFeaturesByActiveAreaTreeKey,

	getAttributeValuesForActivePeriodByFeatureKey,
	getIndicatorDataForActivePeriod,
};
