import {createSelector} from 'reselect';
import {
	intersection as _intersection,
	includes as _includes,
	without as _without,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {
	coverageTagKey,
	tagBaseKey,
	tagExpertKey,
	themeTagKey,
	sdgTagKey,
	sdgIndicatorsOnlyTagKey,
} from '../../../constants/app';

const getDataSwitcherTags = createSelector(
	[CommonSelect.tags.getAllAsObject],
	tags => {
		if (tags) {
			return [tags[tagBaseKey], tags[tagExpertKey]];
		} else {
			return null;
		}
	},
);

const getActiveDataSwitcherOptionKey = createSelector(
	[CommonSelect.tags.getActiveKeys, getDataSwitcherTags],
	(activeKeys, availableTags) => {
		if (activeKeys && availableTags) {
			return _intersection(
				availableTags.map(tag => tag.key),
				activeKeys,
			)?.[0];
		} else {
			return null;
		}
	},
);

const getActiveExpertFilterKeys = createSelector(
	[CommonSelect.tags.getActiveKeys],
	activeKeys => {
		if (activeKeys) {
			return _without(activeKeys, tagBaseKey, tagExpertKey);
		} else {
			return null;
		}
	},
);

const getCategories = createSelector(
	[CommonSelect.tags.getAllAsObject],
	tags => {
		if (tags) {
			return [coverageTagKey, themeTagKey, sdgTagKey].map(key => tags[key]);
		} else {
			return null;
		}
	},
);

const getByTagKey = createSelector(
	[CommonSelect.tags.getAll, (state, tagKey) => tagKey],
	(tags, tagKey) => {
		if (tags?.length && tagKey) {
			return tags.filter(tag => tag.data.tags?.includes(tagKey));
		} else {
			return null;
		}
	},
);

const getCategoryByTagKey = createSelector(
	[CommonSelect.tags.getAllAsObject, (state, tagKey) => tagKey],
	(tags, tagKey) => {
		if (tags && tagKey) {
			const tag = tags[tagKey];
			const categoryKey = tag?.data.tags?.[0];
			if (categoryKey) {
				return tags[categoryKey];
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
);

const getActiveKeyByTagKey = createSelector(
	[CommonSelect.tags.getActiveKeys, getByTagKey],
	(activeKeys, availableTags) => {
		if (activeKeys && availableTags) {
			return _intersection(
				availableTags.map(tag => tag.key),
				activeKeys,
			)?.[0];
		} else {
			return null;
		}
	},
);

const getKeyByTagKeyFromGivenTags = createSelector(
	[
		(state, tagKeys) => tagKeys,
		(state, tagKeys, key) => getByTagKey(state, key),
	],
	(tagKeys, availableTags) => {
		if (tagKeys && availableTags) {
			return _intersection(
				availableTags.map(tag => tag.key),
				tagKeys,
			)?.[0];
		} else {
			return null;
		}
	},
);

const isSdgIndicatorsTagActive = createSelector(
	[CommonSelect.tags.getActiveKeys],
	tagKeys => {
		if (tagKeys?.length) {
			return _includes(tagKeys, sdgIndicatorsOnlyTagKey);
		} else {
			return null;
		}
	},
);

export default {
	getActiveDataSwitcherOptionKey,
	getDataSwitcherTags,

	getActiveExpertFilterKeys,

	getCategories,
	getCategoryByTagKey,
	getByTagKey,
	getActiveKeyByTagKey,
	getKeyByTagKeyFromGivenTags,
	isSdgIndicatorsTagActive,
};
