import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const layerKey = ownProps?.data?.layerKey || ownProps?.data?.key;
	let layerOpacity;

	if (ownProps?.datasetLayerKey) {
		layerOpacity = Select.unhab.layers.getLayerOpacityByDatasetLayerKey(
			state,
			ownProps.mapKey,
			ownProps.datasetLayerKey,
		);
	} else {
		layerOpacity = Select.unhab.layers.getLayerOpacity(
			state,
			ownProps.mapKey,
			layerKey,
			ownProps?.data?.layerTemplateKey,
		);
	}

	return {
		layerKey: layerKey,
		layerTemplateKey: ownProps?.data?.layerTemplateKey,
		opacity: layerOpacity,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (opacity, layerKey, layerTemplateKey) => {
			if (ownProps?.datasetLayerKey) {
				dispatch(
					Action.unhab.layers.setLayersOpacityByDatasetLayerKey(
						ownProps.mapKey,
						ownProps.datasetLayerKey,
						opacity,
					),
				);
			} else {
				dispatch(
					Action.unhab.layers.setLayerOpacity(
						ownProps.mapKey,
						layerKey,
						layerTemplateKey,
						opacity,
					),
				);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
