import PropTypes from 'prop-types';
import './style.scss';
import {isArray as _isArray} from 'lodash';

const MapLegendTitle = ({unit, children}) => {
	let name = <>{children}</>;
	if (_isArray(children)) {
		name = (
			<>
				<span>{children[0]}</span>
				<span>({children[1]})</span>
			</>
		);
	}

	return (
		<div className="ptr-MapLegendTitle">
			<div className="ptr-MapLegendTitle-name">{name}</div>
			{unit ? <span className="ptr-MapLegendTitle-unit">[{unit}]</span> : null}
		</div>
	);
};

MapLegendTitle.propTypes = {
	children: PropTypes.node,
	unit: PropTypes.string,
};

export default MapLegendTitle;
