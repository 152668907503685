import {Action as CommonAction} from '@gisatcz/ptr-state';
import Select from '../../Select';
import {
	areaComparisonType,
	benchmarkViewKey,
	reportViewKey,
} from '../../../constants/app';
import datasetSetsActions from '../datasetSets/actions';

function setActiveKeys(tagKeys) {
	return (dispatch, getState) => {
		const state = getState();
		dispatch(CommonAction.tags.setActiveKeys(tagKeys));

		// check if datasetSet set fits filter
		const viewKey = Select.views.getActiveKey(state);
		const isBenchmark = viewKey === benchmarkViewKey || reportViewKey;
		if (isBenchmark) {
			const activeSetKey = Select.unhab.datasetSets.getActiveKey(getState());
			const comparisonType =
				Select.unhab.benchmarkComparisonTypes.getActiveKey(getState());
			if (activeSetKey) {
				const availableSets =
					Select.unhab.datasetSets.getAllForActiveTags(getState());
				const activeAmongAvailable = availableSets.find(
					set => set.key === activeSetKey,
				);
				if (!activeAmongAvailable) {
					if (comparisonType === areaComparisonType) {
						dispatch(datasetSetsActions.setActive(null));
					} else {
						dispatch(datasetSetsActions.setActive(availableSets[0].key));
					}
				}
			}
		}
	};
}

export default {
	setActiveKeys,
};
