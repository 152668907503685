import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = state => {
	return {
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeDatasetKeys: Select.unhab.datasets.getActiveKeys(state),
		datasets: Select.unhab.datasets.getAllForActiveTags(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSave: activeDatasets => {
			dispatch(Action.unhab.datasets.setActiveKeys(activeDatasets));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
