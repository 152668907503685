import {Action as CommonAction, commonActions} from '@gisatcz/ptr-state';
import Select from './Select';
import router from './router/actions';
import unhab from './unhab/actions';
import xCubeLayers from './xCubeLayers/actions';
import xCubeFeatures from './xCubeFeatures/actions';
import xCubeTimeSeries from './xCubeTimeSeries/actions';
import providersActions from './unhab/providers/actions';
import config from '../config';
import {
	appKey,
	xCubeViewKey,
	defaultPeriodKey,
	defaultActiveCityClusterMetodKeys,
} from '../constants/app';
import views from '../data/metadata/views';
import attributes from '../data/metadata/attributes';
import attributSets from '../data/metadata/attributeSets/';
import auSelection from '../data/selections/auSelection';
import {
	benchmarkArea1Selection,
	benchmarkArea2Selection,
} from '../data/selections/benchmarkSelection';
import limassolAreasSelection from '../data/selections/limassolAreasSelection';
import ActionTypes from '../constants/ActionTypes';
import periods from '../data/metadata/periods';
import tags from '../data/metadata/tags';
import wsfDatasetLayers from '../data/layers/wsfDatasetLayers';
import ghsDatasetLayers from '../data/layers/ghsDatasetLayers';
import outlinesLayers from '../data/layers/outlinesLayers';

function init(path) {
	return dispatch => {
		// const config = getConfig(getAppEnvConfig());
		// dispatch(CommonAction.app.loadConfiguration());
		dispatch(CommonAction.app.updateLocalConfiguration(config));
		dispatch(CommonAction.app.setKey(appKey));
		dispatch(CommonAction.app.setBaseUrl(path));
		dispatch(CommonAction.views.add(views));
		dispatch(CommonAction.attributes.add(attributes));
		dispatch(CommonAction.attributeSets.add(attributSets));
		dispatch(CommonAction.tags.add(tags));
		dispatch(CommonAction.periods.add(periods));
		dispatch(CommonAction.periods.setActiveKey(defaultPeriodKey));
		dispatch(
			CommonAction.selections.add([
				auSelection,
				benchmarkArea1Selection,
				benchmarkArea2Selection,
				limassolAreasSelection,
			]),
		);
		dispatch(unhab.featuresForScatterPlot.use());
		dispatch(unhab.indicatorsForList.add());
		dispatch(providersActions.init());

		// TODO load from BE
		dispatch(
			commonActions.add(ActionTypes.AREAS.AREA_TREE_LEVELS)([
				{
					key: 'a53a54dd-8a0b-4e28-b7aa-aa566cd2ba47',
					data: {
						nameInternal: 'areaTreeLevel_GAUL_0',
						nameDisplay: 'Countries',
						level: 0,
						areaTreeKey: '79b65d72-9d4c-4959-a7fa-579c3a372406',
					},
				},
				{
					key: 'b8a2f8d0-41b6-4afc-ba3f-a0df21632975',
					data: {
						nameInternal: 'areaTreeLevel_GAUL_1',
						nameDisplay: 'Regions',
						level: 1,
						areaTreeKey: '79b65d72-9d4c-4959-a7fa-579c3a372406',
					},
				},
			]),
		);

		const isXCubeApp = Select.isXCubeApp();

		//temporary solution, later add on mount
		dispatch(unhab.cityClusterMethods.add());
		dispatch(
			unhab.cityClusterMethods.setActiveKeys(defaultActiveCityClusterMetodKeys),
		);
		dispatch(unhab.cityClusterMethodParameters.add());
		dispatch(unhab.udtStructure.add());

		// Due to performance issues
		const allLayerTemplateKeys = Object.values({
			...wsfDatasetLayers,
			...ghsDatasetLayers,
			...outlinesLayers,
		}).map(layer => layer.layerTemplateKey);
		dispatch(CommonAction.layerTemplates.useKeys(allLayerTemplateKeys));

		// redirect if xCubeApp
		if (isXCubeApp) {
			dispatch(CommonAction.views.setActiveKey(xCubeViewKey));
			dispatch(xCubeFeatures.addPublicGreenAreas());
		}
	};
}

export default {
	...CommonAction,
	init,

	unhab,
	xCubeLayers,
	xCubeFeatures,
	xCubeTimeSeries,
	router,
};
