import {commonSelectors, Select as CommonSelect} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {difference as _difference} from 'lodash';

const getSubstate = state => state.unhab.datasetSets;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const getDatasetKeysByKey = createSelector([getByKey], datasetSet => {
	if (!datasetSet) {
		return [];
	}
	return datasetSet.data?.datasets;
});

const getAllForActiveTags = createSelector(
	[getAll, CommonSelect.tags.getActiveKeys],
	(sets, activeTags) => {
		if (sets?.length) {
			if (activeTags?.length) {
				return sets.filter(
					set => _difference(activeTags, set.data.tags).length === 0,
				);
			} else {
				return sets;
			}
		} else {
			return [];
		}
	},
);

export default {
	getSubstate,

	getAll,
	getAllForActiveTags,
	getAllAsObject,
	getActiveKey,
	getActive,

	getDatasetKeysByKey,
};
