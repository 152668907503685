import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useState, forwardRef, useEffect} from 'react';
import ModalWindow from '../../ModalWindow';
import MapExportDialog from '../../MapExportDialog';
import MapForReport from '../../MapForReport';
import classnames from 'classnames';
import './style.scss';

const ExportMapControl = forwardRef(
	({condensedControls, mapKey, hide, printInProgress}, deckRef) => {
		const [isDownloadOpen, setDownloadOpen] = useState(null);
		const [mapImageData, setMapImageData] = useState(null);

		useEffect(() => {
			if (hide && printInProgress) {
				const base64Image = deckRef?.current[mapKey]?.base64Image;
				if (base64Image) {
					setMapImageData(base64Image);
				}
			} else if (mapImageData) {
				setMapImageData(null);
			}
		}, [printInProgress]);

		const handleClick = () => {
			if (deckRef?.current[mapKey]?.onAfterRender == true) {
				const base64Image = deckRef?.current[mapKey]?.base64Image;
				setMapImageData(base64Image);
				setDownloadOpen(true);
			} else {
				console.log(
					'Error: map has not been rendered properly! DeckGl onAfterRender has not been triggered',
				);
			}
		};

		const buttonClasses = classnames('unhab-MapExportControl-button', {
			'is-hidden': hide,
		});

		return hide && mapImageData ? (
			<MapForReport mapImageData={mapImageData} mapKey={mapKey} />
		) : (
			<>
				<Button
					className={buttonClasses}
					onClick={handleClick}
					floating
					small
					icon="ri-download"
				>
					{condensedControls ? '' : 'Export'}
				</Button>
				<ModalWindow
					isOpen={isDownloadOpen || false}
					onClose={() => setDownloadOpen(false)}
					className="unhab-MapExportModal"
				>
					<MapExportDialog mapImageData={mapImageData} mapKey={mapKey} />
				</ModalWindow>
			</>
		);
	},
);

ExportMapControl.propTypes = {
	condensedControls: PropTypes.bool,
	hide: PropTypes.bool,
	mapKey: PropTypes.string,
	printInProgress: PropTypes.bool,
};

ExportMapControl.displayName = 'DownloadMapControl';

export default ExportMapControl;
