import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import {reportViewKey} from '../../../../constants/app';

const mapStateToProps = state => {
	return {
		printInProgress: Select.components.get(state, 'Report', 'printInProgress'),
		hide: Select.views.getActiveKey(state) === reportViewKey,
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(Presentation);
