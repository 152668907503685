import PropTypes from 'prop-types';
import {
	compact as _compact,
	groupBy as _groupBy,
	flatten as _flatten,
	forIn as _forIn,
} from 'lodash';
import {Icon} from '@gisatcz/ptr-atoms';
import Box, {BoxSubtitle, BoxTitle} from '../Box';
import ColorDotLabel from '../ColorDotLabel';

import './style.scss';
import classnames from 'classnames';
import utils from '../../../utils';
import {useEffect} from 'react';

/**
 * Render names in the Indicator Box component.
 *
 * @param {Object} children - The children components to be rendered as names.
 * @return {JSX.Element} - The JSX element representing the row.
 */
const IndicatorBoxNames = ({children}) => {
	return <div className="unhab-IndicatorBoxNames">{children}</div>;
};

IndicatorBoxNames.propTypes = {
	children: PropTypes.node,
};

/**
 * Renders a component that displays the values inside an indicator box.
 *
 * @param {Object} children - The content to be displayed inside the indicator box.
 * @return {JSX.Element} - The rendered indicator box component.
 */
const IndicatorBoxValues = ({children}) => {
	return <div className="unhab-IndicatorBoxValues">{children}</div>;
};

IndicatorBoxValues.propTypes = {
	children: PropTypes.node,
};

/**
 * Renders a component that displays the value inside an indicator box.
 *
 * @param {number} originalValue - The original value.
 * @param {Object} valueFormat
 * @param {Object} valueFormat.decimals - The number of decimals to be displayed.
 * @param {Object} valueFormat.multiplier - The multiplier to be applied to the value.
 * @param {string} unit - The unit of the value.
 * @param {number} yearToShow - The period connected with the value
 * @param {boolean} secondary
 * @return {JSX.Element} - The rendered indicator box component.
 */
const IndicatorBoxValue = ({
	originalValue,
	valueFormat,
	unit,
	yearToShow,
	secondary,
}) => {
	let valueContent = '-';
	if (originalValue || originalValue === 0) {
		const value = originalValue * (valueFormat?.multiplier || 1);
		const wholeNumber = Math.floor(value);
		const decimal = utils.getDigitsAfterDecimal(
			value,
			valueFormat?.decimals || 2,
		);
		valueContent = (
			<>
				{wholeNumber.toLocaleString('en-US')}
				{decimal ? <span>{`.${decimal}`}</span> : null}
			</>
		);
	}

	const valueClasses = classnames('unhab-IndicatorBoxValue', {
		'is-secondary': secondary,
	});

	return (
		<div className={valueClasses}>
			{yearToShow ? (
				<span className="unhab-IndicatorBoxPeriod">{yearToShow}:</span>
			) : null}
			<div>{valueContent}</div>
			{unit ? <span>{unit}</span> : null}
		</div>
	);
};

IndicatorBoxValue.propTypes = {
	originalValue: PropTypes.number,
	valueFormat: PropTypes.object,
	unit: PropTypes.string,
	yearToShow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	secondary: PropTypes.bool,
};

/**
 * Calculates the valid period range based on the provided dataset period and current period.
 *
 * @param {Object} periodInfo - The input object containing datasetPeriod and currentPeriod.
 * @param {string} periodInfo.period - The dataset period in the format "start/end".
 * @param {string} periodInfo.currentPeriod - The current period in the format "start/end".
 * @returns {string|null} - The valid period range in the format "start-end" or null if no dataset period is provided.
 */
const PeriodInfo = ({period, currentPeriod}) => {
	if (period) {
		let [start, end] = period.split('/');
		let content = start && end ? `(${start}-${end})` : '';
		if (currentPeriod && start && end) {
			const [currentStart, currentEnd] = currentPeriod.split('/');
			if (currentStart > start) {
				start = currentStart;
			}
			if (currentEnd < end) {
				end = currentEnd;
			}

			if (currentStart === end) {
				content = end;
			} else if (currentEnd === start) {
				content = start;
			} else if (currentStart > end || currentEnd < start) {
				content = `-`;
			} else {
				content = `${start}-${end}`;
			}
		}
		return <>{content}</>;
	} else {
		return null;
	}
};

PeriodInfo.propTypes = {
	period: PropTypes.string,
	currentPeriod: PropTypes.string,
};

/**
 * Data column header item
 * @param type {string}
 * @param description {string}
 * @returns {JSX.Element|null}
 */
const IndicatorBoxHeaderItem = ({type, description}) => {
	switch (type) {
		case 'period':
			return (
				<div className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem period">
					<Icon icon="calendar" />
					<div className="unhab-IndicatorBoxHeaderItem-name">Period</div>
				</div>
			);
		case 'inhabitants':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem absolute"
					title={description || ''}
				>
					<Icon icon="ri-user" />
					<div className="unhab-IndicatorBoxHeaderItem-name">Inhabitants</div>
				</div>
			);
		case 'sqkm':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem absolute"
					title={description || ''}
				>
					<Icon icon="ri-fullscreen" />
					<div className="unhab-IndicatorBoxHeaderItem-name">Area (sqkm)</div>
				</div>
			);
		case 'sqm_inhabitant':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem relative"
					title={description || ''}
				>
					<div className="unhab-IndicatorBoxHeaderItem-name">{'sqm/inh.'}</div>
				</div>
			);
		case 'inhabitant/sqkm':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem relative"
					title={description || ''}
				>
					<div className="unhab-IndicatorBoxHeaderItem-name">{'inh./sqkm'}</div>
				</div>
			);
		case 'percentage':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem relative"
					title={description || ''}
				>
					<div className="unhab-IndicatorBoxHeaderItem-name">%</div>
				</div>
			);
		case 'rate':
			return (
				<div
					className="unhab-IndicatorBoxHeaderItem unhab-IndicatorBoxItem relative"
					title={description || ''}
				>
					<div className="unhab-IndicatorBoxHeaderItem-name">Rate</div>
				</div>
			);
		default:
			return (
				<div className="unhab-IndicatorBoxHeaderItem" title={description || ''}>
					<div className="unhab-IndicatorBoxHeaderItem-name">{type}</div>
				</div>
			);
	}
};

IndicatorBoxHeaderItem.propTypes = {
	type: PropTypes.string,
	description: PropTypes.string,
};

/**
 * @param componentKey {string}
 * @param title {string}
 * @param subtitle {string}
 * @param columns {Array} List of data columns
 * @param selectable {boolean}
 * @param selected {boolean}
 * @param onSelectedChange {function}
 * @returns {JSX.Element}
 * @constructor
 */
const IndicatorBoxHeader = ({
	title,
	subtitle,
	columns,
	selectable,
	selected,
	onSelectedChange,
}) => {
	return (
		<div className="unhab-IndicatorBoxHeader">
			<div className="unhab-IndicatorBoxHeader-title">
				{selectable ? (
					<input
						checked={selected}
						type="checkbox"
						className="unhab-IndicatorBoxHeader-checkbox"
						onChange={() => onSelectedChange(!selected)}
					/>
				) : null}
				<BoxTitle
					onClick={selectable ? () => onSelectedChange(!selected) : null}
				>
					{title} {subtitle ? <BoxSubtitle>({subtitle})</BoxSubtitle> : null}
				</BoxTitle>
			</div>
			{columns?.map(({type, description}) => {
				return (
					<IndicatorBoxHeaderItem
						key={type}
						type={type}
						description={description}
					/>
				);
			})}
		</div>
	);
};

IndicatorBoxHeader.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onSelectedChange: PropTypes.func,
	columns: PropTypes.array,
};

/**
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 */
const IndicatorBoxBody = ({children}) => {
	return <div className="unhab-IndicatorBoxBody">{children}</div>;
};

IndicatorBoxBody.propTypes = {
	children: PropTypes.node,
};

/**
 * Render a row in the Indicator Box component.
 *
 * @param {Object} children - The children components to be rendered in the row.
 * @return {JSX.Element} - The JSX element representing the row.
 */
const IndicatorBoxBodyRow = ({children}) => {
	return <div className="unhab-IndicatorBoxBodyRow">{children}</div>;
};

IndicatorBoxBodyRow.propTypes = {
	children: PropTypes.node,
};

/**
 * @param data {Array} row data
 * @param valueFormat {Object}
 * @param {Object} valueFormat.decimals - The number of decimals to be displayed.
 * @param {Object} valueFormat.multiplier - The multiplier to be applied to the value.
 * @returns {JSX.Element}
 */
const IndicatorBoxBodyRowDetail = ({data, valueFormat}) => {
	const partsPerPeriod = _compact(
		data?.map(attribute => attribute?.partsPerPeriod),
	);
	if (partsPerPeriod?.length) {
		const allItems = _flatten(partsPerPeriod);
		const rows = _groupBy(allItems, 'period');
		const content = [];
		_forIn(rows, (rowData, period) => {
			content.push(
				<div key={period} className="unhab-IndicatorBoxBodyRowDetail-row">
					<IndicatorBoxNames />
					<IndicatorBoxItem secondary type="period">{`${period
						.split('/')
						.join('-')}`}</IndicatorBoxItem>
					{rowData?.map((value, index) => {
						return (
							<IndicatorBoxItem
								key={value?.originMethod?.parametersKeys || index}
								type={value.type}
							>
								<IndicatorBoxValue
									secondary
									originalValue={value.value}
									valueFormat={valueFormat}
									yearToShow={index === 0 && value.yearToShow}
								/>
							</IndicatorBoxItem>
						);
					})}
				</div>,
			);
		});
		return <div className="unhab-IndicatorBoxBodyRowDetail">{content}</div>;
	} else {
		return null;
	}
};

IndicatorBoxBodyRowDetail.propTypes = {
	data: PropTypes.array,
	valueFormat: PropTypes.object,
};

/**
 * Data column item
 * @param type {string}
 * @param secondary {boolean}
 * @param children {JSX.Element}
 * @returns {JSX.Element|null}
 */
const IndicatorBoxItem = ({type, secondary, children}) => {
	const className = classnames('unhab-IndicatorBoxItem', {
		'is-secondary': secondary,
		period: type === 'period',
		absolute: type === 'absolute',
		relative: type === 'relative',
	});

	return <div className={className}>{children}</div>;
};

IndicatorBoxItem.propTypes = {
	type: PropTypes.string,
	secondary: PropTypes.bool,
	children: PropTypes.node,
};

/**
 * Renders an IndicatorBox component with the given title.
 *
 * @param {string} componentKey
 * @param {string} title - The title of the IndicatorBox.
 * @param {string} subtitle - The subtitle of the IndicatorBox.
 * @param {object} configuration - The configuration object for the IndicatorBox.
 * @param {Array} data - The data to be displayed in the IndicatorBox.
 * @param {boolean} report - True if component is part of the report
 * @param {boolean} selectable - True if component could be selected to report
 * @param {boolean} selected - True if component is selected to report
 * @param {func} onMount
 * @param {func} onSelectedChange - The function to be called when the component is selected/unselected to/from report
 * @return {JSX.Element} The rendered IndicatorBox component.
 */
const IndicatorBox = ({
	title,
	subtitle,
	configuration,
	data,
	report,
	selectable,
	selected,
	onMount,
	onUnmount,
	onSelectedChange,
}) => {
	const boxClasses = classnames('unhab-IndicatorBox', {
		'is-report': report,
	});

	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return data && (!report || (report && selected)) ? (
		<Box className={boxClasses}>
			<IndicatorBoxHeader
				selectable={selectable}
				selected={selected}
				onSelectedChange={onSelectedChange}
				title={title}
				subtitle={subtitle}
				columns={configuration.columns}
			/>
			<IndicatorBoxBody>
				{data.map(row => {
					return (
						<IndicatorBoxBodyRow
							key={`${row?.data[0]?.originMethod?.parametersKeys || row.name}_${row.color}`}
						>
							<div className="unhab-IndicatorBoxBodyRow-main">
								<IndicatorBoxNames>
									<ColorDotLabel color={row.color}>
										<div
											className="unhab-IndicatorBox-itemName"
											title={row.name}
										>
											{report && row.longName ? row.longName : row.name}
										</div>
									</ColorDotLabel>
									{row.name2 ? (
										<ColorDotLabel color={row.color2}>
											<div
												className="unhab-IndicatorBox-itemName"
												title={row.name2}
											>
												{report && row.longName2 ? row.longName2 : row.name2}
											</div>
										</ColorDotLabel>
									) : null}
								</IndicatorBoxNames>
								{configuration.columns?.[0].type === 'period' ? (
									<IndicatorBoxItem type="period">
										<PeriodInfo
											period={row.period}
											currentPeriod={row.currentPeriod}
										/>
									</IndicatorBoxItem>
								) : null}
								{row.data?.map((value, index) => {
									return value.value ||
										value.value === 0 ||
										Number.isNaN(value.value) ? (
										<IndicatorBoxItem key={index} type={value.type}>
											<IndicatorBoxValue
												originalValue={value.value}
												valueFormat={configuration.valueFormat}
												yearToShow={index === 0 && value.yearToShow}
											/>
										</IndicatorBoxItem>
									) : index === data.length - 1 ? (
										// fix for more data in cityclusters
										<IndicatorBoxItem key={index} type={value.type}>
											<IndicatorBoxValue />
										</IndicatorBoxItem>
									) : null;
								})}
							</div>
							<IndicatorBoxBodyRowDetail
								data={row.data}
								valueFormat={configuration.valueFormat}
							/>
						</IndicatorBoxBodyRow>
					);
				})}
			</IndicatorBoxBody>
		</Box>
	) : null;
};

IndicatorBox.propTypes = {
	data: PropTypes.array,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	configuration: PropTypes.object,
	report: PropTypes.bool,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	onSelectedChange: PropTypes.func,
};

export default IndicatorBox;
