import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Selectors from '../../../state/Select';
import Actions from '../../../state/Action';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	const activeSetKey = Selectors.maps.getActiveSetKey(state);
	const activeMapSet = Selectors.maps.getMapSetByKey(state, activeSetKey);
	return {
		activeMapSet,
		mode: Selectors.components.get(state, 'Maps', 'mode'),
		unlocked: Selectors.components.get(state, 'Maps', 'lock') === false,
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
		horizontalMaps: Select.components.get(state, 'App', 'horizontalMaps'),
		displayTooltipsInMap: Select.components.get(
			state,
			'App',
			'displayTooltipsInMap',
		),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			updateView: (update, mapKey) => {
				dispatch(Actions.maps.updateMapAndSetView(mapKey, update));
			},
			onLayerClick: (mapKey, layerKey, selectedFeatureKeys) => {
				dispatch(
					Actions.unhab.layers.setLayerSelectedFeatureKeys(
						mapKey,
						layerKey,
						selectedFeatureKeys,
					),
				);
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
