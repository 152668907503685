import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		active: Select.unhab.layers.hasIndicatorLayerJustOutlines(
			state,
			ownProps.mapKey,
			ownProps.layerKey,
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: active => {
			dispatch(
				Action.unhab.layers.setIndicatorLayerJustOutlines(
					ownProps.mapKey,
					ownProps.layerKey,
					active,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
