import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	byKey: {},
	activeKeys: [],
	activeKey: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.ADD:
			return commonReducers.add(state, action);
		case ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.SET_ACTIVE_KEYS:
			return commonReducers.setActiveMultiple(state, action);
		default:
			return state;
	}
};
