export default {
	componentKey: "urbanArea_2019",
	title: 'Total area of urban extent',
	configuration: {
		attributes: [
			'be396866-7981-4c2c-a90a-f9b23ce9e158', // Urban area, total, absolute
			'b0c2f72d-3977-46a9-bad4-983e7af73e2e', // Urban area, total, relative
		],
		columns: [
			{
				type: 'sqkm',
				description: 'Indicator description',
			},
			{
				type: 'percentage',
				description: 'Indicator 2 description',
			},
		],
	},
};
