import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {MapCoordinatesInfo} from '../../../../constants/app';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, MapCoordinatesInfo, 'active'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChange: active => {
			dispatch(Action.components.set(MapCoordinatesInfo, 'active', active));
			dispatch(Action.components.set(MapCoordinatesInfo, 'coordinates', null));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
