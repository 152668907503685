import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		models: Select.unhab.tags.getByTagKey(state, ownProps.categoryTagKey),
		activeKey: Select.unhab.tags.getKeyByTagKeyFromGivenTags(
			state,
			ownProps.activeTags,
			ownProps.categoryTagKey,
		),
	};
};

export default connect(mapStateToProps)(Presentation);
