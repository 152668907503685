import SidePanel, {
	SidePanelBody,
	SidePanelHeader,
	SidePanelHeaderItem,
} from '../../../common/SidePanel';
import './style.scss';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import AnalyticsPanelStatistics from '../../../common/AnalyticsPanelStatistics';
import PeriodSelector from '../../../common/PeriodSelector';
import SelectedDatasetsControl from '../../../common/SelectedDatasetsControl';
import ExplorerSelectedFeatureControl from './ExplorerSelectedFeatureControl';
import ExplorerSelectedFeatureLabel from './ExplorerSelectedFeatureControl/ExplorerSelectedFeatureLabel';
import ExplorerChangeAreaDialog from './ExplorerSelectedFeatureControl/ExplorerChangeAreaDialog';
import ChangeDatasetDialog from '../../../common/ChangeDatasetDialog';

const AnalyticsPanel = ({
	defaultOpen,
	open,
	setOpen,
	selectedFeatureKeys,
	selectedDatasetKeys,
	onSelectedFeatureKeysChange,
}) => {
	useEffect(() => {
		if (setOpen && defaultOpen) {
			setOpen(true);
		}
	}, []);

	useEffect(() => {
		if (!open && selectedFeatureKeys) {
			setOpen(true);
		}
		onSelectedFeatureKeysChange();
	}, [selectedFeatureKeys]);

	return (
		<SidePanel open={open} setOpen={setOpen} className="unhab-AnalyticsPanel">
			<SidePanelHeader>
				<SidePanelHeaderItem title="Areas">
					<ExplorerSelectedFeatureControl
						selectedDatasetKeys={selectedDatasetKeys}
						FeatureLabel={ExplorerSelectedFeatureLabel}
						ChangeAreaDialog={ExplorerChangeAreaDialog}
					/>
				</SidePanelHeaderItem>
				<SidePanelHeaderItem title="Datasets">
					<SelectedDatasetsControl ChangeDatasetDialog={ChangeDatasetDialog} />
				</SidePanelHeaderItem>
				<SidePanelHeaderItem title="Period">
					<PeriodSelector />
				</SidePanelHeaderItem>
			</SidePanelHeader>
			<SidePanelBody>
				<AnalyticsPanelStatistics />
			</SidePanelBody>
		</SidePanel>
	);
};

AnalyticsPanel.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	defaultOpen: PropTypes.bool,
	selectedFeatureKeys: PropTypes.array,
	selectedDatasetKeys: PropTypes.array,
	onSelectedFeatureKeysChange: PropTypes.func,
};

export default AnalyticsPanel;
