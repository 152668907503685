import PropTypes from 'prop-types';
import './style.scss';
import Checkbox from '../../../../../Checkbox';

const RemoveLayerControl = ({layerVisible, handleLayer}) => {
	return (
		<Checkbox
			checked={layerVisible}
			onChange={() => handleLayer(!layerVisible)}
		/>
	);
};

RemoveLayerControl.propTypes = {
	layerVisible: PropTypes.bool,
	handleLayer: PropTypes.func,
};

export default RemoveLayerControl;
