import styles from '../../data/transformed/styles/merged.json';
import highlightedBorderOutlines from '../data/styles/highlightedBorderOutlines';

/**
 * Get style from based on key
 * @param styleKey
 * @returns {Object}
 */
const getByKey = styleKey => {
	const style = styles[styleKey];
	if (style) {
		return {
			rules: [
				{
					styles: [
						...style.rules[0].styles,
						highlightedBorderOutlines.rules[0].styles[1],
					],
				},
			],
		};
	} else {
		console.warn(`No style found for styleKey ${styleKey}`);
	}
};

export default {
	getByKey,
};
