import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import './style.scss';

const HeaderTool = ({icon, onChange, active, disabled, tooltip}) => {
	return (
		<Button
			primary={active}
			invisible
			small
			icon={icon}
			circular
			disabled={disabled}
			onClick={() => onChange(!active)}
			className="unhab-HeaderTool"
			tooltip={tooltip}
		/>
	);
};

HeaderTool.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
	tooltip: PropTypes.object,
};

export default HeaderTool;
