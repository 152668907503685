import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useRef} from 'react';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';

import {DeckGlMap, MapSet, PresentationMap} from '@gisatcz/ptr-maps';
import {connects} from '@gisatcz/ptr-state';
import MapWrapper from './MapWrapper';
import CompareMapsControl from './CompareMapsControl';
import LockMapsControl from './LockMapsControl';
import MapTooltip from './MapTooltip';
// import {MAX_MAPS_IN_MAP_SET} from '../../../constants/app';
import './style.scss';
import MapComponentsGroup from './MapComponentsGroup';
import MapContainer from './MapContainer';
import KeepScaleControl from './KeepScaleControl';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const Map = MapContainer(PresentationMap);

const Tooltip = ({active, ...props}) => {
	return active ? <MapTooltip {...props}></MapTooltip> : null;
};

Tooltip.propTypes = {
	mapKey: PropTypes.string,
	active: PropTypes.bool,
};

const Maps = ({
	activeMapSet,
	mode,
	updateView,
	unlocked,
	condensedControls,
	horizontalMaps,
	displayTooltipsInMap,
	onLayerClick,
}) => {
	const mapRef = useRef({});
	const mapClasses = classnames('unhab-Map', {
		'is-horizontal': horizontalMaps && activeMapSet?.maps?.length > 1,
	});

	const toolsWrapperClasses = classnames('map-compare-tools-wrapper', {
		'is-horizontal': horizontalMaps && mode !== 'compare',
	});

	const compareClasses = classnames('unhab-CompareMapsControl-wrapper', {
		'is-condensed': condensedControls,
		'is-horizontal': horizontalMaps && mode !== 'compare',
	});

	const lockClasses = classnames('unhab-LockMapsControl-wrapper', {
		'is-condensed': condensedControls,
		'is-horizontal': horizontalMaps && mode !== 'compare',
	});

	const scaleClasses = classnames('unhab-KeepScaleControl-wrapper', {
		'is-condensed': condensedControls,
		'is-horizontal': horizontalMaps && mode !== 'compare',
	});

	return (
		<div className={mapClasses}>
			{mode === 'compare' ? (
				<ReactCompareSlider
					onlyHandleDraggable
					handle={
						<>
							<ReactCompareSliderHandle
								buttonStyle={{position: 'absolute', top: 'calc(50% - 30px)'}}
							/>
							<div className={toolsWrapperClasses}>
								<MapComponentsGroup className={compareClasses}>
									<CompareMapsControl
										mapSetKey={activeMapSet?.key}
										key={'CompareMapsControl'}
									/>
								</MapComponentsGroup>
							</div>
						</>
					}
					className="unhab-CompareSlider"
					itemOne={
						<Map
							ref={mapRef}
							onLayerClick={onLayerClick}
							wrapper={MapWrapper}
							wrapperProps={{
								updateView,
								mapSetKey: activeMapSet?.key,
							}}
							mapComponent={DeckGlMap}
							stateMapKey={activeMapSet?.maps[0]}
							Tooltip={props => (
								<Tooltip
									{...props}
									mapKey={activeMapSet?.maps[0].key}
									active={displayTooltipsInMap}
								/>
							)}
							tooltipProps={{}}
						/>
					}
					itemTwo={
						<Map
							ref={mapRef}
							onLayerClick={onLayerClick}
							wrapper={MapWrapper}
							wrapperProps={{
								updateView,
								mapSetKey: activeMapSet?.key,
							}}
							mapComponent={DeckGlMap}
							stateMapKey={activeMapSet?.maps[1]}
							Tooltip={props => (
								<Tooltip
									{...props}
									mapKey={activeMapSet?.maps[1].key}
									active={displayTooltipsInMap}
								/>
							)}
							tooltipProps={{}}
						/>
					}
				></ReactCompareSlider>
			) : (
				<ConnectedMapSet
					ref={mapRef}
					onLayerClick={onLayerClick}
					stateMapSetKey={activeMapSet?.key}
					mapComponent={DeckGlMap}
					connectedMapComponent={ConnectedMap}
					wrapper={MapWrapper}
					wrapperProps={{
						updateView,
						mapSetKey: activeMapSet?.key,
					}}
					Tooltip={props => (
						<Tooltip {...props} active={displayTooltipsInMap} />
					)}
					tooltipProps={
						{
							// 	componentsByLayer,
						}
					}
				>
					{activeMapSet?.maps?.length === 2 ? (
						<div className={toolsWrapperClasses}>
							<MapComponentsGroup className={compareClasses}>
								<CompareMapsControl
									mapSetKey={activeMapSet?.key}
									disabled={unlocked}
								/>
							</MapComponentsGroup>
							<MapComponentsGroup className={lockClasses}>
								<LockMapsControl mapSetKey={activeMapSet?.key} />
							</MapComponentsGroup>
							<MapComponentsGroup className={scaleClasses}>
								<KeepScaleControl mapSetKey={activeMapSet?.key} />
							</MapComponentsGroup>
						</div>
					) : null}
				</ConnectedMapSet>
			)}
		</div>
	);
};

Maps.propTypes = {
	mode: PropTypes.any,
	activeMapSet: PropTypes.object,
	updateView: PropTypes.func,
	unlocked: PropTypes.bool,
	gridArea: PropTypes.string,
	onLayerClick: PropTypes.func,
	condensedControls: PropTypes.bool,
	horizontalMaps: PropTypes.bool,
	displayTooltipsInMap: PropTypes.bool,
};

export default Maps;
