import PropTypes from 'prop-types';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const ReportTitle = ({value, onValueChange}) => {
	return (
		<div className="unhab-EditableText unhab-ReportTitle">
			<input
				placeholder={'Report'}
				type="text"
				onChange={e => onValueChange(e.target.value)}
				value={value || ''}
			/>
			<Icon icon="edit" />
		</div>
	);
};

ReportTitle.propTypes = {
	value: PropTypes.string,
	onValueChange: PropTypes.func,
};

export default ReportTitle;
