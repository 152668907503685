import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import {isEqual as _isEqual, isEmpty as _isEmpty} from 'lodash';
import Form from '../Form/Form';
import FormFooter from '../Form/FormFooter';
import MethodSelect from '../MethodSelect/presentation';
import './style.scss';
import {stateManagement} from '@gisatcz/ptr-utils';
import {areaComparisonType} from '../../../constants/app';

const ChangeMethodDialog = ({
	activeSelectionKeys,
	activeMethodKeys,
	activeParameters,
	comparisonType,
	allowMultiple,
	methods,
	onClose,
	onSave,
	parameters,
}) => {
	const [activeMethods, setMethods] = useState(activeMethodKeys);
	const [activeLocalParameters, setActiveLocalParameters] = useState({});

	const saveSettings = () => {
		onSave(activeMethods, activeLocalParameters);
		onClose();
	};
	const activeLocalParametersChanged = Object.values(
		activeLocalParameters,
	).some(a => !_isEmpty(a));

	const changed = [
		!_isEqual(activeMethods, activeMethodKeys),
		activeLocalParametersChanged,
	].some(a => a);

	const onActiveLocalParametersChanged = (
		mapIndex,
		methodsKeys,
		newParameter,
	) => {
		const updated = {...activeLocalParameters};

		const activeParameter = activeParameters[mapIndex]?.[newParameter.key];
		const sameAsActiveParameter = activeParameter
			? activeParameter.data.value == newParameter.value
			: false;

		const parameter = activeParameter
			? null
			: parameters.find(p => p.key === newParameter.key);
		const sameAsParameterDefault = parameter
			? parameter.data.defaultValue == newParameter.value
			: false;

		if (sameAsActiveParameter || sameAsParameterDefault) {
			updated[mapIndex] = stateManagement.removeItemByKey(
				updated[mapIndex],
				newParameter.key,
			);
		} else {
			updated[mapIndex] = {
				...updated[mapIndex],
				[newParameter.key]: {
					key: newParameter.key,
					data: {
						value: newParameter.value,
					},
				},
			};
		}

		setActiveLocalParameters(updated);
	};

	const restartParameters = mapIndex => {
		const updated = {
			...activeLocalParameters,
			[mapIndex]: {},
		};
		setActiveLocalParameters(updated);
	};
	return (
		<div className="unhab-ChangeMethodDialog">
			<h2>Set method</h2>
			<Form>
				<MethodSelect
					large
					activeParameters={activeParameters[0]}
					forceSingle={!allowMultiple}
					activeKeys={[activeMethods[0]]}
					models={methods}
					onActiveMethodChanged={key => {
						setMethods([
							...key,
							...(comparisonType === areaComparisonType
								? []
								: [activeMethods[1]]),
						]);
						restartParameters(0);
					}}
					onActiveParametersChanged={(methodsKeys, parameters) =>
						onActiveLocalParametersChanged(0, methodsKeys, parameters)
					}
					activeSelectionKeys={activeSelectionKeys}
				/>
				<>
					{comparisonType === areaComparisonType ? null : (
						<MethodSelect
							large
							activeParameters={activeParameters[1]}
							forceSingle={!allowMultiple}
							activeKeys={[activeMethods[1]]}
							models={methods}
							onActiveMethodChanged={key => {
								setMethods([activeMethods[0], ...key]);
								restartParameters(1);
							}}
							onActiveParametersChanged={(methodsKeys, parameters) =>
								onActiveLocalParametersChanged(1, methodsKeys, parameters)
							}
							activeSelectionKeys={activeSelectionKeys}
						/>
					)}
				</>
			</Form>
			<FormFooter>
				<Button onClick={saveSettings} primary disabled={!changed}>
					{'Save & close'}
				</Button>
				<Button onClick={onClose} primary>
					{'Close'}
				</Button>
			</FormFooter>
		</div>
	);
};

ChangeMethodDialog.propTypes = {
	activeSelectionKeys: PropTypes.array,
	activeMethodKeys: PropTypes.array,
	activeParameters: PropTypes.array,
	methods: PropTypes.array,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	allowMultiple: PropTypes.bool,
	comparisonType: PropTypes.string,
	parameters: PropTypes.array,
};

export default ChangeMethodDialog;
