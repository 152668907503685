import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useRef, useState, cloneElement} from 'react';
import classnames from 'classnames';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import './style.scss';
import Popover from '../../Popover/Popover';
import MapConfigurationControlLabel from './MapConfigurationControlLabel';

const MapConfigurationControl = ({
	detail,
	horizontalPosition,
	verticalPosition,
	marginBottom,
	icon,
	name,
	children,
	closeContentOnFocusOut = true,
	hide,
}) => {
	const wrapperEl = useRef(null);
	const [open, setOpen] = useState(false);
	const classes = classnames('ptr-MapConfigurationControl', {
		'is-right': horizontalPosition === 'right',
		'is-left': horizontalPosition === 'left',
		'is-bottom': verticalPosition === 'bottom',
	});

	useOnClickOutside(
		wrapperEl,
		() => {
			setOpen(false);
		},
		!closeContentOnFocusOut,
	);

	return (
		<div className={classes} ref={wrapperEl} style={{marginBottom}}>
			<div className="ptr-MapConfigurationControl-header">
				<Button
					small
					floating
					icon={icon}
					primary={open}
					onClick={() => setOpen(!open)}
				>
					{name}
				</Button>
				{detail && !hide ? (
					<MapConfigurationControlLabel
						isRight={horizontalPosition === 'right'}
						data={detail}
					/>
				) : null}
			</div>
			<Popover
				isOpen={open}
				showTop={verticalPosition === 'bottom'}
				classNames="ptr-MapConfigurationControl-content"
			>
				{open ? cloneElement(children) : null}
			</Popover>
		</div>
	);
};

MapConfigurationControl.propTypes = {
	detail: PropTypes.node,
	horizontalPosition: PropTypes.string,
	verticalPosition: PropTypes.string,
	icon: PropTypes.string,
	name: PropTypes.string,
	children: PropTypes.node,
	closeContentOnFocusOut: PropTypes.bool,
	marginBottom: PropTypes.number,
	hide: PropTypes.bool,
};

export default MapConfigurationControl;
