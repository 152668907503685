import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	activeKey: 'regions',
	byKey: {
		regions: {
			key: 'regions',
			data: {
				nameDisplay: 'Regions',
			},
		},
		cityClusters: {
			key: 'cityClusters',
			data: {
				nameDisplay: 'City clusters',
			},
		},
	},
};

/**
 * Update whole visat state
 * @param state {Object}
 * @param data {Object}
 * @return {Object}
 */
const update = (state, data) => {
	return {...state, ...data};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.AREA_TREE_TYPES.SET_ACTIVE_KEY:
			return commonReducers.setActive(state, action);
		case ActionTypes.UNHAB.AREA_TREE_TYPES.UPDATE:
			return update(state, action.data);
		default:
			return state;
	}
};
