import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import {reportViewKey} from '../../../../constants/app';

const mapStateToProps = state => {
	return {
		hide: Select.views.getActiveKey(state) === reportViewKey,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
