export default [
	// Attribute sets for map and analytics/statistics sections
	{
		key: '59b06cf6-278f-4d4b-8de7-c929ba94ebef',
		data: {
			nameDisplay: 'Urban area',
			attributes: [
				// WSF 2016-2023 Built-up (10m)
				'1ae9cb89-ea52-400e-8a8c-4a0cd809fbf7', //Total area of urban extent - Minimum abs.
				'1e01f96a-ca6d-4a57-a849-12f2079a5d20', //Total area of urban extent - Minimum rel.
				'8412a01d-3b7f-4c25-abad-caccff9eb4e0', //Total area of urban extent - Maximum abs.
				'feb42dc0-332d-41ad-b96d-633ff3f52fce', //Total area of urban extent - Maximum rel.
				'03a6772d-c07e-4ac2-a18d-a87ec02889fe', // Total area of urban extent - Absolute (timeserie)

				// WSF Evolution 1985-2023 - Built-up (10m)
				'a3f664d8-3fa0-47cd-a0a6-81d4df001c7e', // Total area of urban extent - Minimum abs
				'e9cad7d0-15f0-4439-99a3-e07a808734d0', // Total area of urban extent - Minimum rel
				'72129a88-cd36-45a5-b811-a4ff23b38223', // Total area of urban extent - Max abs
				'870b2857-176a-4471-82a8-5ab909d44b72', // Total area of urban extent - Max rel
				'73e4f64a-47c1-4e8c-9dc6-1687fe3e9eb3', // Total area of urban extent - Absolute (timeserie)

				// WSF Evolution 1985-2015 - Built-up (30m)
				'2f6aa6e8-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum absolute
				'2f6aa60c-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum relative
				'2f6aaf4e-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum absolute
				'2f6aac2e-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum relative,
				'08a819f3-d71b-4753-8929-65f67107dd22', // Total area of urban extent - Absolute (timeserie)

				// WSF 2019 - Built-up (10m)
				'be396866-7981-4c2c-a90a-f9b23ce9e158', // Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)] absolute
				'b0c2f72d-3977-46a9-bad4-983e7af73e2e', // Total area of urban extent - absolute [sqkm] &  relative [per unit area (%)] relative

				// GHS Built-up
				'2f6aa508-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum absolute
				'2f6aa260-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum relative
				'2f6aa8be-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum absolute
				'2f6aa7ce-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum relative
				'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f', // Total area of urban extent - Absolute (timeserie)
			],
			indicatorsForList: ['totalIncrease_urbanAreaEvolution', 'averageIncrease_urbanAreaEvolution', 'minIncrease_urbanAreaEvolution', 'maxIncrease_urbanAreaEvolution', 'yearlyIncrease_urbanAreaEvolution'],
		},
	},
	{
		key: '4c9aa8c4-3d5b-4fd2-acb7-38026e074d91',
		data: {
			nameDisplay: 'Urbanization area evolution',
			attributes: [
				// WSF 2016-2023 - Built-up (10m)
				'5e9898f4-01b2-48e7-a945-9afc664a3cae', //Total increase abs.
				'17843dcd-060c-4c44-83e4-6380ffe40215', //Total increase rel.
				'0eaac7cf-3e6d-4263-b8ac-c95649c9e977', //Average increase abs.
				'840f8957-c450-4cb5-8e2e-2c88ee6ff4a4', //Average increase rel.
				'f5f83add-0c3f-4d4b-acae-28cf0a538a3b', //Min. increase abs.
				'533a1634-b742-4897-9d11-4f11be8dc350', //Min. increase rel.
				'4889a1c5-f6d9-403c-910e-c669d27a6f00', //Max. increase abs.
				'0e998d61-74da-46d4-886b-d30e04d15934', //Max. increase rel.
				'a8558a05-ff96-4a50-aa7f-8187cce59900', // Urban area evolution - Absolute (time series)
				'43a63903-21e3-4b6b-9b09-a4dc2df4402c', // Urban area evolution - Relative (time series)

				// WSF Evolution 1985-2023 - Built-up (10m)
				'882e8fe1-c758-42c1-9344-c60214c699c2', //Total increase
				'6cd70af5-8db3-4a78-8f01-8ccc99e17f5a', //Total increase absolute
				'0a96537a-3da1-4713-96cd-12d9efc1a7ba', //Average increase
				'7938c69d-366f-4642-aea5-a15bc91977de', //Average increase Abs.
				'fc4d2120-a0da-4f72-a718-f1a68bda6681', //Min. increase
				'4e25db77-aaa0-4e8e-9d07-c435199fbe6b', //Min. increase Abs.
				'033ea81e-0660-4234-9d87-47ba1b689910', //Max. increase
				'52761164-26aa-46c6-ad64-7d9e8560025d', //Max. increase Abs.
				'99e44729-2e5b-4a5e-b885-27a90dbf9e4a', // Urban area evolution - Absolute (time series)
				'af18f499-e699-40d7-81d7-00cb4ce0f131', // Urban area evolution - Relative (time series)

				// WSF Evolution 1985-2015 - Built-up (30m)
				'79d763c6-7d3f-11ee-b962-0242ac120002', // Total increase absolute
				'79d7629a-7d3f-11ee-b962-0242ac120002', // Total increase relative
				'79d76862-7d3f-11ee-b962-0242ac120002', // Average increase absolute
				'79d7674a-7d3f-11ee-b962-0242ac120002', // Average increase relative
				'79d77064-7d3f-11ee-b962-0242ac120002', // Minimal increase absolute
				'79d76f56-7d3f-11ee-b962-0242ac120002', // Minimal increase relative
				'79d777f8-7d3f-11ee-b962-0242ac120002', // Maximal increase absolute
				'79d773b6-7d3f-11ee-b962-0242ac120002', // Maximal increase relative
				'3f640746-34c7-4225-b408-c8d77cd21bb0', // Urban area evolution - Absolute (time series)
				'91e8179b-0598-488c-a214-5fa53a03d956', // Urban area evolution - Relative (time series)

				// GHSL 1975-2030 - Built-up  (100m)
				'79d7615a-7d3f-11ee-b962-0242ac120002', // Total increase absolute
				'79d75e58-7d3f-11ee-b962-0242ac120002', // Total increase relative
				'79d7661e-7d3f-11ee-b962-0242ac120002', // Average increase absolute
				'79d764f2-7d3f-11ee-b962-0242ac120002', // Average increase relative
				'79d76e3e-7d3f-11ee-b962-0242ac120002', // Minimal increase absolute
				'79d76d08-7d3f-11ee-b962-0242ac120002', // Minimal increase relative
				'79d77294-7d3f-11ee-b962-0242ac120002', // Maximal increase absolute
				'79d77190-7d3f-11ee-b962-0242ac120002', // Maximal increase relative
				'8f77c076-ec70-4444-8ebb-1c7a757650f9', // Urban area evolution - Absolute (time series)
				'80431bc7-fc92-444d-8748-889fc1b4c5c9', // Urban area evolution - Relative (time series)
			],
			indicatorsForList: ['totalAreaOfUrbanExtentMin', 'totalAreaOfUrbanExtentMax', 'totalAreaOfUrbanExtent'],
		},
	},
	{
		key: '1c2eadd3-abc4-4bc8-b58b-6115e86b5894',
		data: {
			nameDisplay: 'Population',
			attributes: [
				// WSF 2016-2023 - Population (10m)
				'b626dff7-2382-403f-9907-87c9b7a54e3c',
				'8281c4e7-3fb0-4024-afae-ea476de33a73',
				'c88e464a-5dbc-4c87-9aa8-b619abfc6d58',
				'66b88a05-8b68-4afb-a8b0-1cb5e9f635eb',
				'5b9897d2-6a55-4010-b4db-c172006188b2', // WSF 2016-2023 - Population (10m) - absolute - time series

				// GHSL 1975-2030 - Population (100m)
				'00eb991b-167c-4b2e-a7c9-86e5725374e7', // GHSL Total population - Minimum, absolute
				'c3914386-8047-4526-93dd-ad6b7497f8c4', // GHSL Total population - Minimum, relative
				'0440450f-2e73-49a0-abdb-6f147515d998', // GHSL Total population - Maximum, absolute
				'48b6e7c7-b386-43ae-8172-2395df2900e6', // GHSL Total population - Maximum, relative
			],
			indicatorsForList: ['totalPopulationMin', 'totalPopulationMax', 'totalPopulation']
		},
	},
	{
		key: '731cdec7-27cd-483e-895f-4eb6d3bf312f',
		data: {
			nameDisplay: 'Population evolution',
			attributes: [
				// WSF 2016-2023 - Population (10m)
				'02d27e1c-44cd-4a0a-8834-8be215e8f44e', //'Total increase abs.',
				'bab2ea5b-50f8-400e-a725-ef6ffb4eef9c', //'Total increase',
				'1225d913-6aff-4674-a8ac-2e0240daf11d', //'Average increase abs.',
				'7341e0a4-a9da-4bce-a516-62d8bd7abc87', //'Average increase',
				'056c47e1-ae4e-4933-9a23-286088590a35', //'Min. increase abs.',
				'695cd019-df5e-4cb8-b8b5-e841fbaa49e4', //'Min. increase',
				'6b882ebf-cc81-4ce4-a788-eb574a3e359d', //'Max. increase abs.',
				'2951954d-cacf-470a-96a5-41bb5fd8bdad', //'Max. increase',
				'34d478e3-c42c-4893-9330-1e961a93d667', //'Absolute increase (time series)',
				'de413f2a-02a3-4ec5-93a9-348d6ef71fc0', //'Relative increase (time series)',

				// GHSL 1975-2030 - Population (100m)
				'ab4fe885-65db-4843-b43b-3c16693a9ac7', // Population evolution total, absolute
				'07c38b31-9fdb-463e-b917-dbbafaa01f7a', // Population evolution total, relative
				'cbd8cc55-5a1c-4492-892b-86e87ed63872', // Population evolution average, absolute
				'ff258f32-5a90-4b51-a760-17a6d6e2b704', // Population evolution average, relative
				'6da76d87-977e-4b50-9bfa-3190c28f8a20', // Population evolution minimum, absolute
				'e1e76504-3a30-48fb-936b-1830557ad812', // Population evolution minimum, relative
				'08630acd-7ecf-4e90-bcaf-b0af90ca52e4', // Population evolution maximum, absolute
				'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a', // Population evolution maximum, relative
				'a1e05dde-4a6b-46f0-a233-f6db97f9b386', // Absolute increase (time series)
				'ba1ae30f-df22-4914-8e28-57ce2a88c1cc', // Relative increase (time series)

				// WSF 2019 next gen
				'76b858f6-a289-4fa2-916a-7fb0bbe13f25', // Total Population (WSF 2019 next gen)
			],
			indicatorsForList: ['totalIncrease_populationEvolution', 'averageIncrease_populationEvolution', 'minIncrease_populationEvolution', 'maxIncrease_populationEvolution', 'yearlyIncrease_populationEvolution'],
		},
	},
	{
		key: '762f0ade-9663-4287-8a5e-8c0e10c89a49',
		data: {
			nameDisplay: 'SDG Indicators',
			attributes: [
				// WSF 2016-2023 Built-up (10m)
				'61d2b450-9546-4e68-8bf6-d09603dbaea2', // Land consumption rate
				'f950af79-83be-42b7-8174-12f9353cb642', // Land consumption rate (time series)

				// WSF 2016-2023 Population (10m)
				'de759d8f-bb00-441a-a06f-0a060c855637', // Population growth rate
				'0a17814e-18cc-46e3-ac26-f7e97465d3a1', // Population growth rate (time series)

				// Combined WSF 2016-2023 (WSF 2016-2023 + WSF 2016-2023 Population)
				'0432a5e6-f169-45c1-bb6a-c7407c41ea45', // Land Consumption Rate/Population Growth Rate
				'd5e2a536-b344-4bc6-9bc8-d509801d82f9', // Land Consumption Rate/Population Growth Rate (time series)
				'eeb7c339-67f1-4c4c-a9a3-b1e89610ac83', // Land Consumption per capita
				'f27dc482-2112-4ebb-8a1f-ab3e466d4a35', // Land Consumption per capita (time series)

				// WSF Evolution 1985-2023 - Built-up (10m)
				'73e1d399-0f65-46ea-b8d4-fc91b715cd21', // Land consumption rate
				'ee76613c-362b-43f0-b8fe-71560e7ac094', // Land consumption rate (time series)

				//TODO WSF 2019 - Population (10m)

				// Combined WSF 2019 - Built-up + Population (10m)
				'86fc0cb4-cd4d-4286-bd12-68d4db1969c5', // Land Consumption per capita

				// WSF Evolution 1985-2015 - Built-up (30m)
				'ca15b319-d332-49ca-870c-b9240bd340a2', // Land consumption rate
				'58db1745-da16-420a-bb48-7aa5c654b279', // Land consumption rate (time series)

				// GHSL 1975-2030 - Built-up  (100m)
				'c09bac02-f276-4ea4-a5f6-e090a8313454', // Land consumption rate
				'42154c13-4360-4de1-ae1e-7a0ceb5162f4', // Land consumption rate (time series)

				// GHSL 1975-2030 - Population (100m)
				'fedcb00a-92dc-4a03-acf0-a63040373b9f', // Population Growth Rate
				'338aa0b6-8ec4-4ad3-ab05-4e917bf8cee7', // Population Growth Rate (time series)

				// Combined GHSL 1975-2030 - Built-up + Population (100m)
				'3483d7cc-1cde-4a98-b09a-7dddc0ee16f4', // Land Consumption Rate/Population Growth Rate
				'a260ff12-fd67-444f-8b7a-2a6d44f67cec', // Land Consumption Rate/Population Growth Rate (time series)
				'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', // Land Consumption Per Capita (GHS)
				'c1f287e2-a30f-465b-a384-1e283471aca2', // Land Consumption Per Capita (GHS) (time series)
			],
			indicatorsForList: ['landConsumptionRate', 'populationGrowthRate', 'landConsumptionRate_populationGrowthRate','landConsumptionPerCapita'],
		},
	},
];
