import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';

import './style.scss';

const ControlAllSections = ({openAll, setOpenAll}) => {
	return (
		<div className="unhab-ControlAllSections" onClick={setOpenAll}>
			<div className="unhab-ControlAllSections-divider" />
			<div className="unhab-ControlAllSections-control">
				<Icon icon={openAll ? 'ri-chevron-up' : 'ri-chevron-down'} />
			</div>
		</div>
	);
};

ControlAllSections.propTypes = {
	openAll: PropTypes.bool,
	setOpenAll: PropTypes.func,
};

export default ControlAllSections;
