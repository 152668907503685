import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import {cityClustersAreaTreeKey} from '../../../constants/app';
const mapStateToProps = state => {
	return {
		categories: Select.unhab.tags.getCategories(state),
		activeTagKeys: Select.tags.getActiveKeys(state),
		cityClustersActive:
			Select.unhab.areaTreeTypes.getActiveKey(state) ===
			cityClustersAreaTreeKey,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSave: (tagKeys, cityClustersActive) => {
			dispatch(Action.unhab.tags.setActiveKeys(tagKeys));
			dispatch(Action.unhab.clearMapLayers(cityClustersActive));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
