import ActionTypes from '../../../constants/ActionTypes';
import layersActions from '../layers/actions';
import cityClusterMethods from '../../../data/cityClusterMethods';

function add() {
	return dispatch => {
		dispatch(actionAdd(cityClusterMethods));
	};
}

function setActiveKeys(keys) {
	return dispatch => {
		dispatch(actionSetActiveKeys(keys));
		dispatch(layersActions.handleLayersOnDatasetChange());
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.ADD,
		data,
	};
};

const actionSetActiveKeys = keys => {
	return {
		type: ActionTypes.UNHAB.CITY_CLUSTERS_METHODS.SET_ACTIVE_KEYS,
		keys,
	};
};

export default {
	add,
	setActiveKeys,
};
