import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import presentation from './presentation';
import backgroundLayers from '../../../../data/layers/backgroundLayers';

const mapStateToProps = (state, ownProps) => {
	const map = Select.maps.getMapByKey(state, ownProps.mapKey);

	return {
		activeLayer: map?.data?.backgroundLayer,
		layers: Object.values(backgroundLayers).map(layer => {
			return {
				key: layer.key,
				data: {
					nameDisplay: layer.name,
					thumbnail: layer.thumbnail,
				},
			};
		}),
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSelect: layerKey => {
			dispatch(
				Action.maps.setMapBackgroundLayer(
					ownProps.mapKey,
					backgroundLayers[layerKey],
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
