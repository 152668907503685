// import PropTypes from 'prop-types';
import './style.scss';
import BenchmarkSelectedFeatureLabel from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedFeatureControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedFeatureControl';
import BenchmarkSelectedDatasetControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedDatasetControl';
import {
	cityClustersAreaTreeKey,
	defaultPeriod,
} from '../../../../../constants/app';
import PeriodSelector from '../../../../common/PeriodSelector';
import PropTypes from 'prop-types';
import BenchmarkSelectedUDTFeatureLabel from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedUDTFeatureControl/BenchmarkSelectedFeatureLabel';
import BenchmarkSelectedUDTFeatureControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedUDTFeatureControl';
import BenchmarkSelectedMethodControl from '../../../Benchmark/BenchmarkSidePanel/BenchmarkSelectedMethodControl';

const ReportSelections = ({activeAreaTreeKey}) => {
	return (
		<div className="unhab-ReportSelections">
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">Areas:</div>
				<div className="unhab-ReportSelections-item">
					{activeAreaTreeKey === cityClustersAreaTreeKey ? (
						<BenchmarkSelectedUDTFeatureControl
							FeatureLabel={BenchmarkSelectedUDTFeatureLabel}
							simpleList
						/>
					) : (
						<BenchmarkSelectedFeatureControl
							FeatureLabel={BenchmarkSelectedFeatureLabel}
							simpleList
						/>
					)}
				</div>
			</div>
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">
					{activeAreaTreeKey === cityClustersAreaTreeKey
						? 'Methods:'
						: 'Datasets:'}
				</div>
				<div className="unhab-ReportSelections-item">
					{activeAreaTreeKey === cityClustersAreaTreeKey ? (
						<BenchmarkSelectedMethodControl simpleList />
					) : (
						<BenchmarkSelectedDatasetControl simpleList />
					)}
				</div>
			</div>
			<div className="unhab-ReportSelections-row">
				<div className="unhab-ReportSelections-name">Period:</div>
				<div className="unhab-ReportSelections-item">
					<PeriodSelector defaultPeriod={defaultPeriod} simpleView />
				</div>
			</div>
		</div>
	);
};

ReportSelections.propTypes = {
	activeAreaTreeKey: PropTypes.string,
};

export default ReportSelections;
