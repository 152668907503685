import {createSelector} from 'reselect';
import {createCachedSelector} from 're-reselect';
import {cloneDeep as _cloneDeep, compact as _compact} from 'lodash';
import {
	Select,
	createRecomputeSelector,
	createRecomputeObserver,
} from '@gisatcz/ptr-state';
import {stateManagement} from '@gisatcz/ptr-utils';
import layerUtils from '../../../utils/layers';
import ghsDatasetLayers, {
	ghsBuiltUpLayerKeys,
	ghsPopulationLayerKeys,
} from '../../../data/layers/ghsDatasetLayers';
import wsfDatasetLayers, {
	wsfBuiltUpLayerKeys,
	wsfPopulationLayerKeys,
} from '../../../data/layers/wsfDatasetLayers';
import outlinesLayers from '../../../data/layers/outlinesLayers';
import limassolAreas from '../../../data/layers/limassolAreas';
import indicatorLayers from '../../../data/layers/indicatorLayers';
import backgroundLayers from '../../../data/layers/backgroundLayers';
import {
	unhabIndicatorLayersRadioGroupKey,
	statisticsGroupsAttributeSets,
} from '../../../constants/app';
import featuresSelectors from '../../xCubeFeatures/selectors';
import datasetsSelectors from '../datasets/selectors';
import providersSelectors from '../providers/selectors';
import unhabSelectors from '../selectors';
import datasets from '../../../data/datasets';
import {getOriginalLayerKey} from './helpers';

const getAvailableOutlinesLayers = createSelector(
	[
		() => {
			return outlinesLayers;
		},
	],
	outlinesLayers => {
		return Object.values(outlinesLayers) || null;
	},
);

const getIndicatorLayersByProviders = createSelector(
	[datasetsSelectors.getAllAsObject, providersSelectors.getAll],
	(datasets, providers) => {
		return providers.map(provider => {
			return {
				key: provider.key,
				nameDisplay: provider.nameDisplay,
				data: {
					datasets: provider.data.datasets.map(
						datasetKey => datasets[datasetKey],
					),
				},
			};
		});
	},
);

const getIndicatorLayersByProvidersFilteredByActiveTags = createSelector(
	[
		getIndicatorLayersByProviders,
		Select.tags.getActiveKeys,
		Select.attributes.getAllAsObject,
		(state, filterEmpty) => filterEmpty,
		(state, filterEmpty, activeTags) => activeTags,
	],
	(
		layersByProviders,
		activeKeys,
		attributes,
		filterEmpty = false,
		customActiveTags,
	) => {
		const withFilteredAttributes = filterIndicatorLayersByTagKeys(
			layersByProviders,
			customActiveTags || activeKeys,
			attributes,
		);

		if (filterEmpty === true) {
			return filterEmptyItemsOfIndicatorLayers(withFilteredAttributes);
		} else {
			return withFilteredAttributes;
		}
	},
);

const getAvailableIndicatorLayers = createSelector(
	[
		() => {
			return indicatorLayers;
		},
	],
	indicatorLayers => {
		return Object.values(indicatorLayers) || null;
	},
);

const getAvailableDatasetLayers = createSelector(
	[() => wsfDatasetLayers, () => ghsDatasetLayers],
	(wsfLayers, ghsLayers) => {
		return Object.values({...wsfLayers, ...ghsLayers}) || null;
	},
);

/**
 * Get ghs dataset layers
 * @param {Object} state
 * @param {bool} filterByTags
 */
const getGhsDatasetLayers = createSelector(
	[
		() => {
			return ghsDatasetLayers;
		},
		Select.tags.getActiveKeys,
		(state, filterByTags) => filterByTags,
	],
	(ghsDatasetLayers, activeTagKeys, filterByTags) => {
		const ghsLayers = Object.values(ghsDatasetLayers) || [];
		if (filterByTags === true) {
			return ghsLayers.filter(l => {
				return activeTagKeys.every(tagKey => l.tags.includes(tagKey));
			});
		} else {
			return ghsLayers;
		}
	},
);

/**
 * Get ghs BuiltUp dataset layers
 * @param {Object} state
 */
const getGhsBuiltUpDatasetLayers = createSelector(
	[getGhsDatasetLayers],
	ghsDatasetLayers => {
		return ghsDatasetLayers.filter(l => ghsBuiltUpLayerKeys.includes(l.key));
	},
);
/**
 * Get ghs Population dataset layers
 * @param {Object} state
 */
const getGhsPopulationDatasetLayers = createSelector(
	[getGhsDatasetLayers],
	ghsDatasetLayers => {
		return ghsDatasetLayers.filter(l => ghsPopulationLayerKeys.includes(l.key));
	},
);

/**
 * Get wsf dataset layers
 * @param {Object} state
 * @param {bool} filterByTags
 */
const getWsfDatasetLayers = createSelector(
	[
		() => {
			return wsfDatasetLayers;
		},
		Select.tags.getActiveKeys,
		Select.periods.getActive,
		(state, filterByTags) => filterByTags,
	],
	(wsfDatasetLayers, activeTagKeys, activePeriod, filterByTags) => {
		const wsfLayers = Object.values(wsfDatasetLayers) || [];
		const layers = wsfLayers.map(layer => {
			if (layer.dependingOnActivePeriod) {
				return updateLayerStyleWithActivePeriod(
					layer,
					activePeriod?.data?.period,
				);
			} else {
				return layer;
			}
		});

		if (filterByTags === true) {
			return layers.filter(l => {
				return activeTagKeys.every(tagKey => l.tags?.includes(tagKey));
			});
		} else {
			return layers;
		}
	},
);

/**
 * Get WSF BuiltUp dataset layers
 * @param {Object} state
 */
const getWsfPopulationDatasetLayers = createSelector(
	[getWsfDatasetLayers],
	wsfDatasetLayers => {
		return wsfDatasetLayers.filter(l => wsfPopulationLayerKeys.includes(l.key));
	},
);

/**
 * Get WSF population dataset layers
 * @param {Object} state
 */
const getWsfBuiltUpDatasetLayers = createSelector(
	[getWsfDatasetLayers],
	wsfDatasetLayers => {
		return wsfDatasetLayers.filter(l => wsfBuiltUpLayerKeys.includes(l.key));
	},
);

/**
 * Get WSF dataset layers which are not in population or in Built Up
 * @param {Object} state
 */
const getWsfRestDatasetLayers = createSelector(
	[getWsfDatasetLayers],
	wsfDatasetLayers => {
		return wsfDatasetLayers.filter(
			l =>
				!wsfBuiltUpLayerKeys.includes(l.key) &&
				!wsfPopulationLayerKeys.includes(l.key),
		);
	},
);

/**
 * Get all dataset layers
 * @param {Object} state
 * @param {bool} filterByTags
 */
const getDatasetLayers = createSelector(
	[getWsfDatasetLayers, getGhsDatasetLayers],
	(wsfDatasetLayers, ghsDatasetLayers) => {
		return [...ghsDatasetLayers, ...wsfDatasetLayers];
	},
);

const getIndicatorLayerByLayerKey = createSelector(
	[
		getAvailableIndicatorLayers,
		layerKey => layerKey,
		(layerKey, mapKey) => mapKey,
	],
	(availableIndicatorLayers, layerKey, mapKey) => {
		let layer =
			availableIndicatorLayers.find(layer => layer.key === layerKey) || null;

		if (!layer) {
			// fallback if null, then check layers by style key because of SDG layers
			layer =
				availableIndicatorLayers.find(
					layer =>
						layer?.options?.style?.rules?.[0]?.styles?.[1].attributeKey ===
						layerKey,
				) || null;
		}
		const activeSelectionKey = unhabSelectors.getSelectionKeyByMapKey(mapKey);

		return layerUtils.getVectorLayerDefinitionWithSelection(
			layer,
			activeSelectionKey,
		);
	},
);

const getDatasetLayerByLayerKey = createSelector(
	[getAvailableDatasetLayers, layerKey => layerKey],
	(availableDatasetLayers, layerKey) => {
		return availableDatasetLayers.find(layer => layer.key === layerKey) || null;
	},
);

const getAvailableXCubeAreas = createSelector(
	[featuresSelectors.getAll],
	features => {
		if (features) {
			return [
				{...limassolAreas, options: {...limassolAreas.options, features}},
			];
		} else {
			return [limassolAreas];
		}
	},
);

const getAvailableLayersByAttributeKeys = createSelector(
	[attributes => attributes, getAvailableIndicatorLayers],
	(attributes, indicatorLayers) => {
		if (indicatorLayers && attributes) {
			const indicatorLayersByAttributSetKey = [];
			indicatorLayers.forEach(layer => {
				if (attributes?.includes(layer?.options?.attributes?.relative)) {
					indicatorLayersByAttributSetKey.push(layer);
				}
			});
			return indicatorLayersByAttributSetKey;
		} else {
			return null;
		}
	},
);

const getAttributeSetParentByAttributeKey = createCachedSelector(
	[Select.attributeSets.getAllAsObject, (state, attributeKey) => attributeKey],
	(attributeSets, attributeKey) => {
		statisticsGroupsAttributeSets;
		const parentAttributeSetKey = [...statisticsGroupsAttributeSets].find(
			atributeSetKey => {
				return attributeSets[atributeSetKey].data.attributes.includes(
					attributeKey,
				);
			},
		);
		return attributeSets[parentAttributeSetKey];
	},
)((state, attributeKey) => {
	return attributeKey;
});

const getAttributeKeyObserver = createRecomputeObserver((state, key) => {
	return Select.attributes.getByKey(state, key);
});

const getLayerName = createCachedSelector(
	[layerData => layerData],
	layerData => {
		const attributeKey = layerData?.options?.attributes?.relative;
		if (attributeKey) {
			const attribute = getAttributeKeyObserver(attributeKey);
			const dataset = datasetsSelectors.getByAttributeKeyObserver(attributeKey);
			const layerName = `${attribute?.data?.nameDisplay}${attribute?.data?.mapLayerPeriod ? ` [${attribute?.data?.mapLayerPeriod}]` : ''}`;
			return [layerName, dataset?.data?.shortName];
		} else {
			return (
				layerData?.metadata?.layerTemplate?.data?.nameDisplay ||
				layerData?.nameDisplay
			);
		}
	},
)(layerData => {
	return layerData?.key;
});

const getMapLayersStateEnhancedObserver = createRecomputeObserver(
	(state, mapKey) => {
		return getMapLayersStateEnhanced(state, mapKey);
	},
);

const getMapActiveLayerNames = createRecomputeSelector(mapKey => {
	const activeLayers = getMapLayersStateEnhancedObserver(mapKey);
	const layerNames = activeLayers?.map(getLayerName);
	return layerNames?.length ? _compact(layerNames) : null;
});

const getIndicatorLayerByKey = createRecomputeSelector(indicatorLayerKey => {
	return indicatorLayers[indicatorLayerKey];
});

const getActiveIndicatorLayerByMapKey = createSelector(
	[Select.maps.getLayersStateByMapKey],
	layers => {
		const layer = layers?.find(layer => {
			return layer?.radioGroup === unhabIndicatorLayersRadioGroupKey;
		});
		return layer ? {...layer} : null;
	},
);
const getAttributeByKeyObserver = createRecomputeObserver(
	Select.attributes.getByKey,
);

const getRelativeAttributeByIndicatorLayerKey = createRecomputeSelector(
	indicatorLayerKey => {
		const indicatorLayer = getIndicatorLayerByKey(indicatorLayerKey);
		const relativeAttributeKey = indicatorLayer?.options?.attributes?.relative;
		return getAttributeByKeyObserver(relativeAttributeKey);
	},
);

const getAbsoluteAttributeByIndicatorLayerKey = createRecomputeSelector(
	indicatorLayerKey => {
		const indicatorLayer = getIndicatorLayerByKey(indicatorLayerKey);
		const absoluteAttributeKey = indicatorLayer?.options?.attributes?.absolute;
		return getAttributeByKeyObserver(absoluteAttributeKey);
	},
);

const getLayerByLayerTemplateOrKey = createSelector(
	[
		Select.maps.getLayersStateByMapKey,
		(state, mapKey, layerKey) => layerKey,
		(state, mapKey, layerKey, layerTemplateKey) => layerTemplateKey,
	],
	(layers, layerKey, layerTemplateKey) => {
		const layer = layers?.find(layer => {
			return (
				(layer?.layerTemplateKey &&
					layerTemplateKey &&
					layer?.layerTemplateKey === layerTemplateKey) ||
				(layer?.key && layerKey && layer?.key === layerKey)
			);
		});

		return layer || null;
	},
);

const getLayerOpacity = createSelector(
	[getLayerByLayerTemplateOrKey],
	layer => {
		return !layer?.opacity && layer?.opacity !== 0 ? 1 : layer?.opacity;
	},
);

const isLayerLegendHidden = createSelector(
	[getLayerByLayerTemplateOrKey],
	layer => {
		return layer?.hideLegend;
	},
);

const hasIndicatorLayerJustOutlines = createSelector(
	[getLayerByLayerTemplateOrKey],
	layer => {
		return (
			layer?.options?.style?.rules?.[0]?.styles?.[1]?.attributeClasses?.[0]
				.fillOpacity === 0
		);
	},
);

// enhance map layers state with connected metadata models
const getMapLayersStateEnhanced = createSelector(
	[Select.maps.getMapLayersStateByMapKey, Select.layerTemplates.getAllAsObject],
	(layers, layerTemplates) => {
		if (layers?.length) {
			return layers.map(layer => {
				const layerTemplate =
					layerTemplates?.[layer.layerTemplateKey] ||
					layer?.metadata?.layerTemplate;
				return {
					...layer,
					metadata: {
						layerTemplate,
					},
				};
			});
		} else {
			return null;
		}
	},
);

const getDefaultBackgroundLayerByAppMode = createSelector(
	[state => Select.components.get(state, 'UnhabApp', 'darkMode')],
	darkModeActive => {
		return Object.values(backgroundLayers).find(l =>
			darkModeActive ? l.defaultDark : l.defaultLight,
		);
	},
);

//
// Helpers
//
function filterEmptyItemsOfIndicatorLayers(indicatorLayers = []) {
	let copyIndicatorLayers = _cloneDeep(indicatorLayers);

	copyIndicatorLayers?.forEach(provider => {
		//remove dataset if contain empty attributes
		const datasetsIndexesToRemove = [];
		provider?.data?.datasets.forEach((dataset, i) => {
			if (!dataset.data.attributes || dataset.data.attributes.length === 0) {
				datasetsIndexesToRemove.push(i);
			}
		});

		datasetsIndexesToRemove.forEach(
			(originalDatasetIndexToRemove, datasetIndex) => {
				provider.data.datasets = stateManagement.removeItemByIndex(
					provider.data.datasets,
					originalDatasetIndexToRemove - datasetIndex,
				);
			},
		);
	});
	//remove provider if contain empty datasets
	const providersIndexesToRemove = [];
	copyIndicatorLayers?.forEach((provider, providerIndex) => {
		if (!provider?.data?.datasets || provider.data.datasets.length === 0) {
			providersIndexesToRemove.push(providerIndex);
		}
	});
	providersIndexesToRemove.forEach((originalProviderIndexToRemove, index) => {
		copyIndicatorLayers = stateManagement.removeItemByIndex(
			copyIndicatorLayers,
			originalProviderIndexToRemove - index,
		);
	});
	return copyIndicatorLayers;
}

function filterIndicatorLayersByTagKeys(
	layersByProviders = [],
	tags = [],
	attributes = {},
) {
	const layersByProvidersFilteredByTags = layersByProviders?.map(provider => {
		return {
			...provider,
			data: {
				...provider.data,
				datasets: [
					...provider.data.datasets.map(dataset => {
						return {
							...dataset,
							data: {
								...dataset.data,
								attributes: [
									...dataset.data.attributes
										.filter(attr => {
											const attribute = attributes[attr.key];
											const attributeTags = attribute.data.tags || [];
											const fitActiveTags = tags.every(tagKey =>
												attributeTags.includes(tagKey),
											);
											return (
												attr.mapLayer && attributes[attr.key] && fitActiveTags
											);
										})
										.map(a => a.key),
								],
							},
						};
					}),
				],
			},
		};
	});
	return layersByProvidersFilteredByTags || [];
}

function updateLayerStyleWithActivePeriod(layer, period) {
	if (period) {
		const [start, end] = period.split('/');
		const startYear = Number(start);
		const endYear = Number(end);
		const colorsBasedOnValues = [];
		const layerKey = `${layer.key.split(`_suffix`)?.[0]}`;
		const allColorsBasedOnValues =
			wsfDatasetLayers[layerKey]?.options?.cogBitmapOptions
				?.colorsBasedOnValues;
		allColorsBasedOnValues.forEach(pair => {
			const [year, color] = pair;
			if (year >= startYear && year <= endYear) {
				colorsBasedOnValues.push([year, color]);
			} else if (year < startYear) {
				colorsBasedOnValues.push([year, allColorsBasedOnValues[0][1]]);
			}
		});
		return {
			...layer,
			options: {
				...layer.options,
				cogBitmapOptions: {
					...layer.options.cogBitmapOptions,
					colorsBasedOnValues,
				},
			},
		};
	} else {
		return layer;
	}
}

const getLayersByDatasetLayerKey = createSelector(
	[
		Select.maps.getMapLayersStateByMapKey,
		(state, mapKey, datasetLayerKey) => datasetLayerKey,
	],
	(activeLayers, datasetLayerKey) => {
		if (activeLayers && datasetLayerKey) {
			let datasetLayers = [];

			activeLayers.forEach(item => {
				let dataset = datasets?.find(dataset => {
					return dataset?.key === datasetLayerKey;
				});
				if (
					dataset?.data?.layerKeys?.includes(
						getOriginalLayerKey(item.key, item?.options?.alpha),
					)
				) {
					datasetLayers.push(item);
				}
			});

			return datasetLayers;
		}
	},
);

const getLayerOpacityByDatasetLayerKey = createSelector(
	[getLayersByDatasetLayerKey],
	layer => {
		return !layer?.[0]?.opacity && layer?.[0]?.opacity !== 0
			? 1
			: layer?.[0]?.opacity;
	},
);

const isLayerLegendHiddenByDatasetKey = createSelector(
	[getLayersByDatasetLayerKey],
	layers => {
		return layers?.[0]?.hideLegend;
	},
);

export default {
	getLayerName,
	getAttributeSetParentByAttributeKey,
	getGhsDatasetLayers,
	getWsfDatasetLayers,
	getWsfRestDatasetLayers,
	getWsfPopulationDatasetLayers,
	getWsfBuiltUpDatasetLayers,
	getDatasetLayers,
	getDatasetLayerByLayerKey,
	getIndicatorLayerByLayerKey,
	getAvailableOutlinesLayers,
	getAvailableXCubeAreas,
	getIndicatorLayerByKey,
	getIndicatorLayersByProvidersFilteredByActiveTags,
	getActiveIndicatorLayerByMapKey,
	getAvailableLayersByAttributeKeys,
	getRelativeAttributeByIndicatorLayerKey,
	getAbsoluteAttributeByIndicatorLayerKey,
	getLayerOpacity,
	getMapLayersStateEnhanced,
	getMapActiveLayerNames,
	isLayerLegendHidden,
	getDefaultBackgroundLayerByAppMode,
	getGhsBuiltUpDatasetLayers,
	getGhsPopulationDatasetLayers,
	getLayersByDatasetLayerKey,
	getLayerOpacityByDatasetLayerKey,
	isLayerLegendHiddenByDatasetKey,
	hasIndicatorLayerJustOutlines,
	getLayerByLayerTemplateOrKey,

	updateLayerStyleWithActivePeriod,
};
