import ActionTypes from '../../../constants/ActionTypes';

const set = (key, value) => {
	return {
		type: ActionTypes.UNHAB.PARAMETER_VALUES.SET,
		key,
		value,
	};
};

const remove = key => {
	return {
		type: ActionTypes.UNHAB.PARAMETER_VALUES.REMOVE,
		key,
	};
};

export default {
	set,
	remove,
};
