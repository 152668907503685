import {createCachedSelector} from 're-reselect';
import {Select as CommonSelect, commonSelectors} from '@gisatcz/ptr-state';
import {
	areaComparisonType,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	benchmarkViewKey,
	reportViewKey,
} from '../../../constants/app';
import benchmarkComparisonTypesSelectors from '../benchmarkComparisonTypes/selectors';

const getSubstate = state => state.unhab.features;
const getAllAsObject = state => state.unhab.features.byKey;
const getByKey = commonSelectors.getByKey(getSubstate);
const getByKeys = commonSelectors.getByKeys(getSubstate);

const getSelectedFeatures = createCachedSelector(
	[
		CommonSelect.views.getActiveKey,
		benchmarkComparisonTypesSelectors.getActiveKey,
		CommonSelect.selections.getActive,
		state =>
			CommonSelect.selections.getByKey(state, benchmarkArea1SelectionKey),
		state =>
			CommonSelect.selections.getByKey(state, benchmarkArea2SelectionKey),
		getAllAsObject,
	],
	(
		viewKey,
		benchmarkType,
		activeSelection,
		area1Selection,
		area2Selection,
		allFeatures,
	) => {
		let selectedFeatureKeys = activeSelection?.data?.featureKeysFilter?.keys;
		if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
			const area1Key = area1Selection?.data?.featureKeysFilter?.keys?.[0];
			selectedFeatureKeys = [area1Key];
			if (benchmarkType === areaComparisonType) {
				const area2Key = area2Selection?.data?.featureKeysFilter?.keys?.[0];
				selectedFeatureKeys = [area1Key, area2Key];
			}
		}
		if (selectedFeatureKeys?.length && allFeatures) {
			const features = [];
			selectedFeatureKeys.forEach(key => {
				const feature = allFeatures?.[key];
				if (feature) {
					features.push({...feature, key});
				}
			});

			return features?.length ? features : null;
		} else {
			return null;
		}
	},
)((state, config, key) => key);

export default {
	getSelectedFeatures,
	getByKey,
	getByKeys,
	getAllAsObject,
};
