import Header from '../../common/Header';
import AppContent from '../../common/AppContent';
import Map from '../../common/Map';
import ExplorerSidePanel from './ExplorerSidePanel';

import './style.scss';

const Explorer = () => {
	return (
		<>
			<Header />
			<AppContent>
				<Map />
				<ExplorerSidePanel />
			</AppContent>
		</>
	);
};

Explorer.propTypes = {};

export default Explorer;
