import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';
import {reportViewKey} from '../../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	const mapSetKey = ownProps.mapSetKey;
	return {
		maps: mapSetKey ? Select.maps.getMapSetMapKeys(state, mapSetKey) : null,
		mapMode: Select.components.get(state, 'Maps', 'mode'),
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
		disabled:
			!Select.unhab.hasMapSetSyncedLocation(state, mapSetKey) ||
			Select.components.get(state, 'App', 'horizontalMaps'),
		benchmarkComparisonType:
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state),
		hidden: Select.views.getActiveKey(state) === reportViewKey,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setMapMode: mode => {
			dispatch(Action.components.set('Maps', 'mode', mode));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
