import queryString from 'query-string';
import fetch from 'isomorphic-fetch';
import {distinctColours} from './constants/app';

function request(url, method, query, payload) {
	if (query) {
		url += '?' + queryString.stringify(query);
	}

	return fetch(url, {
		method: method,
		body: payload ? JSON.stringify(payload) : null,
		// headers: {
		// 	'ignore-cache': true,
		// },
	}).then(
		async response => {
			return response.json().then(body => {
				if (body) {
					return body;
				} else {
					throw new Error('no layers returned');
				}
			});
		},
		error => {
			throw error;
		},
	);
}

/**
 * Update the value of a query parameter in a URL.
 *
 * @param {string} url - The original URL with the query parameters.
 * @param {string} key - The key of the query parameter to update.
 * @param {string} value - The new value to set for the specified key.
 * @returns {string} The updated URL with the modified query parameter.
 */
function updateQueryParam(url, key, value) {
	const urlParts = url.split('?');
	if (urlParts.length < 2) {
		// If there are no query parameters, just append the key and value.
		return `${url}?${key}=${value}`;
	}

	const queryString = urlParts[1];
	const queryParameters = queryString.split('&');
	let updatedQueryString = '';
	let updated = false;

	for (const parameter of queryParameters) {
		const [paramKey, paramValue] = parameter.split('=');
		if (paramKey === key) {
			updatedQueryString += `${key}=${value}&`;
			updated = true;
		} else {
			updatedQueryString += `${paramKey}=${paramValue}&`;
		}
	}

	if (!updated) {
		updatedQueryString += `${key}=${value}&`;
	}

	// Remove the trailing '&' and construct the updated URL.
	const updatedURL = `${urlParts[0]}?${updatedQueryString.slice(0, -1)}`;

	return updatedURL;
}

/**
 * Get the value of a query parameter from a URL.
 *
 * @param {string} paramName - The name of the query parameter to retrieve.
 * @param {string} url - The URL containing the query parameters.
 * @returns {string|null} - The value of the query parameter, or null if not found.
 */
function getQueryParamValue(url, paramName) {
	const queryString = url.split('?')[1];

	if (!queryString) {
		return null;
	}

	const params = new URLSearchParams(queryString);

	if (params.has(paramName)) {
		return params.get(paramName);
	}

	return null;
}

/**
 * Get color of selected feature
 * @param featureKey {string}
 * @param selectionData {Object}
 * @returns {string} hex colour code
 */
function getSelectedFeaturePrimaryColor(featureKey, selectionData) {
	const fallbackColor =
		selectionData?.style?.fill ||
		selectionData?.style?.outlineColor ||
		'#aaaaaa';
	const distinctItems = selectionData?.distinctItems;
	if (distinctItems) {
		const colorIndex =
			selectionData?.featureKeysFilter?.keyColourIndexPairs?.[featureKey];
		return distinctColours[colorIndex] || fallbackColor;
	} else {
		return fallbackColor;
	}
}

const extentOfExtents = extents => {
	const count = extents.length;

	const LeftBottomsLon = extents.map(e => e[0]).sort((a, b) => a - b);
	const LeftBottomsLat = extents.map(e => e[1]).sort((a, b) => a - b);

	const RightTopsLon = extents.map(e => e[2]).sort((a, b) => a - b);
	const RightTopsLat = extents.map(e => e[3]).sort((a, b) => a - b);

	return [
		LeftBottomsLon[0],
		LeftBottomsLat[0],
		RightTopsLon[count - 1],
		RightTopsLat[count - 1],
	];
};

const getAdmLevelByKey = admKey =>
	admKey.toString().split('_').length === 2 ? 1 : 0;

/**
 * @param number {number}
 * @param numDigits {number}
 * @returns {string|null}
 */
function getDigitsAfterDecimal(number, numDigits) {
	// Convert the number to a string to easily manipulate it
	let numberString = number.toString();

	// Find the position of the decimal point
	let decimalIndex = numberString.indexOf('.');

	// If there is no decimal point, return null
	if (decimalIndex === -1) {
		return null;
	}

	// Extract the digits after the decimal point
	let digitsAfterDecimal = numberString.slice(
		decimalIndex + 1,
		decimalIndex + 1 + numDigits,
	);

	// If there are not enough digits after the decimal point, append '0's to make it the required length
	while (digitsAfterDecimal.length < numDigits) {
		digitsAfterDecimal += '0';
	}

	return digitsAfterDecimal === '00' ? null : digitsAfterDecimal;
}

export default {
	request,
	updateQueryParam,
	getQueryParamValue,
	getSelectedFeaturePrimaryColor,
	extentOfExtents,
	getAdmLevelByKey,
	getDigitsAfterDecimal,
};
