import PropTypes from 'prop-types';
import {createElement} from 'react';
import moment from 'moment';
import './style.scss';

/**
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param startDate {string} ISO 8601 date
 * @param endDate {string} ISO 8601 date
 * @returns {JSX.Element|null}
 */
const TimeRangeOverviewChart = ({
	ChartComponent,
	chartComponentSettings,
	data,
	metadata,
	startDate,
	endDate,
}) => {
	return (
		<div className="ptr-TimeRangeOverviewChart">
			{createElement(ChartComponent, {
				data,
				metadata,
				customSettings: {
					...chartComponentSettings,
					xScale: {
						...metadata.settings.xScale,
						min: moment(startDate).format(),
						max: moment(endDate).format(),
					},
				},
			})}
		</div>
	);
};

TimeRangeOverviewChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default TimeRangeOverviewChart;
