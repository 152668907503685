import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		maps: Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey),
		unlocked: !Select.unhab.hasMapSetSyncedLocation(state, ownProps.mapSetKey),
		condensedControls: Select.components.get(
			state,
			'UnhabApp',
			'condensedControls',
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		lockMaps: lock => {
			// toggle sync view
			dispatch(Action.unhab.setMapSetSync(ownProps.mapSetKey, lock));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
