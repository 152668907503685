import PropTypes from 'prop-types';
import Tag from '../Tag';
import './style.scss';

const DatasetTags = ({tagKeys}) => {
	return tagKeys?.length > 0 ? (
		<div className="unhab-DatasetTags">
			{tagKeys?.map(key => (
				<Tag tagKey={key} key={key} small />
			))}
		</div>
	) : null;
};

DatasetTags.propTypes = {
	tagKeys: PropTypes.array,
};

export default DatasetTags;
