/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default values only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	featuresRepo:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/features',
	admStructureUrl:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/structure.json',
	cityClustersFeaturesRepo:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/UDT_Statistics_v1',
	scatterPlotDataUrl:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/scatterPlotData.json',
	grafanaFaroServiceUrl:
		'https://faro-collector-prod-us-central-0.grafana.net/collect/7239a8f5d48c7fdfea5afe233549504c',
};
