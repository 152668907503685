import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormItem from '../Form/FormItem';
import FormItemLabel from '../Form/FormItemLabel';
import Select from '../Select';

import './style.scss';

const FormatGroupLabel = data => {
	const classes = classnames('unhab-FormatGroupLabel', {
		'with-border': data.label !== 'Featured',
	});

	return (
		<div className={classes}>
			<span>{data.label}</span>
		</div>
	);
};

FormatGroupLabel.propTypes = {
	data: PropTypes.object,
};

const AreaPicker = ({
	countries,
	selectedCountry,
	selectedRegion,
	onCountryChange,
	onRegionChange,
	large,
	keysToOmit = [],
	regionsClearable = true,
	regionLabel,
}) => {
	// countries
	const countriesAsArray = Object.values(countries);
	const selectedCountryData = countriesAsArray?.find(
		country => country?.key == selectedCountry?.value,
	);

	const featuredCountryOptions = [];
	const allCountryOptions = [];

	countriesAsArray?.forEach(country => {
		const data = {key: country.key, data: {nameDisplay: country.data.name}};
		if (country.data.featured) {
			featuredCountryOptions.push(data);
		}
		allCountryOptions.push(data);
	});

	let countryGroupedOptions, options;
	if (featuredCountryOptions?.length) {
		countryGroupedOptions = [
			{
				label: 'Featured',
				options: featuredCountryOptions,
			},
			{
				label: 'All',
				options: allCountryOptions,
			},
		];
	} else {
		options = allCountryOptions;
	}

	// regions
	const regionsAsArray =
		(selectedCountryData?.regionsByFeatureKey &&
			Object.values(selectedCountryData?.regionsByFeatureKey)) ||
		[];

	const regionOptions = regionsAsArray
		?.filter(r => !keysToOmit.includes(r.key))
		?.map(region => {
			return {key: region.key, data: {nameDisplay: region.data.name}};
		});

	return (
		<>
			<FormItem>
				<FormItemLabel>Country</FormItemLabel>
				<Select
					large={large}
					portaling
					groupedModels={countryGroupedOptions}
					models={options}
					activeKey={selectedCountry?.value}
					onChange={option => {
						onCountryChange(option);
						onRegionChange(null);
					}}
					menuPosition={'fixed'}
					formatGroupLabel={FormatGroupLabel}
				/>
			</FormItem>
			<FormItem>
				<FormItemLabel>{regionLabel || 'Region'}</FormItemLabel>
				<Select
					isDisabled={!selectedCountry}
					large={large}
					portaling
					models={regionOptions}
					activeKey={selectedRegion?.value || null}
					onChange={option => {
						onRegionChange(option);
					}}
					menuPosition={'fixed'}
					isClearable={regionsClearable}
				/>
			</FormItem>
		</>
	);
};

AreaPicker.propTypes = {
	countries: PropTypes.object,
	keysToOmit: PropTypes.array,
	large: PropTypes.bool,

	selectedCountry: PropTypes.object,
	selectedRegion: PropTypes.object,
	onCountryChange: PropTypes.func,
	onRegionChange: PropTypes.func,
	regionsClearable: PropTypes.bool,
	regionLabel: PropTypes.string,
};

export default AreaPicker;
