import PropTypes from 'prop-types';
import MenuToggleItem from '../../Menu/MenuToggleItem';

const HorizontalMapsControl = ({active, onChange, disabled}) => {
	return (
		<MenuToggleItem
			icon="ri-rows"
			active={active}
			onChange={onChange}
			disabled={disabled}
		>
			Horizontal maps
		</MenuToggleItem>
	);
};

HorizontalMapsControl.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

export default HorizontalMapsControl;
